import React, { useRef, useEffect, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { GaugeChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DatasetComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";
import useWindowDimensions from "../../../utils/getWindowDimensions";

//register required components
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    GaugeChart,
    CanvasRenderer,
    DatasetComponent,
    LegendComponent,
]);

const SemiCircleProgressBar = (props) => {
    const windowWidth = useWindowDimensions().width;

    const chartRef = useRef(null);
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    // this runs every time dataarray and chartRef changes
    useEffect(() => {
        if (!chartRef.current || !chartObject) return;

        let option = {
            series: [
                {
                    type: "gauge",
                    center: ["50%", "50%"],
                    color: ["#12A690"],
                    startAngle: 180,
                    endAngle: 0,
                    min: props.val[1] === 0 ? 1 : 0,
                    max: props.val[1],
                    progress: {
                        show: props.val[2] === "hide" ? false : true,
                        roundCap: true,
                        width: 5,
                        overlap: true,
                    },
                    pointer: {
                        show: false,
                    },
                    axisLine: {
                        show: props.val[2] === "hide" ? false : true,
                        roundCap: true,
                        lineStyle: {
                            width: 5,
                            color: [[1, "#A2A2A2"]],
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },

                    title: {
                        show: false,
                    },
                    detail: {
                        backgroundColor: "#fff",
                        width: 0,
                        lineHeight: 40,
                        height: 20,
                        fontSize: windowWidth < 1024 ? 15 : 28,
                        fontWeight: 800,
                        offsetCenter: [0, "0%"],
                        valueAnimation: true,
                        show: true,
                        formatter: function () {
                            return props.val[1];
                        },
                    },
                    data: [
                        {
                            value: props.val[1] - props.val[0],
                        },
                    ],
                },
            ],
        };

        chartObject.setOption(option);
    }, [chartRef, props.val, windowWidth, chartObject]);

    if (props.val === undefined) return <div></div>;
    return <div ref={chartRef} className="h-full w-full" />;
};

export default SemiCircleProgressBar;
