import React from "react";
import zxcvbn from "zxcvbn";

const PasswordStrengthMeter = (props) => {
    const password = props.password;
    const valid = props.valid;

    const testedResult = valid ? zxcvbn(password) : { score: 0 };

    const createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
                return "Weak";
            case 1:
                return "Weak";
            case 2:
                return "Fair";
            case 3:
                return "Good";
            case 4:
                return "Strong";
            default:
                return "Weak";
        }
    };

    const getColor = (result) => {
        switch (result.score) {
            case 0:
                return "red";
            case 1:
                return "red";
            case 2:
                return "orange";
            case 3:
                return "blue";
            case 4:
                return "green";
            default:
                return "red";
        }
    };

    let bars = [
        <span key={0} style={{ display: "inline-block", width: "25%" }}>
            <hr style={{ border: "1px solid " + getColor(testedResult) }} />
        </span>,
    ];
    for (let i = 1; i < testedResult.score; i++) {
        bars.push(
            <span
                key={i}
                style={
                    i === 0
                        ? { display: "inline-block", width: "25%" }
                        : {
                              paddingLeft: "5px",
                              display: "inline-block",
                              width: "25%",
                          }
                }
            >
                <hr style={{ border: "1px solid " + getColor(testedResult) }} />
            </span>
        );
    }

    if (password.length < 1) {
        return null;
    }
    return (
        <div>
            {bars}
            {bars.length > 0 && (
                <div className="text-center text-semibold">
                    {createPasswordLabel(testedResult)}
                </div>
            )}
        </div>
    );
};

export default PasswordStrengthMeter;
