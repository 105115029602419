import React from "react";
import classnames from "classnames";
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";

const DisplayValue = (props) => {
    const colour = props.colour ? props.colour : "teal-500";
    return (
        <div className="flex flex-col">
            <div className="text-trueGray-400 text-2xs xl:text-xs font-medium">
                {props.title}
            </div>
            <div
                className={classnames(
                    `text-${colour}
                flex flex-row self-center font-medium`
                )}
            >
                {props.value || props.icon ? (
                    <div className="text-sm xl:text-2xl">{props.value}</div>
                ) : (
                    <div className="text-sm xl:text-2xl">0</div>
                )}
                {props.unit && (
                    <div className="text-sm pb-0.5 xl:text-base self-end">
                        {props.unit}
                    </div>
                )}
                {props.icon && (
                    <div className="xl:w-8 xl:h-8 w-6 h-6">
                        {props.icon === "up" ? (
                            <ThumbUpIcon />
                        ) : (
                            <ThumbDownIcon />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DisplayValue;
