import { SelectorIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { useState, useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import classnames from "classnames";

const IprTable = (props) => {
    const columns = useMemo(() => props.titles, [props.titles]);
    const data = useMemo(() => props.data, [props.data]);
    const [clickable, setClickable] = useState(false);

    const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
        useTable(
            {
                columns,
                data,
            },
            useSortBy
        );

    useEffect(() => {
        if (!data || data.length < 1) return;
        setClickable(true);
        // if (data[0].provider) setClickable(true);
        // else setClickable(false);
    }, [data]);

    return (
        <table {...getTableProps()} className="w-full">
            <thead className="text-trueGray-400 xl:text-sm text-2xs bg-coolGray-50">
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="grid grid-cols-4 justify-items-start py-1 lg:py-2"
                    >
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className="border-l-4 w-full border-white flex flex-row px-2"
                            >
                                {column.render("Header")}
                                <span
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="w-4 h-4 inline-block xl:pt-1 ml-2"
                                >
                                    <SelectorIcon />
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody
                {...getTableBodyProps()}
                className="xl:text-sm text-2xs w-full block max-h-60 overflow-y-auto "
            >
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={
                                clickable
                                    ? classnames(
                                          `cursor-pointer hover:text-teal-500 grid grid-cols-4 justify-items-start p-0.5 sm:p-2 border-t items-center`
                                      )
                                    : classnames(
                                          "grid grid-cols-4 justify-items-start sm:p-2 p-0.5 border-t items-center"
                                      )
                            }
                            onClick={() => {
                                props.rowClicked(row.original);
                            }}
                        >
                            {row.cells.map((cell) => {
                                if (cell.column.id === "time") {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {dayjs(cell.value).format(
                                                "DD/MM/YYYY HH:mm A"
                                            )}
                                        </td>
                                    );
                                } else if (cell.column.id === "provider") {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="bg-indigo-100 text-indigo-600 rounded-sm ml-3 px-1"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                } else {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="py-1 pl-3"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
export default IprTable;
