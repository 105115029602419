import React, { useEffect, useState } from "react";
import Button from "./Button";
import ResetConfirm from "./ResetConfirm";

const KeyPad = (props) => {
    const [buttonSequence, setButtonSequence] = useState("");
    const [resetConfirm, setResetConfirm] = useState(false);

    const LENGTH_LIMIT = 28; // 42 - 14 (washtec-button is 14 chars)

    useEffect(() => {
        let command = "";
        buttonSequence.split(" ").map((c, i) => {
            if (i < 10 && c && (command + c).length <= LENGTH_LIMIT) {
                command = command += c + " ";
            }
            return null;
        });
        setButtonSequence(command);
    }, [buttonSequence]);

    return (
        <div className="sm:mx-10 mx-1 mt-1">
            <ResetConfirm
                open={resetConfirm}
                send={props.send}
                setOpen={setResetConfirm}
            />
            <div className="flex flex-row justify-center">
                <div className="grid grid-rows-6">
                    <div className="grid grid-cols-5">
                        <Button
                            text={"1"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "1 "
                                )
                            }
                        />
                        <Button
                            text={"2"}
                            bottomContent={"ABC"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "2 "
                                )
                            }
                        />
                        <Button
                            text={"3"}
                            bottomContent={"DEF"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "3 "
                                )
                            }
                        />
                        <Button
                            text={"OK"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "OK "
                                )
                            }
                        />
                        <div></div>
                    </div>
                    <div className="grid grid-cols-5">
                        <Button
                            text={"4"}
                            bottomContent={"GHI"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "4 "
                                )
                            }
                        />
                        <Button
                            text={"5"}
                            bottomContent={"JKL"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "5 "
                                )
                            }
                        />
                        <Button
                            text={"6"}
                            bottomContent={"MNO"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "6 "
                                )
                            }
                        />
                        <Button
                            text={"START"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) =>
                                        buttonSequence + "START "
                                )
                            }
                        />
                        <div></div>
                    </div>
                    <div className="grid grid-cols-5">
                        <Button
                            text={"7"}
                            bottomContent={"PQR"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "7 "
                                )
                            }
                        />
                        <Button
                            text={"8"}
                            bottomContent={"STU"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "8 "
                                )
                            }
                        />
                        <Button
                            text={"9"}
                            bottomContent={"VWX"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "9 "
                                )
                            }
                        />
                        <Button
                            text={"CANCEL"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) =>
                                        buttonSequence + "CANCEL "
                                )
                            }
                        />
                        <div></div>
                    </div>
                    <div className="grid grid-cols-5">
                        <div></div>
                        <Button
                            text={"0"}
                            bottomContent={"YZ"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "0 "
                                )
                            }
                        />
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="grid grid-cols-5">
                        <div></div>
                        <div></div>
                        <div></div>
                        <Button
                            icon={"ArrowUpIcon"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "UP "
                                )
                            }
                        />
                        <div></div>
                    </div>
                    <div className="grid grid-cols-5">
                        <Button
                            bgColor={"red"}
                            textColor={"white"}
                            text={"RESET"}
                            width={5}
                            height={5}
                            onClick={() => setResetConfirm(true)}
                        />
                        <div></div>
                        <Button
                            icon={"ArrowLeftIcon"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "LEFT "
                                )
                            }
                        />
                        <Button
                            icon={"ArrowDownIcon"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "DOWN "
                                )
                            }
                        />
                        <Button
                            icon={"ArrowRightIcon"}
                            width={5}
                            height={5}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) =>
                                        buttonSequence + "RIGHT "
                                )
                            }
                        />
                    </div>
                </div>
                <div className="ml-5 grid grid-rows-6">
                    <div className="grid grid-cols-2">
                        <Button />
                        <Button />
                    </div>
                    <div className="grid grid-cols-2">
                        <Button />
                        <Button />
                    </div>
                    <div className="grid grid-cols-2">
                        <Button
                            text={"HOME"}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) =>
                                        buttonSequence + "HOME  "
                                )
                            }
                        />
                        <Button />
                    </div>
                    <div className="grid grid-cols-2">
                        <Button />
                        <Button />
                    </div>
                    <div className="grid grid-cols-2">
                        <Button
                            text={"   F1   "}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "F1 "
                                )
                            }
                        />
                        <Button
                            text={"F2"}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "F2 "
                                )
                            }
                        />
                    </div>
                    <div className="grid grid-cols-2">
                        <Button
                            text={"F3"}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "F3 "
                                )
                            }
                        />
                        <Button
                            text={"P"}
                            onClick={() =>
                                setButtonSequence(
                                    (buttonSequence) => buttonSequence + "P "
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row mt-2">
                <div className="grid grid-cols-5 w-full">
                    <div className="col-span-5 border-2 rounded-md m-1 flex flex-col justify-center pl-2">
                        {window.innerWidth > 768 && "Button Sequence: "}
                        {buttonSequence}
                    </div>
                </div>
                <div className="grid grid-cols-2 w-1/2">
                    <Button
                        text={"DELETE"}
                        onClick={() => {
                            setButtonSequence((buttonSequence) =>
                                buttonSequence.substring(
                                    0,
                                    buttonSequence.trim().lastIndexOf(" ")
                                )
                            );
                        }}
                    />
                    <Button
                        disabled={props.disabled || buttonSequence.length < 1}
                        text={"SEND"}
                        onClick={() => {
                            props.send("washtec-button", buttonSequence);
                            setButtonSequence("");
                        }}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row justify-end">
                <div className="w-1/6">
                    <Button
                        disabled={props.loggedIn || props.disabled}
                        text={"LOGIN"}
                        onClick={() => props.setLoginModal(true)}
                    />
                </div>
                <div className="w-1/6">
                    <Button
                        disabled={!props.loggedIn || props.disabled}
                        text={"LOGOUT"}
                        onClick={() => props.setLogoutModal(true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default KeyPad;
