import React, { useState, useEffect } from "react";
import FilterValue from "../../components/Text/FilterValue";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../utils/queryParams";
import DataTable from "../../components/Tables/DataTable";
import Loading from "../Loading";
import AssetListbox from "../../components/Input/AssetListbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCloudRain,
    faCloudShowersHeavy,
    faCloudShowersWater,
    faCloudSun,
    faWind,
} from "@fortawesome/free-solid-svg-icons";
import { faSun } from "@fortawesome/free-regular-svg-icons";

const QUERY_SITE_BY_PERFORMANCE_RATING = gql`
    query getSitePerformanceRating(
        $orgId: Int!
        $monthAgo: timestamptz!
        $sixHrAgo: timestamptz!
    ) {
        organisation_by_pk(id: $orgId) {
            id
            asset_types {
                id
                name
            }
            sites {
                id
                name
                month: performance_ratings_aggregate(
                    where: { time: { _gte: $monthAgo } }
                ) {
                    aggregate {
                        avg {
                            rating
                        }
                    }
                }
                issues_aggregate(where: { status: { _lte: 2 } }) {
                    aggregate {
                        count
                    }
                }
                assets {
                    id
                    description
                    site_id
                    site {
                        id
                        name
                    }
                    organisation_id
                    measurement_points {
                        id
                        measurement_point {
                            id
                            device_type
                            device {
                                device_statuses(
                                    order_by: { time: desc }
                                    limit: 1
                                ) {
                                    id
                                    temperature
                                    relative_humidity
                                }
                            }
                            vac_statistics {
                                id
                                updated
                                current_day_sessions
                                average_day_sessions
                            }
                            wwh_statistics {
                                id
                                updated
                                current_day_sessions
                                average_day_sessions
                            }
                        }
                    }
                    assetsMonthly: performance_aggregate(
                        where: { time: { _gte: $monthAgo } }
                    ) {
                        aggregate {
                            avg {
                                rating
                            }
                            sum {
                                downtime
                            }
                        }
                    }
                    issues_aggregate(where: { status: { _lte: 2 } }) {
                        aggregate {
                            count
                        }
                    }
                    asset_type {
                        name
                    }
                }
                external_climates_aggregate(
                    where: { time: { _lte: $sixHrAgo } }
                ) {
                    aggregate {
                        avg {
                            precipitation_rate
                            wind_speed
                            wind_direction
                        }
                    }
                }
            }
            devices {
                measurement_point {
                    device_type
                }
            }
        }
    }
`;

const Assets = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;

    const siteIdParam = getQueryParameter("siteId");
    const orgIdParam = getQueryParameter("organisationId");
    const assetIdParam = getQueryParameter("assetId");
    const filterParam = getQueryParameter("filter");
    const [selectedSite, setSelectedSite] = useState();

    const now = dayjs();
    const [sixHrAgo] = useState(now.subtract(6, "hours"));
    const [monthAgo] = useState(now.subtract(30, "days"));
    const [hasSessionAssets, setHasSessionAssets] = useState(false);
    const [sitesRatingLow, setSitesRatingLow] = useState([]);
    const [sitesRatingHigh, setSitesRatingHigh] = useState([]);

    const [assetDataWithRatings, setAssetDataWithRatings] = useState([]);
    const [allAssets, setAllAssets] = useState([]);
    const history = useHistory();

    const [selectedAssetType, setSelectedAssetType] = useState(null);

    const [allAssetNames] = useState([
        { id: 1, name: "Car Wash", type: "WASHWATCH" },
        { id: 2, name: "Vac", type: "VAC" },
    ]);

    // const setColumnTitle = (type) => {
    //     if (type === "WASHWATCH" || type === "VAC") {
    //         return "SESSIONS";
    //     }
    //     return "";
    // };

    const [columnTitles, setColumnTitles] = useState([
        { Header: "SITE", accessor: "col1" },
        {
            Header: "SESSIONS",
            accessor: "col3",
        },
        {
            Header: "Weather",
            accessor: "weather",
        },
        {
            Header: "ISSUES",
            accessor: "openIssues",
        },
        {
            Header: "IMPACT",
            accessor: "col4",
        },
        {
            Header: "30 DAY %",
            accessor: "col2",
        },
    ]);

    const [assetNames, setAssetNames] = useState(null);
    const [prevOrgId, setPrevOrgId] = useState(-1);

    const setFilterParam = (title) => {
        history.push(`/assets?filter=${title}&organisationId=${orgIdParam}`);
    };

    //query sites ordered by rating
    const { error: siteByRatingError, data: siteByRatingData } = useQuery(
        QUERY_SITE_BY_PERFORMANCE_RATING,
        {
            variables: {
                monthAgo: monthAgo,
                orgId: props.orgId,
                sixHrAgo: sixHrAgo,
            },
            onError: () => console.log(siteByRatingError),
        }
    );

    useEffect(() => {
        if (!siteByRatingData || prevOrgId === orgIdParam) {
            return;
        }
        setPrevOrgId(orgIdParam);
        setAssetNames(null);
        let assetTypes = [];
        siteByRatingData.organisation_by_pk.devices.map((device) => {
            if (device.measurement_point != null) {
                if (
                    !assetTypes.includes(device.measurement_point.device_type)
                ) {
                    return assetTypes.push(
                        device.measurement_point.device_type
                    );
                }
            }
            return null;
        });

        if (
            assetTypes.includes("WASHWATCH") ||
            assetTypes.includes("VAC")
            // assetTypes.includes("DoorInformer") ||
            // assetTypes.includes("HVAC") ||
            // assetTypes.includes("Celsor") ||
            // assetTypes.includes("TemperatureBeacon")
        ) {
            setHasSessionAssets(true);
            let newArr = [];
            for (let i = 0; i < allAssetNames.length; i++) {
                if (assetTypes.includes(allAssetNames[i].type)) {
                    newArr.push(allAssetNames[i]);
                }
            }
            setAssetNames(newArr);
        } else {
            setHasSessionAssets(false);
            setSelectedAssetType();
        }
    }, [allAssetNames, assetNames, orgIdParam, prevOrgId, siteByRatingData]);

    useEffect(() => {
        if (!siteByRatingData) {
            return;
        }
        let sitesByRating = [];
        let perfectRating = 100.0;
        let assets = [];
        let hasVacOrCarWash = false;

        siteByRatingData.organisation_by_pk.sites.map((site) => {
            let impactTotalBySite = 0;
            let totalSessionsToday = 0;
            let totalSessionAverage = 0;
            let containedAsset = false;

            site.assets.map((asset) => {
                if (
                    !asset.measurement_points[0] ||
                    !asset.measurement_points[0].measurement_point
                ) {
                    return null;
                }

                let mp = asset.measurement_points[0].measurement_point;
                let device = mp.device_type;
                let deviceBool = device === selectedAssetType;
                if (device === "VAC" || device === "WASHWATCH") {
                    hasVacOrCarWash = true;
                }
                if (deviceBool) {
                    containedAsset = true;
                }
                let impactCurrent =
                    asset.assetsMonthly.aggregate.sum.downtime / 60.0;

                impactTotalBySite = impactTotalBySite + impactCurrent;
                let displayVal = "-";
                const today = dayjs().startOf("day");

                if (deviceBool) {
                    switch (selectedAssetType) {
                        case "WASHWATCH":
                            let sessionsTodayWWH =
                                mp.wwh_statistics &&
                                today.isBefore(mp.wwh_statistics.updated)
                                    ? mp.wwh_statistics.current_day_sessions
                                    : 0;
                            totalSessionAverage = mp.wwh_statistics
                                ? mp.wwh_statistics.average_day_sessions
                                : 0;
                            totalSessionsToday = sessionsTodayWWH;

                            displayVal = mp.wwh_statistics
                                ? `${sessionsTodayWWH}/${mp.wwh_statistics.average_day_sessions}`
                                : 0;
                            break;
                        case "VAC":
                            let sessionsTodayVac =
                                mp.vac_statistics &&
                                today.isBefore(mp.vac_statistics.updated)
                                    ? mp.vac_statistics.current_day_sessions
                                    : 0;
                            totalSessionAverage = mp.vac_statistics
                                ? mp.vac_statistics.average_day_sessions
                                : 0;
                            totalSessionsToday = sessionsTodayVac;

                            displayVal = mp.vac_statistics
                                ? `${sessionsTodayVac}/${mp.vac_statistics.average_day_sessions}`
                                : 0;

                            break;
                        // case "DoorInformer":
                        //     currentVal = mp.doorDayCount.aggregate.sum.count1;
                        //     totalSessionsToday =
                        //         totalSessionsToday + currentVal;
                        //     displayVal = currentVal;
                        //     break;
                        // case "HVAC":
                        //     count++;
                        //     if (mp.device.device_statuses[0] != null) {
                        //         currentVal =
                        //             mp.device.device_statuses[0].temperature;
                        //         if (currentVal == null) {
                        //             currentVal = 0;
                        //         }
                        //         displayVal = currentVal;
                        //         totalSessionsToday =
                        //             totalSessionsToday + currentVal;
                        //     } else {
                        //         displayVal = "0";
                        //     }
                        //     displayVal = displayVal + "/";
                        //     if (mp.hvacSetTemp[0] != null) {
                        //         currentVal =
                        //             mp.hvacSetTemp[0].temperature_set_point;
                        //         displayVal = displayVal + currentVal;
                        //         totalSessionAverage =
                        //             totalSessionAverage + currentVal;
                        //     } else {
                        //         displayVal = displayVal + "0";
                        //     }
                        //     break;
                        // case "Celsor":
                        //     count++;
                        //     currentVal =
                        //         mp.celsor_measurement_data[0].temperature;
                        //     totalSessionsToday =
                        //         totalSessionsToday + currentVal;
                        //     displayVal = currentVal;
                        //     break;
                        // case "TemperatureBeacon":
                        //     count++;
                        //     currentVal = mp.beacon_data[0].current_temperature;
                        //     totalSessionsToday =
                        //         totalSessionsToday + currentVal;
                        //     displayVal = currentVal.toFixed(1);
                        //     break;
                        default:
                            break;
                    }
                }

                return assets.push({
                    assetDescription: asset.description,
                    id: asset.id,
                    siteId: asset.site_id,
                    orgId: asset.organisation_id,
                    openIssues: asset.issues_aggregate.aggregate.count,
                    performanceRating: asset.assetsMonthly.aggregate.avg.rating
                        ? parseFloat(
                              asset.assetsMonthly.aggregate.avg.rating * 100
                          ).toFixed(1)
                        : parseFloat(100).toFixed(1),
                    col3: displayVal,
                    col4: asset.assetsMonthly.aggregate.sum.downtime
                        ? parseFloat(
                              asset.assetsMonthly.aggregate.sum.downtime / 60.0
                          ).toFixed(1)
                        : parseFloat(0),
                    ...asset,
                });
            });

            if (hasVacOrCarWash) {
                setColumnTitles([
                    {
                        Header: "SITE",
                        accessor: "col1",
                    },
                    {
                        Header: "SESSIONS",
                        accessor: "col3",
                    },
                    {
                        Header: "Weather",
                        accessor: "weather",
                    },
                    {
                        Header: "ISSUES",
                        accessor: "openIssues",
                    },
                    {
                        Header: "IMPACT",
                        accessor: "col4",
                    },
                    {
                        Header: "30 DAY %",
                        accessor: "col2",
                    },
                ]);
            } else {
                setColumnTitles([
                    {
                        Header: "SITE",
                        accessor: "col1",
                    },
                    // {
                    //     Header: "SESSIONS",
                    //     accessor: "col3",
                    // },
                    {
                        Header: "ISSUES",
                        accessor: "openIssues",
                    },
                    {
                        Header: "IMPACT",
                        accessor: "col4",
                    },
                    {
                        Header: "30 DAY %",
                        accessor: "col2",
                    },
                ]);
            }

            let displayText = "";
            if (
                selectedAssetType === "WASHWATCH" ||
                selectedAssetType === "VAC"
            ) {
                displayText =
                    totalSessionsToday +
                    "/" +
                    parseFloat(totalSessionAverage).toFixed(0);
            }
            // else if (selectedAssetType === "DoorInformer") {
            //     displayText = totalSessionsToday;
            // } else if (selectedAssetType === "HVAC") {
            //     displayText =
            //         parseFloat(totalSessionsToday / count).toFixed(1) +
            //         "/" +
            //         parseFloat(totalSessionAverage / count).toFixed(0);
            // } else if (
            //     selectedAssetType === "Celsor" ||
            //     selectedAssetType === "TemperatureBeacon"
            // ) {
            //     displayText = parseFloat(totalSessionsToday / count).toFixed(1);
            // }

            // weather
            let rain = null;
            if (
                site.external_climates_aggregate.aggregate.avg
                    .precipitation_rate
            ) {
                rain =
                    site.external_climates_aggregate.aggregate.avg
                        .precipitation_rate;
            }

            let avgWindSpeed = null;
            if (site.external_climates_aggregate.aggregate.avg.wind_speed) {
                avgWindSpeed =
                    site.external_climates_aggregate.aggregate.avg.wind_speed *
                    3.6;
            }

            let rainLevel = 0;
            if (rain >= 0.1 && rain <= 2.5) rainLevel = 1;
            if (rain > 2.5) rainLevel = 2;
            if (rain > 7.6) rainLevel = 3;
            if (rain > 10) rainLevel = 4;

            // let windLevel = 0;
            // if (avgWindSpeed >= 1 && avgWindSpeed <= 5) windLevel = 1;
            // if (avgWindSpeed > 20) windLevel = 2;
            // if (avgWindSpeed > 38) windLevel = 3;
            // if (avgWindSpeed > 62) windLevel = 4;

            const renderWeather = () => {
                return (
                    <div className="flex flex-row justify-evenly w-full">
                        {rain !== null ? (
                            <div>
                                {rainLevel === 0 && (
                                    <div className="align-center">
                                        <FontAwesomeIcon icon={faSun} />
                                    </div>
                                )}
                                {rainLevel === 1 && (
                                    <FontAwesomeIcon icon={faCloudSun} />
                                )}
                                {rainLevel === 2 && (
                                    <FontAwesomeIcon icon={faCloudRain} />
                                )}
                                {rainLevel === 3 && (
                                    <FontAwesomeIcon
                                        icon={faCloudShowersHeavy}
                                    />
                                )}
                                {rainLevel === 4 && (
                                    <FontAwesomeIcon
                                        icon={faCloudShowersWater}
                                    />
                                )}
                                <div className="text-xs">
                                    {rain ? rain.toFixed(2) + " mm/hr" : "-"}
                                </div>
                            </div>
                        ) : (
                            <div className="flex w-full">-</div>
                        )}
                        {avgWindSpeed !== null ? (
                            <div className="ml-2">
                                <div className="justify-self-center	">
                                    <FontAwesomeIcon icon={faWind} />
                                </div>
                                <div className="text-xs">
                                    {avgWindSpeed !== null
                                        ? avgWindSpeed.toFixed(2) + " km/hr"
                                        : "-"}
                                </div>
                            </div>
                        ) : (
                            <div className="flex w-full">-</div>
                        )}
                    </div>
                );
            };

            return sitesByRating.push({
                col1: site.name,
                col2: site.month.aggregate.avg.rating
                    ? parseFloat(
                          (site.month.aggregate.avg.rating * 100).toFixed(1)
                      )
                    : perfectRating,
                weather: renderWeather(),
                openIssues: site.issues_aggregate.aggregate.count,
                col3: !containedAsset ? "-" : displayText,
                col4: !containedAsset
                    ? "-"
                    : impactTotalBySite
                    ? parseFloat(impactTotalBySite).toFixed(1)
                    : 0,
                ...site,
            });
        });

        setAllAssets(assets);
        let sortedLowToHigh = [
            ...sitesByRating.sort((a, b) => a.col2 - b.col2),
        ];
        setSitesRatingLow(sortedLowToHigh);
        let sortedHighToLow = [
            ...sitesByRating.sort((a, b) => b.col2 - a.col2),
        ];

        setSitesRatingHigh(sortedHighToLow);
    }, [siteByRatingData, selectedAssetType, assetNames, hasSessionAssets]);

    useEffect(() => {
        if (!siteIdParam || allAssets.length < 1) return;
        let selectedSite = sitesRatingLow.find(
            (site) => site.id === parseInt(siteIdParam)
        );
        setSelectedSite(selectedSite);
        let selectedSiteAssets = allAssets.filter(
            (asset) => asset.siteId === parseInt(siteIdParam)
        );
        let sorted = selectedSiteAssets.sort(
            (a, b) =>
                parseFloat(a.performanceRating) -
                    parseFloat(b.performanceRating) ||
                parseInt(b.openIssues) - parseInt(a.openIssues)
        );
        setAssetDataWithRatings(sorted);
    }, [siteIdParam, allAssets, sitesRatingLow]);

    useEffect(() => {
        if (!assetIdParam || !assetDataWithRatings) return;
        let selectedAsset = assetDataWithRatings.find(
            (asset) => asset.id === parseInt(assetIdParam)
        );
        if (!selectedAsset) return;
    }, [assetIdParam, assetDataWithRatings, isMobile]);

    const setSiteParam = (id) => {
        history.push(
            `assets?filter=${filterParam}&organisationId=${orgIdParam}&siteId=${id}`
        );
    };

    const setAssetParam = (id) => {
        history.push(`assetDetails?organisationId=${orgIdParam}&assetId=${id}`);
    };

    const dummyCallback = () => {
        return;
    };

    if (sitesRatingLow.length < 1) {
        return <Loading message="Loading Your Assets..." />;
    }

    return (
        <div
            className={`w-full  xl:px-12 px-3 ${
                isMobile ? "pt-5" : "pt-10"
            } bg-trueGray-50`}
        >
            {/* {assetIdParam && selectedAsset ? (
                <AssetDetails
                    {...props}
                    deviceType={deviceType}
                    selectedAsset={selectedAsset}
                />
            ) : (
                <> */}
            {sitesRatingLow && sitesRatingLow.length > 0 && (
                <div className="bg-white grid grid-cols-3 justify-evenly rounded-lg border-2 xl:w-1/2 sm:w-5/12 w-full sm:h-24 h-20 mr-2">
                    <div className="px-2.5 self-center text-center xl:text-lg text-xs">
                        {"30 Day Performance Rating"}
                    </div>
                    <FilterValue
                        setUrl={setFilterParam}
                        title={"LOW"}
                        value={sitesRatingLow[0].col2}
                        url="low"
                    />
                    <FilterValue
                        setUrl={setFilterParam}
                        title={"HIGH"}
                        value={sitesRatingLow[sitesRatingLow.length - 1].col2}
                        url="high"
                    />
                </div>
            )}
            {hasSessionAssets && (
                <div className={`${isMobile ? "mt-5" : "mt-10"}`}>
                    <div className="px-2.5 xl:text-lg text-xs">
                        {"Asset Selector:"}
                    </div>
                    <div className="flex justify-evenly xl:w-1/2 sm:w-5/12 ">
                        <AssetListbox
                            names={assetNames}
                            getAssetType={setSelectedAssetType}
                        />
                    </div>
                </div>
            )}
            <div
                className={
                    !isMobile ? "flex flex-row" : "pb-40 overflow-y-auto"
                }
            >
                {sitesRatingLow && siteByRatingData && (
                    <div
                        className={`${
                            isMobile ? "mt-5" : "mt-10"
                        } bg-white xl:w-1/2 sm:w-5/12 border-2 rounded-lg`}
                    >
                        <DataTable
                            headerStyle={`grid ${
                                hasSessionAssets ? "grid-cols-6" : "grid-cols-4"
                            } justify-items-center px-2 pl-5 xl:text-base text-xs`}
                            rowStyle={`grid ${
                                hasSessionAssets ? "grid-cols-6" : "grid-cols-4"
                            } justify-items-center sm:px-5 px-3 sm:py-4 py-3 border-t hover:text-teal-500 cursor-pointer`}
                            setUrl={setSiteParam}
                            search
                            setData={dummyCallback}
                            data={
                                filterParam === "low"
                                    ? sitesRatingLow
                                    : sitesRatingHigh
                            }
                            titles={columnTitles}
                        />
                    </div>
                )}
                {siteIdParam && selectedSite && (
                    <div
                        className={`${
                            isMobile ? "mt-5" : "mt-10 ml-2"
                        } bg-white xl:w-1/2 sm:w-5/12 border-2 rounded-lg  h-auto`}
                    >
                        <div className="flex flex-row text-blueGray-800 py-5 px-3.5 xl:text-2xl lg:text-xl justify-between">
                            <div>{selectedSite.col1}</div>
                            <div className="font-medium">
                                {selectedSite.col2}%
                            </div>
                        </div>
                        <div>
                            {assetDataWithRatings ? (
                                <DataTable
                                    headerStyle={`grid ${
                                        hasSessionAssets
                                            ? "grid-cols-5"
                                            : "grid-cols-4"
                                    } justify-items-end px-2 pl-5 xl:text-base text-xs`}
                                    rowStyle={`grid ${
                                        hasSessionAssets
                                            ? "grid-cols-5"
                                            : "grid-cols-4"
                                    } justify-items-end sm:px-5 px-3 sm:py-4 py-3 border-t hover:text-teal-500 cursor-pointer`}
                                    setUrl={setAssetParam}
                                    data={assetDataWithRatings}
                                    setData={dummyCallback}
                                    titles={
                                        hasSessionAssets
                                            ? [
                                                  {
                                                      Header: "ASSET",
                                                      accessor:
                                                          "assetDescription",
                                                  },
                                                  {
                                                      Header: "SESSIONS",
                                                      accessor: "col3",
                                                  },
                                                  {
                                                      Header: "ISSUES",
                                                      accessor: "openIssues",
                                                  },
                                                  {
                                                      Header: "IMPACT",
                                                      accessor: "col4",
                                                  },
                                                  {
                                                      Header: "30 DAY %",
                                                      accessor:
                                                          "performanceRating",
                                                  },
                                              ]
                                            : [
                                                  {
                                                      Header: "ASSET",
                                                      accessor:
                                                          "assetDescription",
                                                  },
                                                  {
                                                      Header: "ISSUES",
                                                      accessor: "openIssues",
                                                  },
                                                  {
                                                      Header: "IMPACT",
                                                      accessor: "col4",
                                                  },
                                                  {
                                                      Header: "30 DAY %",
                                                      accessor:
                                                          "performanceRating",
                                                  },
                                              ]
                                    }
                                />
                            ) : (
                                <div className="flex justify-center xl:pl-0 pl-2">
                                    No Assets or Assets with Performance Ratings
                                    at this Site
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Assets;
