import React, { useEffect, useState } from "react";
import { getQueryParameter } from "../../utils/queryParams";
import AssetDetailsPanel from "../../components/Panels/AssetDetailsPanel";
import SessionTotalsPanel from "../../components/Panels/AssetTotalsPanel";
import WashProgramPanel from "../../components/Panels/WashProgramPanel";
import IprPanel from "../../components/Panels/IprPanel";
import AssetPerformance from "../../components/Panels/AssetPerformance";
import AssetStatus from "../../components/Panels/AssetStatus";
import TempMonitoringPanel from "../../components/Panels/TempMonitoringPanel";
import EnvironmentMonitoringPanel from "../../components/Panels/EnvironmentMonitoringPanel";
import FaultPanel from "../../components/Panels/FaultPanel";
import VNCRemoteConsole from "../../components/Modals/VNCRemoteConsole";
import WashTecRemoteConsole from "../../components/Modals/WashTecRemoteConsole";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";

const THING_NAME_QUERY = gql`
    query getDevice($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            description
            site {
                id
                name
            }
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    device {
                        id
                        dev_eui
                    }
                    washwatch_status(limit: 1) {
                        connection
                        id
                    }
                }
            }
        }
    }
`;

const AssetDetails = (props) => {
    const assetIdParam = getQueryParameter("assetId");
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [measurementPoint, setMeasurementPoint] = useState();
    const [carWashType, setCarWashType] = useState();
    const [consolePermission, setConsolePermission] = useState(false);
    const [siteName, setSiteName] = useState();
    const [assetDesc, setAssetDesc] = useState();
    const { data } = useQuery(THING_NAME_QUERY, {
        variables: {
            assetId: assetIdParam,
        },
    });

    useEffect(() => {
        if (!measurementPoint || !props.user) return;
        let permission = false;
        props.user.access_roles.map((role) => {
            if (
                role.measurement_point_id === measurementPoint.id &&
                role.role >= 2
            ) {
                permission = true;
            }
            return null;
        });
        setConsolePermission(permission);
    }, [props.user, measurementPoint]);

    useEffect(() => {
        if (!data || !data.asset_by_pk) return;
        setAssetDesc(data.asset_by_pk.description);
        setSiteName(
            data.asset_by_pk.site
                ? data.asset_by_pk.site.name
                : "Asset has no Site"
        );
        if (data.asset_by_pk.measurement_points.length > 0) {
            setMeasurementPoint(
                data.asset_by_pk.measurement_points[0].measurement_point
            );
            let connection =
                data.asset_by_pk.measurement_points[0].measurement_point
                    .washwatch_status.length > 0
                    ? data.asset_by_pk.measurement_points[0].measurement_point
                          .washwatch_status[0].connection
                    : 0;
            if (connection === 1) {
                setCarWashType("WashTec");
            } else if (connection === 2 || connection === 3) {
                setCarWashType("Istobal");
            }
        }
    }, [data]);

    if (!measurementPoint) return <div></div>;
    return (
        <div
            className={`w-full h-full xl:px-12 sm:px-6 px-3 ${
                isMobile ? "pt-5" : "pt-10"
            }`}
        >
            <div
                className={classNames(
                    "flex  pb-3 xl:justify-between xl:gap-x-0 gap-x-8",
                    window.innerWidth < 1024 ? "flex-col" : "flex-row w-4/5"
                )}
            >
                <div className="text-teal-600 sm:text-2xl xl:text-3xl sm:m-0 m-1 text-xl">
                    [{siteName}] {assetDesc}
                </div>
                <div className={classNames(window.innerWidth < 1024 && "mt-2")}>
                    {measurementPoint.device_type === "WASHWATCH" &&
                        carWashType !== undefined &&
                        carWashType === "Istobal" && (
                            <div
                                className={classNames(
                                    window.innerWidth < 1024
                                        ? "w-full"
                                        : "xl:w-52 w-32"
                                )}
                            >
                                <VNCRemoteConsole
                                    disabled={!consolePermission}
                                    device={measurementPoint.device}
                                />
                            </div>
                        )}
                    {measurementPoint.device_type === "WASHWATCH" &&
                        carWashType !== undefined &&
                        carWashType === "WashTec" && (
                            <div
                                className={classNames(
                                    window.innerWidth < 1024
                                        ? "w-full"
                                        : "xl:w-52 w-32"
                                )}
                            >
                                <WashTecRemoteConsole
                                    {...props}
                                    disabled={!consolePermission}
                                />
                            </div>
                        )}
                </div>
            </div>
            <div className="flex flex-col 3xl:w-4/5 overflow-y-auto pb-40 space-y-3">
                <div className="flex flex-col sm:flex-row  sm:space-x-3">
                    <div className="sm:w-1/2 w-full">
                        <AssetDetailsPanel />
                    </div>
                    <div className="sm:w-1/2 w-full space-y-3 h-full mt-2 sm:mt-0">
                        <div className="sm:h-30 h-24">
                            <AssetStatus />
                        </div>
                        <div className="">
                            <AssetPerformance assetIds={[assetIdParam]} />
                        </div>

                        {(measurementPoint.device_type === "DoorInformer" ||
                            measurementPoint.device_type === "VAC" ||
                            measurementPoint.device_type === "WASHWATCH") && (
                            <SessionTotalsPanel
                                type={measurementPoint.device_type}
                            />
                        )}
                        {measurementPoint.device_type ===
                            "TemperatureBeacon" && <TempMonitoringPanel />}
                        {measurementPoint.device_type === "SpaceMon" && (
                            <EnvironmentMonitoringPanel />
                        )}
                    </div>
                </div>
                <IprPanel {...props} title="Issues" />
                {(measurementPoint.device_type === "DoorInformer" ||
                    measurementPoint.device_type === "VAC" ||
                    measurementPoint.device_type === "WASHWATCH") && (
                    <WashProgramPanel type={measurementPoint.device_type} />
                )}
                <FaultPanel />
            </div>
        </div>
    );
};

export default AssetDetails;
