import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import DisplayValue from "../../Text/DisplayValue";
import { getQueryParameter } from "../../../utils/queryParams";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { DOOR_MODES } from "../../../utils/constants";
import TabbedModal from "../../Modals/TabbedModal";

const QUERY_ASSET_STATUS = gql`
    query assetStatus($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            openIssues: issues(where: { status: { _lte: 2 } }) {
                id
            }
            openPrompts: prompts(where: { status: { _lte: 2 } }) {
                id
            }
            resolvedIssues: issues(where: { status: { _eq: 9 } }) {
                id
            }
            resolvedPrompts: prompts(where: { status: { _eq: 9 } }) {
                id
            }
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    beacon_data(limit: 1, order_by: { time: desc }) {
                        id
                        time
                    }
                    device {
                        id
                        last_packet_time
                    }
                    vacActive: vac_sessions(
                        where: { finished: { _eq: false } }
                        limit: 1
                        order_by: { start_time: desc }
                    ) {
                        id
                    }
                    wwhActive: washwatch_status {
                        id
                        wash_active
                    }
                    doorMode: doorinformer_statuses(
                        limit: 1
                        order_by: { time: desc }
                    ) {
                        id
                        mode
                    }
                }
            }
        }
    }
`;

const AssetStatus = (props) => {
    const assetId = getQueryParameter("assetId");
    const [machineValue, setMachineValue] = useState();
    const [stateValue, setStateValue] = useState();
    const [monitoringValue, setMonitoringValue] = useState();
    const [openInfo, setOpenInfo] = useState(false);

    const { data: statusData, error: statusError } = useQuery(
        QUERY_ASSET_STATUS,
        {
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                assetId: assetId,
            },
            onError: () => console.log(statusError),
        }
    );

    useEffect(() => {
        if (
            !statusData ||
            !statusData.asset_by_pk ||
            !statusData.asset_by_pk.measurement_points[0] ||
            !statusData.asset_by_pk.measurement_points[0].measurement_point
        )
            return;
        let data = statusData.asset_by_pk;
        if (data.openIssues.length > 0) {
            setMachineValue({
                colour: "red-600",
                value: "down",
            });
        } else if (
            data.openIssues.length < 1 &&
            data.resolvedIssues.length < 1 &&
            data.openPrompts.length < 1
        ) {
            setMachineValue({
                colour: "teal-500",
                value: "up",
            });
        } else {
            setMachineValue({
                colour: "black",
                value: "up",
            });
        }
        let mp = statusData.asset_by_pk.measurement_points[0].measurement_point;
        if (mp.beacon_data[0]) {
            let lastUpdated = dayjs(mp.beacon_data[0].time);
            let now = dayjs();
            let diffHrs = now.diff(lastUpdated, "hours", true);
            if (diffHrs < 1) {
                setMonitoringValue({
                    colour: "teal-500",
                    value: "up",
                });
            } else if (diffHrs > 1 && diffHrs < 2) {
                setMonitoringValue({
                    colour: "black",
                    value: "up",
                });
            } else if (diffHrs > 2) {
                setMonitoringValue({
                    colour: "red-600",
                    value: "down",
                });
            }
        } else {
            if (!mp.device || !mp.device.last_packet_time) {
                setMonitoringValue({
                    colour: "red-600",
                    value: "down",
                });
                return;
            }
            let lastUpdated = dayjs(mp.device.last_packet_time);
            let now = dayjs();
            let diffHrs = now.diff(lastUpdated, "hours", true);
            if (diffHrs < 1) {
                setMonitoringValue({
                    colour: "teal-500",
                    value: "up",
                });
            } else if (diffHrs > 1 && diffHrs < 2) {
                setMonitoringValue({
                    colour: "black",
                    value: "up",
                });
            } else if (diffHrs > 2) {
                setMonitoringValue({
                    colour: "red-600",
                    value: "down",
                });
            }
        }
    }, [statusData]);

    useEffect(() => {
        if (
            !statusData ||
            !statusData.asset_by_pk ||
            !statusData.asset_by_pk.measurement_points[0] ||
            !statusData.asset_by_pk.measurement_points[0].measurement_point ||
            !statusData.asset_by_pk.measurement_points[0].measurement_point
                .vacActive ||
            !statusData.asset_by_pk.measurement_points[0].measurement_point
                .wwhActive ||
            !statusData.asset_by_pk.measurement_points[0].measurement_point
                .doorMode
        )
            return;
        let data =
            statusData.asset_by_pk.measurement_points[0].measurement_point;
        if (data.device_type === "VAC") {
            setStateValue(data.vacActive[0] ? "ACTIVE" : "IDLE");
        } else if (data.device_type === "WASHWATCH") {
            setStateValue(
                data.wwhActive.length > 0
                    ? data.wwhActive[0].wash_active
                        ? "ACTIVE"
                        : "IDLE"
                    : "UNKNOWN"
            );
        } else if (data.device_type === "DoorInformer") {
            let mode = data.doorMode[0] ? data.doorMode[0].mode : 0;
            setStateValue(DOOR_MODES[mode] ? DOOR_MODES[mode] : "UNKNOWN");
        } else {
            setStateValue("---");
        }
    }, [statusData]);

    return (
        <div className="border-2 rounded-lg bg-white h-full px-3 py-1 sm:mt-0 mt-1">
            <TabbedModal
                title="ASSET STATUS"
                open={openInfo}
                close={setOpenInfo}
            />
            <div className="flex flex-row space-x-3 justify-between">
                <div className="flex flex-row">
                    <div className="text-lg xl:text-2xl">Status</div>
                    <div
                        className="ml-2 w-5 h-4 self-center cursor-pointer"
                        onClick={() => setOpenInfo(true)}
                    >
                        <InformationCircleIcon />
                    </div>
                </div>

                <div className="self-center text-trueGray-400 xl:text-base text-xs ">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row space-x-5 lg:space-x-12 2xl:space-x-16 xl:px-8 lg:px-4 pt-2 justify-around">
                {machineValue ? (
                    <DisplayValue
                        title="MACHINE"
                        icon={machineValue.value}
                        colour={machineValue.colour}
                    />
                ) : (
                    <DisplayValue
                        title="MACHINE"
                        value="N/A"
                        colour="red-600"
                    />
                )}
                {stateValue ? (
                    <DisplayValue title="STATUS" value={stateValue} />
                ) : (
                    <DisplayValue title="STATUS" value="N/A" colour="red-600" />
                )}
                {monitoringValue ? (
                    <DisplayValue
                        title="MONITORING"
                        icon={monitoringValue.value}
                        colour={monitoringValue.colour}
                    />
                ) : (
                    <DisplayValue
                        title="MONITORING"
                        colour="red-600"
                        icon="down"
                    />
                )}
            </div>
        </div>
    );
};

export default AssetStatus;
