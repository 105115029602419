import React from "react";

const SelectedDetails = (props) => {
    return (
        <div className="xl:text-base lg:text-sm text-xs">
            <div className="grid grid-cols-12 pt-4">
                <div className="col-span-2">
                    <div className="text-trueGray-400">DATE</div>
                    <div>{props.date}</div>
                </div>
                <div className="col-span-2">
                    <div className="text-trueGray-400">TIME</div>
                    <div>{props.time}</div>
                </div>
                <div className="col-span-4">
                    <div className="text-trueGray-400">ASSET</div>
                    <div>{props.asset}</div>
                </div>
                <div className="col-span-4">
                    <div className="text-trueGray-400">SITE</div>
                    <div>{props.site}</div>
                </div>
            </div>
            <div className="text-trueGray-400 pt-3">DESCRIPTION</div>
            <div>{props.description}</div>
            <div className="text-trueGray-400 pt-3">DETAILS</div>
            <div className="whitespace-pre-line">{props.details}</div>
            {!props.requestId && !props.promptId && (
                <div>
                    <div className="text-trueGray-400 pt-3">SERVICING</div>
                    <div className="pb-3">
                        {props.service_provider ? (
                            <div className="">
                                Issue has been sent to{" "}
                                {props.service_provider.name}
                            </div>
                        ) : (
                            <div className="">
                                This is a locally resolvable fault.
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectedDetails;
