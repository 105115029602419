import React, { useState, useEffect } from "react";
import Logout from "../../components/Miscellaneous/Logout";
import { getQueryParameter } from "../../utils/queryParams";
import PillButton from "../../components/Miscellaneous/PillButton";
import EditUserModal from "../../components/Modals/EditUserModal/EditUserModal";
import InviteUserModal from "../../components/Modals/InviteUserModal";
import { useHistory } from "react-router";

const Settings = (props) => {
    const orgId = getQueryParameter("organisationId");
    const [openInviteUser, setOpenInviteUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const history = useHistory();

    useEffect(() => {
        if (props.user.access_roles.find((role) => role.role === 2)) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [props]);

    useEffect(() => {
        if (!openEditUser) {
            history.push(`/settings?organisationId=${orgId}`);
        }
    }, [openEditUser, history, orgId]);

    return (
        <div
            className={`w-full h-full xl:px-12 sm:px-6 px-3 ${
                isMobile ? "pt-5" : "pt-10"
            }`}
        >
            <EditUserModal open={openEditUser} close={setOpenEditUser} />
            <InviteUserModal open={openInviteUser} close={setOpenInviteUser} />
            <div className="flex flex-row justify-between">
                <div className="text-teal-600 text-3xl pb-10">Settings</div>
            </div>
            <div className="sm:w-48 w-36" onClick={() => Logout()}>
                <PillButton text="Logout" bg="white" textC="teal-500" />
            </div>
            {isAdmin && (
                <div className="flex flex-row pt-5 max-w-lg sm:justify-between sm:gap-x-0 gap-x-8">
                    <div
                        className="sm:w-48 w-36"
                        onClick={() => setOpenInviteUser(true)}
                    >
                        <PillButton
                            text="INVITE NEW USER"
                            bg="teal-500"
                            textC="white"
                        />
                    </div>
                    <div
                        className="sm:w-48 w-36"
                        onClick={() => setOpenEditUser(true)}
                    >
                        <PillButton
                            text="EDIT EXISTING USER"
                            bg="white"
                            textC="teal-500"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
