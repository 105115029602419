import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import PillButton from "../PillButton";
import AddNoteModal from "../../Modals/AddNoteModal/AddNoteModal";

const NotesDisclosure = (props) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="w-full ">
      {openModal && (
        <AddNoteModal
          open={openModal}
          close={setOpenModal}
          title="ADD NOTE"
          {...props}
        />
      )}
      <div className="mx-auto w-full rounded-lg bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between bg-white py-2 text-left font-medium text-trueGray-400 border-b-2">
                <span>NOTES</span>
                <ChevronDownIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-6 w-6 text-trueGray-400`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 text-2xs sm:text-sm text-trueGray-400">
                <div className="grid grid-cols-6 border-b">
                  <div className="col-start-1 col-span-3">Note</div>
                  <div className="col-start-4 col-span-1 justify-self-center">
                    Author
                  </div>
                  <div className="col-start-5 col-span-2 justify-self-center">
                    Time
                  </div>
                </div>
                <div className="max-h-60 overflow-y-auto">
                  {props.notes.length > 0 ? (
                    props.notes.map((note, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-6 text-trueGray-800 border-b pt-2"
                      >
                        <div className="col-start-1 col-span-3">
                          {note.description}
                        </div>
                        {note.user && (
                          <div className="col-start-4 col-span-1 justify-self-end">
                            {`${note.user.first_name} ${note.user.last_name}`}
                          </div>
                        )}

                        <div className="col-start-5 col-span-2 pb-2 justify-self-end">
                          {dayjs(note.time).format("YYYY-MM-DD HH:mm")}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No Notes Yet..</div>
                  )}
                </div>
                <div className="flex flex-row-reverse">
                  <div
                    className="w-1/4 pt-2"
                    onClick={() => setOpenModal(true)}
                  >
                    <PillButton text="ADD NOTE" bg="white" textC="teal-500" />
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default NotesDisclosure;
