import React, { useEffect, useState } from "react";
import { Logo } from "../../assets/Svgs/Logo";
import { Redirect, Link } from "react-router-dom";
import PasswordStrengthMeter from "../../components/Miscellaneous/PasswordStrengthMeter/PasswordStrengthMeter";
import ReactCodeInput from "react-code-input";
import utils from "../../../src/utils/cognito";
import { getQueryParameter } from "../../utils/queryParams";
import { isUserLoggedIn } from "../../utils/userSession";
import PillButton from "../../components/Miscellaneous/PillButton";
import {
    EyeIcon,
    EyeOffIcon,
    MailIcon,
    RefreshIcon,
    CheckCircleIcon,
} from "@heroicons/react/outline";
import ErrorPanel from "../../components/Panels/ErrorPanel";

const SignUp = () => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userIsLoggedIn = isUserLoggedIn();
    const [success, setSuccess] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [code, setCode] = useState(null);
    const [passwordInfo, setPasswordInfo] = useState(null);
    const [nameInfo, setNameInfo] = useState(null);
    const [codeInfo, setCodeInfo] = useState(null);

    const [resendingCode, setResendingCode] = useState(false);
    const [resentCode, setResentCode] = useState(false);

    const email =
        getQueryParameter("email") !== null ? getQueryParameter("email") : null;
    const codeSent =
        getQueryParameter("codeSent") !== null
            ? getQueryParameter("codeSent")
            : null;

    const validatePassword = (password) => {
        let hasLength = password.length >= 6;
        let hasLowerCase = /(?=.*[a-z])/.test(password);
        let hasUpperCase = /(?=.*[A-Z])/.test(password);
        let hasNumbers = /(?=.*[0-9])/.test(password);
        return hasLength && hasLowerCase && hasUpperCase && hasNumbers;
    };

    const [formData, setFormData] = useState({
        email: "",
        name: "",
        password: "",
    });

    useEffect(() => {
        if (email !== null) {
            setFormData({
                email: email,
                name: "",
                password: "",
            });
        }
        if (codeSent !== null) {
            setConfirm(true);
        }
    }, [email, codeSent]);

    useEffect(() => {
        if (formData.email === "" && confirm) {
            setConfirm(false);
        }
    }, [formData, confirm]);

    useEffect(() => {
        let valid = true;
        let password = formData.password;
        if (formData.name.length > 0) {
            let firstName = formData.name.substr(0, formData.name.indexOf(" "));
            let lastName = formData.name.substr(formData.name.indexOf(" ") + 1);
            if (firstName.length < 1 || lastName.length < 1) {
                setNameInfo("Please enter your first and last name");
                valid = false;
            }
        }
        if (password.length > 0) {
            if (password.length < 6) {
                setPasswordInfo("Password must have at least 6 characters");
                valid = false;
            }
            if (!/(?=.*[a-z])/.test(password)) {
                setPasswordInfo(
                    "Password must have at least 1 lowercase character"
                );
                valid = false;
            }
            if (!/(?=.*[A-Z])/.test(password)) {
                setPasswordInfo(
                    "Password must have at least 1 uppercase character"
                );
                valid = false;
            }
            if (!/(?=.*[0-9])/.test(password)) {
                setPasswordInfo(
                    "Password must have at least 1 numerical character"
                );
                valid = false;
            }
        }
        if (!valid) return;
        setNameInfo(null);
        setPasswordInfo(null);
    }, [formData]);

    if (userIsLoggedIn) {
        return <Redirect to="/" />;
    }

    const handleInputChange = (event) => {
        event.persist();
        setFormData((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
        setError(null);
    };

    const handleFormSubmit = (event) => {
        if (event) {
            event.preventDefault();
            if (validatePassword(formData.password)) {
                setIsSubmitting(true);
                let firstName = formData.name.substr(
                    0,
                    formData.name.indexOf(" ")
                );
                let lastName = formData.name.substr(
                    formData.name.indexOf(" ") + 1
                );
                utils
                    .signUpNewUser(
                        formData.email,
                        formData.password,
                        null,
                        firstName,
                        lastName
                    )
                    .then((result) => {
                        setConfirm(true);
                        setIsSubmitting(false);
                    })
                    .catch((e) => {
                        setError(e.message);
                        setIsSubmitting(false);
                    });
            }
        }
    };

    const handleCodeSubmit = (event) => {
        if (event) {
            event.preventDefault();
            if (code === null || code.length !== 6) {
                setCodeInfo("Please enter the full 6 digit verification code");
                return;
            }
            setIsSubmitting(true);
            utils
                .confirmRegistration(formData.email, code)
                .then((result) => {
                    setSuccess(true);
                    utils
                        .authenticateUser(formData.email, formData.password)
                        .then((result) => {
                            setIsSubmitting(false);
                        })
                        .catch((e) => {
                            setError(e.message);
                            setIsSubmitting(false);
                        });
                })
                .catch((e) => {
                    setError(e.message);
                    setIsSubmitting(false);
                });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleFormSubmit(event);
        }
    };

    const resendCode = () => {
        setResentCode(false);
        setResendingCode(true);
        utils
            .resendConfirmationCode(formData.email)
            .then((result) => {
                setResendingCode(false);
                setResentCode(true);
            })
            .catch((e) => {
                setError(e.message);
                setResendingCode(false);
            });
    };
    return (
        <div className="w-full h-full flex flex-row justify-center">
            <div className="m-5 shadow-lg grid place-items-center text-blueGray-800 text-base border-2 rounded-md py-10 bg-white max-w-400 sm:min-w-96 mt-10 px-5 sm:px-0">
                <div className="flex flex-row space-x-5 items-center mb-5">
                    <div>
                        <Logo width={60} height={60} />
                    </div>
                    <div className="sm:text-4xl text-2xl">BenchMarker</div>
                </div>
                {!confirm && (
                    <div className="w-full px-5">
                        <div className="sm:text-2xl text-lg">Sign up</div>
                        <div className="relative text-sm mt-2">
                            <div>Sign up for an account to continue</div>
                            <div className="flex flex-row pb-5">
                                Already have an account?
                                <Link to="/login">
                                    <div className="pl-1 text-teal-500 cursor-pointer">
                                        Log In
                                    </div>
                                </Link>
                            </div>
                            {nameInfo && (
                                <div className="pb-5">
                                    <ErrorPanel message={nameInfo} />
                                </div>
                            )}
                            <div className="flex flex-col space-y-2 sm:space-y-3 w-full">
                                <input
                                    placeholder="Enter full name"
                                    className="h-10 rounded-md pl-2 border-2 text-sm "
                                    type="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    className="h-10 rounded-md pl-2 border-2 text-sm "
                                    placeholder="Enter email address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                {passwordInfo && (
                                    <ErrorPanel message={passwordInfo} />
                                )}
                                <div className="flex flex-row pb-8 w-full">
                                    <input
                                        className="h-10 rounded-md pl-2 border-2 text-sm w-full"
                                        type={
                                            passwordShown ? "text" : "password"
                                        }
                                        name="password"
                                        placeholder="Create password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        required
                                    />
                                    {passwordShown ? (
                                        <EyeIcon
                                            className="w-6 h-6 cursor-pointer absolute mt-2 right-2"
                                            onClick={() => {
                                                setPasswordShown(
                                                    !passwordShown
                                                );
                                            }}
                                        />
                                    ) : (
                                        <EyeOffIcon
                                            className="w-6 h-6 cursor-pointer absolute mt-2 right-2"
                                            onClick={() => {
                                                setPasswordShown(
                                                    !passwordShown
                                                );
                                            }}
                                        />
                                    )}
                                    <div className="absolute mt-8 w-full">
                                        <PasswordStrengthMeter
                                            valid={validatePassword(
                                                formData.password
                                            )}
                                            password={formData.password}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-xs mt-2 mb-5">
                                By signing up, I acknowledge the SenSys{" "}
                                <a
                                    className="text-teal-500"
                                    href={
                                        "https://www.sensys.co.nz/privacy-policy/"
                                    }
                                >
                                    Privacy Policy
                                </a>
                                .
                            </div>
                            <div
                                className="w-full cursor-pointer"
                                onClick={handleFormSubmit}
                            >
                                <PillButton
                                    loading={isSubmitting}
                                    text="SIGN UP"
                                    bg="teal-500"
                                    textC="white"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {confirm && !success && (
                    <div className="text-center flex flex-col">
                        <div className="text-lg">
                            Check your inbox to finish sign up
                        </div>
                        <MailIcon className="w-14 h-16 self-center" />
                        <div className="text-sm">
                            We have sent a confirmation code to you at{" "}
                            <div className="font-semibold">
                                {formData.email}
                            </div>
                        </div>
                        <div className={""}>
                            <div
                                style={{
                                    margin: "auto",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                }}
                            >
                                {codeInfo && (
                                    <div className={"authErrorMessage"}>
                                        {codeInfo}
                                    </div>
                                )}
                                {error && (
                                    <div className={"authErrorMessage pb-5"}>
                                        <ErrorPanel message={error} />
                                    </div>
                                )}
                                <ReactCodeInput
                                    name={"verificationCode"}
                                    inputStyle={
                                        !isMobile
                                            ? {
                                                  fontFamily: "monospace",
                                                  width: "14.6%",
                                                  MozAppearance: "textfield",
                                                  borderRadius: "6px",
                                                  border: "1px solid",
                                                  boxShadow:
                                                      "0px 0px 10px 0px rgba(0,0,0,.10)",
                                                  margin: "1%",
                                                  paddingLeft: "4%",
                                                  height: "50px",
                                                  fontSize: "1.5em",
                                                  boxSizing: "border-box",
                                                  color: "black",
                                                  backgroundColor: "white",
                                                  borderColor: "lightgrey",
                                              }
                                            : {}
                                    }
                                    inputMode={"numeric"}
                                    type="number"
                                    fields={6}
                                    onChange={(e) => {
                                        setCode(e);
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div
                            className="w-full cursor-pointer self-center pb-5"
                            onClick={handleCodeSubmit}
                        >
                            <PillButton
                                loading={isSubmitting}
                                text="VERIFY EMAIL"
                                bg="teal-500"
                                textC="white"
                            />
                        </div>
                        <div className="border-t-2 w-full self-center" />
                        <div className="flex flex-row self-center pt-5">
                            <div
                                className="text-teal-500 font-semibold cursor-pointer"
                                onClick={resendCode}
                            >
                                Resend confirmation code
                            </div>
                            <div>
                                {resendingCode && !resentCode && (
                                    <RefreshIcon className="w-5 h-5 ml-1 mt-1 animate-spin text-teal-500" />
                                )}
                                {!resendingCode && resentCode && (
                                    <CheckCircleIcon className="w-5 h-5 text-teal-500 ml-1 mt-1 " />
                                )}
                            </div>
                        </div>
                        <div
                            className="self-center text-teal-500 font-semibold cursor-pointer pt-5"
                            onClick={() => {
                                setConfirm(false);
                                setError(null);
                            }}
                        >
                            Change email address
                        </div>
                    </div>
                )}
                {confirm && success && (
                    <div className="self-center space-y-5 w-full px-5">
                        <div className="text-lg sm:text-2xl pt-5">
                            Sign up successful!
                        </div>
                        <div className="text-sm">
                            Thank you for signing up to our service.
                        </div>
                        <Link to="/">
                            <div className="w-full cursor-pointer py-5">
                                <PillButton
                                    text="SIGN IN"
                                    bg="teal-500"
                                    textC="white"
                                />
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignUp;
