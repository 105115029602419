import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core";
import {
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
} from "echarts/components";
import { HeatmapChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";
import dayjs from "dayjs";

echarts.use([
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    HeatmapChart,
    CanvasRenderer,
]);

const timeOptions = ["6am", "10am", "2pm", "6pm", "10pm", "2am"];

const HeatMap = (props) => {
    const chartRef = useRef(null);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [programData, setProgramData] = useState();
    const [maxValue, setMaxValue] = useState();
    const [timeArray, setTimeArray] = useState();
    const [daysArray, setDaysArray] = useState();
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    useEffect(() => {
        if (!props || !props.data || !chartRef.current || !chartObject) return;
        let total = props.data;
        let maxCount = 0;
        let timeArr = [6.0, 10.0, 14.0, 18.0, 22.0, 2.0];
        let daysArr = [];

        for (let i = 0; i < 7; i++) {
            daysArr.push(dayjs().subtract(i, "days").format("dd"));
        }
        setTimeArray(timeOptions);
        setDaysArray(daysArr);
        const baseMap = [
            [0, 0, 0],
            [0, 1, 0],
            [0, 2, 0],
            [0, 3, 0],
            [0, 4, 0],
            [0, 5, 0],
            [1, 0, 0],
            [1, 1, 0],
            [1, 2, 0],
            [1, 3, 0],
            [1, 4, 0],
            [1, 5, 0],
            [2, 0, 0],
            [2, 1, 0],
            [2, 2, 0],
            [2, 3, 0],
            [2, 4, 0],
            [2, 5, 0],
            [3, 0, 0],
            [3, 1, 0],
            [3, 2, 0],
            [3, 3, 0],
            [3, 4, 0],
            [3, 5, 0],
            [4, 0, 0],
            [4, 1, 0],
            [4, 2, 0],
            [4, 3, 0],
            [4, 4, 0],
            [4, 5, 0],
            [5, 0, 0],
            [5, 1, 0],
            [5, 2, 0],
            [5, 3, 0],
            [5, 4, 0],
            [5, 5, 0],
            [6, 0, 0],
            [6, 1, 0],
            [6, 2, 0],
            [6, 3, 0],
            [6, 4, 0],
            [6, 5, 0],
        ];
        if (total.length < 1) {
            setProgramData(baseMap);
            return;
        }

        if (
            props.device &&
            (props.device === "WASHWATCH" || props.device === "VAC")
        ) {
            total.map((program) => {
                let dayIndex = 0;
                let timeIndex = null;
                let day = dayjs(program.start_time).format("dd");
                dayIndex = daysArr.indexOf(day);
                let startFloat = parseFloat(
                    dayjs(program.start_time).format("H.mm")
                );
                let timeVal = timeArr.filter(
                    (time) =>
                        (startFloat - time <= 4 && startFloat - time > 0) ||
                        (time === 22.0 && startFloat + 22 <= 24)
                );
                timeIndex = timeArr.indexOf(timeVal[0]);
                if (timeIndex === null || timeIndex === undefined) return null;
                let itemToIncrement = baseMap.find(
                    (element) =>
                        element[0] === dayIndex && element[1] === timeIndex
                );
                let value = itemToIncrement[2];
                value += 1;
                maxCount = maxCount > value ? maxCount : value;
                let itemIndex = baseMap.findIndex(
                    (element) =>
                        element[0] === dayIndex && element[1] === timeIndex
                );
                baseMap[itemIndex][2] = value;
                return null;
            });
            setMaxValue(maxCount);
            setProgramData(baseMap);
            // Door Cycles
        } else if (props.device === "DoorInformer") {
            let cycles = props.data.filter((cycle) => cycle.count1 !== 0);

            cycles.map((cycle) => {
                let dayIndex = 0;
                let timeIndex = null;
                let day = dayjs(cycle.time).format("dd");
                dayIndex = daysArr.indexOf(day);
                let startFloat = parseFloat(dayjs(cycle.time).format("H.mm"));

                let timeVal = timeArr.filter(
                    (time) =>
                        (startFloat - time <= 4 && startFloat - time > 0) ||
                        (time === 22.0 && startFloat + 22 <= 24)
                );

                timeIndex = timeArr.indexOf(timeVal[0]);
                if (timeIndex === null || timeIndex === undefined) return null;

                let itemToIncrement = baseMap.find(
                    (element) =>
                        element[0] === dayIndex && element[1] === timeIndex
                );

                let value = itemToIncrement[2];
                value += cycle.count1;
                maxCount = maxCount > value ? maxCount : value;
                let itemIndex = baseMap.findIndex(
                    (element) =>
                        element[0] === dayIndex && element[1] === timeIndex
                );
                baseMap[itemIndex][2] = value;
                return baseMap;
            });

            setMaxValue(maxCount);
            setProgramData(baseMap);
        }
    }, [chartObject, props, props.hours]);

    useEffect(() => {
        if (
            !chartRef.current ||
            !programData ||
            !maxValue ||
            !timeArray ||
            !daysArray ||
            !chartObject
        )
            return;
        let option = {
            tooltip: {
                position: "top",
            },
            grid: {
                height: "80%",
                top: "10%",
                width: "70%",
            },
            xAxis: {
                type: "category",
                data: timeArray,
                axisLine: {
                    show: false,
                },
                splitArea: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                position: "top",
                axisLabel: {
                    margin: isMobile ? 3 : 10,
                    fontStyle: "bolder",
                    fontSize: isMobile ? 10 : 12,
                },
            },
            yAxis: {
                type: "category",
                data: daysArray,
                splitArea: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    margin: isMobile ? 3 : 10,
                    fontStyle: "bolder",
                    fontSize: isMobile ? 10 : 12,
                },
            },
            visualMap: {
                min: 0,
                max: maxValue === 0 ? 5 : maxValue,
                calculable: false,
                show: false,
                orient: "horizontal",
                itemWidth: 0,
                itemHeight: 0,
                inRange: {
                    color: [
                        "#F0FDFA",
                        "#CCFBF1",
                        "#99F6E4",
                        "#5EEAD4",
                        "#2DD4BF",
                        "#14B8A6",
                        "#0D9488",
                        "#0F766E",
                        "#115E59",
                        "#134E4A",
                    ],
                },
            },
            series: [
                {
                    name:
                        props.device === "DoorInformer" ? "Cycles" : "Sessions",
                    type: "heatmap",
                    data: programData.map(function (item) {
                        return [item[1], item[0], item[2] || "-"];
                    }),
                    label: {
                        show: false,
                    },
                },
            ],
        };
        chartObject.setOption(option);
    }, [
        props,
        programData,
        maxValue,
        timeArray,
        daysArray,
        chartObject,
        isMobile,
    ]);

    return <div ref={chartRef} className="w-full h-full" />;
};

export default HeatMap;
