import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

const AssetListbox = (props) => {
    const [assetNames, setAssetNames] = useState(props.names);
    const [selectedAsset, setSelected] = useState(assetNames[0]);

    const setSelectedAndType = useCallback(
        (asset) => {
            if (asset != null) {
                setSelected(asset);
                props.getAssetType(asset.type);
            }
        },
        [props]
    );

    useEffect(() => {
        if (selectedAsset == null)
            //Log null
            return;
        return () => {
            props.getAssetType(selectedAsset.type);
            return;
        };
    }, [selectedAsset, props]);

    useEffect(() => {
        if (!props.names) return;
        if (props.names !== assetNames) {
            setAssetNames(props.names);
            setSelectedAndType(props.names[0]);
        }
    }, [assetNames, props.names, setSelectedAndType]);

    return (
        <div className="w-full">
            <Listbox value={selectedAsset} onChange={setSelectedAndType}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border-2 sm:text-sm">
                        <span className="block truncate text-to-blueGray-800">
                            {selectedAsset.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="z-20 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {assetNames.map((option, idx) => (
                                <Listbox.Option
                                    key={idx}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active
                                                ? "bg-gray-100 text-to-blueGray-800"
                                                : "text-to-blueGray-800"
                                        }`
                                    }
                                    value={option}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${
                                                    selected
                                                        ? "font-medium"
                                                        : "font-normal"
                                                }`}
                                            >
                                                {option.name}
                                            </span>
                                            {option.name ===
                                            selectedAsset.name ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-teal-500">
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default AssetListbox;
