import dayjs from "dayjs";

export const calcByDay = (arr) => {
    let issueCount = [];
    for (let i = 0; i < 30; i++) {
        let count = 0;
        let startDate = dayjs()
            .subtract(i + 1, "day")
            .format();
        let endDate = dayjs().subtract(i, "day").format();
        for (let j = 0; j < arr.length; j++) {
            let issue = arr[j].time_raised || arr[j].time;
            if (issue > startDate && issue < endDate) {
                count++;
            }
        }
        issueCount.push(count);
    }
    return issueCount.reverse();
};

//helper function - returns the rating of the site with the highest rating if sites < 10 or gets the avg rating of the top 10% sites rounded up.
export const getTop10AvgRating = (arr) => {
    let sortedArr = arr.sort((a, b) => {
        return b.rating - a.rating;
    });
    if (arr.length === 0) {
        return 0;
    } else if (arr.length <= 10) {
        if (sortedArr[0].rating === null) return 100;
        else {
            return (sortedArr[0].rating * 100).toFixed(1);
        }
    } else {
        let numSites = Math.ceil(arr.length / 10);
        let valueArr = [];
        for (let i = 0; i < numSites; i++) {
            valueArr.push(sortedArr[i]);
        }
        let avg = 0;
        valueArr.map((val) => (avg += val.rating === null ? 1 : val.rating));
        return ((avg / valueArr.length) * 100).toFixed(1);
    }
};

//returns the rating of the site with the lowest rating if sites < 10 or gets the avg rating of the bottom 10% sites rounded up
export const getBottom10AvgRating = (arr) => {
    let sortedArr = arr.sort((a, b) => {
        return b.rating - a.rating;
    });
    if (arr.length === 0) {
        return 0;
    } else if (arr.length <= 10) {
        if (sortedArr[arr.length - 1].rating === null) return 0;
        else {
            return (sortedArr[arr.length - 1].rating * 100).toFixed(1);
        }
    } else {
        let numSites = Math.ceil(arr.length / 10);
        let valueArr = [];
        for (let i = arr.length - 1; i >= arr.length - numSites - 1; i--) {
            valueArr.push(sortedArr[i]);
        }
        let avg = 0;
        valueArr.map((val) => (avg += val.rating === null ? 1 : val.rating));
        return ((avg / valueArr.length) * 100).toFixed(1);
    }
};

//returns the downtime (hrs) of the site with the lowest rating if sites < 10 or gets the avg rating of the top 10% sites rounded up
export const getTop10AvgDowntime = (arr) => {
    let sorted = arr.sort((a, b) => {
        return b.rating - a.rating;
    });
    if (arr.length === 0) {
        return 0;
    } else if (arr.length <= 10) {
        if (sorted[0].downtime === null) return 0;
        else {
            return (sorted[0].downtime / 60).toFixed(1);
        }
    } else {
        let numSites = Math.ceil(arr.length / 10);
        let valueArr = [];
        for (let i = 0; i < numSites; i++) {
            valueArr.push(sorted[i]);
        }
        let avg = 0;
        valueArr.map(
            (val) => (avg += val.downtime === null ? 0 : val.downtime)
        );
        return (avg / 60).toFixed(1);
    }
};

//returns the rating of the site with the lowest rating if sites < 10 or gets the avg rating of the bottom 10% sites rounded up
export const getBottom10AvgDowntime = (arr) => {
    let sorted = arr.sort((a, b) => {
        return b.rating - a.rating;
    });
    if (arr.length === 0) {
        return 0;
    } else if (arr.length <= 10) {
        if (sorted[arr.length - 1].downtime === null) return 0;
        else {
            return (sorted[arr.length - 1].downtime / 60).toFixed(1);
        }
    } else {
        let numSites = Math.ceil(arr.length / 10);
        let valueArr = [];
        for (let i = arr.length - 1; i >= arr.length - numSites - 1; i--) {
            valueArr.push(sorted[i]);
        }
        let avg = 0;
        valueArr.map(
            (val) => (avg += val.downtime === null ? 0 : val.downtime)
        );
        return (avg / 60).toFixed(1);
    }
};

//returns the top 10% of site ids in an array, or the top site id if sites <= 10
export const getTop10SiteId = (arr) => {
    let sorted = arr.sort((a, b) => {
        return b.rating - a.rating;
    });
    if (arr.length === 0) {
        return [];
    } else if (arr.length <= 10) {
        let topSiteIds = [];
        topSiteIds.push(sorted[0].site_id);
        return topSiteIds;
    } else {
        let numSites = Math.ceil(arr.length / 10);
        let valueArr = [];
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            if (count === numSites) break;
            if (sorted[i].rating !== null) {
                valueArr.push(sorted[i]);
                count++;
            }
        }
        let topSiteIds = [];
        valueArr.map((val) => topSiteIds.push(val.site_id));
        return topSiteIds;
    }
};

//returns bottom 10% of site ids in an array, or the top site id if sites <= 10
export const getBottomSiteId = (arr) => {
    let sorted = arr.sort((a, b) => {
        return b.rating - a.rating;
    });
    if (arr.length === 0) {
        return [];
    } else if (arr.length <= 10) {
        let bottomSiteIds = [];
        bottomSiteIds.push(sorted[arr.length - 1].site_id);
        return bottomSiteIds;
    } else {
        let numSites = Math.ceil(arr.length / 10);
        let valueArr = [];
        for (let i = arr.length - 1; i >= arr.length - numSites; i--) {
            valueArr.push(sorted[i]);
        }
        let bottomSiteIds = [];
        valueArr.map((val) => bottomSiteIds.push(val.site_id));
        return bottomSiteIds;
    }
};

//returns the percentage change between 2 values
export const compareChange = (currentVal, lastVal) => {
    let current = Number(currentVal);
    let last = Number(lastVal);
    if (current === undefined || last === undefined) return;
    if (current === last) return 0;
    if (last === 0) {
        return 100;
    } else if (current === 0) {
        return -100;
    } else if (current !== 0 && last !== 0 && current !== last) {
        let increase = current - last;
        let val = (increase / last) * 100;
        return val;
    } else {
        return 0;
    }
};

// export const deviceOnline = (lastPacket) => {
//     if (!lastPacket) return false;
//     let rating = 0;

//     const rssi_coefficient = 3 / 30;
//     const snr_coefficient = 3 / 25;
//     const sf_coefficient = 3 / 5;
//     const rssi_offset = -120;
//     const snr_offset = -20;
//     const sf_offset = 12;

//     rating = Math.round(
//         (Math.abs((rssi_offset - lastPacket.rssi) * rssi_coefficient) +
//             Math.abs((snr_offset - lastPacket.snr) * snr_coefficient) +
//             Math.abs((sf_offset - lastPacket.sf) * sf_coefficient)) /
//             3
//     );

//     let hasPacketLastDay =
//         dayjs(lastPacket.pkt_time) > dayjs().subtract(24, "hours");

//     if (rating === 0 && hasPacketLastDay) {
//         rating = 1;
//     }
//     if (!hasPacketLastDay) {
//         rating = 0;
//     }

//     if (rating >= 1) {
//         return true;
//     } else {
//         return false;
//     }
// };

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused && "#12A690",
        backgroundColor: state.isFocused && "#FAFAFA",
    }),
    menuList: (provided, state) => ({
        ...provided,
        maxHeight: 160,
        overflowY: "auto",
    }),
    control: (provided, state) => ({
        ...provided,
        borderRadius: 8,
        borderWidth: 2,
        borderColor: state.isSelected ? "black" : "#E5E5E5",
        backgroundColor: "#FAFAFA",
        maxHeight: 100,
        overflowY: "auto",
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        backgroundColor: "#CCFBF1",
        color: "#0d9488",
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        backgroundColor: "#CCFBF1",
        color: "#0d9488",
    }),
};
