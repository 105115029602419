import React from "react";
import classnames from "classnames";
import { STATUS_CHOICES } from "../../../utils/constants";

const IprTextDetails = (props) => {
    let ipr = props.data;

    return (
        <div>
            <div className="grid grid-cols-12 sm:pt-4 pt-2 sm:space-x-5 space-x-3">
                <div className="col-span-2">
                    <div className="text-trueGray-400">DATE</div>
                    <div>{ipr.date}</div>
                </div>
                <div className="col-span-2">
                    <div className="text-trueGray-400">TIME</div>
                    <div>{ipr.time}</div>
                </div>
                <div className="col-span-4">
                    <div className="text-trueGray-400">ASSET</div>
                    <div>{ipr.asset.description}</div>
                </div>
                <div className="col-span-4">
                    <div className="text-trueGray-400">SITE</div>
                    <div>{ipr.site.name}</div>
                </div>
            </div>
            <div className="grid grid-cols-2 sm:pt-4 pt-2 space-x-5">
                <div className="col-span-1">
                    <div className="text-trueGray-400">
                        <div className="text-trueGray-400"></div>
                        STATUS
                    </div>
                    <div
                        className={classnames(
                            `inline ${props.style.bg} ${props.style.text} rounded-sm px-1 py-0.5`
                        )}
                    >
                        {STATUS_CHOICES[ipr.status].toUpperCase()}
                    </div>
                </div>
                <div className="col-start-2">
                    <div className="text-trueGray-400">SERVICE PROVIDER</div>
                    <div className="inline rounded-sm bg-indigo-100 text-indigo-600 px-1 py-0.5">
                        {ipr.provider}
                    </div>
                </div>
            </div>
            <div className="text-trueGray-400 sm:pt-3 pt-2">DESCRIPTION</div>
            <div>{ipr.description}</div>
            <div className="text-trueGray-400 sm:pt-3 pt-2">DETAILS</div>
            <div className="whitespace-pre-line">{ipr.details}</div>
        </div>
    );
};

export default IprTextDetails;
