import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { PieChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DatasetComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer,
    DatasetComponent,
    LegendComponent,
]);

const PieGraph = (props) => {
    // const [chartState, setChartState] = useState(null);
    // const [chartWidth, setChartWidth] = useState(null);
    // const [chartHeight, setChartHeight] = useState(null);
    const [totalFaults, setTotalFaults] = useState();
    const chartRef = useRef(null);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    useEffect(() => {
        if (!props.data) return;
        let count = 0;
        props.data.map((data) => {
            return (count += data.value);
        });
        setTotalFaults(count);
    }, [props]);

    // this runs every time dataarray and chartRef changes
    useEffect(() => {
        if (!chartRef.current || !chartObject) return;

        let option = {
            title: {
                itemGap: 2,
                text: "Faults",
                subtext: totalFaults,
                textStyle: {
                    fontSize: isMobile ? 8 : 12,
                    color: "#12100E",
                    fontWeight: 400,
                },
                subtextStyle: {
                    fontSize: isMobile ? 12 : 18,
                    color: "#12100E",
                    fontWeight: 800,
                },
                left: "center",
                top: "center",
            },
            tooltip: {
                position: "left",
                textStyle: {
                    fontSize: window.innerWidth < 640 ? 8 : 14,
                },
            },
            series: [
                {
                    type: "pie",
                    width: "100%",
                    height: "100%",
                    align: "center",
                    radius: ["60%", "90%"],
                    center: ["50%", "51%"],
                    avoidLabelOverlap: true,
                    legendHoverLink: true,
                    stillShowZeroSum: true,
                    data: props.data,
                    label: {
                        show: false,
                        position: "center",
                        fontSize: 18,
                    },
                },
            ],
        };
        chartObject.setOption(option);
    }, [props, chartRef, totalFaults, chartObject, isMobile]);

    return (
        <div className="w-full h-full">
            <div ref={chartRef} className="h-full w-full" />
        </div>
    );
};

export default PieGraph;
