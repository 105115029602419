import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PillButton from "../../Miscellaneous/PillButton";

const PopupDialog = (props) => {
    return (
        <Transition appear show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={() => props.close(false)}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block sm:w-120 w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading"
                            >
                                {props.title ? props.title.toUpperCase() : ""}
                            </Dialog.Title>
                            <div className="mt-4">
                                <div className="sm:text-sm text-xs">
                                    {props.title ===
                                        "ISSUES, PROMPTS AND REQUESTS" && (
                                        <div>
                                            The quantity of open Issues, Prompts
                                            and Requests are shown. The graphs
                                            show quantity arisen each day.
                                            <br />
                                            <br />
                                            <b>Issues </b>arise from assets not
                                            operating correctly or from Requests
                                            that have been turned into an Issue
                                            needing attention.
                                            <br />
                                            <br />
                                            <b>Prompts </b>arise during asset
                                            monitoring and serve to inform
                                            preventative maintenance.
                                            <br />
                                            <br />
                                            <b>Requests </b>are raised to advise
                                            on current or potential problems
                                            with an asset.
                                        </div>
                                    )}
                                    {props.title === "PERFORMANCE RATINGS" && (
                                        <div>
                                            Performance ratings are an
                                            indication on how well assets are
                                            performing with 100% indicating a
                                            perfect score.
                                            <br />
                                            <br />
                                            Performance ratings are calculated
                                            for each asset over the 24 hours up
                                            to the time of the latest dashboard
                                            load. The graphs show daily
                                            Performance for the last 30 days.
                                            <br />
                                            <br />
                                            <b>All </b>shows aggregated
                                            performance for all sites' assets.
                                            <br />
                                            <br />
                                            <b>High </b>and <b>Low </b>show
                                            Performance of the 10% best and
                                            least well performing sets of sites'
                                            assets.
                                        </div>
                                    )}
                                    {props.title === "IMPACT" && (
                                        <div>
                                            Impact shows the accumulated elapsed
                                            time for which an asset's operation
                                            is not perfect.
                                            <br />
                                            <br />
                                            Impact is shown for the 24 hours up
                                            to the time of the latest dashboard
                                            load.
                                            <br />
                                            <br />
                                            The graphs show the Impact daily for
                                            the last 30 day period.
                                            <br />
                                            <br />
                                            <b>High </b>and <b>Low </b>shows the
                                            Impact across the 10% of sites that
                                            are best and least well performing.
                                        </div>
                                    )}
                                    {props.title === "ASSET PERFORMANCE" && (
                                        <div>
                                            <b>Performance </b>shows how the
                                            asset or group of assets has been
                                            performing over the last 30 days.
                                            <br />
                                            <br />
                                            <b>Impact </b>shows the accumulated
                                            elapsed time for which an asset's or
                                            group of assets' operation is not
                                            perfect.
                                            <br />
                                            <br />
                                            <b>Graph </b>shows the asset's or
                                            group of assets' average daily
                                            performance rating over the last 30
                                            days.
                                        </div>
                                    )}
                                    {props.title === "GOALS" && (
                                        <div className="space-y-5">
                                            <div>
                                                <b>Goals </b>are a set of assets
                                                and/or asset types that can be
                                                tracked separate to all of the
                                                other assets.
                                            </div>
                                            <div>
                                                The purpose of creating goals is
                                                to split the assets into groups
                                                which share a common asset
                                                management goal.
                                            </div>
                                        </div>
                                    )}
                                    {(props.title === "Wash Programs" ||
                                        props.title === "Vacuum Sessions" ||
                                        props.title === "Door Cycles") && (
                                        <div>
                                            <b>TODAY</b> and <b>MONTH</b> show
                                            counts for the current day and month
                                            (last 30 days).
                                            <br />
                                            <br />
                                            <b>AVG DAILY</b> shows the average
                                            count for up to the last 30 days.
                                            <br />
                                            <br />
                                            <b>AVG MONTHLY</b> shows the average
                                            count per month for up to the last
                                            12 months
                                        </div>
                                    )}
                                    {props.title === "PROGRESS" && (
                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <b>Pending</b> shows the issue
                                                is open and has not been
                                                addressed.
                                            </div>
                                            <div>
                                                <b>In Progress</b> shows the
                                                issue currently being addressed
                                                either by a staff member or a
                                                service provider.
                                            </div>
                                            <div>
                                                <b>Resolved</b> shows the issue
                                                has been addressed.
                                            </div>
                                            <div>
                                                <b>Closed</b> shows the issue is
                                                no longer relevant. Users can
                                                manually close issues, or any
                                                issues that have been resolved
                                                for 24 hours will automatically
                                                close.
                                            </div>
                                        </div>
                                    )}
                                    {props.title ===
                                        "SITES AND ASSETS DATA - SESSIONS" && (
                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <b>Sessions</b> shows the number
                                                of current sessions today at
                                                this site. The number of
                                                sessions is also shown for the
                                                asset after the '/' for the same
                                                day over the previous four
                                                weeks.
                                            </div>
                                            <div>
                                                <b>Issues</b> shows the number
                                                of current total issues for the
                                                site or asset.
                                            </div>
                                            <div>
                                                <b>Impact</b> shows the
                                                accumulated elapsed time for
                                                which an asset is affected by
                                                issues, and shown in hours.
                                                Within a site impact shows as
                                                total issue duration for each
                                                asset.
                                            </div>
                                            <div>
                                                <b>30 Day %</b> shows how the
                                                site, asset or group of assets
                                                has been performing over the
                                                last 30 days.
                                            </div>
                                        </div>
                                    )}
                                    {props.title ===
                                        "SITES AND ASSETS DATA - CYCLES" && (
                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <b>Cycles</b> shows the number
                                                of current uses today at this
                                                site.
                                            </div>
                                            <div>
                                                <b>Issues</b> shows the number
                                                of current total issues for the
                                                site or asset.
                                            </div>
                                            <div>
                                                <b>Impact</b> shows the
                                                accumulated elapsed time for
                                                which an asset is affected by
                                                issues, and shown in hours.
                                                Within a site impact shows as
                                                total issue duration for each
                                                asset.
                                            </div>
                                            <div>
                                                <b>30 Day %</b> shows how the
                                                site, asset or group of assets
                                                has been performing over the
                                                last 30 days.
                                            </div>
                                        </div>
                                    )}
                                    {props.title ===
                                        "SITES AND ASSETS DATA - TEMP °C" && (
                                        <div className="flex flex-col space-y-5">
                                            <div>
                                                <b>Temp</b> shows the current
                                                temperature at this site. For
                                                HVAC the set temperature is also
                                                shown for the asset after the
                                                '/' which is averaged for all
                                                HVAC assets in the site.
                                            </div>
                                            <div>
                                                <b>Issues</b> shows the number
                                                of current total issues for the
                                                site or asset.
                                            </div>
                                            <div>
                                                <b>Impact</b> shows the
                                                accumulated elapsed time for
                                                which an asset is affected by
                                                issues, and shown in hours.
                                                Within a site impact shows as
                                                total issue duration for each
                                                asset.
                                            </div>
                                            <div>
                                                <b>30 Day %</b> shows how the
                                                site, asset or group of assets
                                                has been performing over the
                                                last 30 days.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                className="mt-4"
                                onClick={() => props.close(false)}
                            >
                                <PillButton
                                    text="THANKS, GOT IT!"
                                    textC="white"
                                    bg="teal-500"
                                />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PopupDialog;
