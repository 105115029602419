import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../utils/queryParams";
import dayjs from "dayjs";
import LiveTable from "../../components/Tables/LiveTable";
import PillButton from "../../components/Miscellaneous/PillButton";
var relativeTime = require("dayjs/plugin/relativeTime");

const QUERY_WASHWATCH_LIVE_DATA = gql`
    query QueryHasWWH($orgId: Int!, $dayAgo: timestamptz!) {
        organisation_by_pk(id: $orgId) {
            assets(
                where: {
                    measurement_points: {
                        measurement_point: { device_type: { _eq: "WASHWATCH" } }
                    }
                }
            ) {
                id
                site {
                    id
                    name
                }
                asset_make {
                    id
                    name
                }
                measurement_points {
                    id
                    measurement_point {
                        id
                        name
                        device {
                            id
                            last_packet_time
                        }
                        washwatch_sessions(
                            where: { start_time: { _gte: $dayAgo } }
                        ) {
                            id
                        }
                    }
                }
                issues(
                    limit: 1
                    order_by: { time_raised: desc }
                    where: { time_resolved: { _is_null: true } }
                ) {
                    id
                    description
                }
                faults(
                    where: { time_resolved: { _is_null: true } }
                    limit: 1
                    order_by: { time_raised: desc }
                ) {
                    id
                    description
                    type {
                        id
                        description
                        fault_code
                    }
                }
            }
        }
    }
`;

const COLUMN_TITLES_BROWSER = [
    { Header: "Site", accessor: "site" },
    { Header: "Asset Make", accessor: "make" },
    {
        Header: "Last Seen",
        accessor: "lastSeen",
        Cell: ({ value }) => {
            return dayjs.unix(value).fromNow();
        },
    },
    { Header: "Sessions Today", accessor: "sessionCount" },
    { Header: "Current Active Fault", accessor: "fault" },
    { Header: "Current Active Issue", accessor: "issue" },
];

const COLUMN_TITLES_MOBILE = [
    { Header: "Site", accessor: "site" },
    {
        Header: "Last Seen",
        accessor: "lastSeen",
        Cell: ({ value }) => {
            return dayjs.unix(value).fromNow();
        },
    },
    { Header: "Sessions Today", accessor: "sessionCount" },
    { Header: "Current Active Fault", accessor: "fault" },
    { Header: "Current Active Issue", accessor: "issue" },
];

const LiveReport = (props) => {
    dayjs.extend(relativeTime);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 840 ? false : true;
    const orgId = getQueryParameter("organisationId");
    const [WashwatchArray, setWashwatchArray] = useState([]);

    const { data, error, refetch, loading } = useQuery(
        QUERY_WASHWATCH_LIVE_DATA,
        {
            variables: {
                orgId: orgId,
                dayAgo: dayjs().startOf("day"),
            },
            onError: () => console.log(error),
        }
    );

    useEffect(() => {
        if (data && data.organisation_by_pk) {
            let wwhArr = [];
            data.organisation_by_pk.assets.map((asset) => {
                return wwhArr.push({
                    make: asset.asset_make.name,
                    site: asset.site.name,
                    sessionCount: asset.measurement_points[0]
                        ? asset.measurement_points[0].measurement_point
                              .washwatch_sessions.length
                        : "No Measurement Point",
                    lastSeen: asset.measurement_points[0]
                        ? dayjs(
                              asset.measurement_points[0].measurement_point
                                  .device.last_packet_time
                          ).unix()
                        : 0,
                    issue: asset.issues[0]
                        ? asset.issues[0].description
                        : "None",
                    fault: asset.faults[0]
                        ? asset.faults[0].type.description
                        : "None",
                });
            });
            wwhArr.sort((a, b) => {
                return b.lastSeen - a.lastSeen;
            });
            setWashwatchArray(wwhArr);
        }
    }, [data]);

    return (
        <div className="w-full h-full overflow-y-auto pt-10">
            <div className="flex flex-row justify-between">
                <div className={`text-teal-600 text-xl lg:text-2xl mb-5 `}>
                    Car Wash Status
                </div>
                <div onClick={() => refetch()} className="w-20">
                    <PillButton
                        text="REFRESH"
                        bg="teal-500"
                        textC="white"
                        loading={loading}
                    />
                </div>
            </div>
            <div className="flex flex-row items-center">
                {WashwatchArray.length > 0 && (
                    <div className="bg-white xl:w-2/3 w-full border-trueGray-300 border">
                        <LiveTable
                            headerStyle="grid grid-cols-8 px-4 gap-x-1 lg:py-2 py-1"
                            rowStyle="grid grid-cols-8 lg:px-4 px-3 sm:py-4 py-3 border-t hover:text-teal-500 cursor-pointer"
                            data={WashwatchArray}
                            titles={
                                isMobile
                                    ? COLUMN_TITLES_MOBILE
                                    : COLUMN_TITLES_BROWSER
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiveReport;
