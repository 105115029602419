import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { LineChart } from "echarts/charts";
import { GridComponent, DatasetComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components
echarts.use([GridComponent, LineChart, CanvasRenderer, DatasetComponent]);

const LineGraph = (props) => {
    const chartRef = useRef(null);
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    //setting chart
    useEffect(() => {
        if (!chartRef.current || !chartObject) return;

        let option = {
            xAxis: {
                show: false,
                type: "category",
                data: Array.from(Array(30).keys()),
            },

            yAxis: {
                type: "value",
                show: false,
                min: props.min,
                max: props.max,
            },
            series: [
                {
                    data: props.data,
                    type: "line",
                    showSymbol: false,
                    itemStyle: {
                        color: "black",
                    },
                    lineStyle: {
                        width: 1,
                        color: "#25213A",
                    },
                },
            ],
        };
        chartObject.setOption(option);
    }, [props.data, props.max, props.min, chartObject]);

    if (props === undefined) return <div></div>;
    return (
        <div className="h-full w-full">
            {props.title && (
                <div className="xl:text-xl text-xs lg:text-lg sm:text-base font-extrabold mr-2 mb-2">
                    {props.title}
                </div>
            )}
            <div ref={chartRef} className="h-full w-full" />
        </div>
    );
};

export default LineGraph;
