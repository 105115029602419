import React, { useState, useEffect, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Select from "react-select";
import { getQueryParameter } from "../../../utils/queryParams";
import { customStyles } from "../../../utils/helperFunctions";
import PillButton from "../../Miscellaneous/PillButton";
import ErrorPanel from "../../Panels/ErrorPanel";

const QUERY_SITES_AND_ASSETS_FROM_ORG = gql`
    query querySiteZoneAssets($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            name
            sites {
                id
                name
            }
            assets {
                id
                description
                site {
                    id
                    name
                }
                asset_type {
                    id
                    name
                }
            }
            asset_types {
                id
                name
            }
        }
    }
`;

const MUTATION_ADD_GOAL = gql`
    mutation addGoal(
        $name: String!
        $description: String!
        $organisation_id: Int!
        $assetTypes: ppcs_core_goal_asset_types_arr_rel_insert_input
        $assets: ppcs_core_goal_assets_arr_rel_insert_input
    ) {
        insert_ppcs_core_goal_one(
            object: {
                assets: $assets
                name: $name
                organisation_id: $organisation_id
                description: $description
            }
        ) {
            id
            name
            description
        }
    }
`;

const AddGoalForm = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const orgId = getQueryParameter("organisationId");
    const [allSites, setAllSites] = useState();
    const [allAssets, setAllAssets] = useState();
    const [allAssetTypes, setAllAssetTypes] = useState();
    const [selectedAssetType, setSelectedAssetType] = useState();
    const [selectedSites, setSelectedSites] = useState();
    const [selectedAssets, setSelectedAssets] = useState();
    const [errorMessage, setErrorMessage] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
    });
    const { data: siteAssetData, error: siteAssetDataError } = useQuery(
        QUERY_SITES_AND_ASSETS_FROM_ORG,
        {
            variables: {
                orgId: orgId,
            },
            onError: () => console.log(siteAssetDataError),
        }
    );

    const [addGoal] = useMutation(MUTATION_ADD_GOAL);

    useEffect(() => {
        if (!siteAssetData) return;

        let typeArr = [];
        siteAssetData.organisation_by_pk.asset_types.map((type) => {
            return typeArr.push({
                value: type.id,
                label: type.name,
            });
        });
        setAllAssetTypes(typeArr);

        let siteArr = [];
        siteAssetData.organisation_by_pk.sites.map((site) => {
            return siteArr.push({
                value: site.id,
                label: site.name,
            });
        });
        setAllSites(siteArr);

        let assetsArr = [];
        siteAssetData.organisation_by_pk.assets.map((asset) => {
            return assetsArr.push({
                value: asset.id,
                label: `[${asset.site.name}] ${asset.description}`,
                asset: asset,
                site: asset.site.id,
            });
        });

        setAllAssets(assetsArr);
    }, [siteAssetData]);

    useEffect(() => {
        let selected = [];
        if (!allAssets) return;

        if (
            selectedAssetType &&
            selectedAssetType.length > 0 &&
            selectedSites &&
            selectedSites.length > 0
        ) {
            let assets = allAssets.filter(
                (asset) =>
                    selectedAssetType
                        .map((type) => type.value)
                        .includes(asset.asset.asset_type.id) &&
                    selectedSites.map((site) => site.value).includes(asset.site)
            );
            selected.push(...assets);
        } else if (selectedAssetType && selectedAssetType.length > 0) {
            let assets = allAssets.filter((asset) =>
                selectedAssetType
                    .map((type) => type.value)
                    .includes(asset.asset.asset_type.id)
            );
            selected.push(...assets);
        } else if (selectedSites && selectedSites.length > 0) {
            let assets = allAssets.filter((asset) =>
                selectedSites.map((site) => site.value).includes(asset.site)
            );
            selected.push(...assets);
        }
        setSelectedAssets(selected);
    }, [allAssets, allSites, selectedAssetType, selectedSites]);

    const validateForm = useCallback(() => {
        if (formData.name === "") {
            setErrorMessage("Please enter a name for your goal");
        } else if (formData.description === "") {
            setErrorMessage("Please enter a description for your goal");
        } else if (!selectedAssets || selectedAssets.length < 1) {
            setErrorMessage("Please select at least one asset or asset type");
        } else {
            let assetIds = [];
            let assetTypeIds = [];

            if (selectedAssets && selectedAssets.length > 0) {
                selectedAssets.map((asset) => {
                    return assetIds.push({ asset_id: asset.value });
                });
            }
            addGoal({
                variables: {
                    name: formData.name,
                    description: formData.description,
                    organisation_id: orgId,
                    assetTypes:
                        assetTypeIds.length > 0
                            ? { data: assetTypeIds }
                            : { data: [] },
                    assets:
                        assetIds.length > 0 ? { data: assetIds } : { data: [] },
                },
            })
                .then((r) => {
                    console.log("goal added");
                    props.refetch();
                    props.close(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [formData, selectedAssets, addGoal, orgId, props]);

    const handleInputChange = (event) => {
        event.persist();
        setFormData((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <div
            className={`${
                isMobile ? "mt-5" : "mt-10"
            } bg-white xl:w-2/5 sm:w-5/12 w-full border-2 rounded-lg h-auto float-left space-y-5 py-4 px-4 sm:text-base text-xs`}
        >
            <div className="text-xl">New Goal</div>
            <div className="px-4">
                <div>GOAL NAME</div>
                <input
                    name="name"
                    className="w-full bg-trueGray-50 border-2 rounded-lg h-8 px-2"
                    value={formData.name}
                    onChange={handleInputChange}
                />
            </div>
            <div className="px-4">
                <div>GOAL DESCRIPTION</div>
                <textarea
                    name="description"
                    className="w-full bg-trueGray-50 border-2 rounded-lg h-20 px-2"
                    value={formData.description}
                    onChange={handleInputChange}
                />
            </div>
            <div className="px-4">
                <div>SITES</div>
                <Select
                    value={selectedSites}
                    onChange={setSelectedSites}
                    options={allSites}
                    isMulti={true}
                    styles={customStyles}
                />
            </div>
            <div className="px-4">
                <div>ASSET TYPES</div>
                <Select
                    value={selectedAssetType}
                    onChange={setSelectedAssetType}
                    options={allAssetTypes}
                    isMulti={true}
                    styles={customStyles}
                />
            </div>
            <div className="px-4">
                <div>ASSETS</div>
                <Select
                    value={selectedAssets}
                    onChange={setSelectedAssets}
                    options={allAssets}
                    isMulti={true}
                    styles={customStyles}
                />
            </div>
            {errorMessage && <ErrorPanel message={errorMessage} />}
            <div className="flex flex-row gap-x-5 px-5 pb-5">
                <div className="w-1/2" onClick={validateForm}>
                    <PillButton text="CONFIRM" bg="white" textC="teal-500" />
                </div>
                <div className="w-1/2" onClick={() => props.close(false)}>
                    <PillButton text="CANCEL" bg="teal-500" textC="white" />
                </div>
            </div>
        </div>
    );
};

export default AddGoalForm;
