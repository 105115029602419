import React, { useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import IssueDetails from "../IssueDetails";
import PromptDetails from "../PromptDetails";
import RequestDetails from "../RequestDetails";

const IprDetails = (props) => {
    const [title, setTitle] = useState(null);
    return (
        <div className="p-5 text-blueGray-800">
            <div className="flex flex-row justify-between">
                <div className="text-xl sm:text-2xl">{title}</div>
                <div
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => {
                        if (props.onCloseClicked) props.onCloseClicked();
                    }}
                >
                    <XIcon />
                </div>
            </div>
            {props.issueId && (
                <IssueDetails
                    {...props}
                    goToAssetButton
                    issueId={props.issueId}
                    setTitle={(title) => {
                        setTitle(title);
                    }}
                    onStatusUpdated={(status) => {
                        if (status && status >= 10) {
                            if (props.onCloseClicked) props.onCloseClicked();
                        }
                        if (props.onStatusUpdated) props.onStatusUpdated();
                    }}
                />
            )}
            {props.promptId && (
                <PromptDetails
                    promptId={props.promptId}
                    setTitle={(title) => {
                        setTitle(title);
                    }}
                    onStatusUpdated={(status) => {
                        if (props.onCloseClicked) props.onCloseClicked();
                        if (props.onStatusUpdated) props.onStatusUpdated();
                    }}
                />
            )}
            {props.requestId && (
                <RequestDetails
                    requestId={props.requestId}
                    setTitle={(title) => {
                        setTitle(title);
                    }}
                    onStatusUpdated={(status) => {
                        if (props.onCloseClicked) props.onCloseClicked();
                        if (props.onStatusUpdated) props.onStatusUpdated();
                    }}
                />
            )}
        </div>
    );
};

export default IprDetails;
