import classNames from "classnames";
import React from "react";

const ReportCard = (props) => {
    return (
        <div className={"cursor-pointer"}>
            <div
                className={
                    props.selected
                        ? "font-semibold text-lg px-3 pt-2 text-teal-500"
                        : "font-semibold text-lg px-3 pt-2"
                }
            >
                {props.title}
            </div>
            <div className="text-sm text-trueGray-400 px-3 pb-2">
                {props.description}
            </div>
            <div
                className={classNames(
                    "sm:border-b-2",
                    props.border && "border-b-2"
                )}
            />
        </div>
    );
};

export default ReportCard;
