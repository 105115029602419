import React from "react";
import classnames from "classnames";

const Timeline = (props) => {
    const state = props.issue.status;
    return (
        <div>
            <div className="flex flex-row py-2">
                <div className="flex flex-row w-1/3">
                    <div
                        className={classnames(
                            `w-full ${state > 0 ? "bg-teal-500" : "bg-zinc-300"}
                            } h-1 self-center`
                        )}
                    />
                </div>
                <div className="flex flex-row w-1/3">
                    <div
                        className={classnames(
                            `w-full ${state > 2 ? "bg-teal-500" : "bg-zinc-300"}
                            } h-1 self-center`
                        )}
                    />
                </div>
                <div className="flex flex-row w-1/3">
                    <div
                        className={classnames(
                            `w-full ${state > 9 ? "bg-teal-500" : "bg-zinc-300"}
                            } h-1 self-center`
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default Timeline;
