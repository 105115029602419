import React, { useState, useEffect, useCallback } from "react";
import PillButton from "../../Miscellaneous/PillButton/PillButton";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import SelectedDetails from "../../Text/SelectedDetails/SelectedDetails";

const QUERY_PROMPT_DETAILS = gql`
    query promptDetails($promptId: Int!) {
        prompt_by_pk(id: $promptId) {
            id
            description
            details
            time_raised
            status
            asset {
                id
                description
                site {
                    id
                    name
                }
            }
        }
    }
`;

const MUTATION_CLOSE_PROMPT = gql`
    mutation closePrompt($promptId: Int!, $resolved: timestamptz!) {
        update_prompt_by_pk(
            pk_columns: { id: $promptId }
            _set: { status: 10, time_resolved: $resolved }
        ) {
            id
            status
            time_resolved
        }
    }
`;

const PromptDetails = (props) => {
    const [submitStatus, setSubmitStatus] = useState("open");
    const [selectedDetails, setSelectedDetails] = useState();
    const [confirmClose, setConfirmClose] = useState(false);

    //mutations
    const [closePrompt] = useMutation(MUTATION_CLOSE_PROMPT);

    //query data from selected prompt
    const [getPromptData, { data: promptData, error: promptDataError }] =
        useLazyQuery(QUERY_PROMPT_DETAILS, {
            onError: () => console.log(promptDataError),
            variables: {
                promptId: props.promptId,
            },
        });

    //passing prompt id into lazy query
    useEffect(() => {
        if (!props.promptId) return;
        getPromptData({
            variables: {
                promptId: props.promptId,
            },
        });
    }, [props.promptId, getPromptData]);

    //getting data from query for selected prompt
    useEffect(() => {
        if (promptData === undefined || promptData.prompt_by_pk === undefined)
            return;
        let promptDetails = {
            date: dayjs(promptData.prompt_by_pk.time_raised).format(
                "YYYY-MM-DD"
            ),
            promptId: promptData.prompt_by_pk.id,
            time: dayjs(promptData.prompt_by_pk.time_raised).format("HH:mm"),
            asset: promptData.prompt_by_pk.asset.description,
            site: promptData.prompt_by_pk.asset.site.name,
            description: promptData.prompt_by_pk.description,
            details: promptData.prompt_by_pk.details,
            status: promptData.prompt_by_pk.status,
        };
        setSelectedDetails(promptDetails);
        if (props.setTitle) props.setTitle(promptDetails.asset);
    }, [promptData, props]);

    //mutation to update issue/prompt to acknowledged(status = 1)
    const handleConfirm = useCallback(() => {
        //close a prompt
        if (props.promptId !== undefined && confirmClose) {
            closePrompt({
                variables: {
                    promptId: props.promptId,
                    resolved: dayjs().format(),
                },
            })
                .then((r) => {
                    // console.log("prompt closed");
                    setConfirmClose(false);
                    props.onStatusUpdated();
                    // history.push(
                    //     `?filter=${filterParam}&organisationId=${orgId}`
                    // );
                })
                .catch((e) => console.log(e));
            //acknowledge a prompt
        }
    }, [props, confirmClose, closePrompt]);

    if (selectedDetails === undefined) return <div></div>;
    return (
        <div className="text-blueGray-800">
            <SelectedDetails {...selectedDetails} />
            {selectedDetails.status < 10 && (
                <div>
                    <div className="text-trueGray-400 py-4">ACTIONS</div>
                    <div className="flex flex-row gap-x-5">
                        {/* closing an issue or prompt */}
                        {!props.requestId &&
                            !confirmClose &&
                            submitStatus === "open" && (
                                <div
                                    className="pb-2 w-1/2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmClose(true);
                                        setSubmitStatus("confirm");
                                    }}
                                >
                                    <PillButton
                                        text="CLOSE PROMPT"
                                        bg="teal-500"
                                        textC="white"
                                    />
                                </div>
                            )}
                    </div>
                    {/* confirm action  */}
                    {submitStatus === "confirm" && (
                        <div className="flex flex-row pb-4 gap-x-4">
                            <div onClick={handleConfirm} className="w-1/2">
                                <PillButton
                                    text="CONFIRM"
                                    bg="teal-500"
                                    textC="white"
                                />
                            </div>
                            <div
                                onClick={() => {
                                    setSubmitStatus("open");
                                    setConfirmClose(false);
                                }}
                                className="w-1/2"
                            >
                                <PillButton
                                    text="CANCEL"
                                    bg="white"
                                    textC="teal-500"
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PromptDetails;
