import React, { useState, useEffect, useCallback } from "react";
import PillButton from "../../Miscellaneous/PillButton/PillButton";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import SelectedDetails from "../../Text/SelectedDetails/SelectedDetails";

const QUERY_REQUEST_DETAILS = gql`
    query requestDetails($requestId: Int!) {
        request_by_pk(id: $requestId) {
            id
            details
            description
            open
            priority
            organisation_id
            reporter_id
            asset_id
            organisation {
                id
                short_name
            }
            asset {
                id
                description
                site {
                    id
                    name
                    short_name
                }
            }
            time
        }
    }
`;

const QUERY_ISSUE_COUNT = gql`
    query issueCount($siteId: Int!) {
        site_by_pk(id: $siteId) {
            id
            issues(limit: 1, order_by: { time_raised: desc }) {
                id
                key
            }
        }
    }
`;

const MUTATION_CLOSE_REQUEST = gql`
    mutation closeRequest($requestId: Int!, $timeClosed: timestamptz!) {
        update_request_by_pk(
            pk_columns: { id: $requestId }
            _set: { open: false, time_closed: $timeClosed }
        ) {
            id
            open
        }
    }
`;

const MUTATION_ISSUE_FROM_REQUEST = gql`
    mutation requestToIssue($object: issue_insert_input!) {
        insert_issue_one(object: $object) {
            id
            time_raised
            status
            key
            organisation_id
            description
            summary
            source
            request_id
            reporter_id
            asset_id
            site_id
            priority
            locally_resolvable
        }
    }
`;

const MUTATION_PROMPT_FROM_REQUEST = gql`
    mutation requestToPrompt($object: prompt_insert_input!) {
        insert_prompt_one(object: $object) {
            id
            description
            details
            asset_id
            organisation_id
            time_raised
            status
            request_id
            updated_at
        }
    }
`;

const RequestDetails = (props) => {
    const [submitStatus, setSubmitStatus] = useState("open");
    const [selectedDetails, setSelectedDetails] = useState();
    // const [editTicket, setEditTicket] = useState(false);
    // const [ticketDetails, setTicketDetails] = useState({
    //     ticket: "",
    // });
    const [totalIssueCount, setTotalIssueCount] = useState();
    const [convertIssue, setConvertIssue] = useState(false);
    const [convertPrompt, setConvertPrompt] = useState(false);

    //mutations
    const [closeRequest] = useMutation(MUTATION_CLOSE_REQUEST);
    const [requestToIssue] = useMutation(MUTATION_ISSUE_FROM_REQUEST);
    const [requestToPrompt] = useMutation(MUTATION_PROMPT_FROM_REQUEST);

    //query data from selected request
    const [getRequestData, { data: requestData, error: requestDataError }] =
        useLazyQuery(QUERY_REQUEST_DETAILS, {
            onError: () => console.log(requestDataError),
            variables: {
                requestId: props.requestId,
            },
        });

    const [
        getIssueCount,
        {
            data: issueCountData,
            error: issueCountError,
            refetch: refetchIssueCount,
        },
    ] = useLazyQuery(QUERY_ISSUE_COUNT, {
        fetchPolicy: "network-only",
        onError: () => console.log(issueCountError),
    });

    // setting variables for lazy query to get issue count for a site
    useEffect(() => {
        if (
            selectedDetails === undefined ||
            selectedDetails.siteId === undefined
        )
            return;
        getIssueCount({
            variables: {
                siteId: selectedDetails.siteId,
            },
        });
    }, [getIssueCount, selectedDetails]);

    //passing request Id into lazy query
    useEffect(() => {
        if (!props.requestId) return;
        getRequestData({
            variables: {
                requestId: props.requestId,
            },
        });
    }, [props.requestId, getRequestData]);

    useEffect(() => {
        if (
            issueCountData === undefined ||
            issueCountData.site_by_pk === undefined
        )
            return;
        if (issueCountData.site_by_pk.issues.length < 1) {
            setTotalIssueCount(0);
            return;
        }
        let key = issueCountData.site_by_pk.issues[0].key;
        let totalCount = Number(key.split("-")[2]);
        setTotalIssueCount(totalCount + 1);
    }, [issueCountData]);

    //getting data from query for selected request
    useEffect(() => {
        if (
            requestData === undefined ||
            requestData.request_by_pk === undefined ||
            requestData.request_by_pk.organisation === undefined ||
            requestData.request_by_pk.asset === undefined
        )
            return;
        let requestDetails = {
            date: dayjs(requestData.request_by_pk.time).format("YYYY-MM-DD"),
            time: dayjs(requestData.request_by_pk.time).format("HH:mm"),
            dateTime: dayjs(requestData.request_by_pk.time),
            asset: requestData.request_by_pk.asset.description,
            site: requestData.request_by_pk.asset.site.name,
            status: requestData.request_by_pk.open,
            description: requestData.request_by_pk.description,
            details: requestData.request_by_pk.details,
            requestId: requestData.request_by_pk.id,
            siteId: requestData.request_by_pk.asset.site.id,
            assetId: requestData.request_by_pk.asset_id,
            siteShortName: requestData.request_by_pk.asset.site.short_name,
            priority: requestData.request_by_pk.priority,
            orgShortName: requestData.request_by_pk.organisation.short_name,
            orgId: requestData.request_by_pk.organisation_id,
            reporterId: requestData.request_by_pk.reporter_id,
        };
        setSelectedDetails(requestDetails);
        if (props.setTitle) props.setTitle(requestDetails.asset);
    }, [props, requestData]);

    // mutation to update issue/prompt to acknowledged(status = 1)
    const handleConfirm = useCallback(() => {
        //convert request to issue
        if (convertIssue && props.requestId) {
            let issueData = {
                time_raised: dayjs().format(),
                status: 0,
                key: `${selectedDetails.orgShortName}-${selectedDetails.siteShortName}-${totalIssueCount}`,
                organisation_id: selectedDetails.orgId,
                description: selectedDetails.details,
                summary: selectedDetails.description,
                source: "User",
                locally_resolvable: false,
                request_id: selectedDetails.requestId,
                reporter_id: selectedDetails.reporterId,
                asset_id: selectedDetails.assetId,
                site_id: selectedDetails.siteId,
                priority: selectedDetails.priority === true ? 4 : 3,
            };
            requestToIssue({
                variables: {
                    object: issueData,
                },
            });
            closeRequest({
                variables: {
                    requestId: props.requestId,
                    timeClosed: dayjs().format(),
                },
            }).then((r) => {
                // console.log("request converted to issue, request closed");
                setConvertIssue(false);
                if (props.onStatusUpdated) props.onStatusUpdated(false);
                refetchIssueCount({
                    variables: {
                        siteId: selectedDetails.siteId,
                    },
                });
                // history.push(
                //     `?filter=${filterParam}&organisationId=${orgId}`
                // );
            });
            //convert request to prompt
        } else if (convertPrompt && props.requestId) {
            let promptData = {
                time_raised: dayjs().format(),
                status: 0,
                organisation_id: selectedDetails.orgId,
                description: selectedDetails.description,
                details: selectedDetails.details,
                request_id: selectedDetails.requestId,
                asset_id: selectedDetails.assetId,
                updated_at: dayjs().format(),
            };
            requestToPrompt({
                variables: {
                    object: promptData,
                },
            });
            closeRequest({
                variables: {
                    requestId: props.requestId,
                    timeClosed: dayjs().format(),
                },
            }).then((r) => {
                // console.log("request converted to prompt, request closed");
                setConvertPrompt(false);
                if (props.onStatusUpdated) props.onStatusUpdated(false);
            });
            // history.push(`?filter=${filterParam}&organisationId=${orgId}`);
        }
    }, [
        convertIssue,
        props,
        convertPrompt,
        selectedDetails,
        totalIssueCount,
        requestToIssue,
        closeRequest,
        refetchIssueCount,
        requestToPrompt,
    ]);

    if (selectedDetails === undefined) return <div></div>;
    return (
        <div>
            <SelectedDetails {...selectedDetails} />
            {selectedDetails.status && (
                <div>
                    <div className="text-trueGray-400 py-4">ACTIONS</div>
                    <div>
                        {props.requestId && submitStatus !== "confirm" && (
                            <div className="flex flex-row pb-4 gap-x-4">
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSubmitStatus("confirm");
                                        setConvertIssue(true);
                                        setConvertPrompt(false);
                                    }}
                                    className="w-1/2"
                                >
                                    <PillButton
                                        text="CONVERT TO ISSUE"
                                        bg="teal-500"
                                        textC="white"
                                    />
                                </div>
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSubmitStatus("confirm");
                                        setConvertPrompt(true);
                                        setConvertIssue(false);
                                    }}
                                    className="w-1/2"
                                >
                                    <PillButton
                                        text="CONVERT TO PROMPT"
                                        bg="white"
                                        textC="teal-500"
                                    />
                                </div>
                            </div>
                        )}
                        {/* confirm action  */}
                        {submitStatus === "confirm" && (
                            <div className="flex flex-row pb-4 gap-x-4">
                                <div onClick={handleConfirm} className="w-1/2">
                                    <PillButton
                                        text="CONFIRM"
                                        bg="teal-500"
                                        textC="white"
                                    />
                                </div>
                                <div
                                    onClick={() => {
                                        setSubmitStatus("open");
                                        setConvertIssue(false);
                                        setConvertPrompt(false);
                                    }}
                                    className="w-1/2"
                                >
                                    <PillButton
                                        text="CANCEL"
                                        bg="white"
                                        textC="teal-500"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RequestDetails;
