import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import TimeFilter from "../../Input/TimeFilter";
import dayjs from "dayjs";
import { getQueryParameter } from "../../../utils/queryParams";
import StackedBarGraph from "../../Graphs/StackedBarGraph";
import DoorBarGraph from "../../Graphs/DoorBarGraph";
import HeatMap from "../../Graphs/HeatMap";

const QUERY_WASHWATCH_PROGRAMS = gql`
    query getWWHPrograms(
        $assetId: Int!
        $start: timestamptz!
        $end: timestamptz!
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    program1: washwatch_sessions(
                        where: {
                            start_time: { _gte: $start, _lte: $end }
                            program_number: { _eq: 1 }
                        }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                    program2: washwatch_sessions(
                        where: {
                            start_time: { _gte: $start, _lte: $end }
                            program_number: { _eq: 2 }
                        }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                    program3: washwatch_sessions(
                        where: {
                            start_time: { _gte: $start, _lte: $end }
                            program_number: { _eq: 3 }
                        }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                    program4: washwatch_sessions(
                        where: {
                            start_time: { _gte: $start, _lte: $end }
                            program_number: { _eq: 4 }
                        }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                    program5: washwatch_sessions(
                        where: {
                            start_time: { _gte: $start, _lte: $end }
                            program_number: { _eq: 5 }
                        }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                    program6: washwatch_sessions(
                        where: {
                            start_time: { _gte: $start, _lte: $end }
                            program_number: { _eq: 6 }
                        }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                    total: washwatch_sessions(
                        where: { start_time: { _gte: $start, _lte: $end } }
                        order_by: { start_time: desc }
                    ) {
                        id
                        start_time
                    }
                }
            }
        }
    }
`;
const QUERY_DOOR_DATA = gql`
    query getDoorData(
        $assetId: Int!
        $start: timestamptz!
        $end: timestamptz!
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    doorinformer_measurement_data(
                        order_by: { time: desc }
                        where: { time: { _gte: $start, _lte: $end } }
                    ) {
                        id
                        time
                        count1
                        people
                    }
                }
            }
        }
    }
`;

const QUERY_VAC_SESSIONS = gql`
    query getVacSessions(
        $assetId: Int!
        $start: timestamptz!
        $end: timestamptz!
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    vac_sessions(
                        order_by: { start_time: desc }
                        where: { start_time: { _gte: $start, _lte: $end } }
                    ) {
                        id
                        start_time
                    }
                }
            }
        }
    }
`;
const titles = {
    DoorInformer: "Door Cycles",
    WASHWATCH: "Wash Programs",
    VAC: "Vacuum Sessions",
};
const WashProgramPanel = (props) => {
    const assetId = getQueryParameter("assetId");
    const [startTime, setStartTime] = useState(
        dayjs().subtract(30, "days").startOf("day")
    );
    const [doorBarGraphData, setDoorBarGraphData] = useState();
    const [endTime, setEndTime] = useState(dayjs().format());
    const [programTotals, setProgramTotals] = useState();
    const [timeFilter, setTimeFilter] = useState();
    const [xAxisData, setxAxisData] = useState();
    const [programHeatMap, setProgramHeatMap] = useState();
    const [timePeriod, setTimePeriod] = useState();
    const [device, setDevice] = useState();

    const { data: programData, error: programDataError } = useQuery(
        QUERY_WASHWATCH_PROGRAMS,
        {
            skip: props.type !== "WASHWATCH",
            variables: {
                assetId: assetId,
                start: startTime,
                end: endTime,
            },
            onError: () => console.log(programDataError),
        }
    );

    const { data: doorData, error: doorDataError } = useQuery(QUERY_DOOR_DATA, {
        skip: props.type !== "DoorInformer",
        variables: {
            assetId: assetId,
            start: startTime,
            end: endTime,
        },
        onError: () => console.log(doorDataError),
    });

    const { data: vacSessionData, error: vacSessionDataError } = useQuery(
        QUERY_VAC_SESSIONS,
        {
            skip: props.type !== "VAC",
            variables: {
                assetId: assetId,
                start: startTime,
                end: endTime,
            },
            onError: () => console.log(vacSessionDataError),
        }
    );

    useEffect(() => {
        if (
            !vacSessionData ||
            !vacSessionData.asset_by_pk ||
            !vacSessionData.asset_by_pk.measurement_points[0] ||
            !vacSessionData.asset_by_pk.measurement_points[0]
                .measurement_point ||
            !vacSessionData.asset_by_pk.measurement_points[0].measurement_point
                .vac_sessions
        )
            return;
        if (
            vacSessionData.asset_by_pk.measurement_points[0].measurement_point
                .device_type !== "VAC"
        )
            return;
        let vacData =
            vacSessionData.asset_by_pk.measurement_points[0].measurement_point
                .vac_sessions;
        let hours = Math.abs(dayjs(startTime).diff(dayjs(endTime), "hours"));

        setTimePeriod(hours);
        setProgramHeatMap(vacData);
        setDevice(
            vacSessionData.asset_by_pk.measurement_points[0].measurement_point
                .device_type
        );
        setProgramTotals(sortPrograms(vacData, startTime, endTime));
    }, [endTime, startTime, vacSessionData]);

    useEffect(() => {
        if (
            !doorData ||
            !doorData.asset_by_pk ||
            !doorData.asset_by_pk.measurement_points[0] ||
            !doorData.asset_by_pk.measurement_points[0].measurement_point ||
            doorData.asset_by_pk.measurement_points[0].measurement_point
                .device_type !== "DoorInformer" ||
            !doorData.asset_by_pk.measurement_points[0].measurement_point
                .doorinformer_measurement_data
        )
            return;
        let doorCounts =
            doorData.asset_by_pk.measurement_points[0].measurement_point
                .doorinformer_measurement_data;

        setDoorBarGraphData(sortDoorCycles(doorCounts, startTime, endTime));
        let hours = Math.abs(dayjs(startTime).diff(dayjs(endTime), "hours"));
        setTimePeriod(hours);
        setProgramHeatMap(doorCounts);
        setDevice(
            doorData.asset_by_pk.measurement_points[0].measurement_point
                .device_type
        );
    }, [doorData, endTime, startTime]);

    const sortDoorCycles = (cycles, start, end) => {
        let daysToCount = dayjs(end).diff(dayjs(start), "days");
        let countPerDay = [];
        let times = [];
        if (daysToCount === 1) {
            times.push(dayjs().subtract(1, "days").format("DD/MM"));
        } else if (daysToCount === 0) {
            times.push(dayjs().format("DD/MM"));
        } else {
            for (let i = 0; i < daysToCount; i++) {
                times.push(dayjs().subtract(i, "days").format("DD/MM"));
            }
        }
        times = times.reverse();
        let doorCycles = cycles.filter(
            (cycle) => !(cycle.count1 === 0 && cycle.people === 0)
        );
        times.map((time) => {
            let peopleCount = 0;
            let cycleCount = 0;
            doorCycles.map((cycle) => {
                if (time === dayjs(cycle.time).format("DD/MM")) {
                    peopleCount += cycle.people;
                    cycleCount += cycle.count1;
                }
                return null;
            });
            return countPerDay.push({
                people: peopleCount,
                cycles: cycleCount,
            });
        });

        setxAxisData(times);
        return countPerDay;
    };

    useEffect(() => {
        if (
            !programData ||
            !programData.asset_by_pk ||
            !programData.asset_by_pk.measurement_points[0] ||
            !programData.asset_by_pk.measurement_points[0].measurement_point ||
            !programData.asset_by_pk.measurement_points[0].measurement_point
                .program1 ||
            !programData.asset_by_pk.measurement_points[0].measurement_point
                .program2 ||
            !programData.asset_by_pk.measurement_points[0].measurement_point
                .program3 ||
            !programData.asset_by_pk.measurement_points[0].measurement_point
                .program4 ||
            !programData.asset_by_pk.measurement_points[0].measurement_point
                .program5 ||
            !programData.asset_by_pk.measurement_points[0].measurement_point
                .program6
        )
            return;
        if (
            programData.asset_by_pk.measurement_points[0].measurement_point
                .device_type !== "WASHWATCH"
        )
            return;
        let p1 =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .program1;
        let p2 =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .program2;
        let p3 =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .program3;
        let p4 =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .program4;
        let p5 =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .program5;
        let p6 =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .program6;
        let total =
            programData.asset_by_pk.measurement_points[0].measurement_point
                .total;
        let hours = Math.abs(dayjs(startTime).diff(dayjs(endTime), "hours"));

        setTimePeriod(hours);
        setProgramHeatMap(total);
        setDevice(
            programData.asset_by_pk.measurement_points[0].measurement_point
                .device_type
        );

        let programTotals = {
            p1: sortPrograms(p1, startTime, endTime),
            p2: sortPrograms(p2, startTime, endTime),
            p3: sortPrograms(p3, startTime, endTime),
            p4: sortPrograms(p4, startTime, endTime),
            p5: sortPrograms(p5, startTime, endTime),
            p6: sortPrograms(p6, startTime, endTime),
        };
        setProgramTotals(programTotals);
    }, [endTime, programData, startTime]);

    useEffect(() => {
        if (!timeFilter) return;
        setStartTime(timeFilter.start);
        setEndTime(timeFilter.end);
    }, [timeFilter]);

    const sortPrograms = (programs, start, end) => {
        let daysToCount = dayjs(end).diff(dayjs(start), "days");
        let countPerDay = [];
        let times = [];
        if (daysToCount === 1) {
            times.push(dayjs().subtract(1, "days").format("DD/MM"));
        } else if (daysToCount === 0) {
            times.push(dayjs().format("DD/MM"));
        } else {
            for (let i = 0; i < daysToCount; i++) {
                times.push(dayjs().subtract(i, "days").format("DD/MM"));
            }
        }
        times = times.reverse();
        times.map((time) => {
            let count = 0;
            programs.map((program) => {
                if (time === dayjs(program.start_time).format("DD/MM")) {
                    count++;
                }
                return null;
            });
            return countPerDay.push(count);
        });
        setxAxisData(times);
        return countPerDay;
    };

    if (!programTotals && !doorBarGraphData) return <div></div>;
    return (
        <div className="border-2 rounded-md bg-white flex flex-col">
            <div className="flex flex-row justify-between pt-2 px-4">
                <div className="text-xl lg:text-2xl">{titles[device]}</div>
                <div className="w-36 z-10">
                    <TimeFilter setTime={setTimeFilter} />
                </div>
            </div>
            <div className="flex sm:flex-row flex-col">
                <div className="sm:w-3/4 px-3">
                    {programTotals && (
                        <StackedBarGraph
                            data={programTotals}
                            xAxis={xAxisData}
                        />
                    )}
                    {doorBarGraphData && (
                        <DoorBarGraph
                            data={doorBarGraphData}
                            xAxis={xAxisData}
                        />
                    )}
                </div>
                {programHeatMap && (
                    <div className="sm:w-1/2 w-full sm:h-auto h-44 sm:ml-0 ml-5">
                        <HeatMap
                            data={programHeatMap}
                            hours={timePeriod}
                            device={device}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default WashProgramPanel;
