import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";

const DataTable = (props) => {
    const columns = useMemo(() => props.titles, [props.titles]);
    const data = useMemo(() => props.data, [props.data]);
    const [defaultSorting, setDefaultSorting] = useState(true); // Flag to indicate initial sorting

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }, // Access the sorting state
    } = useTable(
        {
            columns,
            data,
            autoResetSortBy: false,
            initialState: defaultSorting
                ? {
                      sortBy: [{ id: "sessionCount", desc: false }], // Set the default sorting
                  }
                : {},
        },
        useGlobalFilter,
        useSortBy
    );
    useEffect(() => {
        if (defaultSorting && sortBy.length > 0) {
            setDefaultSorting(false); // Disable the initial sorting flag once the user interacts with sorting
        }
    }, [defaultSorting, sortBy]);

    const handleCellColor = (colId, val) => {
        if (colId === "lastSeen") {
            let timeVal = dayjs.unix(val);
            let current = dayjs();
            let diffHrs = current.diff(timeVal, "hour");
            if (diffHrs > 23) {
                return "text-red-500";
            } else {
                return "text-teal-500";
            }
        } else if (colId === "fault" || colId === "issue") {
            if (val === "None") {
                return "text-teal-500";
            } else {
                return "text-red-500";
            }
        } else if (colId === "sessionCount") {
            if (val === 0) {
                return "text-red-500";
            } else {
                return "text-teal-500";
            }
        } else {
            return "";
        }
    };

    return (
        <table {...getTableProps()} className="w-full">
            <thead className="text-trueGray-400 xl:text-sm sm:text-2xs text-3xs bg-coolGray-50">
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className={`grid grid-cols-${props.titles.length} justify-items-start`}
                    >
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className="w-full border-white border-l-4 flex flex-row justify-between items-center px-1 py-2 pl-2"
                            >
                                <span
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="hover:underline flex flex-row justify-between"
                                >
                                    {column.render("Header")}
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <ArrowUpIcon className="sm:w-5 w-4 h-4 sm:h-5" />
                                        ) : (
                                            <ArrowDownIcon className="sm:w-5 w-4 h-4 sm:h-5" />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody
                {...getTableBodyProps()}
                className="xl:text-sm text-2xs max-h-144 block overflow-y-auto"
            >
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={`grid grid-cols-${props.titles.length} justify-items-start px-3 sm:py-1.5 py-0.5 border-t`}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className={`sm:py-1 py-0.5 pl-3 self-center ${handleCellColor(
                                            cell.column.id,
                                            cell.value
                                        )}`}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default DataTable;
