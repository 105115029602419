import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../../utils/queryParams";

const AutoCompleteSearch = (props) => {
  const orgId = getQueryParameter("organisationId");
  const userId = getQueryParameter("userId");
  const history = useHistory();
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (selected && !userId) {
      props.setUser(selected.name);
      history.push(`/settings?organisationId=${orgId}&userId=${selected.id}`);
    }
  }, [selected, history, orgId, props, userId]);

  const filteredOptions =
    query === ""
      ? props.options
      : props.options.filter((option) =>
          option.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default rounded-lg bg-coolGray-50 text-left sm:text-sm">
            <div className="bg-white text-base">
              Start typing the user's email address
            </div>
            <Combobox.Input
              className="w-full py-2 pl-3 pr-10 text-sm text-gray-900 border-2 rounded-md"
              displayValue={""}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center">
              <SearchIcon
                className="h-6 w-6 text-blueGray-800 mt-5 mr-3"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            {filteredOptions && (
              <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base sm:text-sm border-2">
                {filteredOptions.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? "bg-teal-500 text-white" : "text-gray-900"
                        }`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option.name}
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            )}
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default AutoCompleteSearch;
