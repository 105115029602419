import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import IssueDetails from "../../Panels/IssueDetails";
import PromptDetails from "../../Panels/PromptDetails";
import RequestDetails from "../../Panels/RequestDetails";

const IprDetailsModal = (props) => {
  const [title, setTitle] = useState(null);
  const [issueId, setIssueId] = useState(null);
  const [promptId, setPromptId] = useState(null);
  const [requestId, setRequestId] = useState(null);

  useEffect(() => {
    if (!props.item) return;
    if (props.item.type === "issue") {
      setIssueId(props.item.id);
      setPromptId(null);
      setRequestId(null);
    }
    if (props.item.type === "prompt") {
      setPromptId(props.item.id);
      setIssueId(null);
      setRequestId(null);
    }
    if (props.item.type === "request") {
      setRequestId(props.item.id);
      setIssueId(null);
      setPromptId(null);
    }
  }, [props.item]);

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => props.closeClicked()}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block sm:w-120 w-full sm:p-6 p-5 sm:my-8 my-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
              <Dialog.Title as="h3" className="text-lg font-medium leading">
                <div className="flex flex-row justify-between">
                  <div className=" text-2xl">{title}</div>
                  <div
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => props.closeClicked()}
                  >
                    <XIcon />
                  </div>
                </div>
              </Dialog.Title>
              <div>
                {issueId && (
                  <IssueDetails
                    {...props}
                    issueId={issueId}
                    setTitle={(title) => {
                      setTitle(title);
                    }}
                    onStatusUpdated={(status) => {
                      if (status && status >= 10) {
                        if (props.onCloseClicked) props.onCloseClicked();
                      }
                      if (props.onStatusUpdated) props.onStatusUpdated();
                    }}
                  />
                )}
                {promptId && (
                  <PromptDetails
                    promptId={promptId}
                    setTitle={(title) => {
                      setTitle(title);
                    }}
                    onStatusUpdated={(status) => {
                      if (props.onCloseClicked) props.onCloseClicked();
                      if (props.onStatusUpdated) props.onStatusUpdated();
                    }}
                  />
                )}
                {requestId && (
                  <RequestDetails
                    requestId={requestId}
                    setTitle={(title) => {
                      setTitle(title);
                    }}
                    onStatusUpdated={(status) => {
                      if (props.onCloseClicked) props.onCloseClicked();
                      if (props.onStatusUpdated) props.onStatusUpdated();
                    }}
                  />
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default IprDetailsModal;
