import React, { useState, useEffect } from "react";
import DisplayValue from "../../Text/DisplayValue";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { getQueryParameter } from "../../../utils/queryParams";

const QUERY_SPACEMON_DATA = gql`
    query getSpaceMonData($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    spacemon_measurement_data(
                        order_by: { time: desc }
                        limit: 1
                    ) {
                        id
                        co2_concentration
                        temperature
                        relative_humidity
                    }
                }
            }
        }
    }
`;

const EnvironmentMonitoringPanel = (props) => {
    const assetId = getQueryParameter("assetId");
    const [environmentData, setEnvironmentData] = useState();

    const { data: spacemonData, error: spaceMonError } = useQuery(
        QUERY_SPACEMON_DATA,
        {
            variables: {
                assetId: assetId,
            },
            onError: () => console.log(spaceMonError),
        }
    );

    useEffect(() => {
        if (
            !spacemonData ||
            !spacemonData.asset_by_pk ||
            !spacemonData.asset_by_pk.measurement_points[0] ||
            !spacemonData.asset_by_pk.measurement_points[0].measurement_point
        )
            return;
        let data =
            spacemonData.asset_by_pk.measurement_points[0].measurement_point
                .spacemon_measurement_data;
        setEnvironmentData(data[0]);
        return;
    }, [spacemonData]);
    return (
        <div className="sm:h-30 h-24 rounded-lg bg-white border-2 px-3 py-1">
            <div className="flex flex-row justify-between">
                <div className="text-lg xl:text-2xl">
                    Environment Monitoring
                </div>
                <div className="self-center xl:self-end text-trueGray-400 xl:text-base text-xs">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row w-3/4 py-4 lg:px-10 lg:gap-x-12 gap-x-5 px-2">
                <div>
                    {environmentData ? (
                        <DisplayValue
                            title="CO2"
                            value={environmentData.co2_concentration}
                            unit="PPM"
                        />
                    ) : (
                        <DisplayValue title="CO2" value="N/A" unit="PPM" />
                    )}
                </div>
                <div>
                    {environmentData ? (
                        <DisplayValue
                            title="TEMPERATURE"
                            value={environmentData.temperature.toFixed(1)}
                            unit="°C"
                        />
                    ) : (
                        <DisplayValue
                            title="TEMPERATURE"
                            value="N/A"
                            unit="°C"
                        />
                    )}
                </div>
                <div>
                    {environmentData ? (
                        <DisplayValue
                            title="HUMIDITY"
                            value={environmentData.relative_humidity}
                            unit="%"
                        />
                    ) : (
                        <DisplayValue title="HUMIDITY" value="N/A" unit="%" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EnvironmentMonitoringPanel;
