import React, { useState, useEffect, useCallback } from "react";
import PillButton from "../../Miscellaneous/PillButton/PillButton";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import IprTextDetails from "../../Text/IprTextDetails/IprTextDetails";
import { STATUS_CHOICES, STATE_STYLES } from "../../../utils/constants";
import Timeline from "../../Miscellaneous/Timeline";
import { InformationCircleIcon } from "@heroicons/react/outline";
import PopupDialog from "../../Modals/PopupDialog";
import ConfirmServiceProviderModal from "../../Modals/ConfirmServiceProviderModal";
import TransitionHistoryDetails from "../../Text/TransitionHistoryDetails/TransitionHistoryDetails";
import { useHistory } from "react-router";
import NotesDisclosure from "../../Miscellaneous/NotesDisclosure";

const QUERY_ISSUE_DETAILS = gql`
    query issueDetails($issueId: Int!) {
        issue_by_pk(id: $issueId) {
            id
            description
            key
            summary
            time_raised
            locally_resolvable
            status
            ticket
            organisation_id
            service_provider {
                id
                name
            }
            site {
                id
                name
            }
            issue_states(
                order_by: { time: asc }
                where: { _not: { type: { _eq: "service_provider_update" } } }
            ) {
                id
                time
                value
                type
            }
            asset {
                id
                description
                service_partner {
                    id
                    organisation_id
                }
                asset_type {
                    id
                    service_partner {
                        id
                        organisation_id
                    }
                }
            }
            notes(order_by: { time: desc }) {
                id
                description
                time
                user {
                    id
                    first_name
                    email
                    last_name
                }
            }
        }
    }
`;

const MUTATION_SET_ISSUE_TO_IN_PROGRESS = gql`
    mutation acknowledgeIssue($issueId: Int!) {
        update_issue_by_pk(pk_columns: { id: $issueId }, _set: { status: 2 }) {
            id
            status
        }
    }
`;

const MUTATION_CLOSE_ISSUE = gql`
    mutation closeIssue($issueId: Int!, $resolved: timestamptz!) {
        update_issue_by_pk(
            pk_columns: { id: $issueId }
            _set: { status: 10, time_resolved: $resolved }
        ) {
            id
            status
            time_resolved
        }
    }
`;

const IssueDetails = (props) => {
    const [submitStatus, setSubmitStatus] = useState("open");
    const [selectedDetails, setSelectedDetails] = useState();
    const [confirmClose, setConfirmClose] = useState(false);
    const [issueStateStyle, setIssueStateStyle] = useState();
    const [openInfo, setOpenInfo] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [notes, setNotes] = useState([]);
    const history = useHistory();

    //mutations
    const [setIssueInProgress] = useMutation(MUTATION_SET_ISSUE_TO_IN_PROGRESS);
    const [closeIssue] = useMutation(MUTATION_CLOSE_ISSUE);

    //query data from selected issue
    const [
        getIssueData,
        {
            data: issueData,
            error: issueDataError,
            refetch: refetchIssueDetails,
        },
    ] = useLazyQuery(QUERY_ISSUE_DETAILS, {
        onError: () => console.log(issueDataError),
    });

    useEffect(() => {
        if (!props.issueId) return;
        getIssueData({
            variables: {
                issueId: props.issueId,
            },
        });
    }, [props.issueId, getIssueData]);

    //getting data from query for selected issue
    useEffect(() => {
        if (!issueData || !issueData.issue_by_pk) return;
        let issueDetails = {
            id: issueData.issue_by_pk.id,
            date: dayjs(issueData.issue_by_pk.time_raised).format("YYYY-MM-DD"),
            time: dayjs(issueData.issue_by_pk.time_raised).format("HH:mm"),
            asset: issueData.issue_by_pk.asset,
            site: issueData.issue_by_pk.site,
            key: issueData.issue_by_pk.key,
            service_provider: issueData.issue_by_pk.service_provider,
            description: issueData.issue_by_pk.summary,
            details: issueData.issue_by_pk.description,
            status: issueData.issue_by_pk.status,
            ticket: issueData.issue_by_pk.ticket,
            provider: issueData.issue_by_pk.locally_resolvable
                ? "LOCALLY RESOLVABLE"
                : issueData.issue_by_pk.service_provider
                ? issueData.issue_by_pk.service_provider.name
                : "No service provider",
            providerId: issueData.issue_by_pk.asset.service_partner
                ? issueData.issue_by_pk.asset.service_partner.organisation_id
                : issueData.issue_by_pk.asset.asset_type.service_partner
                ? issueData.issue_by_pk.asset.asset_type.service_partner
                      .organisation_id
                : null,
            issueState: issueData.issue_by_pk.issue_states,
            organisation_id: issueData.issue_by_pk.organisation_id,
        };
        let issueState = STATUS_CHOICES[issueData.issue_by_pk.status];
        setIssueStateStyle(STATE_STYLES[issueState]);
        setNotes(issueData.issue_by_pk.notes);
        setSelectedDetails(issueDetails);
        if (props.setTitle) props.setTitle(issueDetails.key);
    }, [issueData, props]);

    //mutation to update issue/prompt to acknowledged(status = 1)
    const handleConfirm = useCallback(() => {
        //close an issue
        if (props.issueId !== undefined && confirmClose) {
            closeIssue({
                variables: {
                    issueId: props.issueId,
                    resolved: dayjs().format(),
                },
            })
                .then((r) => {
                    console.log("issue closed");
                    setConfirmClose(false);
                    setSubmitStatus("open");
                    if (props.onStatusUpdated)
                        props.onStatusUpdated(selectedDetails.status);
                })
                .catch((e) => console.log(e));
        }
        //set an issue to in progress
        if (props.issueId !== undefined && !confirmClose) {
            setIssueInProgress({
                variables: {
                    issueId: props.issueId,
                },
            })
                .then((r) => {
                    console.log("issue in progress");
                    setSubmitStatus("open");
                    if (props.onStatusUpdated)
                        props.onStatusUpdated(selectedDetails.status);
                })
                .catch((e) => console.log(e));
        }
    }, [props, confirmClose, closeIssue, setIssueInProgress, selectedDetails]);

    const viewAsset = () => {
        history.push(
            `assetDetails?organisationId=${selectedDetails.organisation_id}&assetId=${selectedDetails.asset.id}`
        );
    };

    if (selectedDetails === undefined) return <div></div>;
    return (
        <div className=" text-blueGray-800 sm:text-base text-xs overflow-y-auto">
            {props.issueId && (
                <div>
                    <IprTextDetails
                        data={selectedDetails}
                        style={issueStateStyle}
                    />
                    <NotesDisclosure
                        notes={notes}
                        {...props}
                        refetch={refetchIssueDetails}
                    />
                    <div className="flex flex-row space-x-3 mt-2">
                        <div className="text-trueGray-400 pb-3">PROGRESS</div>
                        <div
                            className="w-4 h-4 sm:pt-1 cursor-pointer"
                            onClick={() => setOpenInfo(true)}
                        >
                            <InformationCircleIcon />
                        </div>
                    </div>
                    <div>
                        <PopupDialog
                            open={openInfo}
                            close={setOpenInfo}
                            title="PROGRESS"
                        />
                    </div>
                    <div className="w-full sm:pb-4 pb-2">
                        <Timeline issue={selectedDetails} />
                        <TransitionHistoryDetails issue={selectedDetails} />
                    </div>
                </div>
            )}
            {selectedDetails.status < 10 && (
                <div className="text-trueGray-400 pb-3">ACTIONS</div>
            )}
            <div className="flex flex-row gap-x-5">
                {props.issueId &&
                    selectedDetails.status === 0 &&
                    submitStatus === "open" && (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                setSubmitStatus("confirm");
                            }}
                            className="sm:pb-4 pb-2 w-1/2"
                        >
                            <PillButton
                                text="SET IN PROGRESS"
                                bg="white"
                                textC="teal-500"
                            />
                        </div>
                    )}
                {/* closing an issue or prompt */}
                {!confirmClose &&
                    selectedDetails.status !== 10 &&
                    submitStatus !== "confirm" && (
                        <div
                            className="pb-2 w-1/2"
                            onClick={(e) => {
                                e.preventDefault();
                                setConfirmClose(true);
                                setSubmitStatus("confirm");
                            }}
                        >
                            <PillButton
                                text="CLOSE ISSUE"
                                bg="teal-500"
                                textC="white"
                            />
                        </div>
                    )}
            </div>
            {
                //open issues
                props.issueId &&
                    selectedDetails.status === 2 &&
                    selectedDetails.provider === "LOCALLY RESOLVABLE" &&
                    selectedDetails.providerId !== null && (
                        <div className="pb-4">
                            <div>
                                <ConfirmServiceProviderModal
                                    title={selectedDetails.key}
                                    issue={selectedDetails}
                                    open={openConfirm}
                                    close={setOpenConfirm}
                                    refetch={refetchIssueDetails}
                                />
                            </div>
                            <div onClick={() => setOpenConfirm(true)}>
                                <PillButton
                                    text="ASSIGN TO SERVICE PROVIDER"
                                    bg="white"
                                    textC="teal-500"
                                />
                            </div>
                        </div>
                    )
            }
            {/* confirm action  */}
            {submitStatus === "confirm" && (
                <div className="flex flex-row sm:pb-4 sm:gap-x-4 gap-x-3 pb-2">
                    <div onClick={handleConfirm} className="w-1/2">
                        <PillButton
                            text="CONFIRM"
                            bg="teal-500"
                            textC="white"
                        />
                    </div>
                    <div
                        onClick={() => {
                            setSubmitStatus("open");
                            setConfirmClose(false);
                        }}
                        className="w-1/2"
                    >
                        <PillButton text="CANCEL" bg="white" textC="teal-500" />
                    </div>
                </div>
            )}
            {submitStatus !== "confirm" &&
                !confirmClose &&
                props.goToAssetButton && (
                    <div onClick={viewAsset}>
                        <PillButton
                            text="VIEW ASSET"
                            bg="white"
                            textC="teal-500"
                        />
                    </div>
                )}
        </div>
    );
};

export default IssueDetails;
