import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import FaultTable from "../../Tables/FaultTable";
import dayjs from "dayjs";
import PieGraph from "../../Graphs/PieGraph";
import TimeFilter from "../../Input/TimeFilter";
import { getQueryParameter } from "../../../utils/queryParams";

const titles = [
    { Header: "Time Raised", accessor: "timeRaised" },
    { Header: "Time Resolved", accessor: "timeResolved" },
    { Header: "Fault", accessor: "fault" },
];

const QUERY_ASSET_FAULTS = gql`
    query getFaults($assetId: Int!, $start: timestamptz!, $end: timestamptz!) {
        asset_by_pk(id: $assetId) {
            id
            faults(
                order_by: { time_raised: desc }
                where: { time_raised: { _gte: $start, _lte: $end } }
            ) {
                id
                time_raised
                time_resolved
                description
                alarm_configuration {
                    id
                    description
                }
                type {
                    id
                    module
                    part_code
                    fault_code
                    description
                    asset_make {
                        id
                        name
                        description
                    }
                }
            }
        }
    }
`;

const FaultPanel = (props) => {
    const assetId = getQueryParameter("assetId");
    const [activeFaults, setActiveFaults] = useState();
    const [sortedFaultCount, setSortedFaultCount] = useState();
    const [timeFilter, setTimeFilter] = useState();
    const [startTime, setStartTime] = useState(
        dayjs().subtract(30, "days").startOf("day")
    );
    const [endTime, setEndTime] = useState(dayjs().format());

    const { data: faultData, error: faultDataError } = useQuery(
        QUERY_ASSET_FAULTS,
        {
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                assetId: assetId,
                start: startTime,
                end: endTime,
            },
            onError: () => console.log(faultDataError),
        }
    );

    useEffect(() => {
        if (
            !faultData ||
            !faultData.asset_by_pk ||
            !faultData.asset_by_pk.faults
        )
            return;
        let faults = [];
        setSortedFaultCount(sortFaults(faultData.asset_by_pk.faults));
        faultData.asset_by_pk.faults.map((fault) => {
            let description = !fault.type
                ? fault.description
                : fault.type.description;

            if (fault.alarm_configuration) {
                description += " - " + fault.alarm_configuration.description;
            }
            return faults.push({
                timeRaised: dayjs(fault.time_raised).format("DD-MM-YYYY HH:mm"),
                timeResolved: fault.time_resolved
                    ? dayjs(fault.time_resolved).format("DD-MM-YYYY HH:mm")
                    : "---",
                fault: description,
            });
        });
        setActiveFaults(faults);
    }, [faultData]);

    useEffect(() => {
        if (!timeFilter) return;
        setStartTime(timeFilter.start);
        setEndTime(timeFilter.end);
    }, [timeFilter]);

    const sortFaults = (faults) => {
        let faultTypes = {};
        for (let i = 0; i < faults.length; i++) {
            if (faults[i].type) {
                if (faultTypes[faults[i].type.description] === undefined) {
                    faultTypes[faults[i].type.description] = 1;
                } else {
                    faultTypes[faults[i].type.description] += 1;
                }
            } else {
                if (!faultTypes[faults[i].description]) {
                    faultTypes[faults[i].description] = 1;
                } else {
                    faultTypes[faults[i].description] += 1;
                }
            }
        }
        let types = [];
        for (const [key, value] of Object.entries(faultTypes)) {
            types.push({
                name: key,
                value: value,
            });
        }
        return types;
    };

    if (!activeFaults || !sortedFaultCount) return <div></div>;

    return (
        <div className="border-2 rounded-lg bg-white">
            <div className="flex flex-row justify-between pt-1.5 px-4">
                <div className="text-xl lg:text-2xl">Faults</div>
                <div className="w-36 z-10">
                    <TimeFilter setTime={setTimeFilter} />
                </div>
            </div>
            <div className="flex flex-row">
                <div className="sm:w-2/3 w-3/4">
                    <div className="sm:pl-2 pl-0.5">
                        <FaultTable data={activeFaults} titles={titles} />
                    </div>
                </div>
                <div className="sm:w-1/3 w-1/4 self-center h-40">
                    <PieGraph data={sortedFaultCount} />
                </div>
            </div>
        </div>
    );
};

export default FaultPanel;
