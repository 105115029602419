import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { MenuIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import DynamicHeroIcon from "../../Miscellaneous/DynamicHeroIcon";
import { Logo } from "../../../assets/Svgs/Logo";
import DefaultFooter from "../../Layout/DefaultFooter";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const MobileSideNav = (props) => {
    const currentUrl = window.location.pathname;

    let [categories] = useState({
        Home: "HomeIcon",
        Goals: "PresentationChartLineIcon",
        Issues: "ExclamationIcon",
        Prompts: "ChartSquareBarIcon",
        Requests: "QuestionMarkCircleIcon",
        Reports: "ClipboardCheckIcon",
        Assets: "OfficeBuildingIcon",
        Settings: "CogIcon",
    });

    return (
        <Menu as="div" className="relative inline-block text-left z-99">
            <div>
                <Menu.Button
                    className={`inline-flex w-full mt-2 justify-center rounded-md bg-opacity-20 py-2 text-white hover:bg-opacity-30`}
                >
                    <MenuIcon className="h-6 w-6 ml-4" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-in-out duration-500 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-500 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
            >
                <Menu.Items
                    className={`absolute flex flex-col left-0 z-40 h-screen -mt-14 origin-top-right bg-blueGray-800 shadow-lg`}
                >
                    <Menu.Item>
                        <div className="flex py-8 self-center">
                            <Logo width={40} height={40} />
                            <div className="text-white self-center ml-2">
                                BenchMarker
                            </div>
                        </div>
                    </Menu.Item>
                    <div className="flex flex-col py-2 px-4 gap-y-4 overflow-y-auto h-screen">
                        {Object.keys(categories).map((category, index) => (
                            <Menu.Item
                                key={category}
                                className={({ selected }) =>
                                    classNames(
                                        "grid grid-cols-2 h-10 text-sm w-40 pr-12 justify-between",
                                        currentUrl.includes(
                                            category.toLowerCase()
                                        )
                                            ? "text-teal-500"
                                            : "text-white"
                                    )
                                }
                            >
                                <Link
                                    key={index}
                                    to={
                                        category === "Issues" ||
                                        category === "Prompts" ||
                                        category === "Requests"
                                            ? `/${category.toLocaleLowerCase()}?filter=open&organisationId=${
                                                  props.orgId
                                              }`
                                            : category === "Assets"
                                            ? `/${category.toLocaleLowerCase()}?filter=low&organisationId=${
                                                  props.orgId
                                              }`
                                            : `/${category.toLowerCase()}?organisationId=${
                                                  props.orgId
                                              }`
                                    }
                                >
                                    <DynamicHeroIcon
                                        icon={categories[category]}
                                        height={5}
                                        width={5}
                                    />
                                    <div className="self-center pl-2">
                                        {category}
                                    </div>
                                </Link>
                            </Menu.Item>
                        ))}
                        <div className="pt-5">
                            <DefaultFooter />
                        </div>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default MobileSideNav;
