import React, { useState, useEffect } from "react";
import DynamicHeroIcon from "../../Miscellaneous/DynamicHeroIcon";
import classnames from "classnames";

const DataChange = (props) => {
    const [icon, setIcon] = useState();
    const [colour, setColour] = useState();

    useEffect(() => {
        if (props.valueChange < 0 && props.colour === "green") {
            setColour("red-600");
            setIcon("ChevronDownIcon");
        } else if (props.valueChange < 0 && props.colour === "red") {
            setColour("teal-500");
            setIcon("ChevronDownIcon");
        } else if (props.valueChange > 0 && props.colour === "green") {
            setColour("teal-500");
            setIcon("ChevronUpIcon");
        } else if (props.valueChange > 0 && props.colour === "red") {
            setColour("red-600");
            setIcon("ChevronUpIcon");
        } else {
            setColour("gray-400");
            setIcon("ChevronRightIcon");
        }
    }, [props]);

    if (
        icon === undefined ||
        colour === undefined ||
        props.valueChange === undefined
    )
        return <div></div>;

    return (
        <div
            className={classnames(
                `text-${colour}`,
                "sm:text-xs text-3xs font-semibold flex flex-row pt-2"
            )}
        >
            <DynamicHeroIcon icon={icon} height={4} width={4} />
            <div className="math 2xl:pr-5">
                {Number(props.valueChange).toFixed(1)}% mo/mo
            </div>
        </div>
    );
};

export default DataChange;
