import React, { useState, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import zlogo from "../../../assets/imgs/z_logo.png";
import BNZLogo from "../../../assets/imgs/BNZ-logo.png";
import CDSLogo from "../../../assets/imgs/CDS.png";
import classnames from "classnames";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../../utils/queryParams";
import { useMemo } from "react";
import classNames from "classnames";

const QUERY_ORG_INFO_FROM_ID = gql`
    query getSelectedOrganisation($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            logo_image_filename
            name
            theme
        }
    }
`;

const QUERY_USERS_ORGANISATIONS = gql`
    query getUsersOrganisations {
        organisation(order_by: { name: asc }) {
            id
            name
            theme
            logo_image_filename
            access_roles {
                organisation_id
            }
        }
    }
`;

const logos = {
    "z_logo.png": zlogo,
    "BNZ-logo.png": BNZLogo,
    "CDS.png": CDSLogo,
};

const OrganisationSelector = (props) => {
    const history = useHistory();
    const [selected, setSelected] = useState();
    const [orgName, setOrgName] = useState();
    const [orgLogo, setOrgLogo] = useState();
    // const [orgPrimaryColor, setOrgPrimaryColor] = useState(null);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const filterParam = getQueryParameter("filter");
    const orgIdParam = getQueryParameter("organisationId");
    const siteIdParam = getQueryParameter("siteId");
    const assetIdParam = getQueryParameter("assetId");

    const { data: orgData, error: orgError } = useQuery(
        QUERY_ORG_INFO_FROM_ID,
        {
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                orgId: orgIdParam,
            },
            onError: () => console.log(orgError),
        }
    );

    const { data: userOrgsData, error: userOrgsDataError } = useQuery(
        QUERY_USERS_ORGANISATIONS,
        {
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            onError: () => console.log(userOrgsDataError),
        }
    );

    const orgOptions = useMemo(() => {
        if (!userOrgsData) return;
        let options = [];
        userOrgsData.organisation.map((org) => {
            if (org.access_roles.length > 0) {
                return options.push(org);
            } else {
                return null;
            }
        });
        return options;
    }, [userOrgsData]);

    useEffect(() => {
        if (!orgData || !orgData.organisation_by_pk) return;
        setOrgName(orgData.organisation_by_pk.name);
        setOrgLogo(orgData.organisation_by_pk.logo_image_filename);
    }, [orgData]);

    useEffect(() => {
        if (!selected) return;
        let url = "?";
        if (filterParam) url += `filter=${filterParam}`;
        if (orgIdParam) {
            if (filterParam) {
                url += `&organisationId=${selected.id}`;
            } else {
                url += `organisationId=${selected.id}`;
            }
        }
        if (siteIdParam) {
            url += `&siteId=${siteIdParam}`;
        }
        if (assetIdParam) {
            url += `&assetId=${assetIdParam}`;
        }
        history.push(url);
    }, [selected, history, filterParam, orgIdParam, siteIdParam, assetIdParam]);

    if (!orgOptions || !orgName) return <div></div>;

    return (
        <div
            className={classNames(
                "h-full z-2",
                orgOptions.length === 1 && "mr-2"
            )}
        >
            <Listbox
                value={selected}
                onChange={setSelected}
                disabled={orgOptions.length === 1}
            >
                <div className={classNames(`relative mt-1 grid justify-end}`)}>
                    <Listbox.Button className="relative w-full text-right">
                        <span
                            className={`truncate flex flex-row justify-end pt-1 ${
                                isMobile ? "text-white" : "text-blueGray-800"
                            }`}
                        >
                            {orgLogo ? (
                                <div
                                    className={classNames(
                                        orgName === "Z Energy"
                                            ? `bg-indigo-900 w-12 h-12 rounded-full`
                                            : `bg-white w-12 h-12 rounded-full`
                                    )}
                                >
                                    <div
                                        className={
                                            orgLogo === "BNZ-logo.png"
                                                ? classnames("pl-1")
                                                : classnames("pl-3 pt-3")
                                        }
                                    >
                                        <img
                                            src={logos[orgLogo]}
                                            alt=""
                                            className={
                                                orgLogo === "BNZ-logo.png"
                                                    ? classnames(`w-11 h-11`)
                                                    : classnames(`w-6 h-6`)
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="w-11 h-11">
                                    <OfficeBuildingIcon />
                                </div>
                            )}
                            <div
                                className={`text-${
                                    isMobile || orgName.length > 16
                                        ? "xs"
                                        : "xl"
                                } sm:pl-7 pl-3 sm:pr-2.5 pr-1 self-center `}
                            >
                                {!selected ? orgName : selected.name}
                            </div>
                            {orgOptions.length > 1 && (
                                <ChevronDownIcon className="sm:h-5 sm:w-5 w-4 h-4 self-center sm:mr-0 mr-1" />
                            )}
                        </span>
                    </Listbox.Button>
                    <Listbox.Options className="justify-self-end min-w-44 max-w-60 py-1 mt-2 overflow-y-auto max-h-60 text-base bg-white rounded-lg border-2 hover:text-teal-500">
                        {orgOptions.map((org, personIdx) => (
                            <Listbox.Option
                                key={personIdx}
                                className={({ active }) =>
                                    `${
                                        active
                                            ? "text-gray-900 bg-gray-100"
                                            : "text-gray-900"
                                    }
                            cursor-pointer select-none relative py-2 px-4`
                                }
                                value={org}
                            >
                                {({ selected, active }) => (
                                    <>
                                        <span
                                            className={`${
                                                selected
                                                    ? "font-medium"
                                                    : "font-normal"
                                            } block truncate`}
                                        >
                                            {org.name}
                                        </span>
                                        {selected ? (
                                            <span
                                                className={`${
                                                    active
                                                        ? "text-amber-600"
                                                        : "text-amber-600"
                                                }
                                  absolute inset-y-0 left-0 flex items-center pl-3`}
                                            ></span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
        </div>
    );
};

export default OrganisationSelector;
