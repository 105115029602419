import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { gql, useQuery, useMutation } from "@apollo/client";
import Select from "react-select";
import { customStyles } from "../../../utils/helperFunctions";
import { getQueryParameter } from "../../../utils/queryParams";
import PillButton from "../../Miscellaneous/PillButton";
import ErrorPanel from "../../Panels/ErrorPanel";

const QUERY_SITES_AND_ASSETS_FROM_ORG = gql`
    query querySiteZoneAssets($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            sites {
                id
                name
                assets {
                    id
                    description
                }
            }
        }
    }
`;

const MUTATION_ADD_ASSET_TO_GOAL = gql`
    mutation AddAssetToGoal(
        $assets: [ppcs_core_goal_assets_insert_input!] = {}
    ) {
        insert_ppcs_core_goal_assets(objects: $assets) {
            returning {
                asset_id
                goal_id
                id
            }
        }
    }
`;
const AddGoalAssetModal = (props) => {
    const orgId = getQueryParameter("organisationId");
    const goalId = getQueryParameter("goalId");
    const [allSites, setAllSites] = useState();
    const [allAssets, setAllAssets] = useState();
    const [selectedSites, setSelectedSites] = useState();
    const [selectedAssets, setSelectedAssets] = useState();
    const [errorMessage, setErrorMessage] = useState(false);

    const { data: siteAssetData, error: siteAssetDataError } = useQuery(
        QUERY_SITES_AND_ASSETS_FROM_ORG,
        {
            variables: {
                orgId: orgId,
            },
            onError: () => console.log(siteAssetDataError),
        }
    );
    const [addAsset] = useMutation(MUTATION_ADD_ASSET_TO_GOAL);

    useEffect(() => {
        if (
            !siteAssetData ||
            !siteAssetData.organisation_by_pk ||
            !siteAssetData.organisation_by_pk.sites
        )
            return;
        let sites = siteAssetData.organisation_by_pk.sites;
        let siteArr = [];
        sites.map((site) => {
            return siteArr.push({
                value: site.id,
                label: site.name,
            });
        });
        setAllSites(siteArr);
    }, [siteAssetData]);

    useEffect(() => {
        if (
            !selectedSites ||
            !siteAssetData ||
            !siteAssetData.organisation_by_pk ||
            !siteAssetData.organisation_by_pk.sites
        )
            return;
        let sites = siteAssetData.organisation_by_pk.sites;
        let assetsArr = [];
        sites.map((site) => {
            selectedSites.map((selected) => {
                if (site.id === selected.value) {
                    site.assets.map((asset) =>
                        assetsArr.push({
                            value: asset.id,
                            label: `[${site.name}] ${asset.description}`,
                        })
                    );
                }
                return null;
            });
            return null;
        });
        let filteredAssets = [];
        assetsArr.map((asset) => {
            if (!props.assets.includes(asset.value)) {
                return filteredAssets.push(asset);
            }
            return null;
        });
        setAllAssets(filteredAssets);
    }, [props.assets, selectedSites, siteAssetData]);

    const validateForm = useCallback(() => {
        if (!selectedAssets || selectedAssets.length < 1) {
            setErrorMessage("Please select at least one asset");
        } else {
            let assetList = [];
            if (selectedAssets && selectedAssets.length > 0) {
                selectedAssets.map((asset) => {
                    return assetList.push({
                        asset_id: asset.value,
                        goal_id: goalId,
                    });
                });
            }
            addAsset({
                variables: {
                    assets: assetList,
                },
            })
                .then((r) => {
                    console.log("asset added to goal");
                    props.refetch();
                    props.close(false);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [addAsset, props, selectedAssets, goalId]);

    return (
        <Transition appear show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={() => {
                    if (props.close) {
                        props.close(false);
                    }
                }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block sm:w-120 w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading"
                            >
                                <div className="space-y-8 text-base">
                                    <div className="flex flex-row justify-between">
                                        <div className=" text-2xl">
                                            Add Asset
                                        </div>
                                        <div
                                            className="w-5 h-5 cursor-pointer"
                                            onClick={() => props.close(false)}
                                        >
                                            <XIcon />
                                        </div>
                                    </div>
                                    <div className="px-4">
                                        <div>SITES</div>
                                        <Select
                                            isClearable
                                            value={selectedSites}
                                            onChange={setSelectedSites}
                                            options={allSites}
                                            isMulti={true}
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className="px-4">
                                        <div>ASSETS</div>
                                        <Select
                                            isClearable
                                            value={selectedAssets}
                                            onChange={setSelectedAssets}
                                            options={allAssets}
                                            isMulti={true}
                                            styles={customStyles}
                                        />
                                    </div>
                                    {errorMessage && (
                                        <ErrorPanel message={errorMessage} />
                                    )}

                                    <div className="flex flex-row gap-x-5 px-5 py-10">
                                        <div
                                            className="w-1/2"
                                            onClick={validateForm}
                                        >
                                            <PillButton
                                                text="CONFIRM"
                                                bg="white"
                                                textC="teal-500"
                                            />
                                        </div>
                                        <div
                                            className="w-1/2"
                                            onClick={() => props.close(false)}
                                        >
                                            <PillButton
                                                text="CANCEL"
                                                bg="teal-500"
                                                textC="white"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Title>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddGoalAssetModal;
