import React, { useEffect, useState } from "react";
import Toggle from "../../components/Input/Toggle/Toggle";
import MonthlyReports from "./MonthlyReports";
import LiveReport from "./LiveReport";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../utils/queryParams";

const QUERY_CHECK_HAS_WWH = gql`
    query QueryHasWWH($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            assets(
                where: {
                    measurement_points: {
                        measurement_point: { device_type: { _eq: "WASHWATCH" } }
                    }
                }
            ) {
                id
            }
        }
    }
`;
const Reports = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const orgId = getQueryParameter("organisationId");

    const [showLive, setShowLive] = useState(true);
    const [hasWWH, setHasWWH] = useState(false);

    const { data, error } = useQuery(QUERY_CHECK_HAS_WWH, {
        variables: {
            orgId: orgId,
        },
        onError: () => console.log(error),
    });

    useEffect(() => {
        if (data) {
            if (data.organisation_by_pk.assets.length > 0) {
                setHasWWH(true);
            } else {
                setHasWWH(false);
            }
        }
    }, [data]);

    return (
        <div
            className={`w-full h-full xl:px-12 sm:px-6 px-3 ${
                isMobile ? "pt-5 pb-28" : "pt-10 pb-0"
            }`}
        >
            <div className="flex lg:flex-row flex-col gap-x-5l">
                <div className="text-teal-600 text-3xl lg-full lg:w-1/3">
                    {showLive && hasWWH ? "Live Reports" : "Monthly Reports"}
                </div>
                {hasWWH && (
                    <div className="flex flex-row w-full lg:justify-center gap-x-5 lg:py-0 py-5">
                        <div>Monthly Reports</div>
                        <Toggle enabled={showLive} setEnabled={setShowLive} />
                        <div>Live Reports</div>
                    </div>
                )}
            </div>
            {showLive && hasWWH ? <LiveReport /> : <MonthlyReports />}
        </div>
    );
};

export default Reports;
