import React, { useState, useEffect } from "react";
import LargeLineGraph from "../../components/Graphs/LargeLineGraph";
import IprDetails from "../../components/Panels/IprDetails/IprDetails";
import { useHistory } from "react-router";
import { calcByDay } from "../../utils/helperFunctions";
import FilterValue from "../../components/Text/FilterValue";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import DataTable from "../../components/Tables/DataTable";
import { getQueryParameter } from "../../utils/queryParams";

const QUERY_PROMPTS_TOTAL = gql`
    query promptsTotal($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            total_closed: prompts_aggregate(where: { status: { _gt: 2 } }) {
                aggregate {
                    count
                }
            }
            total_open: prompts_aggregate(where: { status: { _lte: 2 } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const QUERY_PROMPTS_HISTORY = gql`
    query promptHistory($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            promptHistory: prompts {
                id
                time_raised
            }
        }
    }
`;

const QUERY_PROMPTS = gql`
    query prompts($orgId: Int!, $statusMax: Int!, $statusMin: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            prompts(
                order_by: { time_raised: desc }
                where: { status: { _gte: $statusMin, _lte: $statusMax } }
            ) {
                id
                status
                time_raised
                asset {
                    id
                    description
                    site {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const Prompts = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [totalOpen, setTotalOpen] = useState();
    const [totalClosed, setTotalClosed] = useState();
    const [promptsGraphData, setPromptsGraphData] = useState();
    const [activeTableData, setActiveTableData] = useState([]);
    const [activeTitle, setActiveTitle] = useState("");
    const [filterStatusMin, setFilterStatusMin] = useState(0);
    const [filterStatusMax, setFilterStatusMax] = useState(2);
    const [selectedPrompt, setSelectedPrompt] = useState();
    const history = useHistory();
    const orgIdParam = getQueryParameter("organisationId");
    const filterParam = getQueryParameter("filter");
    const promptIdParam = getQueryParameter("promptId");

    const setFilterParam = (title) => {
        history.push(`/prompts?filter=${title}&organisationId=${orgIdParam}`);
    };

    const setPromptIdParam = (promptId) => {
        if (promptId) {
            history.push(
                `?filter=${filterParam}&organisationId=${orgIdParam}&promptId=${promptId}`
            );
        } else {
            history.push(`?filter=${filterParam}&organisationId=${orgIdParam}`);
        }
    };

    const {
        error: promptsError,
        data: promptsData,
        refetch: refetchPrompts,
    } = useQuery(QUERY_PROMPTS, {
        fetchPolicy: "network-only",
        variables: {
            orgId: props.orgId,
            statusMin: filterStatusMin,
            statusMax: filterStatusMax,
        },
        onError: () => console.log(promptsError),
    });

    const {
        error: promptsTotalError,
        data: promptsTotalData,
        refetch: refetchPromptsTotals,
    } = useQuery(QUERY_PROMPTS_TOTAL, {
        fetchPolicy: "network-only",
        variables: {
            orgId: props.orgId,
        },
        onError: () => console.log(promptsTotalError),
    });

    const { error: promptsHistoryError, data: promptsHistoryData } = useQuery(
        QUERY_PROMPTS_HISTORY,
        {
            fetchPolicy: "network-only",
            variables: {
                orgId: props.orgId,
            },
            onError: () => console.log(promptsHistoryError),
        }
    );

    useEffect(() => {
        if (
            promptsTotalData === undefined ||
            promptsTotalData.organisation_by_pk === undefined ||
            promptsTotalData.organisation_by_pk.total_open === undefined ||
            promptsTotalData.organisation_by_pk.total_closed === undefined
        )
            return;
        setTotalOpen(
            promptsTotalData.organisation_by_pk.total_open.aggregate.count
        );
        setTotalClosed(
            promptsTotalData.organisation_by_pk.total_closed.aggregate.count
        );
    }, [promptsTotalData]);

    //handling prompts graph data
    useEffect(() => {
        if (
            promptsHistoryData === undefined ||
            promptsHistoryData.organisation_by_pk === undefined ||
            promptsHistoryData.organisation_by_pk.promptHistory === undefined
        )
            return;
        setPromptsGraphData(
            calcByDay(promptsHistoryData.organisation_by_pk.promptHistory)
        );
    }, [promptsHistoryData]);

    //handling prompts data
    useEffect(() => {
        if (
            promptsData === undefined ||
            promptsData.organisation_by_pk === undefined ||
            promptsData.organisation_by_pk.prompts === undefined
        )
            return;
        let prompts = [];
        promptsData.organisation_by_pk.prompts.map((prompt) => {
            return prompts.push({
                prompt: prompt,
                col1: dayjs(prompt.time_raised).format("YYYY-MM-DD"),
                col2: dayjs(prompt.time_raised).format("HH:mm"),
                col3: prompt.asset.description,
                col4: prompt.asset.site.name,
                id: prompt.id,
            });
        });
        setActiveTableData(prompts);
    }, [promptsData]);

    useEffect(() => {
        if (!filterParam) return;
        if (filterParam === "open") {
            setActiveTitle("Open");
            setFilterStatusMax(2);
            setFilterStatusMin(0);
        } else if (filterParam === "closed") {
            setActiveTitle("Closed");
            setFilterStatusMax(99);
            setFilterStatusMin(3);
        }
        setActiveTableData([]);
        refetchPrompts();
        refetchPromptsTotals();
    }, [filterParam, refetchPrompts, refetchPromptsTotals]);

    useEffect(() => {
        if (!promptIdParam) return;
        if (isMobile) {
            let container = document.querySelector("#container");
            container.scrollTo({
                top: 800,
                left: 0,
                behavior: "smooth",
            });
        }
        if (!selectedPrompt || promptIdParam !== selectedPrompt.id) {
            setSelectedPrompt({ id: promptIdParam });
        }
    }, [promptIdParam, selectedPrompt, isMobile]);

    return (
        <div
            className={`grid sm:grid-cols-2 w-full h-full xl:px-12 sm:px-6 px-3 pb-20 overflow-y-auto ${
                isMobile ? "pt-5" : "pt-10"
            }`}
            id="container"
        >
            <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between">
                    <div
                        className={`text-teal-600 text-3xl ${
                            isMobile ? "pb-6" : "pb-12"
                        } xl:pl-1.5`}
                    >
                        Prompts
                    </div>
                </div>
                {totalOpen !== undefined && totalClosed !== undefined && (
                    <div className="bg-white flex justify-around sm:pl-20 rounded-lg border-2 w-full sm:w-11/12 h-20 sm:h-24">
                        <FilterValue
                            setUrl={setFilterParam}
                            title={"OPEN"}
                            value={totalOpen}
                            url="open"
                        />
                        <FilterValue
                            setUrl={setFilterParam}
                            title={"CLOSED"}
                            value={totalClosed}
                            url="closed"
                        />
                    </div>
                )}

                {activeTableData && (
                    <div
                        className={`${
                            isMobile ? "mt-5" : "mt-10"
                        } bg-white w-full sm:w-11/12 border-2 rounded-lg`}
                    >
                        <div className="text-blueGray-800 m-4 text-2xl">
                            {activeTitle}
                        </div>
                        <DataTable
                            headerStyle="grid grid-cols-4 pl-2 lg:pl-5 lg:py-2 py-1 place-items-start"
                            rowStyle="grid grid-cols-4 lg:pl-5 pl-2 place-self-start py-4 border-t hover:text-teal-500 cursor-pointer"
                            setUrl={setPromptIdParam}
                            data={activeTableData}
                            titles={[
                                { Header: "DATE", accessor: "col1" },
                                { Header: "TIME", accessor: "col2" },
                                { Header: "ASSET", accessor: "col3" },
                                { Header: "SITE", accessor: "col4" },
                            ]}
                        />
                    </div>
                )}
            </div>
            <div className="flex flex-col w-full gap-y-6 sm:pt-21 pt-5">
                {promptsGraphData && !isMobile && (
                    <div className="bg-white rounded-lg border-2 xl:w-3/4 w-full h-72">
                        <LargeLineGraph data={promptsGraphData} />
                    </div>
                )}
                {selectedPrompt && (
                    <div className="bg-white border-2 xl:w-3/4 w-full rounded-lg h-3/7 mb-20">
                        <IprDetails
                            promptId={selectedPrompt.id}
                            onCloseClicked={() => {
                                setSelectedPrompt(null);
                                setPromptIdParam(null);
                            }}
                            onStatusUpdated={() => {
                                refetchPrompts();
                                refetchPromptsTotals();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Prompts;
