import { DownloadIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import ReportCard from "../../components/Cards/ReportCard";
import { getFile } from "../../utils/s3Service";
import { getUserSession } from "../../utils/userSession";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { getQueryParameter } from "../../utils/queryParams";

const REPORTS_QUERY = gql`
    query getReports($organisation: Int!) {
        ppcs_core_reporttype(
            where: { reports: { organisation_id: { _eq: $organisation } } }
        ) {
            id
            name
            description
            report_type
            reports(
                where: { organisation_id: { _eq: $organisation } }
                order_by: { created: desc }
            ) {
                id
                created
                file_key
                name
                organisation_id
            }
        }
    }
`;

const MonthlyReports = (props) => {
    const orgId = getQueryParameter("organisationId");

    const { data } = useQuery(REPORTS_QUERY, {
        variables: {
            organisation: orgId,
        },
    });

    const [reports, setReports] = useState(null);
    const [selectedReportType, setSelectedReportType] = useState({
        report_type: "DOORINFORMER",
    });

    function download(blob, filename) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = blob;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(blob);
        }
    }

    useEffect(() => {
        if (!data) return;
        setReports(data.ppcs_core_reporttype);
        if (data.ppcs_core_reporttype.length > 0) {
            setSelectedReportType(data.ppcs_core_reporttype[0]);
        } else {
            setSelectedReportType(null);
        }
    }, [data]);

    const downloadReport = (fileName, name) => {
        let session = getUserSession();
        let token = session.credentials.idToken;
        getFile(fileName, token).then((r) => {
            download(r, name + ".csv");
        });
    };

    return (
        <div className="flex sm:flex-row flex-col gap-x-5 pt-10">
            <div className="max-h-144 overflow-y-auto w-full sm:w-1/3 xl:w-1/4 border-2 rounded-lg bg-white">
                {reports && reports.length === 0 && (
                    <div className="flex justify-center mt-5 text-md text-trueGray-400">
                        No reports yet
                    </div>
                )}
                {reports &&
                    reports.length > 0 &&
                    reports.map((reportType, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() =>
                                    setSelectedReportType(reportType)
                                }
                            >
                                <ReportCard
                                    selected={
                                        selectedReportType.report_type ===
                                        reportType.report_type
                                    }
                                    title={reportType.name}
                                    description={reportType.description}
                                    border={index !== reports.length - 1}
                                />
                            </div>
                        );
                    })}
            </div>
            <div className="2xl:w-2/5 sm:w-2/3 w-full border-2 bg-white rounded-lg max-h-144 overflow-y-auto sm:mt-0 mt-5">
                <div>
                    <div className="text-2xl p-3">
                        {selectedReportType && selectedReportType.name}
                    </div>
                    <div className="px-3 py-2 text-gray-400">REPORT DATE</div>
                    <div className="border-b-2" />
                </div>
                {reports &&
                    reports.map((reportType, index) => {
                        if (
                            reportType.report_type ===
                                selectedReportType.report_type &&
                            reportType.reports
                        ) {
                            return reportType.reports.map((report, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={classNames(
                                            "flex flex-row justify-between px-4 py-2",
                                            "sm:border-b-2",
                                            index !==
                                                reportType.reports.length - 1 &&
                                                "border-b-2"
                                        )}
                                    >
                                        <div>{report.name}</div>
                                        <div className="w-5 h-5 cursor-pointer">
                                            <DownloadIcon
                                                onClick={() =>
                                                    downloadReport(
                                                        report.file_key,
                                                        report.name
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            });
                        } else {
                            return null;
                        }
                    })}
            </div>
        </div>
    );
};

export default MonthlyReports;
