import React, { useMemo, useState, useCallback } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import SearchInput from "../../Input/SearchInput";
import {
    SelectorIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/outline";
import AssetCard from "../../Cards/AssetCard";

const DataTable = (props) => {
    const columns = useMemo(() => props.titles, [props.titles]);
    const [columnToggle, setColumnToggle] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState();
    const [activeRow, setActiveRow] = useState();
    const [newData, setNewData] = useState();
    const data = useMemo(
        () => (newData ? newData : props.data),
        [props.data, newData]
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = useTable({ columns, data }, useGlobalFilter, useSortBy);

    const removeRow = useCallback(
        (deletedId) => {
            let updated = props.data.filter((data) => data.id !== deletedId);
            setNewData(updated);
        },
        [props]
    );

    const handleHeaderClick = () => {
        if (columnToggle === 0) {
            setColumnToggle(1);
        } else if (columnToggle === 1) {
            setColumnToggle(2);
        } else if (columnToggle === 2) {
            setColumnToggle(0);
        }
    };

    return (
        <div className="h-auto">
            {props.search && (
                <SearchInput
                    name="Sites"
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    colTitle={columns[1].Header}
                />
            )}
            <table {...getTableProps()} className="w-full mt-2">
                <thead className="text-trueGray-400 lg:text-base text-sm ">
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                            className={props.headerStyle}
                        >
                            {headerGroup.headers.map((column, index) => (
                                <th
                                    key={index}
                                    {...column.getHeaderProps()}
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    onClick={(e) => {
                                        if (index !== selectedColumn) {
                                            setColumnToggle(0);
                                        }
                                        handleHeaderClick();
                                        setSelectedColumn(index);
                                        column
                                            .getHeaderProps(
                                                column.getSortByToggleProps()
                                            )
                                            .onClick(e);
                                    }}
                                    className={`
                                        ${
                                            (column.id === "col1" ||
                                                column.id ===
                                                    "assetDescription" ||
                                                column.id === "name") &&
                                            "justify-self-start grid-cols-1 col-span-1 col-start-1"
                                        } xl:text-sm text-2xs flex flex-row `}
                                >
                                    {column.render("Header")}
                                    <div className="sm:w-4 sm:h-4 h-3 w-3 inline-block sm:pt-0.5 sm:ml-1">
                                        {columnToggle === 0 ||
                                        index !== selectedColumn ? (
                                            <SelectorIcon />
                                        ) : columnToggle === 1 ? (
                                            <ChevronDownIcon />
                                        ) : (
                                            <ChevronUpIcon />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody
                    {...getTableBodyProps()}
                    className="xl:text-sm text-xs 2xl:max-h-120 lg:max-h-90 max-h-120 overflow-y-auto block"
                >
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                key={index}
                                {...row.getRowProps()}
                                onClick={() => {
                                    if (activeRow === row.original.id) {
                                        setActiveRow(null);
                                    } else {
                                        setActiveRow(row.original.id);
                                    }
                                    if (props.setUrl) {
                                        props.setUrl(row.original.id);
                                    }
                                }}
                                className={`${props.rowStyle} ${
                                    activeRow === row.original.id
                                        ? "text-teal-500"
                                        : "text-to-blueGray-800"
                                }`}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            key={index}
                                            className={
                                                cell.column.id === "col1" ||
                                                cell.column.id ===
                                                    "assetDescription" ||
                                                cell.column.id === "name"
                                                    ? "justify-self-start self-center"
                                                    : "self-center"
                                            }
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                                {props.hasDropdown &&
                                    row.original.id === activeRow && (
                                        <AssetCard
                                            hasEdit={props.hasEdit}
                                            id={activeRow}
                                            remove={setActiveRow}
                                            update={removeRow}
                                        />
                                    )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DataTable;
