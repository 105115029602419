import { SET_USER_SESSION, CLEAR_USER_SESSION } from "../actionTypes";

const initialState = {
    isLoggedIn: false
};

const userSession = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_SESSION:
            return Object.assign({}, action.session, { isLoggedIn: true });

        case CLEAR_USER_SESSION:
            return initialState;

        default:
            return state;
    }
};

export default userSession;
