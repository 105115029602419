export const DOOR_MODES = {
    0: "Unknown",
    1: "Automatic",
    2: "Locked",
    3: "Manual",
    4: "Open",
    5: "Exit",
    6: "Learning",
    7: "P Lock",
    8: "Smoke",
    9: "Delivery",
};

export const STATUS_CHOICES = {
    0: "Pending",
    1: "In Progress",
    2: "In Progress",
    9: "Resolved",
    10: "Closed",
};

export const STATE_STYLES = {
    Pending: {
        text: "text-amber-600",
        bg: "bg-amber-100",
    },
    "In Progress": {
        text: "text-blue-600",
        bg: "bg-blue-100",
    },
    Resolved: {
        text: "text-gray-700",
        bg: "bg-gray-200",
    },
    Closed: {
        text: "text-teal-500",
        bg: "bg-teal-100",
    },
};
