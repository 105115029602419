import { store } from "../redux";
import { CLEAR_USER_SESSION, SET_USER_SESSION } from "../redux/actionTypes";
import cognitoUtils from "./cognito";
const CognitoRefreshToken =
    require("amazon-cognito-identity-js").CognitoRefreshToken;

export function clearSession() {
    store.dispatch({
        type: CLEAR_USER_SESSION,
    });
}

// Initialise the Cognito sesson from a callback href
export function initSessionFromCallbackURI(callbackHref) {
    clearSession();
    return function (dispatch) {
        return cognitoUtils
            .parseCognitoWebResponse(callbackHref) // parse the callback URL
            .then(() => cognitoUtils.getCognitoSession()) // get a new session
            .then((session) => {
                dispatch({ type: SET_USER_SESSION, session });
            });
    };
}

export function initSession(session) {
    store.dispatch({ type: SET_USER_SESSION, session });
}

export const getUserSession = () => {
    const state = store.getState();
    return state.userSession;
};

export const getUserEmail = () => {
    const session = getUserSession();
    if (session.user === undefined) return null;
    let email = session.user.email;
    return email.toLowerCase();
};

export const getIdToken = () => {
    const session = getUserSession();
    if (session.credentials === undefined) return null;
    return session.credentials.idToken;
};

const decodeTokenPayload = (token) => {
    /**
     * Decode the payload part of the supplied JSON web token
     */
    let base64 = token.split(".")[1];
    return JSON.parse(window.atob(base64));
};

export const getTokenExpiry = (token) => {
    /**
     * Decodes and returns the expiry of the token as a Date object
     *
     * The expiry in our token is timestamp in seconds.
     */
    const decodedToken = decodeTokenPayload(token);
    // - 3500000 to debug
    return new Date(decodedToken.exp * 1000);
};

export const isTokenExpired = () => {
    /**
     * Returns true if the token has expired.
     *
     */
    const token = getIdToken();
    if (token === null) return true;
    let expiry = getTokenExpiry(token);
    return expiry <= new Date();
};

export const refreshAccessToken = () => {
    return new Promise((resolve, reject) => {
        cognitoUtils
            .getCognitoSession()
            .then((session) => {
                const user = cognitoUtils.createCognitoUser();
                let refreshToken = new CognitoRefreshToken({
                    RefreshToken: session.credentials.refreshToken,
                });
                user.refreshSession(refreshToken, (err, result) => {
                    if (err || !result) {
                        reject(
                            new Error(
                                "Failure refreshing Cognito session: " + err
                            )
                        );
                        console.log(
                            "Failure refreshing session: " + JSON.stringify(err)
                        );
                        return;
                    }
                    const session = {
                        credentials: {
                            accessToken: result.accessToken.jwtToken,
                            idToken: result.idToken.jwtToken,
                            refreshToken: result.refreshToken.token,
                        },
                        user: {
                            userName:
                                result.idToken.payload["cognito:username"],
                            email: result.idToken.payload.email,
                        },
                    };
                    initSession(session);
                    resolve(session);
                });
            })
            .catch((e) => {
                reject(new Error("Failure getting Cognito session"));
            });
    });
};

export const updateAccessToken = () => {
    return new Promise((resolve, reject) => {
        const user = cognitoUtils.createCognitoUser();
        user.getSession((err, result) => {
            if (err || !result) {
                reject(new Error("Failure getting Cognito session: " + err));
            }
            const session = {
                credentials: {
                    accessToken: result.accessToken.jwtToken,
                    idToken: result.idToken.jwtToken,
                    refreshToken: result.refreshToken.token,
                },
                user: {
                    userName: result.idToken.payload["cognito:username"],
                    email: result.idToken.payload.email,
                },
            };
            store.dispatch({ type: SET_USER_SESSION, session });
            resolve(result);
        });
    });
};

export const isUserLoggedIn = () => {
    const session = getUserSession();
    let expired = isTokenExpired();
    return session.isLoggedIn && !expired;
};
