import React, { useState, useEffect } from "react";
import DisplayValue from "../../Text/DisplayValue/DisplayValue";
import PillButton from "../../Miscellaneous/PillButton/PillButton";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../../utils/queryParams";
import { gql, useQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { compareChange } from "../../../utils/helperFunctions";
import { EyeIcon, TrashIcon } from "@heroicons/react/outline";

const QUERY_ASSET_PERFORMANCE = gql`
    query getAssetPerformance(
        $assetIds: _int4!
        $endTime: timestamp!
        $dayAgo: timestamp!
        $daysAgo2: timestamp!
        $weekAgo: timestamp!
        $weeksAgo2: timestamp!
        $assetId: Int!
    ) {
        last24: assets_performance_for_time_period(
            args: {
                asset_ids: $assetIds
                end_time: $endTime
                start_time: $dayAgo
            }
        ) {
            rating
            downtime
            asset_id
        }
        previous24: assets_performance_for_time_period(
            args: {
                asset_ids: $assetIds
                end_time: $dayAgo
                start_time: $daysAgo2
            }
        ) {
            rating
            downtime
            asset_id
        }
        lastWeek: assets_performance_for_time_period(
            args: {
                asset_ids: $assetIds
                end_time: $endTime
                start_time: $weekAgo
            }
        ) {
            rating
            downtime
            asset_id
        }
        previousWeek: assets_performance_for_time_period(
            args: {
                asset_ids: $assetIds
                end_time: $weekAgo
                start_time: $weeksAgo2
            }
        ) {
            rating
            downtime
            asset_id
        }
        asset_by_pk(id: $assetId) {
            id
            site_id
        }
    }
`;

const MUTATION_REMOVE_ASSET_FROM_GOAL = gql`
    mutation removeAssetFromGoal($assetId: Int!, $goalId: Int!) {
        delete_ppcs_core_goal_assets(
            where: { asset_id: { _eq: $assetId }, goal_id: { _eq: $goalId } }
        ) {
            returning {
                id
                asset_id
                goal_id
            }
        }
    }
`;
const AssetCard = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const history = useHistory();
    const orgId = getQueryParameter("organisationId");
    const goalId = getQueryParameter("goalId");
    const [currentTime] = useState(dayjs().format());
    const [dayAgo] = useState(dayjs().subtract(24, "hours"));
    const [daysAgo2] = useState(dayjs().subtract(48, "hours"));
    const [weekAgo] = useState(dayjs().subtract(7, "days"));
    const [weeksAgo2] = useState(dayjs().subtract(14, "days"));
    const [assetPerformance, setAssetPerformance] = useState();
    const [dailyPerformanceChange, setDailyPerformanceChange] = useState();
    const [weeklyPerformanceChange, setWeeklyPerformanceChange] = useState();

    const { data: assetRating, error: assetRatingError } = useQuery(
        QUERY_ASSET_PERFORMANCE,
        {
            variables: {
                assetIds: `{${props.id}}`,
                assetId: props.id,
                endTime: currentTime,
                dayAgo: dayAgo,
                weekAgo: weekAgo,
                daysAgo2: daysAgo2,
                weeksAgo2: weeksAgo2,
            },
            onError: () => console.log(assetRatingError),
        }
    );

    const [removeAsset] = useMutation(MUTATION_REMOVE_ASSET_FROM_GOAL);

    useEffect(() => {
        if (!assetRating) return;
        let ratingDayAgo = assetRating.last24[0]
            ? assetRating.last24[0].rating
            : 1;
        let ratingDayPrevious = assetRating.previous24[0]
            ? assetRating.previous24[0].rating
            : 1;
        let ratingWeekAgo = assetRating.lastWeek[0]
            ? assetRating.lastWeek[0].rating
            : 1;
        let ratingWeekPrevious = assetRating.previousWeek[0]
            ? assetRating.previousWeek[0].rating
            : 1;
        setDailyPerformanceChange(
            compareChange(ratingDayAgo, ratingDayPrevious)
        );
        setWeeklyPerformanceChange(
            compareChange(ratingWeekAgo, ratingWeekPrevious)
        );

        setAssetPerformance(assetRating);
    }, [assetRating]);

    const handleRemove = () => {
        if (!props.id) return;
        removeAsset({
            variables: {
                goalId: goalId,
                assetId: props.id,
            },
        })
            .then((r) => {
                console.log("asset removed");
                props.update(props.id);
                props.remove(null);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    if (
        !assetPerformance ||
        dailyPerformanceChange === undefined ||
        weeklyPerformanceChange === undefined
    )
        return <div></div>;

    return (
        <div
            className={`w-full border-t bg-white pt-4 grid  ${
                isMobile ? "grid-cols-9 col-span-9" : "grid-cols-7 col-span-7"
            } justify-between`}
        >
            <div
                className={`grid col-start-1 ${
                    isMobile ? "col-span-4" : "col-span-3"
                } gap-y-4`}
            >
                <div className="flex flex-row 2xl:gap-x-5 xl:gap-x-2 gap-x-1">
                    <div className="text-xs xl:text-base text-black">
                        Last 24 Hours
                    </div>
                    <div
                        className={
                            dailyPerformanceChange < 0
                                ? "text-red-500 self-end 2xl:text-base xl:text-xs  text-2xs"
                                : "text-teal-500 self-end 2xl:text-base xl:text-xs text-2xs"
                        }
                    >
                        {dailyPerformanceChange < 0
                            ? `${dailyPerformanceChange.toFixed(1)}%`
                            : `+${dailyPerformanceChange.toFixed(1)}%`}
                    </div>
                </div>
                <div className="flex flex-row 2xl:gap-x-5 xl:gap-x-3 lg:gap-x-2 gap-x-1">
                    <DisplayValue
                        title="RATING"
                        value={
                            assetPerformance.last24[0]
                                ? (
                                      assetPerformance.last24[0].rating * 100
                                  ).toFixed(1)
                                : "100.0"
                        }
                        unit="%"
                    />
                    <DisplayValue
                        title="DOWNTIME"
                        value={
                            assetPerformance.last24[0]
                                ? (
                                      assetPerformance.last24[0].downtime / 60
                                  ).toFixed(1)
                                : "0.0"
                        }
                        unit="hrs"
                    />
                </div>
            </div>
            <div
                className={`grid ${
                    isMobile
                        ? "col-start-5 col-span-4"
                        : "col-start-4 col-span-3"
                } gap-y-4`}
            >
                <div className="flex flex-row 2xl:gap-x-5 lg:gap-x-2 gap-x-1">
                    <div className="text-xs xl:text-base text-black">
                        Last 7 Days
                    </div>
                    <div
                        className={
                            weeklyPerformanceChange < 0
                                ? "text-red-500 self-end 2xl:text-base xl:text-xs text-2xs"
                                : "text-teal-500 self-end 2xl:text-base xl:text-xs text-2xs"
                        }
                    >
                        {weeklyPerformanceChange < 0
                            ? `${weeklyPerformanceChange.toFixed(1)}%`
                            : `+${weeklyPerformanceChange.toFixed(1)}%`}
                    </div>
                </div>
                <div className="flex flex-row 2xl:gap-x-5 xl:gap-x-3 lg:gap-x-2 gap-x-1">
                    <DisplayValue
                        title="RATING"
                        value={
                            assetPerformance.lastWeek[0]
                                ? (
                                      assetPerformance.lastWeek[0].rating * 100
                                  ).toFixed(1)
                                : "100.0"
                        }
                        unit="%"
                    />
                    <DisplayValue
                        title="DOWNTIME"
                        value={
                            assetPerformance.lastWeek[0]
                                ? (
                                      assetPerformance.lastWeek[0].downtime / 60
                                  ).toFixed(1)
                                : "0.0"
                        }
                        unit="hrs"
                    />
                </div>
            </div>
            <div
                className={`grid ${
                    isMobile
                        ? "col-start-9"
                        : "col-start-7 items-end place-self-end"
                } gap-y-2 xl:gap-y-4 xl:w-24 lg:w-20 w-16`}
            >
                <div
                    className={`${isMobile ? "w-7 h-7" : "w-full"}`}
                    onClick={() => {
                        history.push(
                            `assetDetails?organisationId=${orgId}&assetId=${props.id}`
                        );
                    }}
                >
                    {isMobile ? (
                        <EyeIcon />
                    ) : (
                        <PillButton
                            text="DETAILS"
                            bg="teal-500"
                            textC="white"
                        />
                    )}
                </div>
                {props.hasEdit && (
                    <div
                        className={`${
                            isMobile
                                ? "w-7 h-7 text-red-500"
                                : "xl:w-24 lg:w-20 w-16 "
                        }`}
                        onClick={handleRemove}
                    >
                        {isMobile ? (
                            <TrashIcon />
                        ) : (
                            <PillButton
                                text="REMOVE"
                                bg="teal-500"
                                textC="white"
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssetCard;
