import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Button from "./Button";

const Display = (props) => {
  const [currentDisplay, setCurrentDisplay] = useState();

  const [line1, setLine1] = useState();
  const [line2, setLine2] = useState();
  const [line3, setLine3] = useState();

  const generateCursor = (line, cursorPosition) => {
    return (
      <span>
        {line.substring(0, cursorPosition)}
        <u>{line.substring(cursorPosition, cursorPosition + 1)}</u>
        {line.substring(cursorPosition + 1)}
      </span>
    );
  };

  useEffect(() => {
    if (!props.logs) return;
    let current = props.logs[0]
      ? props.logs[0]
      : {
          display_1: null,
          display_2: null,
          display_3: null,
          cursor_line: null,
          cursor_position: 0,
        };
    setCurrentDisplay(current);

    let cursorLine = current.cursor_line;
    let cursorPosition = parseInt(current.cursor_position);

    let line1 = current.display_1;
    let line2 = current.display_2;
    let line3 = current.display_3;

    if (cursorLine !== null && cursorPosition !== null) {
      if (cursorLine === 1) {
        line1 = generateCursor(line1, cursorPosition);
      } else if (cursorLine === 2) {
        line2 = generateCursor(line2, cursorPosition);
      } else if (cursorLine === 3) {
        line3 = generateCursor(line3, cursorPosition);
      }
    }

    if (!line1) line1 = "\n";
    if (!line2) line2 = "\n";
    if (!line3) line3 = "\n";

    setLine1(line1);
    setLine2(line2);
    setLine3(line3);
  }, [props.logs]);

  return (
    <div
      className={classNames(
        "flex flex-row sm:mx-0 mx-2 justify-center",
        !props.isMobile && "w-full"
      )}
    >
      <div className={classNames("flex flex-col", !props.isMobile && "w-2/3")}>
        <div className="flex flex-row justify-center w-full">
          <div className="border-2 rounded-xl bg-trueGray-200 w-full">
            {line1 && line2 && line3 && (
              <div className="flex flex-col m-2 whitespace-pre-wrap lg:text-lg sm:text-md md:text-md  font-mono font-medium">
                <div>{line1}</div>
                <div>{line2}</div>
                <div>{line3}</div>
              </div>
            )}
            {!currentDisplay && (
              <div className="flex flex-col m-2 whitespace-pre-wrap text-lg font-mono font-medium">
                No Display logs...
              </div>
            )}
          </div>
          <div className="flex flex-col justify-end ml-2">
            <Button
              disabled={props.disabled}
              onClick={() => {
                props.send("washtec-display", "all report");
              }}
              text={"REFRESH"}
            />
          </div>
        </div>
        <div className="text-sm self-stretch pl-1 pt-1 flex flex-row">
          Logged In:{" "}
          <div className="ml-1 self-center">
            {props.loggedIn ? (
              <CheckCircleIcon color="green" width={18} height={18} />
            ) : (
              <MinusCircleIcon color="red" width={18} height={18} />
            )}
          </div>
        </div>
        {currentDisplay && (
          <div className="text-sm self-stretch pl-1 pb-1">
            Last updated:{" "}
            {dayjs(currentDisplay.time).format("ddd D MMM, h:mm a")}
          </div>
        )}
      </div>
    </div>
  );
};

export default Display;
