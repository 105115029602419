import React from "react";

const Loading = (props) => {
    return (
        <div className="w-full h-full my-60">
            <div className="grid place-items-center space-y-3">
                <div className="text-blueGray-800 text-lg">{props.message}</div>
                <div className="flex flex-row space-x-3 items-center">
                    <div
                        style={{ animationDelay: "0.1s" }}
                        className="w-2 h-2 rounded-full bg-blueGray-800 animate-ping"
                    ></div>
                    <div
                        style={{ animationDelay: "0.3s" }}
                        className="w-2 h-2 rounded-full bg-blueGray-800 animate-ping"
                    ></div>
                    <div
                        style={{ animationDelay: "0.5s" }}
                        className="w-2 h-2 rounded-full bg-blueGray-800 animate-ping"
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
