import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { gql, useQuery, useMutation } from "@apollo/client";
import Select from "react-select";
import { customStyles } from "../../../utils/helperFunctions";
import { getQueryParameter } from "../../../utils/queryParams";
import PillButton from "../../Miscellaneous/PillButton";
import ErrorPanel from "../../Panels/ErrorPanel";

const QUERY_ORG_OPTIONS = gql`
  query getOrgOptions($orgId: Int!) {
    organisation_by_pk(id: $orgId) {
      id
      name
      sites {
        id
        name
      }
      asset_types {
        id
        name
      }
      users {
        id
        email
      }
    }
  }
`;

const INVITE_USER_MUTATION = gql`
  mutation inviteUser($user: UserPolicyInput!) {
    generate_user_policy(user: $user) {
      id
      email
    }
  }
`;

const InviteUserModal = (props) => {
  const orgId = getQueryParameter("organisationId");
  const [siteOptions, setSiteOptions] = useState();
  const [typeOptions, setTypeOptions] = useState();
  const [selectedApp, setSelectedApp] = useState();
  const [selectedSites, setSelectedSites] = useState();
  const [selectedTypes, setSelectedTypes] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [allUsers, setAllUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { data: optionsData, error: optionError } = useQuery(
    QUERY_ORG_OPTIONS,
    {
      variables: {
        orgId: orgId,
      },
      onError: () => console.log(optionError),
    }
  );

  const [inviteUser] = useMutation(INVITE_USER_MUTATION);

  useEffect(() => {
    if (!optionsData) return;
    let sites = [];
    let assetTypes = [];
    let users = [];
    optionsData.organisation_by_pk.sites.map((site) => {
      return sites.push({
        value: site.id,
        label: site.name,
      });
    });
    optionsData.organisation_by_pk.asset_types.map((type) => {
      return assetTypes.push({
        value: type.id,
        label: type.name,
      });
    });
    optionsData.organisation_by_pk.users.map((user) => {
      return users.push(user.email);
    });
    setSiteOptions(sites);
    setTypeOptions(assetTypes);
    setAllUsers(users);
  }, [optionsData]);

  useEffect(() => {
    if (props.open === false) {
      handleClear();
    }
  }, [props.open]);

  const handleInvite = useCallback(() => {
    if (!email) {
      setErrorMessage("Please enter an Email Address.");
      return;
    }
    if (!selectedApp) {
      setErrorMessage("Please select an Application.");
      return;
    }
    if (!selectedRole) {
      setErrorMessage("Please select a role.");
      return;
    }
    if (!email.includes("@")) {
      setErrorMessage("Please enter a valid Email.");
      return;
    }
    if (allUsers.includes(email)) {
      setErrorMessage("A user with that email address already exists.");
      return;
    }
    if (selectedApp === "shop" && !selectedSites) {
      setErrorMessage("Shop users require access to one site");
      return;
    }
    setIsLoading(true);
    let type_ids = [];
    let site_ids = [];
    if (selectedTypes) {
      selectedTypes.map((value) => {
        return type_ids.push(value.value);
      });
    }
    if (selectedSites) {
      if (Array.isArray(selectedSites)) {
        selectedSites.map((value) => {
          return site_ids.push(value.value);
        });
        if (selectedApp === "shop") {
          site_ids = site_ids[0];
        }
      } else {
        site_ids.push(selectedSites.value);
      }
    }
    inviteUser({
      variables: {
        user: {
          email: email,
          role: parseInt(selectedRole.value),
          application: selectedApp,
          organisation: parseInt(orgId),
          site_ids: site_ids,
          assettype_ids: type_ids,
        },
      },
    })
      .then((r) => {
        setSuccess(true);
        setIsLoading(false);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setIsLoading(false);
      });
  }, [
    allUsers,
    email,
    inviteUser,
    orgId,
    selectedApp,
    selectedRole,
    selectedSites,
    selectedTypes,
  ]);

  const handleClear = () => {
    setEmail("");
    setSelectedRole(null);
    setSelectedSites(null);
    setSelectedTypes(null);
    setErrorMessage(null);
    setSelectedApp(null);
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          if (props.close) {
            props.close(false);
          }
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="overflow-y-auto inline-block sm:w-160 w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
              <Dialog.Title as="h3" className="text-lg font-medium leading">
                <div className="space-y-4 text-base">
                  <div className="flex flex-row justify-between">
                    <div className="text-base sm:text-xl font-semibold">
                      Invite a New User to your Organisation
                    </div>
                    <div
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => props.close(false)}
                    >
                      <XIcon />
                    </div>
                  </div>
                  {!success && (
                    <div className="space-y-4">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="w-full border-2 rounded-md text-sm sm:text-base h-10 pl-2 bg-coolGray-50"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                      <div className="sm:text-sm text-xs">
                        The <b>Role</b> defines the level of access that a user
                        is entitled to. The <b>Staff </b>
                        role defines a level of access required to e.g. generate
                        requests and adding notes to local issues. The{" "}
                        <b>Admin </b>
                        role is required for tasks such as remote machinery
                        access and new user invitations.
                      </div>
                      <div className="flex flex-row w-full gap-x-4">
                        <div className="w-full">
                          <div>Role</div>
                          <Select
                            value={selectedRole}
                            onChange={setSelectedRole}
                            options={[
                              {
                                value: 1,
                                label: "STAFF",
                              },
                              {
                                value: 2,
                                label: "ADMIN",
                              },
                            ]}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="sm:text-sm text-xs">
                        Select which <b>Sites</b> and/or
                        <b> Asset Types</b> to which the new user has access. To
                        access all leave fields blank. Note Shop staff access
                        requires exactly one site.
                      </div>
                      <div className="flex flex-row w-full gap-x-4">
                        <div className="w-1/2">
                          <div>Sites</div>
                          <Select
                            value={selectedSites}
                            onChange={setSelectedSites}
                            options={siteOptions}
                            isMulti={selectedApp === "shop" ? false : true}
                            styles={customStyles}
                          />
                        </div>
                        <div className="w-1/2">
                          <div>Asset Types</div>
                          <Select
                            value={selectedTypes}
                            onChange={setSelectedTypes}
                            options={typeOptions}
                            isMulti={true}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="font-semibold">Select Application</div>
                      <div className="flex flex-row sm:px-5">
                        <div className="w-1/2">
                          <input
                            onChange={(e) => setSelectedApp(e.target.value)}
                            type="radio"
                            id="shop"
                            name="app"
                            value="shop"
                            className="mr-1 cursor-pointer"
                          />
                          <label for="shop">BenchMarker Shop</label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => setSelectedApp(e.target.value)}
                            type="radio"
                            id="assets"
                            name="app"
                            value="assets"
                            className="mr-1 cursor-pointer"
                          />
                          <label for="assets">BenchMarker Assets</label>
                        </div>
                      </div>
                      {errorMessage && <ErrorPanel message={errorMessage} />}

                      <div className="flex flex-row gap-x-5">
                        <div className="w-1/2" onClick={handleClear}>
                          <PillButton
                            text="CLEAR"
                            bg="white"
                            textC="teal-500"
                          />
                        </div>
                        <div className="w-1/2" onClick={handleInvite}>
                          <PillButton
                            text="INVITE"
                            bg="teal-500"
                            textC="white"
                            loading={isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {success && (
                    <div>
                      <div className="text-2xl text-teal-500 text-semibold text-center py-4">
                        Success!
                      </div>
                      <div className="text-center">
                        An Invite email has been sent to <b>{email}</b>
                      </div>
                      <div
                        className="pt-5 px-20"
                        onClick={() => {
                          props.close(false);
                          setSuccess(false);
                        }}
                      >
                        <PillButton text="OK" bg="teal-500" textC="white" />
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Title>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InviteUserModal;
