import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import HomeDataPanel from "../../../Panels/HomeDataPanel";
import PopupDialog from "../../../Modals/PopupDialog";
const HomePanelContainer = (props) => {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <div className="bg-white 2xl:w-4/5 w-full rounded-2xl border-2 mb-2">
            <div className="flex flex-row m-3">
                <div className="text-blueGray-800  text-xl ml-2">
                    {props.panelTitle}
                </div>
                <div
                    className="sm:w-7 sm:h-7 w-6 h-5 pl-1.5 self-center pt-0.5 cursor-pointer flex"
                    onClick={() => setShowPopup(true)}
                >
                    <InformationCircleIcon />
                </div>
                <div className="absolute z-20">
                    <PopupDialog
                        open={showPopup}
                        close={setShowPopup}
                        title={props.info}
                    />
                </div>
            </div>
            <div className="flex flex-row lg:justify-evenly sm:gap-x-10 gap-x-4 justify-center">
                <HomeDataPanel
                    colour={props.colours.colour1}
                    percentageDuration={props.data.data1.value}
                    title={props.titles.title1}
                    comparisonVal={props.data.data1.comparison}
                    display={props.display}
                    unit={props.unit}
                    lineData={props.data.data1.graphData}
                    min={props.graph !== undefined ? props.graph.graph1.min : 0}
                    max={
                        props.graph !== undefined ? props.graph.graph1.max : 100
                    }
                />
                <HomeDataPanel
                    colour={props.colours.colour2}
                    percentageDuration={props.data.data2.value}
                    title={props.titles.title2}
                    comparisonVal={props.data.data2.comparison}
                    display={props.display}
                    unit={props.unit}
                    lineData={props.data.data2.graphData}
                    min={props.graph !== undefined ? props.graph.graph2.min : 0}
                    max={
                        props.graph !== undefined ? props.graph.graph2.max : 100
                    }
                />
                <HomeDataPanel
                    colour={props.colours.colour3}
                    percentageDuration={props.data.data3.value}
                    title={props.titles.title3}
                    comparisonVal={props.data.data3.comparison}
                    display={props.display}
                    unit={props.unit}
                    lineData={props.data.data3.graphData}
                    min={props.graph !== undefined ? props.graph.graph3.min : 0}
                    max={
                        props.graph !== undefined ? props.graph.graph3.max : 100
                    }
                />
            </div>
        </div>
    );
};

export default HomePanelContainer;
