import React from "react";
import dayjs from "dayjs";
const TransitionHistoryDetails = (props) => {
    const states = props.issue.issueState;
    if (!states) return <div></div>;
    return (
        <div
            className="grid grid-cols-4 py-2 text-black justify-items-start gap-x-2
            "
        >
            <div>
                <div className="text-2xs xl:text-xs">
                    {states.some((state) => state.type === "issue_opened")
                        ? dayjs(
                              states.find(
                                  (state) => state.type === "issue_opened"
                              ).time
                          ).format("DD/MM/YY HH:mm")
                        : "---"}
                </div>
                <div className="text-trueGray-400 text-2xs sm:text-xs xl:text-sm">
                    PENDING
                </div>
            </div>
            <div className="justify-self-center">
                <div className="text-2xs xl:text-xs">
                    {states.some((state) => state.value === "2")
                        ? dayjs(
                              states.find((state) => state.value === "2").time
                          ).format("DD/MM/YY HH:mm")
                        : "---"}
                </div>
                <div className="text-trueGray-400 text-2xs sm:text-xs xl:text-sm">
                    IN PROGRESS
                </div>
            </div>
            <div className="justify-self-end">
                <div className="text-2xs xl:text-xs">
                    {states.some((state) => state.value === "9")
                        ? dayjs(
                              states.find((state) => state.value === "9").time
                          ).format("DD/MM/YY HH:mm")
                        : "---"}
                </div>
                <div className="text-trueGray-400 text-2xs sm:text-xs xl:text-sm">
                    RESOLVED
                </div>
            </div>
            <div className="justify-self-end">
                <div className="text-2xs xl:text-xs">
                    {states.some((state) => state.value === "10")
                        ? dayjs(
                              states.find((state) => state.value === "10").time
                          ).format("DD/MM/YY HH:mm")
                        : "---"}
                </div>
                <div className="text-trueGray-400 text-2xs sm:text-xs xl:text-sm">
                    CLOSED
                </div>
            </div>
        </div>
    );
};

export default TransitionHistoryDetails;
