import React, { useEffect, useRef } from "react";
import * as echarts from "echarts/core"; //import all charts
import { LineChart } from "echarts/charts";
import { GridComponent, DatasetComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";
import dayjs from "dayjs";
import useWindowDimensions from "../../../utils/getWindowDimensions";

//register required components
echarts.use([GridComponent, LineChart, CanvasRenderer, DatasetComponent]);

const LargeLineGraph = (props) => {
    const chartRef = useRef(null);
    const windowWidth = useWindowDimensions().width;

    // const [chartState, setChartState] = useState(null);
    // const [chartWidth, setChartWidth] = useState(null);
    // const [chartHeight, setChartHeight] = useState(null);
    // const { windowWidth } = useWindowDimensions();

    //returns array containing days of the month from today
    const getMonthArray = () => {
        let xAxis = [];
        for (let i = 29; i >= 0; i--) {
            xAxis.push(dayjs().subtract(i, "days").get("date"));
        }
        return xAxis;
    };

    //setting chart
    useEffect(() => {
        if (!chartRef.current) return;
        const myChart = init(chartRef.current);
        let option = {
            title: {
                text: "TOTAL",
                bottom: "bottom",
                padding: [15, 25],
                textStyle: {
                    color: "#9C9C9C",
                    fontWeight: "normal",
                },
            },
            xAxis: {
                type: "category",
                data: getMonthArray(),
                axisLine: {
                    lineStyle: {
                        color: "rgba(0, 0, 0, 0.25)",
                    },
                },
            },
            yAxis: {
                type: "value",
                axisLine: {
                    lineStyle: {
                        color: "rgba(0, 0, 0, 0.25)",
                    },
                },
                axisLabel: {
                    margin: windowWidth < 1024 ? 4 : 8,
                },
                splitLine: {
                    lineStyle: {
                        color: "rgba(0, 0, 0, 0.25)",
                    },
                },
            },
            series: [
                {
                    data: props.data,
                    type: "line",
                    showSymbol: false,
                    lineStyle: {
                        width: 2,
                        color: "#25213A",
                    },
                },
            ],
        };
        myChart.setOption(option);
        // setChartState(myChart);
        // setChartHeight(myChart.getHeight());
        // setChartWidth(myChart.getWidth());
    }, [props.data, windowWidth]);

    // useEffect(() => {
    //     if (chartState !== null)chartState.resize();
    // }, [chartWidth, chartHeight, windowWidth, chartState])

    return (
        <div className="w-full h-full">
            <div ref={chartRef} className="h-full w-full" />
        </div>
    );
};

export default LargeLineGraph;
