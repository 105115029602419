import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import PillButton from "../../Miscellaneous/PillButton";

const LoginModal = (props) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    return (
        <div>
            <Transition appear show={props.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    open={props.open}
                    onClose={() => {}}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="flex flex-row transition-all transform justify-center">
                            <div className="flex flex-col w-full md:w-1/4 xl:w-1/4 mt-20">
                                <div className="bg-white p-5 m-auto w-full overflow-auto md:mt-0 mt-16 rounded-2xl">
                                    <div className="mb-1">Username</div>
                                    <input
                                        className="w-full h-8 rounded-md border border-trueGray-300 p-2 mb-2"
                                        name="search"
                                        placeholder="Enter username"
                                        type="text"
                                        value={username}
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                        }}
                                    />
                                    <div className="mb-1">Password</div>
                                    <input
                                        className="w-full h-8 rounded-md border border-trueGray-300 p-2 mb-5"
                                        name="search"
                                        placeholder="Enter password"
                                        type="text"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                    <div className="flex flex-row w-full justify-between">
                                        <div
                                            className="w-1/2 mr-1"
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <PillButton
                                                bg="white"
                                                textC="teal-500"
                                                text="CANCEL"
                                                disabled={props.disabled}
                                            />
                                        </div>
                                        <div
                                            className="w-1/2 ml-1"
                                            onClick={() => {
                                                if (username && password) {
                                                    props.login(
                                                        username,
                                                        password
                                                    );
                                                    props.setOpen(false);
                                                }
                                            }}
                                        >
                                            <PillButton
                                                bg="teal-500"
                                                textC="white"
                                                text="LOGIN"
                                                disabled={
                                                    !username || !password
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </div>
    );
};

export default LoginModal;
