import { gql, useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import DisplayValue from "../../components/Text/DisplayValue";
import PopupDialog from "../../components/Modals/PopupDialog";

const DOOR_CYCLE_QUERY = gql`
    query getDoorCycles(
        $asset_ids: [Int!]
        $dayAgo: timestamptz!
        $monthAgo: timestamptz!
        $yearAgo: timestamptz!
    ) {
        day_count: doorinformer_core_measurementdata_aggregate(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
                time: { _gte: $dayAgo }
            }
        ) {
            aggregate {
                sum {
                    count1
                }
            }
        }
        month_count: doorinformer_core_measurementdata_aggregate(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
                time: { _gte: $monthAgo }
            }
        ) {
            aggregate {
                sum {
                    count1
                }
            }
        }
        year_count: doorinformer_core_measurementdata_aggregate(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
                time: { _gte: $yearAgo }
            }
        ) {
            aggregate {
                sum {
                    count1
                }
            }
        }
        first: doorinformer_core_measurementdata(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
            }
            order_by: { time: asc_nulls_last }
            limit: 1
        ) {
            id
            time
        }
        last: doorinformer_core_measurementdata(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
            }
            order_by: { time: desc_nulls_last }
            limit: 1
        ) {
            id
            time
        }
    }
`;

const DoorCycleTotals = (props) => {
    const [dayAgo] = useState(dayjs().startOf("day"));
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [yearAgo] = useState(dayjs().subtract(365, "days"));
    const [openInfo, setOpenInfo] = useState(false);

    const [todayTotal, setTodayTotal] = useState();
    const [monthTotal, setMonthTotal] = useState();
    const [averageDaily, setAverageDaily] = useState();
    const [averageMonthly, setAverageMonthly] = useState();

    const { data: doorData } = useQuery(DOOR_CYCLE_QUERY, {
        fetchPolicy: "network-only", // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        variables: {
            asset_ids: props.assetIds,
            dayAgo: dayAgo,
            monthAgo: monthAgo,
            yearAgo: yearAgo,
        },
    });

    useEffect(() => {
        if (!doorData || !doorData.first.length > 0) return;
        let first = dayjs(doorData.first[0].time);
        let last = dayjs(doorData.last[0].time);
        let day = doorData.day_count.aggregate.sum.count1;
        let month = doorData.month_count.aggregate.sum.count1;
        let year = doorData.year_count.aggregate.sum.count1;

        let diffDays =
            last.diff(first, "day", true) >= 1
                ? last.diff(first, "day", true)
                : 1;
        let diffMonths =
            last.diff(first, "month", true) > 12
                ? 12
                : last.diff(first, "month");

        setTodayTotal(day);
        setMonthTotal(month);
        setAverageDaily(
            Math.round(diffDays >= 30 ? month / 30 : month / diffDays)
        );
        setAverageMonthly(
            Math.round(diffMonths <= 1 ? month : year / diffMonths)
        );
    }, [props.assetIds, doorData]);

    return (
        <div className="border-2 rounded-lg bg-white px-3 h-24 sm:h-30 py-1">
            <PopupDialog
                open={openInfo}
                close={setOpenInfo}
                title="Door Cycles"
            />
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <div className="text-lg xl:text-2xl">Door Cycles</div>
                    <div
                        className="w-5 h-4 self-center ml-2 cursor-pointer"
                        onClick={() => setOpenInfo(true)}
                    >
                        <InformationCircleIcon />
                    </div>
                </div>
                <div className="self-center text-trueGray-400 xl:text-base text-xs">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row justify-between space-x-5 2xl:space-x-12 pt-1 lg:pt-4 px-1 lg:px-3 xl:px-8">
                <DisplayValue title="TODAY" value={todayTotal} />
                <DisplayValue title="MONTH" value={monthTotal} />
                <DisplayValue title="AVG DAILY" value={averageDaily} />
                <DisplayValue title="AVG MONTH" value={averageMonthly} />
            </div>
        </div>
    );
};

export default DoorCycleTotals;
