import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { React, useEffect, useState } from "react";
import FaultTable from "../../components/Tables/FaultTable/FaultTable";
import TimeFilter2 from "./../../components/Input/TimeFilter2/TimeFilter2";

const QUERY_ASSET_FAULTS = gql`
  query getAssetFaults(
    $asset_ids: [Int!]
    $start: timestamptz!
    $end: timestamptz!
  ) {
    fault(
      where: {
        asset_id: { _in: $asset_ids }
        _and: {
          time_raised: { _gte: $start }
          _and: { time_raised: { _lte: $end } }
        }
      }
      order_by: { time_raised: desc }
    ) {
      id
      time_raised
      time_resolved
      asset {
        id
        description
        site {
          id
          name
        }
      }
      description
      type {
        description
        id
        module
        part_code
        fault_code
      }
      alarm_configuration {
        id
        description
      }
    }
  }
`;

const AssetFaults = (props) => {
  const [faults, setFaults] = useState([]);

  const [timeFilter, setTimeFilter] = useState();
  const [startTime, setStartTime] = useState(
    dayjs().subtract(30, "days").startOf("day")
  );
  const [endTime, setEndTime] = useState(dayjs().format());

  const { data: faultData, error: faultDataError } = useQuery(
    QUERY_ASSET_FAULTS,
    {
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions
      variables: {
        asset_ids: props.assetIds,
        start: startTime,
        end: endTime,
      },
      onError: () => console.log(faultDataError),
    }
  );

  useEffect(() => {
    if (!faultData) return;
    let faults = [];
    faultData.fault.map((fault) => {
      let description = !fault.type
        ? fault.description
        : fault.type.description;

      if (fault.alarm_configuration) {
        description += " - " + fault.alarm_configuration.description;
      }
      return faults.push({
        timeRaised: dayjs(fault.time_raised).format("DD-MM-YYYY HH:mm"),
        timeResolved: fault.time_resolved
          ? dayjs(fault.time_resolved).format("DD-MM-YYYY HH:mm")
          : "---",
        fault: description,
        asset: "[" + fault.asset.site.name + "] " + fault.asset.description,
      });
    });
    setFaults(faults);
  }, [faultData]);

  useEffect(() => {
    if (!timeFilter) return;
    setStartTime(timeFilter.start);
    setEndTime(timeFilter.end);
  }, [timeFilter]);

  const titles = [
    { Header: "Time Raised", accessor: "timeRaised" },
    { Header: "Time Resolved", accessor: "timeResolved" },
    { Header: "Asset", accessor: "asset" },
    { Header: "Fault", accessor: "fault" },
  ];

  return (
    <div className="border-2 rounded-lg bg-white">
      <div className="flex flex-row justify-between pt-1.5 px-4">
        <div className="text-xl lg:text-2xl">Faults</div>
        <div className="w-36 z-10">
          <TimeFilter2 setTime={setTimeFilter} />
        </div>
      </div>

      <FaultTable data={faults} titles={titles} />
    </div>
  );
};

export default AssetFaults;
