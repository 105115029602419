import React from "react";
import classnames from "classnames";
import { RefreshIcon } from "@heroicons/react/outline";

const PillButton = (props) => {
    let bgColor = props.bg;
    if (!bgColor) {
        bgColor = "teal";
    }
    let textColor = props.textColor;
    if (!textColor) {
        textColor = "white";
    }
    return (
        <div
            className={classnames(
                `bg-${bgColor}`,
                props.disabled && "opacity-70",
                `text-${props.textC}`,
                `border-teal-500`,
                "border-2 rounded-3xl h-10 flex flex-row justify-center font-semibold",
                !props.disabled && "cursor-pointer "
            )}
        >
            <div className="text-center xl:text-sm sm:text-xs text-2xs font-semibold self-center ">
                {props.loading ? (
                    <RefreshIcon className="w-5 h-5 animate-spin" />
                ) : (
                    props.text
                )}
            </div>
        </div>
    );
};

export default PillButton;
