export const Logo = (props) => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
                fill="#12A690"
            />
            <path
                d="M22.5 40.2985C32.3298 40.2985 40.2985 32.3298 40.2985 22.5C40.2985 12.6701 32.3298 4.70148 22.5 4.70148C12.6702 4.70148 4.70149 12.6701 4.70149 22.5C4.70149 32.3298 12.6702 40.2985 22.5 40.2985Z"
                fill="white"
            />
            <path
                d="M41.6405 24.2025H3.5639V28.209L7.059 34.6502L12.701 38.9804L20.4217 42.2067C20.4217 42.2067 27.2394 42.3989 27.7332 41.8879C28.227 41.377 33.5178 39.2911 33.5178 38.9552C33.5178 38.6194 38.0382 34.1651 38.0382 34.1651C38.0382 34.1651 41.6405 30.5547 41.6405 30.1762C41.6405 29.7977 41.6405 24.2025 41.6405 24.2025Z"
                fill="#12A690"
            />
            <path
                d="M29.9498 36.9403L31.2611 32.5331L27.5373 31.5353L32.0113 26.8657L30.7276 30.4711L33.9179 31.1036L29.9498 36.9403Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7687 36.6029C22.7687 36.6029 19.7048 36.7732 19.8164 33.5937C19.9127 30.8417 22.7687 26.8657 22.7687 26.8657C22.7687 26.8657 25.1053 30.8417 25.1841 33.5937C25.2755 36.7732 22.7687 36.6029 22.7687 36.6029Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6361 30.2296C11.6361 30.2296 11.693 31.6917 12.2624 31.7648C12.8317 31.8377 14.0841 29.7173 13.5717 26.8657C13.5717 26.8657 15.2797 28.4743 15.5644 29.7173C15.8487 30.9604 15.9855 29.529 15.9855 29.529C15.9855 29.529 19.208 33.1541 16.1338 35.9328C16.1338 35.9328 16.6687 34.0363 16.3044 33.0075C15.9698 32.0621 16.0763 32.9537 15.8487 33.6849C15.6208 34.416 15.1088 32.0572 14.5967 31.618C14.0841 31.1794 14.4828 35.055 13.1163 34.3238C11.7498 33.5929 13.1732 35.7866 13.1732 35.7866C13.1732 35.7866 8.9599 36.0788 11.6361 30.2296Z"
                fill="white"
            />
            <rect
                x="8.39552"
                y="17.1269"
                width="2.35075"
                height="6.04478"
                fill="#12A690"
            />
            <rect
                x="12.0896"
                y="19.1418"
                width="2.35075"
                height="4.02985"
                fill="#12A690"
            />
            <rect
                x="15.7836"
                y="11.0821"
                width="2.35075"
                height="12.0896"
                fill="#12A690"
            />
            <rect
                x="19.4776"
                y="19.8134"
                width="2.35075"
                height="3.35821"
                fill="#12A690"
            />
            <rect
                x="23.1716"
                y="17.1269"
                width="2.35075"
                height="6.04478"
                fill="#12A690"
            />
            <rect
                x="26.8657"
                y="12.7612"
                width="2.35075"
                height="10.4104"
                fill="#12A690"
            />
            <rect
                x="30.5597"
                y="14.7761"
                width="2.35075"
                height="8.39552"
                fill="#12A690"
            />
            <rect
                x="34.2537"
                y="18.4702"
                width="2.35075"
                height="4.70149"
                fill="#12A690"
            />
        </svg>
    );
};
