import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { gql, useQuery, useMutation } from "@apollo/client";
import Select from "react-select";
import { customStyles } from "../../../utils/helperFunctions";
import { getQueryParameter } from "../../../utils/queryParams";
import PillButton from "../../Miscellaneous/PillButton";
import AutoCompleteSearch from "../../Input/AutoCompleteSearch";
import { useCallback } from "react";
import ErrorPanel from "../../Panels/ErrorPanel";

const QUERY_USER_INFO = gql`
  query getUserDetails($userId: Int!, $policyName: String!) {
    user_profile_by_pk(id: $userId) {
      id
      email
      first_name
      last_name
      policies(where: { policy: { name: { _ilike: $policyName } } }) {
        id
        policy {
          id
          name
          role
          sites {
            id
            site {
              id
              name
            }
          }
          asset_types {
            id
            asset_type {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const QUERY_ORG_OPTIONS = gql`
  query getOrgOptions($orgId: Int!) {
    organisation_by_pk(id: $orgId) {
      id
      name
      sites {
        id
        name
      }
      asset_types {
        id
        name
      }
      users {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

const DELETE_POLICY_RELATIONSHIPS_MUTATION = gql`
  mutation deletePolicyRelationships($policyId: Int!) {
    delete_ppcs_core_policy_asset_types(
      where: { policy_id: { _eq: $policyId } }
    ) {
      returning {
        id
        assettype_id
        policy_id
      }
    }
    delete_ppcs_core_policy_sites(where: { policy_id: { _eq: $policyId } }) {
      returning {
        id
        site_id
        policy_id
      }
    }
  }
`;

const INSERT_POLICY_RELATIONSHIPS_MUTATION = gql`
  mutation insertAssetTypePolicy(
    $assetTypePolicyObjects: [ppcs_core_policy_asset_types_insert_input!]!
    $sitePolicyObjects: [ppcs_core_policy_sites_insert_input!]!
  ) {
    insert_ppcs_core_policy_asset_types(objects: $assetTypePolicyObjects) {
      returning {
        id
        assettype_id
        policy_id
      }
    }
    insert_ppcs_core_policy_sites(objects: $sitePolicyObjects) {
      returning {
        id
        policy_id
        site_id
      }
    }
  }
`;

const UPDATE_POLICY_MUTATION = gql`
  mutation updatePolicy($policyId: Int!, $role: Int!) {
    update_ppcs_core_policy_by_pk(
      pk_columns: { id: $policyId }
      _set: { role: $role }
    ) {
      id
      name
      organisation_id
      role
      owner_id
      sites {
        id
        site {
          id
          name
        }
      }
      asset_types {
        id
        asset_type {
          id
          name
        }
      }
    }
  }
`;

const ROLE_LABEL = {
  1: "STAFF",
  2: "ADMIN",
};

const EditUserModal = (props) => {
  const orgId = getQueryParameter("organisationId");
  const userId = getQueryParameter("userId");
  const [userEmail, setUserEmail] = useState();
  const [policyId, setPolicyId] = useState();
  const [currentSites, setCurrentSites] = useState();
  const [currentTypes, setCurrentTypes] = useState();
  const [siteOptions, setSiteOptions] = useState();
  const [userOptions, setUserOptions] = useState();
  const [typeOptions, setTypeOptions] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [hasEditablePolicy, setHasEditablePolicy] = useState(true);

  const {
    data: userData,
    error: userError,
    refetch: refetchUserData,
  } = useQuery(QUERY_USER_INFO, {
    variables: {
      userId: userId,
      policyName: `${userEmail}%`,
    },
    onError: () => console.log(userError),
    skip: !userId || !userEmail,
  });

  const { data: optionsData, error: optionError } = useQuery(
    QUERY_ORG_OPTIONS,
    {
      variables: {
        orgId: orgId,
      },
      onError: () => console.log(optionError),
    }
  );

  const [deletePolicyRelationships] = useMutation(
    DELETE_POLICY_RELATIONSHIPS_MUTATION
  );
  const [insertPolicyRelationships] = useMutation(
    INSERT_POLICY_RELATIONSHIPS_MUTATION
  );

  const [updatePolicy] = useMutation(UPDATE_POLICY_MUTATION);

  useEffect(() => {
    if (!userData || !userData.user_profile_by_pk) return;
    if (!userData.user_profile_by_pk.policies[0]) {
      setHasEditablePolicy(false);
      return;
    }
    setHasEditablePolicy(true);
    let userPolicy = userData.user_profile_by_pk.policies[0].policy;
    setPolicyId(userPolicy.id);
    setSelectedRole({
      value: userPolicy.role,
      label: ROLE_LABEL[userPolicy.role],
    });
    let sites = [];
    let assetTypes = [];
    userPolicy.sites.map((site) => {
      return sites.push({
        value: site.site.id,
        label: site.site.name,
      });
    });
    userPolicy.asset_types.map((type) => {
      return assetTypes.push({
        value: type.asset_type.id,
        label: type.asset_type.name,
      });
    });
    setCurrentSites(sites);
    setCurrentTypes(assetTypes);
  }, [userData]);

  useEffect(() => {
    if (!optionsData) return;
    let sites = [];
    let assetTypes = [];
    let users = [];
    optionsData.organisation_by_pk.sites.map((site) => {
      return sites.push({
        value: site.id,
        label: site.name,
      });
    });
    optionsData.organisation_by_pk.asset_types.map((type) => {
      return assetTypes.push({
        value: type.id,
        label: type.name,
      });
    });
    optionsData.organisation_by_pk.users.map((user) => {
      return users.push({
        id: user.id,
        name: user.email,
      });
    });
    setUserOptions(users);
    setSiteOptions(sites);
    setTypeOptions(assetTypes);
  }, [optionsData]);

  useEffect(() => {
    if (!userId) {
      handleClear();
    }
  }, [userId]);

  const handleClear = () => {
    setHasEditablePolicy(true);
    setSelectedRole();
    setCurrentSites(null);
    setCurrentTypes(null);
  };

  const updateUserDetails = useCallback(async () => {
    setUpdateLoading(true);
    let siteIds = [];
    let assetTypeIds = [];
    await deletePolicyRelationships({
      variables: {
        policyId: policyId,
      },
    });

    if (currentSites) {
      currentSites.map((site) => {
        return siteIds.push({
          policy_id: policyId,
          site_id: site.value,
        });
      });
    }

    if (currentTypes) {
      currentTypes.map((type) => {
        return assetTypeIds.push({
          policy_id: policyId,
          assettype_id: type.value,
        });
      });
    }

    await insertPolicyRelationships({
      variables: {
        assetTypePolicyObjects: assetTypeIds,
        sitePolicyObjects: siteIds,
      },
    });

    updatePolicy({
      variables: {
        policyId: policyId,
        role: selectedRole.value,
      },
    })
      .then((r) => {
        refetchUserData();
        setUpdateLoading(false);
        props.close(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    currentSites,
    props,
    currentTypes,
    deletePolicyRelationships,
    insertPolicyRelationships,
    policyId,
    selectedRole,
    updatePolicy,
    refetchUserData,
  ]);

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          if (props.close) {
            props.close(false);
          }
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="overflow-y-auto inline-block sm:w-160 w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
              <Dialog.Title as="h3" className="text-lg font-medium leading">
                <div className="space-y-4 text-base">
                  <div className="flex flex-row justify-between">
                    <div className="text-xl">Edit User Details</div>
                    <div
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => props.close(false)}
                    >
                      <XIcon />
                    </div>
                  </div>
                  <AutoCompleteSearch
                    options={userOptions}
                    setUser={setUserEmail}
                  />
                  {hasEditablePolicy && (
                    <div>
                      <div className="w-full">
                        <div>Role</div>
                        <Select
                          value={selectedRole}
                          onChange={setSelectedRole}
                          options={[
                            {
                              value: 1,
                              label: "STAFF",
                            },
                            {
                              value: 2,
                              label: "ADMIN",
                            },
                          ]}
                          styles={customStyles}
                        />
                      </div>
                      <div className="flex flex-row w-full gap-x-4 pb-20">
                        <div className="w-1/2">
                          <div>Sites</div>
                          <Select
                            value={currentSites}
                            onChange={setCurrentSites}
                            options={siteOptions}
                            isMulti={true}
                            styles={customStyles}
                          />
                        </div>
                        <div className="w-1/2">
                          <div>Asset Types</div>
                          <Select
                            value={currentTypes}
                            onChange={setCurrentTypes}
                            options={typeOptions}
                            isMulti={true}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="w-full pb-3" onClick={updateUserDetails}>
                        <PillButton
                          loading={updateLoading}
                          text="UPDATE"
                          bg="teal-500"
                          textC="white"
                        />
                      </div>
                    </div>
                  )}
                  {!hasEditablePolicy && (
                    <div className="py-5">
                      <ErrorPanel message="User has no policies that allow editing" />
                    </div>
                  )}
                </div>
              </Dialog.Title>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditUserModal;
