import React, { useState, useEffect } from "react";
import DisplayValue from "../../Text/DisplayValue";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { getQueryParameter } from "../../../utils/queryParams";
import TempBoxPlot from "../../Graphs/TempBoxPlot";

const QUERY_TEMPERATURE_RANGE = gql`
    query getTemperatureRanges(
        $assetId: Int!
        $dayAgo: timestamptz!
        $monthAgo: timestamptz
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    current: beacon_data(order_by: { time: desc }, limit: 1) {
                        id
                        current_temperature
                    }
                    dayAgo: beacon_data(
                        order_by: { current_temperature: desc }
                        where: { time: { _gte: $dayAgo } }
                    ) {
                        id
                        current_temperature
                    }
                    monthAgo: beacon_data(
                        order_by: { current_temperature: desc }
                        where: { time: { _gte: $monthAgo } }
                    ) {
                        id
                        current_temperature
                    }
                }
            }
        }
    }
`;

const TempMonitoringPanel = (props) => {
    const assetId = getQueryParameter("assetId");
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [dayAgo] = useState(dayjs().subtract(24, "hours"));
    const [temperatureArray, setTemperatureArray] = useState();
    const [currentTemp, setCurrentTemp] = useState();

    const { data: temperatureRange, error: temperatureRangeError } = useQuery(
        QUERY_TEMPERATURE_RANGE,
        {
            variables: {
                assetId: assetId,
                dayAgo: dayAgo,
                monthAgo: monthAgo,
            },
            onError: () => console.log(temperatureRangeError),
        }
    );

    useEffect(() => {
        if (
            !temperatureRange ||
            !temperatureRange.asset_by_pk ||
            !temperatureRange.asset_by_pk.measurement_points[0] ||
            !temperatureRange.asset_by_pk.measurement_points[0]
                .measurement_point
        )
            return;
        let mp =
            temperatureRange.asset_by_pk.measurement_points[0]
                .measurement_point;

        if (
            mp.current.length < 1 ||
            mp.dayAgo.length < 1 ||
            mp.monthAgo.length < 1
        ) {
            return;
        }
        let current = mp.current[0].current_temperature.toFixed(1);
        let highestDay = mp.dayAgo[0].current_temperature.toFixed(1);
        let lowestDay =
            mp.dayAgo[mp.dayAgo.length - 1].current_temperature.toFixed(1);
        let highestMonth = mp.monthAgo[0].current_temperature.toFixed(1);
        let lowestMonth =
            mp.monthAgo[mp.monthAgo.length - 1].current_temperature.toFixed(1);
        setCurrentTemp(current);

        let rangeArray = [];
        rangeArray.push(
            lowestMonth,
            lowestDay,
            current,
            highestDay,
            highestMonth,
            "Time periods"
        );
        setTemperatureArray(rangeArray);
    }, [temperatureRange]);
    return (
        <div className="h-28 sm:h-30 rounded-lg bg-white border-2 px-3 py-1">
            <div className="flex flex-row justify-between">
                <div className="text-lg xl:text-2xl">
                    Temperature Monitoring
                </div>
                <div className="self-center xl:self-end text-trueGray-400 xl:text-base text-xs">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row justify-between px-4">
                <div className="py-4 2xl:pl-5 sm:pl-4 self-center">
                    {currentTemp ? (
                        <DisplayValue
                            title="TEMPERATURE"
                            value={currentTemp}
                            unit="°C"
                        />
                    ) : (
                        <DisplayValue
                            title="TEMPERATURE"
                            value="N/A"
                            unit="°C"
                        />
                    )}
                </div>
                <div className="h-20 w-2/3">
                    {temperatureArray && (
                        <TempBoxPlot data={temperatureArray} />
                    )}
                </div>
            </div>
        </div>
    );
};
export default TempMonitoringPanel;
