import { DIM_SCREEN, CLEAR_DIM } from "../actionTypes";

const initialState = {
    dimmed: false,
};

const screenDim = (state = initialState, action) => {
    if (action.type === DIM_SCREEN) {
        return { dimmed: true };
    }
    if (action.type === CLEAR_DIM) {
        return { dimmed: false };
    }
    return initialState;
};

export default screenDim;
