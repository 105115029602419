import React, { useState } from "react";
import { initSession, isUserLoggedIn } from "../../../utils/userSession";
import cognitoUtils from "../../../utils/cognito";
import { Redirect } from "react-router-dom";
import { RefreshIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const LoginForm = () => {
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userIsLoggedIn = isUserLoggedIn();

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    if (userIsLoggedIn) {
        return <Redirect to="/home" />;
    }

    const handleInputChange = (event) => {
        event.persist();
        setFormData((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
        setError(null);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleFormSubmit(event);
        }
    };

    const handleFormSubmit = (event) => {
        if (event) {
            event.preventDefault();
            setIsSubmitting(true);
            cognitoUtils
                .authenticateUser(formData.email, formData.password)
                .then((result) => {
                    if (result) {
                        initSession(result);
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    if (e.code === "UserNotConfirmedException") {
                        cognitoUtils
                            .resendConfirmationCode(formData.email)
                            .then((result) => {
                                if (result) {
                                    window.location =
                                        "/signup/?email=" +
                                        formData.email +
                                        "&codeSent=true";
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    } else {
                        setIsSubmitting(false);
                        setError(e.message);
                    }
                });
        }
    };

    return (
        <form id="loginForm" method="post" onSubmit={handleFormSubmit}>
            <div className="flex flex-col gap-y-2.5 placeholder-gray-text text-gray-text text-xs">
                <div>
                    <div className="text-teal-500 font-semibold cursor-pointer text-sm mb-2">
                        Sign in to your account to continue

                    </div>
                    <Link to="/signup">
                        <div className="text-teal-500 text-xs">or create an account</div>
                    </Link>
                </div>
                {error && <div className={"authErrorMessage"}>{error}</div>}
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center">
                        <button
                            id="timeIcon"
                            type="submit"
                            className="p-2.5 focus:outline-none focus:shadow-outline cursor-default text-blue-org1Template"
                            disabled
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg>
                        </button>
                    </span>
                    <input
                        className="w-72 xl:w-1/2 h-8 rounded-md pl-7 border-0 text-xs"
                        name="email"
                        type="Email"
                        placeholder="Enter your email"
                        onChange={handleInputChange}
                        required
                        value={formData.email}
                    />
                </div>
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center">
                        <button
                            id="timeIcon"
                            type="submit"
                            className="p-2.5 focus:outline-none focus:shadow-outline cursor-default text-blue-org1Template"
                            disabled
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </span>
                    <input
                        className="w-72 xl:w-1/2  h-8 rounded-md pl-7 border-0 text-xs"
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        value={formData.password}
                        required
                    />
                </div>
                <Link to="/forgot-password">
                    <div className="text-teal-500 font-semibold underline cursor-pointer">
                        Forgotten your password?
                    </div>
                </Link>
            </div>
            <button
                id="submitBtn"
                className="bg-teal-500 text-sm rounded-md w-24 h-9 mt-5 text-white font-bold"
                type="submit"
                disabled={isSubmitting}
            >
                {isSubmitting && (
                    <div className="flex flex-row justify-center">
                        <RefreshIcon className="w-5 h-5 animate-spin" />
                    </div>
                )}
                {!isSubmitting && <div>SIGN IN</div>}
            </button>
        </form>
    );
};

export default LoginForm;
