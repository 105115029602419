import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../../utils/queryParams";
import { PencilAltIcon } from "@heroicons/react/outline";
import EditAssetDetails from "../../Modals/EditAssetDetails";

const GET_ASSET_DETAILS_QUERY = gql`
    query assetDetailsQuery($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            asset_make {
                id
                name
                description
            }
            model
            notes
            serial_number
            item_number
            year
            asset_type {
                id
                name
            }
        }
    }
`;

const AssetDetailsPanel = (props) => {
    const assetId = getQueryParameter("assetId");
    const [assetDetails, setAssetDetails] = useState();
    const [editMode, setEditMode] = useState(false);

    const {
        data: detailsData,
        error: detailsError,
        refetch: refetchDetails,
    } = useQuery(GET_ASSET_DETAILS_QUERY, {
        onError: () => console.log(detailsError),
        fetchPolicy: "network-only", // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        variables: {
            assetId: assetId,
        },
    });

    useEffect(() => {
        if (!detailsData || !detailsData.asset_by_pk) return;
        let asset = detailsData.asset_by_pk;
        setAssetDetails(asset);
    }, [detailsData]);

    if (!assetDetails) return <div></div>;

    return (
        <div className="border-2 rounded-lg bg-white w-full xl:pb-0  overflow-y-auto sm:h-96 h-72">
            <div className="flex flex-row justify-between">
                <div className="text-xl lg:text-2xl  m-3 text-blueGray-800">
                    Details
                </div>
                <div className="m-3" onClick={() => setEditMode(true)}>
                    <PencilAltIcon className="h-6 w-6 text-blueGray-800 cursor-pointer hover:text-teal-500" />
                </div>
            </div>
            <EditAssetDetails
                title="Details"
                open={editMode}
                close={setEditMode}
                details={assetDetails}
                refetch={refetchDetails}
            />
            <div className="flex-col mx-3 space-y-3 text-xs lg:text-sm xl:text-base text-trueGray-400 ">
                <div className="grid grid-cols-2">
                    <div>ASSET TYPE</div>
                    <div className="text-blueGray-800">
                        {assetDetails.asset_type
                            ? assetDetails.asset_type.name
                            : "---"}
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div>MAKE</div>
                    <div className="text-blueGray-800">
                        {assetDetails.asset_make
                            ? assetDetails.asset_make.name
                            : "---"}
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div>MODEL</div>
                    <div className="text-blueGray-800">
                        {assetDetails.model ? assetDetails.model : "---"}
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div>YEAR</div>
                    <div className="text-black">
                        {assetDetails.year ? assetDetails.year : "---"}
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div>ITEM NUMBER</div>
                    <div className="text-blueGray-800">
                        {assetDetails.item_number
                            ? assetDetails.item_number
                            : "---"}
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div>SERIAL NUMBER</div>
                    <div className="text-blueGray-800">
                        {assetDetails.serial_number
                            ? assetDetails.serial_number
                            : "---"}
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div>NOTES</div>
                    <div className="text-blueGray-800">
                        {assetDetails.notes ? assetDetails.notes : "---"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetDetailsPanel;
