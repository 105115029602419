import React from "react";
import classnames from "classnames";

const IprValue = (props) => {
    return (
        <div className="sm:px-2 lg:px-3 self-center text-center">
            <div className="2xl:text-lg sm:text-xs text-2xs">
                <div
                    className={
                        props.parent === props.filter
                            ? classnames("text-teal-500")
                            : classnames("text-trueGray-400")
                    }
                >
                    {props.title}
                </div>
                <div
                    className={
                        props.parent === props.filter
                            ? classnames("text-teal-500 lg:text-2xl text-base")
                            : classnames("text-black lg:text-2xl text-base")
                    }
                >
                    {props.value}
                </div>
            </div>
        </div>
    );
};

export default IprValue;
