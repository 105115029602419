import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { SelectorIcon } from "@heroicons/react/outline";
import classNames from "classnames";

const FaultTable = (props) => {
    const columns = useMemo(() => props.titles, [props.titles]);
    const data = useMemo(() => props.data, [props.data]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useSortBy);

    return (
        <table {...getTableProps()} className="w-full">
            <thead className="text-trueGray-400 xl:text-sm sm:text-2xs text-3xs bg-coolGray-50">
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className={classNames(
                            `grid grid-cols-${props.titles.length} justify-items-start`
                        )}
                    >
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className="w-full border-white border-l-4 flex flex-row sm:px-3 py-2 pl-2"
                            >
                                {column.render("Header")}
                                <span
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="sm:w-4 sm:h-4 w-3 h-3 inline-block"
                                >
                                    <SelectorIcon />
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody
                {...getTableBodyProps()}
                className="xl:text-sm text-2xs max-h-60 block overflow-y-auto"
            >
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={classNames(
                                `grid grid-cols-${props.titles.length} justify-items-start px-3 sm:py-1.5 py-0.5 border-t`
                            )}
                        >
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className="sm:py-1 py-0.5 pl-3 self-center"
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default FaultTable;
