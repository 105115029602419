import React, { useEffect, useRef, useState } from "react";

import * as echarts from "echarts/core"; //import all charts

import { BarChart } from "echarts/charts";

import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    DataZoomComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

import { init } from "echarts/core";

//register required components

echarts.use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    BarChart,
    CanvasRenderer,
    DataZoomComponent,
]);

const DoorBarGraph = (props) => {
    const chartRef = useRef(null);
    const [peopleData, setPeopleData] = useState();
    const [cycleData, setCycleData] = useState();
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    useEffect(() => {
        if (!props.data) return;
        let peopleArr = [];
        let cyclesArr = [];
        props.data.map((data) => {
            cyclesArr.push(data.cycles);
            return peopleArr.push(data.people);
        });
        setPeopleData(peopleArr);
        setCycleData(cyclesArr);
    }, [props.data]);

    useEffect(() => {
        if (
            !chartRef.current ||
            !peopleData ||
            !cycleData ||
            !props.xAxis ||
            !chartObject
        )
            return;
        let option = {
            tooltip: {},
            xAxis: {
                type: "category",
                data: props.xAxis,
                axisLine: { onZero: true, show: false },
                axisTick: { show: false },
                splitLine: { show: false },
                splitArea: { show: false },
                label: { show: false },
                axisLabel: {
                    margin: isMobile ? 5 : 10,
                    fontStyle: "bolder",
                    fontSize: isMobile ? 10 : 12,
                },
            },
            yAxis: {
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    margin: isMobile ? 5 : 10,
                    fontStyle: "bolder",
                    fontSize: isMobile ? 10 : 12,
                },
            },
            grid: {
                show: false,
            },
            dataZoom: {
                start: 0,
                type: "inside",
            },
            series: [
                {
                    name: "Door Cycles",
                    type: "bar",
                    data: cycleData,
                },
                {
                    name: "People Count",
                    type: "bar",
                    data: peopleData,
                },
            ],
        };

        chartObject.setOption(option);
    }, [chartObject, cycleData, peopleData, props, isMobile]);

    return (
        <div className="h-56 w-full">
            <div ref={chartRef} className="h-full w-full" />
        </div>
    );
};

export default DoorBarGraph;
