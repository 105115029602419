import React, { useState, useEffect } from "react";

const HomePanelValue = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [unit, setUnit] = useState();
    useEffect(() => {
        if (props.unit === "%") setUnit("%");
        else {
            setUnit("hr");
        }
    }, [props.unit]);
    if (props.value === undefined) return <div></div>;
    return (
        <div className="text-teal-500 2xl:text-2xl lg:text-xl text-xs sm:text-base font-extrabold flex flex-row pl-2">
            {isMobile ? parseFloat(props.value).toFixed(0) : props.value}
            <div className="lg:text-sm text-2xs sm:text-xs xl:pt-2.5 lg:pt-2 sm:pt-1.5 pt-0.5">
                {unit}
            </div>
        </div>
    );
};

export default HomePanelValue;
