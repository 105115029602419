import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../utils/queryParams";
import { PlusIcon } from "@heroicons/react/outline";
import DataTable from "../../components/Tables/DataTable";
import dayjs from "dayjs";
import AssetFaults from "./AssetFaults";
import AddGoalAssetModal from "../../components/Modals/AddGoalAssetModal";
import AssetPerformance from "../../components/Panels/AssetPerformance";
import VacSessionTotals from "./VacSessionTotals";
import WashSessionTotals from "./WashSessionTotals";
import DoorCycleTotals from "./DoorCycleTotals";
const isMobile =
    window.innerHeight > 640 && window.innerWidth > 640 ? false : true;

const QUERY_GOAL_DETAILS = gql`
    query getGoalDetails($goalId: Int!, $start: timestamptz!) {
        ppcs_core_goal_by_pk(id: $goalId) {
            id
            organisation {
                id
            }
            name
            description
            assets(
                order_by: {
                    asset: { performance_aggregate: { avg: { rating: asc } } }
                }
            ) {
                id
                asset {
                    id
                    description
                    site {
                        id
                        name
                    }
                    issues_aggregate(where: { status: { _lt: 9 } }) {
                        aggregate {
                            count
                        }
                    }
                    performance_aggregate(where: { time: { _gte: $start } }) {
                        aggregate {
                            avg {
                                rating
                            }
                            sum {
                                downtime
                            }
                        }
                    }
                    measurement_points {
                        id
                        measurement_point {
                            id
                            device_type
                        }
                    }
                }
            }
        }
    }
`;

const GoalDetails = (props) => {
    const goalIdParam = getQueryParameter("goalId");
    const [goal, setGoal] = useState();
    const [goalAssets, setGoalAssets] = useState();
    const [isAddAsset, setIsAddAsset] = useState(false);

    const [hasVac, setHasVac] = useState(false);
    const [hasWashWatch, setHasWashWatch] = useState(false);
    const [hasDoor, setHasDoor] = useState(false);

    const [start] = useState(dayjs().subtract(30, "days"));

    const [assetIds, setAssetIds] = useState([]);

    const {
        data: goalDetailsData,
        error: goalDetailsError,
        refetch: refetchGoalData,
    } = useQuery(QUERY_GOAL_DETAILS, {
        onError: () => console.log(goalDetailsError),
        variables: {
            goalId: goalIdParam,
            start: start,
        },
    });

    useEffect(() => {
        if (!goalDetailsData || !goalDetailsData.ppcs_core_goal_by_pk) return;
        setGoal(goalDetailsData.ppcs_core_goal_by_pk);
        let assetIds = [];

        let goalAssets = goalDetailsData.ppcs_core_goal_by_pk.assets.map(
            (asset) => {
                assetIds.push(asset.asset.id);
                asset.asset.measurement_points.map((mp) => {
                    if (mp.measurement_point.device_type === "VAC") {
                        setHasVac(true);
                    }
                    if (mp.measurement_point.device_type === "WASHWATCH") {
                        setHasWashWatch(true);
                    }
                    if (mp.measurement_point.device_type === "DoorInformer") {
                        setHasDoor(true);
                    }
                    return null;
                });
                return {
                    id: asset.asset.id,
                    name: `[${asset.asset.site.name}] ${asset.asset.description}`,
                    issues: asset.asset.issues_aggregate.aggregate.count,
                    performance: (asset.asset.performance_aggregate.aggregate
                        .avg.rating
                        ? asset.asset.performance_aggregate.aggregate.avg
                              .rating * 100
                        : 100
                    ).toFixed(1),
                    impact: (asset.asset.performance_aggregate.aggregate.sum
                        .downtime
                        ? asset.asset.performance_aggregate.aggregate.sum
                              .downtime / 60
                        : 0
                    ).toFixed(1),
                };
            }
        );
        let sorted = goalAssets.sort(
            (a, b) => parseFloat(a.performance) - parseFloat(b.performance)
        );
        setAssetIds(assetIds);
        setGoalAssets(sorted);
    }, [goalDetailsData]);

    if (!goal || !goalAssets) return <div></div>;
    return (
        <div
            className={`w-full xl:px-12 sm:px-6 px-3 h-full overflow-y-auto ${
                isMobile ? "mt-5 pb-32" : "pt-10 pb-0"
            } bg-trueGray-50`}
        >
            <AddGoalAssetModal
                open={isAddAsset}
                close={setIsAddAsset}
                refetch={refetchGoalData}
                assets={assetIds}
            />
            <div className="flex flex-row justify-between">
                <div className="text-teal-600 text-3xl pb-5">{goal.name}</div>
            </div>
            <div className="mb-2 sm:w-1/3 text-trueGray-400 whitespace-pre-line">
                {goal.description.toUpperCase()}
            </div>
            <div className="flex flex-col sm:h-auto mb-2 sm:pb-0">
                <div className="sm:space-x-7">
                    <div className=" bg-white xl:w-2/5 sm:w-1/2 w-full border-2 rounded-lg h-auto float-left sm:mb-0 mb-3">
                        <div className="flex flex-row justify-between px-4 pt-4">
                            <div className="flex flex-row gap-x-1">
                                <div className="text-2xl">Assets</div>
                            </div>
                            {props.hasEdit && (
                                <div className="flex flex-row gap-x-2">
                                    <div className="text-lg">Add Asset</div>
                                    <div
                                        className="mt-0.5 w-6 h-6 text-white rounded-full bg-teal-500 p-0.5 cursor-pointer"
                                        onClick={() => setIsAddAsset(true)}
                                    >
                                        <PlusIcon />
                                    </div>
                                </div>
                            )}
                        </div>
                        <DataTable
                            hasEdit={props.hasEdit}
                            hasDropdown
                            headerStyle="grid grid-cols-4 px-4 gap-x-1 lg:py-2 py-1 justify-items-end"
                            rowStyle="grid grid-cols-4 justify-items-end lg:px-4 px-3 sm:py-4 py-3 border-t hover:text-teal-500 cursor-pointer"
                            data={goalAssets}
                            titles={[
                                { Header: "NAME", accessor: "name" },
                                {
                                    Header: "ISSUES",
                                    accessor: "issues",
                                },
                                {
                                    Header: "30 DAY IMPACT",
                                    accessor: "impact",
                                },

                                {
                                    Header: "30 DAY %",
                                    accessor: "performance",
                                },
                            ]}
                        />
                    </div>
                    {assetIds && (
                        <div className="flex flex-col w-full sm:w-1/2 xl:w-2/5 sm:pl-2">
                            <AssetPerformance assetIds={assetIds} />
                            {hasVac && (
                                <div className="sm:pt-2 pt-3">
                                    <VacSessionTotals assetIds={assetIds} />
                                </div>
                            )}
                            {hasWashWatch && (
                                <div className="sm:pt-2 pt-3">
                                    <WashSessionTotals assetIds={assetIds} />
                                </div>
                            )}
                            {hasDoor && (
                                <div className="sm:pt-2 pt-3">
                                    <DoorCycleTotals assetIds={assetIds} />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="pt-3 xl:w-4/5 w-full">
                    {assetIds && <AssetFaults assetIds={assetIds} />}
                </div>
            </div>
        </div>
    );
};

export default GoalDetails;
