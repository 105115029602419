import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import FilterValue from "../../components/Text/FilterValue";
import { calcByDay } from "../../utils/helperFunctions";
import LargeLineGraph from "../../components/Graphs/LargeLineGraph";
import { useHistory } from "react-router";
import IprDetails from "../../components/Panels/IprDetails/IprDetails";
import DataTable from "../../components/Tables/DataTable";
import { getQueryParameter } from "../../utils/queryParams";

const QUERY_REQUESTS_TOTAL = gql`
    query requestsTotal($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            total_closed: requests_aggregate(where: { open: { _eq: false } }) {
                aggregate {
                    count
                }
            }
            total_open: requests_aggregate(where: { open: { _eq: true } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const QUERY_REQUESTS = gql`
    query getRequestsQuery($orgId: Int!, $open: Boolean!) {
        organisation_by_pk(id: $orgId) {
            id
            requestGraphData: requests {
                id
                time
            }
            requests(
                order_by: { time: desc }
                where: { open: { _eq: $open } }
            ) {
                id
                description
                details
                time
                asset {
                    id
                    description
                    site {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const Requests = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [openRequestCount, setOpenRequestCount] = useState();
    const [closedRequestCount, setClosedRequestCount] = useState();
    const [requestGraphData, setRequestGraphData] = useState();
    const [filterOpen, setFilterOpen] = useState(true);
    const [activeTableData, setActiveTableData] = useState([]);
    const [activeTitle, setActiveTitle] = useState("");
    const [selectedRequest, setSelectedRequest] = useState();
    const history = useHistory();
    const orgIdParam = getQueryParameter("organisationId");
    const filterParam = getQueryParameter("filter");
    const requestIdParam = getQueryParameter("requestId");

    const {
        data: requestData,
        error: requestError,
        refetch: refetchRequests,
    } = useQuery(QUERY_REQUESTS, {
        onError: () => console.log(requestError),
        variables: {
            orgId: props.orgId,
            open: filterOpen,
        },
    });

    const {
        error: requestsTotalError,
        data: requestsTotalData,
        refetch: refetchRequestTotals,
    } = useQuery(QUERY_REQUESTS_TOTAL, {
        fetchPolicy: "network-only",
        variables: {
            orgId: props.orgId,
        },
        onError: () => console.log(requestsTotalError),
    });

    useEffect(() => {
        if (
            requestsTotalData === undefined ||
            requestsTotalData.organisation_by_pk === undefined ||
            requestsTotalData.organisation_by_pk.total_open === undefined ||
            requestsTotalData.organisation_by_pk.total_closed === undefined
        )
            return;
        setOpenRequestCount(
            requestsTotalData.organisation_by_pk.total_open.aggregate.count
        );
        setClosedRequestCount(
            requestsTotalData.organisation_by_pk.total_closed.aggregate.count
        );
    }, [requestsTotalData]);

    //handling request data
    useEffect(() => {
        if (
            requestData === undefined ||
            requestData.organisation_by_pk === undefined ||
            requestData.organisation_by_pk.requests === undefined ||
            requestData.organisation_by_pk.requestGraphData === undefined
        )
            return;
        let requests = [];
        requestData.organisation_by_pk.requests.map((request) => {
            return requests.push({
                request: request,
                col1: dayjs(request.time).format("YYYY-MM-DD"),
                col2: dayjs(request.time).format("HH:mm"),
                col3: request.asset.description,
                col4: request.asset.site.name,
                id: request.id,
            });
        });
        setActiveTableData(requests);
        setRequestGraphData(
            calcByDay(requestData.organisation_by_pk.requestGraphData)
        );
    }, [requestData]);

    const setFilterParam = (filter) => {
        history.push(`?filter=${filter}&organisationId=${orgIdParam}`);
    };

    const setRequestIdParam = (requestId) => {
        if (requestId) {
            history.push(
                `?filter=${filterParam}&organisationId=${orgIdParam}&requestId=${requestId}`
            );
        } else {
            history.push(`?filter=${filterParam}&organisationId=${orgIdParam}`);
        }
    };

    useEffect(() => {
        if (!filterParam) return;
        if (filterParam === "open") {
            setActiveTitle("Open");
            setFilterOpen(true);
        } else if (filterParam === "closed") {
            setActiveTitle("Closed");
            setFilterOpen(false);
        }
        setActiveTableData([]);
        refetchRequests();
        refetchRequestTotals();
    }, [filterParam, refetchRequestTotals, refetchRequests]);

    useEffect(() => {
        if (!requestIdParam) return;
        if (isMobile) {
            let container = document.querySelector("#container");
            container.scrollTo({
                top: 800,
                left: 0,
                behavior: "smooth",
            });
        }
        if (!selectedRequest || requestIdParam !== selectedRequest.id) {
            setSelectedRequest({ id: requestIdParam });
        }
    }, [requestIdParam, selectedRequest, isMobile]);

    return (
        <div
            className={`w-full h-full xl:px-12 px-3 sm:px-6 ${
                isMobile ? "pt-5" : "pt-10"
            } grid sm:grid-cols-2 pb-32 overflow-y-auto`}
            id="container"
        >
            <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between">
                    <div
                        className={`text-teal-600 text-3xl xl:pl-1.5 ${
                            isMobile ? "pb-6" : "pb-12"
                        }`}
                    >
                        Requests
                    </div>
                </div>
                {openRequestCount !== undefined &&
                    closedRequestCount !== undefined && (
                        <div className="bg-white flex justify-around sm:pl-20 rounded-lg border-2 w-full sm:w-11/12 h-20 sm:h-24">
                            <FilterValue
                                setUrl={setFilterParam}
                                title={"OPEN"}
                                value={openRequestCount}
                                url="open"
                            />
                            <FilterValue
                                setUrl={setFilterParam}
                                title={"CLOSED"}
                                value={closedRequestCount}
                                url="closed"
                            />
                        </div>
                    )}
                {activeTableData && (
                    <div
                        className={`${
                            isMobile ? "mt-5" : "mt-10"
                        } bg-white w-full sm:w-11/12 border-2 rounded-lg`}
                    >
                        <div className="text-blueGray-800 m-4 text-2xl">
                            {activeTitle}
                        </div>
                        <DataTable
                            setUrl={setRequestIdParam}
                            headerStyle="grid grid-cols-4 pl-2 lg:pl-5 lg:py-2 py-1 place-items-start"
                            rowStyle="grid grid-cols-4 lg:pl-5 pl-2 place-self-start py-4 border-t hover:text-teal-500 cursor-pointer"
                            onRowClicked={(item) => {
                                setRequestIdParam(item.id);
                                setSelectedRequest(item.request);
                            }}
                            data={activeTableData}
                            titles={[
                                { Header: "DATE", accessor: "col1" },
                                { Header: "TIME", accessor: "col2" },
                                { Header: "ASSET", accessor: "col3" },
                                { Header: "SITE", accessor: "col4" },
                            ]}
                        />
                    </div>
                )}
            </div>
            <div className={`${isMobile ? "pt-5" : "pt-21"}`}>
                {requestGraphData && !isMobile && (
                    <div className="bg-white rounded-lg border-2 xl:w-3/4 w-full h-72 mb-6">
                        <LargeLineGraph data={requestGraphData} />
                    </div>
                )}
                {props.orgId && requestIdParam && (
                    <div className="bg-white border-2 xl:w-3/4 w-full rounded-lg h-3/7 mb-20">
                        <IprDetails
                            requestId={requestIdParam}
                            orgId={props.orgId}
                            onCloseClicked={() => {
                                setRequestIdParam(null);
                                setSelectedRequest(null);
                            }}
                            onStatusUpdated={() => {
                                refetchRequests();
                                refetchRequestTotals();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Requests;
