import classNames from "classnames";
import React, { useEffect, useState } from "react";

const DETAILS = {
    0: "Pending",
    1: "Queued",
    2: "Retrying",
    20: "IN FLIGHT: Sent to provider",
    21: "IN FLIGHT: Sent to device",
    80: "IN FLIGHT: Device confirmation received",
    90: "SUCCESS: Completed",
    91: "FAIL: Cloud to network",
    92: "FAIL: Network to device",
    93: "FAIL: Device did not confirm",
    94: "FAIL: Unknown device command",
    95: "FAIL: Invalid device command arguments",
    96: "FAIL: Device command error",
    97: "FAIL: Device command timeout",
    98: "FAIL: Cloud server error",
    99: "FAIL: Max number of retry reached",
    100: "FAIL: Message is not repeatable",
};

const PROGRESS_VALUE = {
    0: 10,
    1: 25,
    2: 50,
    20: 60,
    21: 70,
    80: 85,
    90: 100,
    91: 100,
    92: 100,
    93: 100,
    94: 100,
    95: 100,
    96: 100,
    97: 100,
    98: 100,
    99: 100,
    100: 100,
};

const ProgressBar = (props) => {
    const [label, setLabel] = useState("...");

    useEffect(() => {
        setLabel(DETAILS[props.status]);
    }, [props]);

    return (
        <div className="sm:mx-10 mx-2">
            <div className="flex mb-2 items-center justify-between">
                <div>
                    <span
                        className={classNames(
                            "text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ",
                            props.status === 90 && "text-teal-600 bg-teal-200",
                            props.status > 90 && "text-red-600 bg-red-200",
                            props.status < 90 &&
                                "text-trueGray-600 bg-trueGray-200"
                        )}
                    >
                        {label}
                    </span>
                    <span className="ml-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-600 ">
                        {props.command}
                    </span>
                </div>
                <div className="text-right">
                    <span className=" text-xs font-semibold inline-block text-teal-600">
                        {PROGRESS_VALUE[props.status]}%
                    </span>
                </div>
            </div>
            <div className="overflow-hidden h-2 mb-2 text:sm flex rounded bg-teal-200">
                <div
                    style={{ width: `${PROGRESS_VALUE[props.status]}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar;
