import React, { useCallback } from "react";
import classnames from "classnames";
import { getQueryParameter } from "../../../utils/queryParams";

const FilterValue = (props) => {
    const currentUrl = getQueryParameter("filter");

    const handleClick = useCallback(() => {
        if (props.setUrl) props.setUrl(props.url);
    }, [props]);
    return (
        <div
            onClick={handleClick}
            className="xl:p-5 lg:px-2.5 self-center text-center xl:text-lg text-xs"
        >
            <div className="cursor-pointer">
                <div
                    className={
                        currentUrl === props.url
                            ? classnames("text-teal-500 ")
                            : classnames(
                                  "text-trueGray-400 hover:text-teal-500"
                              )
                    }
                >
                    {props.title}
                </div>
                <div
                    className={
                        currentUrl === props.url
                            ? classnames("text-teal-500 xl:text-2xl text-lg")
                            : classnames("text-black xl:text-2xl text-lg")
                    }
                >
                    {props.value} {props.unit}
                </div>
            </div>
        </div>
    );
};

export default FilterValue;
