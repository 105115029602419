import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import "./index.css";
import Base from "./views/Base/Base";
import SignUp from "./views/SignUp";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./views/Login";
import { createClient } from "./utils/graphql";
import { Provider } from "react-redux";
import { store } from "./redux";
import ForgotPassword from "./views/ForgotPassword";
import { ApolloProvider } from "@apollo/client";

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://426a1551b3facc8f5fe8619cd7620539@sentry.benchmarker.nz/10",
        environment: process.env.REACT_APP_STAGE,
    });
}

createClient(process.env.REACT_APP_API_URL + "/graphql").then((client) => {
    ReactDOM.render(
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Provider store={store}>
                    <React.Suspense
                        fallback={<div>loading...</div>}
                        basename={process.env.REACT_APP_ROUTER_BASE || ""}
                    >
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route
                                exact
                                path="/forgot-password"
                                component={ForgotPassword}
                            />
                            <Route exact path="/signup" component={SignUp} />
                            <Route path={["/", "/home"]} component={Base} />
                        </Switch>
                    </React.Suspense>
                </Provider>
            </BrowserRouter>
        </ApolloProvider>,

        document.getElementById("root")
    );
});
