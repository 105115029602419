import { setContext } from "@apollo/client/link/context";
import {
    clearSession,
    getIdToken,
    isTokenExpired,
    refreshAccessToken,
    updateAccessToken,
} from "../utils/userSession";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "@apollo/client/link/core/ApolloLink";

export async function createClient(endpoint) {
    const authLink = setContext((_, { headers }) => {
        const token = getIdToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        };
    });

    const cache = new InMemoryCache();

    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
    });

    const refreshLink = new TokenRefreshLink({
        isTokenValidOrUndefined: () => !isTokenExpired(),
        fetchAccessToken: () => {
            clearSession();
            console.log("refresh tokens");
            return refreshAccessToken();
        },
        handleFetch: (accessToken) => {
            updateAccessToken();
        },
        handleResponse: (operation, accessTokenField) => (response) => {
            return { access_token: response.credentials.accessToken };
        },
        handleError: (err) => {
            console.warn("Your refresh token is invalid. Try to relogin");
            console.error(err);
            clearSession();
            window.location = "/login";
        },
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: navigator.onLine ? 5 : 1,
            retryIf: (error, _operation) => !!error,
        },
    });

    const errorHandler = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const defaultOptions = {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            notifyOnNetworkStatusChange: true,
        },
    };

    const link = new HttpLink({
        uri: endpoint,
        credentials: "same-origin",
    });

    return new ApolloClient({
        link: ApolloLink.from([
            refreshLink,
            authLink,
            retryLink,
            errorHandler,
            link,
        ]),
        cache: cache,
        defaultOptions: defaultOptions,
    });
}
