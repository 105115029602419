import { gql, useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { getQueryParameter } from "../../../utils/queryParams";
import PillButton from "../../Miscellaneous/PillButton";
import Display from "./Display";
import History from "./History";
import KeyPad from "./KeyPad";
import LoginModal from "./LoginModal";
import LogoutModal from "./LogoutModal";
import ProgressBar from "./ProgressBar";

const ASSET_QUERY = gql`
    query getDevice($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    device {
                        id
                        dev_eui
                    }
                    washwatch_display_logs(
                        limit: 10
                        order_by: { time: desc_nulls_last }
                    ) {
                        id
                        cursor_active
                        cursor_line
                        cursor_position
                        display_1
                        display_2
                        display_3
                        line_change
                        time
                    }
                    washwatch_button_commands(
                        limit: 5
                        order_by: { time: desc_nulls_last }
                    ) {
                        id
                        downlink {
                            id
                            cli_command
                            status
                            response
                            last_updated
                        }
                        time
                        button_sequence
                        user {
                            email
                            id
                            first_name
                            last_name
                        }
                    }
                    washwatch_status(limit: 1) {
                        connection
                        id
                        console_logged_in
                    }
                }
            }
        }
    }
`;

const SEND_SEQUENCE_MUTATION = gql`
    mutation sendButtonSequence(
        $object: washwatch_core_buttonsequencecommand_insert_input!
    ) {
        insert_washwatch_core_buttonsequencecommand_one(object: $object) {
            button_sequence
            id
            time
        }
    }
`;


const WashTecRemoteConsole = (props) => {
    const user = props.user;
    const [open, setOpen] = useState(false);

    const assetIdParam = getQueryParameter("assetId");

    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;

    const [displayLogs, setDisplayLogs] = useState();
    const [currentDisplay, setCurrentDisplay] = useState();
    const [previousDisplay, setPreviousDisplay] = useState();

    const [buttonCommands, setButtonCommands] = useState([]);
    const [measurementPoint, setMeasurementPoint] = useState();

    const [loginModal, setLoginModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false)

    const [disabled, setDisabled] = useState();
    const [status, setStatus] = useState(false);

    const { data } = useQuery(ASSET_QUERY, {
        pollInterval: 1000,
        fetchPolicy: "network-only",
        variables: {
            assetId: assetIdParam,
        },
    });

    const [sendButtonSequence] = useMutation(SEND_SEQUENCE_MUTATION);

    useEffect(() => {
        if (!data || !data.asset_by_pk) return;
        if (data.asset_by_pk.measurement_points.length > 0) {
            setMeasurementPoint(
                data.asset_by_pk.measurement_points[0].measurement_point
            );
            setDisplayLogs([
                ...data.asset_by_pk.measurement_points[0].measurement_point
                    .washwatch_display_logs,
            ]);
            setButtonCommands([
                ...data.asset_by_pk.measurement_points[0].measurement_point
                    .washwatch_button_commands,
            ]);
            let loggedIn =
                data.asset_by_pk.measurement_points[0].measurement_point
                    .washwatch_status.length > 0
                    ? data.asset_by_pk.measurement_points[0].measurement_point
                        .washwatch_status[0].console_logged_in
                    : false;
            setLoggedIn(loggedIn)
        }
    }, [data]);

    useEffect(() => {
        if (!buttonCommands || buttonCommands.length < 1) return;

        if (!buttonCommands[0].downlink) {
            setDisabled(true);
            return;
        }

        let status = buttonCommands[0].downlink.status;
        setStatus(status);
        if (status < 90) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [buttonCommands]);

    const sendLogin = async (username, password) => {
        send("washtec-button", "ok").then(() => {
            console.log("sent ok");
            setTimeout(() => {
                send(`washtec-login`, `${username} ${password}`).then(() => {
                    console.log("sent login");
                });
            }, 1000);
        });
    };

    const sendLogout = () => {
        send("washtec-logout", "").then(() => {
            console.log("sent logout command");
        });
    };

    const send = useCallback(
        async (prefix, command) => {
            command = command.toLowerCase()

            if (
                !currentDisplay.cursor_active &&
                loggedIn
            ) {
                console.log("sending CANCEL CANCEL")
                command = command.replace('cancel', 'CANCEL CANCEL')
            }

            return sendButtonSequence({
                variables: {
                    object: {
                        button_sequence: (prefix + " " + command),
                        measurement_point_id: measurementPoint.id,
                        time: dayjs(),
                        user_id: user.id,
                    },
                },
            });
        },
        [sendButtonSequence, measurementPoint, user, currentDisplay, loggedIn]
    );

    useEffect(() => {
        if (!displayLogs) return
        setCurrentDisplay(displayLogs[0])
        setPreviousDisplay(displayLogs[1])
    }, [displayLogs]);

    return (
        <div>
            <LoginModal
                open={loginModal}
                setOpen={setLoginModal}
                login={sendLogin}
            />
            <LogoutModal
                open={logoutModal}
                setOpen={setLogoutModal}
                logout={sendLogout}
            />
            <div
                className=""
                onClick={props.disabled ? null : () => setOpen(true)}
            >
                <PillButton
                    bg="teal-500"
                    textC="white"
                    text="REMOTE CONSOLE"
                    disabled={props.disabled}
                />
            </div>

            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    open={open}
                    onClose={() => { }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="flex flex-row transition-all transform justify-center">
                            <div className="flex flex-col w-full md:w-3/4 xl:w-1/2">
                                <div className="bg-white py-3 m-auto w-full overflow-auto md:mt-0 mt-16">
                                    {isMobile && (
                                        <div
                                            onClick={() => setOpen(false)}
                                            className="float-right mr-2 mt-2"
                                        >
                                            <XIcon width={25} height={25} />
                                        </div>
                                    )}
                                    <Display
                                        isMobile={isMobile}
                                        loggedIn={loggedIn}
                                        disabled={disabled}
                                        logs={displayLogs}
                                        send={send}
                                    />
                                    <KeyPad
                                        loggedIn={loggedIn}
                                        setLoginModal={setLoginModal}
                                        setLogoutModal={setLogoutModal}
                                        send={send}
                                        disabled={disabled}
                                    />
                                    {buttonCommands.length > 0 && (
                                        <div className="w-full pt-1">
                                            <ProgressBar
                                                status={status}
                                                command={buttonCommands[0].button_sequence
                                                    .replace(
                                                        "washtec-button",
                                                        ""
                                                    )
                                                    .replace(
                                                        "washtec-display all report",
                                                        "REFRESH DISPLAY"
                                                    )
                                                    .replace(
                                                        "washtec-reset",
                                                        "RESET"
                                                    )}
                                            />
                                            <History
                                                buttonCommands={buttonCommands}
                                            />
                                        </div>
                                    )}
                                </div>
                                {!isMobile && <div
                                    className="w-32 mt-2 self-end"
                                    onClick={() => setOpen(false)}
                                >
                                    <PillButton
                                        bg="teal-500"
                                        textC="white"
                                        text="Close"
                                    />
                                </div>}

                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </div>
    );
};

export default WashTecRemoteConsole;
