import { gql, useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import DisplayValue from "../../components/Text/DisplayValue";
import PopupDialog from "../../components/Modals/PopupDialog";

const VAC_SESSIONS_QUERY = gql`
    query getVacSessionCounts(
        $asset_ids: [Int!]
        $dayAgo: timestamptz!
        $monthAgo: timestamptz!
        $yearAgo: timestamptz!
    ) {
        day_count: vac_core_session_aggregate(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
                start_time: { _gte: $dayAgo }
            }
        ) {
            aggregate {
                count
            }
        }
        month_count: vac_core_session_aggregate(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
                start_time: { _gte: $monthAgo }
            }
        ) {
            aggregate {
                count
            }
        }
        year_count: vac_core_session_aggregate(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
                start_time: { _gte: $yearAgo }
            }
        ) {
            aggregate {
                count
            }
        }
        first: vac_core_session(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
            }
            order_by: { start_time: asc_nulls_last }
            limit: 1
        ) {
            id
            start_time
        }
        last: vac_core_session(
            where: {
                measurement_point: { assets: { asset_id: { _in: $asset_ids } } }
            }
            order_by: { start_time: desc_nulls_last }
            limit: 1
        ) {
            id
            start_time
        }
    }
`;

const VacSessionTotals = (props) => {
    const [dayAgo] = useState(dayjs().startOf("day"));
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [yearAgo] = useState(dayjs().subtract(365, "days"));
    const [openInfo, setOpenInfo] = useState(false);

    const [todayTotal, setTodayTotal] = useState();
    const [monthTotal, setMonthTotal] = useState();
    const [averageDaily, setAverageDaily] = useState();
    const [averageMonthly, setAverageMonthly] = useState();

    const { data: vacData } = useQuery(VAC_SESSIONS_QUERY, {
        fetchPolicy: "network-only", // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        variables: {
            asset_ids: props.assetIds,
            dayAgo: dayAgo,
            monthAgo: monthAgo,
            yearAgo: yearAgo,
        },
    });

    useEffect(() => {
        if (!vacData || !vacData.first.length > 0) return;
        let first = dayjs(vacData.first[0].start_time);
        let last = dayjs(vacData.last[0].start_time);
        let day = vacData.day_count.aggregate.count;
        let month = vacData.month_count.aggregate.count;
        let year = vacData.year_count.aggregate.count;

        let diffDays =
            last.diff(first, "day", true) >= 1
                ? last.diff(first, "day", true)
                : 1;
        let diffMonths =
            last.diff(first, "month", true) > 12
                ? 12
                : last.diff(first, "month");

        setTodayTotal(day);
        setMonthTotal(month);
        setAverageDaily(
            Math.round(diffDays >= 30 ? month / 30 : month / diffDays)
        );
        setAverageMonthly(
            Math.round(diffMonths <= 1 ? month : year / diffMonths)
        );
    }, [props.assetIds, vacData]);

    return (
        <div className="border-2 rounded-lg bg-white px-3 h-24 sm:h-30 py-1">
            <PopupDialog
                open={openInfo}
                close={setOpenInfo}
                title="Vacuum Sessions"
            />
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <div className="text-lg xl:text-2xl">Vac sessions</div>
                    <div
                        className="w-5 h-4 self-center cursor-pointer ml-2"
                        onClick={() => setOpenInfo(true)}
                    >
                        <InformationCircleIcon />
                    </div>
                </div>
                <div className="self-center text-trueGray-400 xl:text-base text-xs">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row justify-between space-x-5 2xl:space-x-12 pt-1 lg:pt-4 px-1 lg:px-3 xl:px-8">
                <DisplayValue title="TODAY" value={todayTotal} />
                <DisplayValue title="MONTH" value={monthTotal} />
                <DisplayValue title="AVG DAILY" value={averageDaily} />
                <DisplayValue title="AVG MONTH" value={averageMonthly} />
            </div>
        </div>
    );
};

export default VacSessionTotals;
