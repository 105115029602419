import React, { useState, useEffect } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import {
    calcByDay,
    getTop10AvgRating,
    getTop10AvgDowntime,
    getTop10SiteId,
    getBottom10AvgRating,
    getBottom10AvgDowntime,
    getBottomSiteId,
} from "../../utils/helperFunctions";
import HomePanelContainer from "../../components/Miscellaneous/Containers/HomePanelContainer";
import Loading from "../Loading";
import { compareChange } from "../../utils/helperFunctions";
import { getQueryParameter } from "../../utils/queryParams";

const GET_PERFORMANCE_IMPACT_PERCENTAGE = gql`
    query getOrganisationPerformance(
        $monthAgo: timestamptz!
        $twoMonthsAgo: timestamptz!
        $orgId: Int!
    ) {
        last24: organisation_performance(
            where: {
                time: { _gte: $monthAgo }
                organisation_id: { _eq: $orgId }
            }
            order_by: { time: desc }
        ) {
            id
            rating
            downtime
            time
        }
        Month: organisation_performance_aggregate(
            where: {
                time: { _gte: $monthAgo }
                organisation_id: { _eq: $orgId }
                downtime: { _is_null: false }
                rating: { _is_null: false }
            }
        ) {
            aggregate {
                avg {
                    rating
                }
                sum {
                    downtime
                }
            }
        }
        twoMonths: organisation_performance_aggregate(
            where: {
                time: { _gte: $twoMonthsAgo, _lte: $monthAgo }
                organisation_id: { _eq: $orgId }
                downtime: { _is_null: false }
                rating: { _is_null: false }
            }
        ) {
            aggregate {
                avg {
                    rating
                }
                sum {
                    downtime
                }
            }
        }
    }
`;

const GET_SITES_BY_RATING_HIGH_LOW = gql`
    query getSitePerformanceByOrg(
        $orgId: Int!
        $monthAgo: timestamptz!
        $twoMonthsAgo: timestamptz!
    ) {
        organisation_by_pk(id: $orgId) {
            sites {
                id
                name
                day: performance_ratings(order_by: { time: desc }, limit: 1) {
                    id
                    downtime
                    rating
                }
                monthAgo: performance_ratings_aggregate(
                    where: {
                        time: { _gte: $monthAgo }
                        downtime: { _is_null: false }
                        rating: { _is_null: false }
                    }
                ) {
                    aggregate {
                        avg {
                            rating
                        }
                        sum {
                            downtime
                        }
                    }
                }
                twoMonthsAgo: performance_ratings_aggregate(
                    where: {
                        time: { _gte: $twoMonthsAgo, _lte: $monthAgo }
                        downtime: { _is_null: false }
                        rating: { _is_null: false }
                    }
                ) {
                    aggregate {
                        avg {
                            rating
                        }
                        sum {
                            downtime
                        }
                    }
                }
            }
        }
    }
`;

const GET_ISSUES_PROMPTS_REQUESTS_DATA = gql`
    query getIssuePromptsRequestsData(
        $orgId: Int!
        $days30: timestamptz!
        $current: timestamptz!
        $days60: timestamptz
    ) {
        organisation_by_pk(id: $orgId) {
            id
            issuesOutstanding: issues_aggregate(where: { status: { _eq: 0 } }) {
                aggregate {
                    count
                }
            }
            issuesOpenTotal: issues_aggregate(where: { status: { _lte: 2 } }) {
                aggregate {
                    count
                }
            }
            issueGraphData: issues(where: { time_raised: { _gte: $days30 } }) {
                id
                time_raised
            }
            issuesLast30: issues_aggregate(
                where: { time_raised: { _gt: $days30, _lt: $current } }
            ) {
                aggregate {
                    count
                }
            }
            issuesLastPeriod: issues_aggregate(
                where: { time_raised: { _gt: $days60, _lt: $days30 } }
            ) {
                aggregate {
                    count
                }
            }
            unacknowledgedPrompts: prompts_aggregate(
                where: { status: { _lte: 0 } }
            ) {
                aggregate {
                    count
                }
            }
            totalOpenPrompts: prompts_aggregate(
                where: { status: { _lte: 2 } }
            ) {
                aggregate {
                    count
                }
            }
            promptGraphData: prompts(
                where: { time_raised: { _gte: $days30 } }
            ) {
                id
                time_raised
            }
            promptsLast30: prompts_aggregate(
                where: { time_raised: { _gt: $days30, _lt: $current } }
            ) {
                aggregate {
                    count
                }
            }
            promptsLastPeriod: prompts_aggregate(
                where: { time_raised: { _gt: $days60, _lt: $days30 } }
            ) {
                aggregate {
                    count
                }
            }
            openRequests: requests_aggregate(where: { open: { _eq: true } }) {
                aggregate {
                    count
                }
            }
            totalRequests: requests_aggregate {
                aggregate {
                    count
                }
            }
            requestGraphData: requests(where: { time: { _gte: $days30 } }) {
                id
                time
            }
            requestsLast30: requests_aggregate(
                where: { time: { _gt: $days30, _lt: $current } }
            ) {
                aggregate {
                    count
                }
            }
            requestsLastPeriod: requests_aggregate(
                where: { time: { _gt: $days60, _lt: $days30 } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const GET_GRAPH_DATA_FOR_PERFORMANCE_IMPACT_HIGH_LOW = gql`
    query getGraphDataForHighLowPerformanceImpact(
        $siteIdsPerformanceHigh: _int4
        $siteIdsPerformanceLow: _int4
        $start: timestamp
        $end: timestamp
    ) {
        performanceHigh: sites_performance_grouped(
            args: {
                end_time: $end
                site_ids: $siteIdsPerformanceHigh
                start_time: $start
            }
            order_by: { time: asc }
        ) {
            rating
            downtime
        }
        performanceLow: sites_performance_grouped(
            args: {
                end_time: $end
                site_ids: $siteIdsPerformanceLow
                start_time: $start
            }
            order_by: { time: asc }
        ) {
            rating
            downtime
        }
    }
`;

const Home = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const organisationId = getQueryParameter("organisationId");
    const [issueData, setIssueData] = useState();
    const [promptData, setPromptData] = useState();
    const [requestData, setRequestData] = useState();
    const [currentTime] = useState(dayjs().format());
    const [monthAgo] = useState(dayjs().subtract(30, "days").format());
    const [monthAgo2] = useState(dayjs().subtract(60, "days").format());
    const [highLowData, setHighLowData] = useState();
    const [monthlyPerformanceGraphData, setMonthlyPerformanceGraphData] =
        useState();
    const [monthlyImpactGraphData, setMonthlyImpactGraphData] = useState();
    const [dailyPerformancePercentage, setDailyPerformancePercentage] =
        useState();
    const [dailyImpactPercentage, setDailyImpactPercentage] = useState();
    const [
        monthlyPerformanceComparisonPercentage,
        setMonthlyPerformanceComparisonPercentage,
    ] = useState();
    const [
        monthlyImpactComparisonPercentage,
        setMonthlyImpactComparisonPercentage,
    ] = useState();
    const [
        monthlyPerformanceComparisonPercentageHigh,
        setMonthlyPerformanceComparisonPercentageHigh,
    ] = useState();
    const [
        monthlyPerformanceComparisonPercentageLow,
        setMonthlyPerformanceComparisonPercentageLow,
    ] = useState();
    const [
        monthlyDowntimeComparisonPercentageHigh,
        setMonthlyDowntimeComparisonPercentageHigh,
    ] = useState();
    const [
        monthlyDowntimeComparisonPercentageLow,
        setMonthlyDowntimeComparisonPercentageLow,
    ] = useState();
    const [top10PercentSiteRatingsDay, setTop10PercentSiteRatingsDay] =
        useState();
    const [top10PercentSiteRatingsMonth, setTop10PercentSiteRatingsMonth] =
        useState();
    const [
        top10PercentSiteRatingsLastMonth,
        setTop10PercentSiteRatingsLastMonth,
    ] = useState();
    const [bottom10PercentSiteRatingsDay, setBottom10PercentSiteRatingsDay] =
        useState();
    const [
        bottom10PercentSiteRatingsMonth,
        setBottom10PercentSiteRatingsMonth,
    ] = useState();
    const [
        bottom10PercentSiteRatingsLastMonth,
        setBottom10PercentSiteRatingsLastMonth,
    ] = useState();
    const [
        top10PercentSiteRatingsDowntimeDay,
        setTop10PercentSiteRatingsDowntimeDay,
    ] = useState();
    const [
        top10PercentSiteRatingsDowntimeMonth,
        setTop10PercentSiteRatingsDowntimeMonth,
    ] = useState();
    const [
        top10PercentSiteRatingsDowntimeLastMonth,
        setTop10PercentSiteRatingsDowntimeLastMonth,
    ] = useState();
    const [
        bottom10PercentSiteRatingsDowntimeDay,
        setBottom10PercentSiteRatingsDowntimeDay,
    ] = useState();
    const [
        bottom10PercentSiteRatingsDowntimeMonth,
        setBottom10PercentSiteRatingsDowntimeMonth,
    ] = useState();
    const [
        bottom10PercentSiteRatingsDowntimeLastMonth,
        setBottom10PercentSiteRatingsDowntimeLastMonth,
    ] = useState();
    const [topSiteIdsByRating, setTopSiteIdsByRating] = useState();
    const [bottomSiteIdsByRating, setBottomSiteIdsByRating] = useState();
    const [minMaxAssets, setMinMaxAssets] = useState();
    const [maxValImpact, setMaxValImpact] = useState();
    const [minValImpact, setMinValImpact] = useState();

    //get issue data
    const { error: iprError, data: iprData } = useQuery(
        GET_ISSUES_PROMPTS_REQUESTS_DATA,
        {
            fetchPolicy: "network-only",
            skip: !organisationId,
            variables: {
                orgId: organisationId,
                current: currentTime,
                days30: monthAgo,
                days60: monthAgo2,
            },
            onError: () => console.log(iprError),
        }
    );

    useEffect(() => {
        if (
            !iprData ||
            !iprData.organisation_by_pk ||
            !iprData.organisation_by_pk.issuesOutstanding ||
            !iprData.organisation_by_pk.issuesOpenTotal ||
            !iprData.organisation_by_pk.issueGraphData
        )
            return;
        for (const value of Object.values(iprData.organisation_by_pk)) {
            if (value === undefined) {
                return;
            }
        }

        //issues
        let issues = [];
        issues.push(
            iprData.organisation_by_pk.issuesOutstanding.aggregate.count,
            iprData.organisation_by_pk.issuesOpenTotal.aggregate.count,
            "hide"
        );
        let issueDataObject = {
            value: issues,
            graphData: calcByDay(iprData.organisation_by_pk.issueGraphData),
            comparison: compareChange(
                iprData.organisation_by_pk.issuesLast30.aggregate.count,
                iprData.organisation_by_pk.issuesLastPeriod.aggregate.count
            ),
        };
        setIssueData(issueDataObject);
        //prompts
        let prompts = [];
        prompts.push(
            iprData.organisation_by_pk.unacknowledgedPrompts.aggregate.count,
            iprData.organisation_by_pk.totalOpenPrompts.aggregate.count,
            "hide"
        );
        let promptDataObject = {
            value: prompts,
            graphData: calcByDay(iprData.organisation_by_pk.promptGraphData),
            comparison: compareChange(
                iprData.organisation_by_pk.promptsLast30.aggregate.count,
                iprData.organisation_by_pk.promptsLastPeriod.aggregate.count
            ),
        };
        setPromptData(promptDataObject);

        //requests
        let requests = [];
        requests.push(
            iprData.organisation_by_pk.totalRequests.aggregate.count,
            iprData.organisation_by_pk.openRequests.aggregate.count,
            "hide"
        );
        let requestDataObject = {
            value: requests,
            graphData: calcByDay(iprData.organisation_by_pk.requestGraphData),
            comparison: compareChange(
                iprData.organisation_by_pk.requestsLast30.aggregate.count,
                iprData.organisation_by_pk.requestsLastPeriod.aggregate.count
            ),
        };
        setRequestData(requestDataObject);
    }, [iprData]);

    //get performance data as a % for last 24 hours, as well as the comparison between this month vs. last month
    const { error: performanceRatingError, data: performanceRatingData } =
        useQuery(GET_PERFORMANCE_IMPACT_PERCENTAGE, {
            skip: !organisationId,
            variables: {
                orgId: organisationId,
                monthAgo: monthAgo,
                twoMonthsAgo: monthAgo2,
            },
            onError: () => console.log(performanceRatingError),
        });

    //setting daily performance rating as a percentage, as well as calculating/setting monthly comparisons as a percentage increase/decrease
    useEffect(() => {
        if (!performanceRatingData) return;
        let ratingDataArray = [];
        let downTimeDataArray = [];
        performanceRatingData.last24.map((val) => {
            ratingDataArray.push(val.rating * 100);
            return downTimeDataArray.push(val.downtime / 60);
        });
        if (ratingDataArray.length < 30) {
            let nums = 30 - ratingDataArray.length;
            for (let i = 0; i < nums; i++) {
                ratingDataArray.push(100);
                downTimeDataArray.push(0);
            }
        }
        setMonthlyPerformanceGraphData(ratingDataArray.reverse());
        setMonthlyImpactGraphData(downTimeDataArray.reverse());
        let last24HrsRating = (
            performanceRatingData.last24[0]
                ? performanceRatingData.last24[0].rating * 100
                : 100
        ).toFixed(1);
        let thisMonthRating = performanceRatingData.Month.aggregate.avg.rating
            ? performanceRatingData.Month.aggregate.avg.rating
            : 1 * 100;
        let lastMonthRating = performanceRatingData.twoMonths.aggregate.avg
            .rating
            ? performanceRatingData.twoMonths.aggregate.avg.rating
            : 1 * 100;
        let last24HrsDownTime = (
            performanceRatingData.last24[0]
                ? performanceRatingData.last24[0].downtime / 60
                : 0
        ).toFixed(1);
        let thisMonthDowntime = performanceRatingData.Month.aggregate.sum
            .downtime
            ? performanceRatingData.Month.aggregate.sum.downtime / 60
            : 0;
        let lastMonthDowntime = performanceRatingData.twoMonths.aggregate.sum
            .downtime
            ? performanceRatingData.twoMonths.aggregate.sum.downtime / 60
            : 0;
        setMonthlyPerformanceComparisonPercentage(
            compareChange(thisMonthRating, lastMonthRating)
        );
        setMonthlyImpactComparisonPercentage(
            compareChange(thisMonthDowntime, lastMonthDowntime)
        );
        setDailyPerformancePercentage(last24HrsRating);
        setDailyImpactPercentage(last24HrsDownTime);
    }, [performanceRatingData]);

    //GETTING SITES ORDERED BY HIGHEST/LOWEST PERFORMANCE RATING)
    const {
        error: sitesError,
        data: sitesData,
        loading: sitesLoading,
    } = useQuery(GET_SITES_BY_RATING_HIGH_LOW, {
        variables: {
            orgId: organisationId,
            monthAgo: monthAgo,
            twoMonthsAgo: monthAgo2,
        },
        onError: () => console.log(sitesError),
    });

    const [
        getOrderedGraphData,
        {
            error: graphDataOrderedError,
            data: graphDataOrdered,
            loading: graphDataOrderedLoading,
        },
    ] = useLazyQuery(GET_GRAPH_DATA_FOR_PERFORMANCE_IMPACT_HIGH_LOW, {
        fetchPolicy: "network-only",
        onError: () => console.log(graphDataOrderedError),
    });

    useEffect(() => {
        if (!topSiteIdsByRating || !bottomSiteIdsByRating) return;
        getOrderedGraphData({
            variables: {
                siteIdsPerformanceHigh:
                    "{" + topSiteIdsByRating.toString() + "}",
                siteIdsPerformanceLow:
                    "{" + bottomSiteIdsByRating.toString() + "}",
                start: monthAgo,
                end: currentTime,
            },
        });
    }, [
        bottomSiteIdsByRating,
        currentTime,
        getOrderedGraphData,
        monthAgo,
        topSiteIdsByRating,
    ]);

    const backFillImpact = (dataArray) => {
        if (dataArray.length < 30) {
            let daysToFill = 30 - dataArray.length;
            for (let i = 0; i < daysToFill; i++) {
                dataArray.push(0);
            }
        } else return;
    };

    const backFillPerformance = (dataArray) => {
        if (dataArray.length < 30) {
            let daysToFill = 30 - dataArray.length;
            for (let i = 0; i < daysToFill; i++) {
                dataArray.push(100);
            }
        } else return;
    };

    useEffect(() => {
        if (graphDataOrdered === undefined) return;
        let performanceHighData = [];
        let performanceLowData = [];
        let impactHighData = [];
        let impactLowData = [];
        graphDataOrdered.performanceHigh.map((val) =>
            performanceHighData.push((val.rating * 100).toFixed(1))
        );
        graphDataOrdered.performanceLow.map((val) =>
            performanceLowData.push((val.rating * 100).toFixed(1))
        );
        graphDataOrdered.performanceHigh.map((val) =>
            impactLowData.push((val.downtime / 60).toFixed(1))
        );
        graphDataOrdered.performanceLow.map((val) =>
            impactHighData.push((val.downtime / 60).toFixed(1))
        );

        backFillPerformance(performanceHighData);
        backFillPerformance(performanceLowData);
        backFillImpact(impactHighData);
        backFillImpact(impactLowData);

        let highLowData = {
            monthlyPerformanceHigh: performanceHighData,
            monthlyPerformanceLow: performanceLowData,
            monthlyImpactHigh: impactHighData,
            monthlyImpactLow: impactLowData,
        };
        setHighLowData(highLowData);
    }, [graphDataOrdered]);

    useEffect(() => {
        if (!sitesData || !sitesData.organisation_by_pk) return;
        let last24Hrs = [];
        let thisMonth = [];
        let previousMonth = [];
        sitesData.organisation_by_pk.sites.map((site) => {
            last24Hrs.push({
                site_id: site.id,
                rating: site.day[0] ? site.day[0].rating : 1,
                downtime: site.day[0] ? site.day[0].downtime : 0,
            });
            thisMonth.push({
                site_id: site.id,
                rating: site.monthAgo.aggregate.avg.rating
                    ? site.monthAgo.aggregate.avg.rating
                    : 1,
                downtime: site.monthAgo.aggregate.sum.downtime
                    ? site.monthAgo.aggregate.sum.downtime
                    : 0,
            });
            return previousMonth.push({
                site_id: site.id,
                rating: site.twoMonthsAgo.aggregate.avg.rating
                    ? site.twoMonthsAgo.aggregate.avg.rating
                    : 1,
                downtime: site.twoMonthsAgo.aggregate.sum.downtime
                    ? site.twoMonthsAgo.aggregate.sum.downtime
                    : 0,
            });
        });

        //ratings
        setTop10PercentSiteRatingsDay(getTop10AvgRating(last24Hrs));
        setBottom10PercentSiteRatingsDay(getBottom10AvgRating(last24Hrs));
        setTop10PercentSiteRatingsMonth(getTop10AvgRating(thisMonth));
        setBottom10PercentSiteRatingsMonth(getBottom10AvgRating(thisMonth));
        setTop10PercentSiteRatingsLastMonth(getTop10AvgRating(previousMonth));
        setBottom10PercentSiteRatingsLastMonth(
            getBottom10AvgRating(previousMonth)
        );
        //downtime
        setTop10PercentSiteRatingsDowntimeDay(getTop10AvgDowntime(last24Hrs));
        setBottom10PercentSiteRatingsDowntimeDay(
            getBottom10AvgDowntime(last24Hrs)
        );
        setTop10PercentSiteRatingsDowntimeMonth(getTop10AvgDowntime(thisMonth));
        setBottom10PercentSiteRatingsDowntimeMonth(
            getBottom10AvgDowntime(thisMonth)
        );
        setTop10PercentSiteRatingsDowntimeLastMonth(
            getTop10AvgDowntime(previousMonth)
        );
        setBottom10PercentSiteRatingsDowntimeLastMonth(
            getBottom10AvgDowntime(previousMonth)
        );
        //site_id ordered by rating
        setTopSiteIdsByRating(getTop10SiteId(thisMonth));
        setBottomSiteIdsByRating(getBottomSiteId(thisMonth));
    }, [sitesData]);

    //setting the comparison values for high/low performance rating and impact
    useEffect(() => {
        setMonthlyPerformanceComparisonPercentageHigh(
            compareChange(
                top10PercentSiteRatingsMonth,
                top10PercentSiteRatingsLastMonth
            )
        );
        setMonthlyPerformanceComparisonPercentageLow(
            compareChange(
                bottom10PercentSiteRatingsMonth,
                bottom10PercentSiteRatingsLastMonth
            )
        );
        setMonthlyDowntimeComparisonPercentageHigh(
            compareChange(
                top10PercentSiteRatingsDowntimeMonth,
                top10PercentSiteRatingsDowntimeLastMonth
            )
        );
        setMonthlyDowntimeComparisonPercentageLow(
            compareChange(
                bottom10PercentSiteRatingsDowntimeMonth,
                bottom10PercentSiteRatingsDowntimeLastMonth
            )
        );
    }, [
        top10PercentSiteRatingsLastMonth,
        bottom10PercentSiteRatingsLastMonth,
        top10PercentSiteRatingsMonth,
        bottom10PercentSiteRatingsMonth,
        top10PercentSiteRatingsDowntimeMonth,
        top10PercentSiteRatingsDowntimeLastMonth,
        bottom10PercentSiteRatingsDowntimeMonth,
        bottom10PercentSiteRatingsDowntimeLastMonth,
    ]);

    useEffect(() => {
        if (
            issueData === undefined ||
            promptData === undefined ||
            requestData === undefined
        )
            return;
        let minMax = {
            maxIssue: Math.max(...issueData.graphData),
            maxPrompt: Math.max(...promptData.graphData),
            maxRequest: Math.max(...requestData.graphData),
            minIssue: Math.min(...issueData.graphData),
            minPrompt: Math.min(...promptData.graphData),
            minRequest: Math.min(...requestData.graphData),
        };
        setMinMaxAssets(minMax);
        if (monthlyImpactGraphData === undefined || highLowData === undefined)
            return;
        let maxNetworkHrs = Math.max(...monthlyImpactGraphData);
        let maxHighHrs = Math.max(...highLowData.monthlyImpactHigh);
        let maxLowHrs = Math.max(...highLowData.monthlyImpactLow);
        let minNetworkHrs = Math.min(...monthlyImpactGraphData);
        let minHighHrs = Math.min(...highLowData.monthlyImpactHigh);
        let minLowHrs = Math.min(...highLowData.monthlyImpactLow);
        setMaxValImpact(Math.max(maxNetworkHrs, maxHighHrs, maxLowHrs));
        setMinValImpact(Math.min(minNetworkHrs, minHighHrs, minLowHrs));
    }, [
        highLowData,
        issueData,
        monthlyImpactGraphData,
        promptData,
        requestData,
    ]);

    if (
        !highLowData ||
        !minMaxAssets ||
        graphDataOrderedLoading ||
        sitesLoading
    )
        return <Loading message="Loading Data For Your Organisation..." />;
    return (
        <div
            className={`w-full h-full xl:px-12 px-3 sm:px-6 sm:mt-10 mt-5 bg-trueGray-50 overflow-y-auto ${
                isMobile ? "pb-32" : "pb-0"
            }`}
        >
            <div className="flex flex-row justify-between">
                <div
                    className={`text-teal-600 text-3xl ${
                        isMobile ? "pb-6" : "pb-12"
                    }`}
                >
                    Home
                </div>
            </div>
            <div className="flex flex-row">
                {minMaxAssets && (
                    <HomePanelContainer
                        panelTitle="ASSETS"
                        colours={{
                            colour1: "red",
                            colour2: "green",
                            colour3: "green",
                        }}
                        unit="%"
                        titles={{
                            title1: "Issues",
                            title2: "Prompts",
                            title3: "Requests",
                        }}
                        display="graph"
                        data={{
                            data1: issueData,
                            data2: promptData,
                            data3: requestData,
                        }}
                        graph={{
                            graph1: {
                                min: minMaxAssets.minIssue,
                                max: minMaxAssets.maxIssue,
                            },
                            graph2: {
                                min: minMaxAssets.minPrompt,
                                max: minMaxAssets.maxPrompt,
                            },
                            graph3: {
                                min: minMaxAssets.minRequest,
                                max: minMaxAssets.maxRequest,
                            },
                        }}
                        info="ISSUES, PROMPTS AND REQUESTS"
                    />
                )}
            </div>
            <div className="flex flex-row">
                <HomePanelContainer
                    panelTitle="PERFORMANCE"
                    colours={{
                        colour1: "green",
                        colour2: "green",
                        colour3: "green",
                    }}
                    titles={{
                        title1: "All",
                        title2: "High",
                        title3: "Low",
                    }}
                    unit="%"
                    display="text"
                    data={{
                        data1: {
                            value: dailyPerformancePercentage,
                            graphData: monthlyPerformanceGraphData,
                            comparison: monthlyPerformanceComparisonPercentage,
                        },
                        data2: {
                            value: top10PercentSiteRatingsDay,
                            graphData: highLowData.monthlyPerformanceHigh,
                            comparison:
                                monthlyPerformanceComparisonPercentageHigh,
                        },
                        data3: {
                            value: bottom10PercentSiteRatingsDay,
                            graphData: highLowData.monthlyPerformanceLow,
                            comparison:
                                monthlyPerformanceComparisonPercentageLow,
                        },
                    }}
                    info="PERFORMANCE RATINGS"
                />
            </div>
            <div className="flex flex-row">
                <HomePanelContainer
                    panelTitle="IMPACT"
                    colours={{
                        colour1: "red",
                        colour2: "red",
                        colour3: "red",
                    }}
                    titles={{
                        title1: "All",
                        title2: "Low",
                        title3: "High",
                    }}
                    unit="hrs"
                    display="text"
                    data={{
                        data1: {
                            value: dailyImpactPercentage,
                            graphData: monthlyImpactGraphData,
                            comparison: monthlyImpactComparisonPercentage,
                        },
                        data2: {
                            value: top10PercentSiteRatingsDowntimeDay,
                            graphData: highLowData.monthlyImpactLow,
                            comparison: monthlyDowntimeComparisonPercentageHigh,
                        },
                        data3: {
                            value: bottom10PercentSiteRatingsDowntimeDay,
                            graphData: highLowData.monthlyImpactHigh,
                            comparison: monthlyDowntimeComparisonPercentageLow,
                        },
                    }}
                    graph={{
                        graph1: {
                            min: minValImpact,
                            max: maxValImpact,
                        },
                        graph2: {
                            min: minValImpact,
                            max: maxValImpact,
                        },
                        graph3: {
                            min: minValImpact,
                            max: maxValImpact,
                        },
                    }}
                    info="IMPACT"
                />
            </div>
        </div>
    );
};
export default Home;
