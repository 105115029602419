import React, { useState, Fragment, useEffect } from "react";
import { CalendarIcon } from "@heroicons/react/solid";
import { Listbox, Transition } from "@headlessui/react";
import dayjs from "dayjs";

const dateRanges = [
    { name: "Today" },
    { name: "Yesterday" },
    { name: "Week To Date" },
    { name: "Last 7 Days" },
    { name: "Month To Date" },
    { name: "Last 30 Days" },
    { name: "Last 90 Days" },
];

const TimeFilter = (props) => {
    const [selected, setSelected] = useState(dateRanges[5]);
    const setTime = props.setTime;

    useEffect(() => {
        if (!selected || !setTime) return;
        switch (selected.name) {
            case "Today":
                setTime({ start: dayjs().startOf("day"), end: dayjs() });
                break;
            case "Yesterday":
                setTime({
                    start: dayjs().subtract(1, "day").startOf("day"),
                    end: dayjs().startOf("day"),
                });
                break;
            case "Week To Date":
                setTime({ start: dayjs().startOf("week"), end: dayjs() });
                break;
            case "Last 7 Days":
                setTime({
                    start: dayjs().subtract(7, "days"),
                    end: dayjs(),
                });
                break;
            case "Month To Date":
                setTime({
                    start: dayjs().startOf("month"),
                    end: dayjs(),
                });
                break;
            case "Last 30 Days":
                setTime({
                    start: dayjs().subtract(30, "days"),
                    end: dayjs(),
                });
                break;
            case "Last 90 Days":
                setTime({
                    start: dayjs().subtract(90, "days"),
                    end: dayjs(),
                });
                break;
            default:
                setTime({
                    start: dayjs().subtract(30, "days"),
                    end: dayjs(),
                });
        }
    }, [selected, setTime]);

    return (
        <div>
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white cursor-pointer hover:text-teal-500">
                        <span className="block truncate text-sm">
                            {selected.name}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <CalendarIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute w-40 py-1 mt-1 overflow-auto bg-white max-h-48 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm font-normal">
                            {dateRanges.map((range, rangeId) => (
                                <Listbox.Option
                                    key={rangeId}
                                    className={({ active }) =>
                                        `${
                                            active
                                                ? "font-medium bg-gray-100"
                                                : "text-left"
                                        }
                                cursor-pointer select-none relative py-1 pl-2.5`
                                    }
                                    value={range}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span
                                                className={`${
                                                    selected
                                                        ? "font-medium"
                                                        : "font-normal"
                                                } block truncate`}
                                            >
                                                {range.name}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`${
                                                        active
                                                            ? "text-amber-600"
                                                            : "text-amber-600"
                                                    }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                                ></span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
};

export default TimeFilter;
