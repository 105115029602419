import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../assets/Svgs/Logo";
import PillButton from "../../components/Miscellaneous/PillButton";
import { isUserLoggedIn } from "../../utils/userSession";
import { Redirect } from "react-router-dom";
import utils from "../../../src/utils/cognito";
import ReactCodeInput from "react-code-input";
import {
    CheckCircleIcon,
    EyeIcon,
    EyeOffIcon,
    RefreshIcon,
} from "@heroicons/react/outline";
import ErrorPanel from "../../components/Panels/ErrorPanel";
import PasswordStrengthMeter from "../../components/Miscellaneous/PasswordStrengthMeter/PasswordStrengthMeter";

const ForgotPassword = () => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [formData, setFormData] = useState({
        email: "",
    });

    const [passwordData, setPasswordData] = useState({
        newPassword: "",
    });

    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userIsLoggedIn = isUserLoggedIn();
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const [code, setCode] = useState("");
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordInfo, setPasswordInfo] = useState(null);
    const [codeInfo, setCodeInfo] = useState(null);

    const [resendingCode, setResendingCode] = useState(false);
    const [resentCode, setResentCode] = useState(false);

    const validatePassword = (password) => {
        let hasLength = password.length >= 6;
        let hasLowerCase = /(?=.*[a-z])/.test(password);
        let hasUpperCase = /(?=.*[A-Z])/.test(password);
        let hasNumbers = /(?=.*[0-9])/.test(password);
        return hasLength && hasLowerCase && hasUpperCase && hasNumbers;
    };

    useEffect(() => {
        if (code.length === 6) setShowPasswordField(true);
        else setShowPasswordField(false);
    }, [code]);

    useEffect(() => {
        let valid = true;
        let password = passwordData.newPassword;
        if (password.length > 0) {
            if (password.length < 6) {
                setPasswordInfo("Password must have at least 6 characters");
                valid = false;
            }
            if (!/(?=.*[a-z])/.test(password)) {
                setPasswordInfo(
                    "Password must have at least 1 lowercase character"
                );
                valid = false;
            }
            if (!/(?=.*[A-Z])/.test(password)) {
                setPasswordInfo(
                    "Password must have at least 1 uppercase character"
                );
                valid = false;
            }
            if (!/(?=.*[0-9])/.test(password)) {
                setPasswordInfo(
                    "Password must have at least 1 numerical character"
                );
                valid = false;
            }
        }
        if (!valid) return;
        setPasswordInfo(null);
    }, [passwordData]);

    useEffect(() => {
        if (formData.email === "" && confirmPassword) {
            setConfirmPassword(false);
        }
    }, [formData, confirmPassword]);

    if (userIsLoggedIn) {
        return <Redirect to="/" />;
    }

    const handleFormInputChange = (event) => {
        event.persist();
        setFormData((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
        setError(null);
    };

    const handlePasswordInputChange = (event) => {
        event.persist();
        setPasswordData((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
        setError(null);
    };

    const handleFormSubmit = (event) => {
        if (event) {
            event.preventDefault();
            setIsSubmitting(true);
            utils
                .forgotPassword(formData.email)
                .then((result) => {
                    setIsSubmitting(false);
                    setConfirmPassword(true);
                })
                .catch((e) => {
                    setError(e.message);
                    setIsSubmitting(false);
                });
        }
    };

    const handleNewPasswordSubmit = (event) => {
        if (event) {
            event.preventDefault();
            if (code.length !== 6) {
                setCodeInfo("Please enter the full 6 digit verification code");
                return;
            }
            if (
                validatePassword(passwordData.newPassword) &&
                code.length === 6
            ) {
                setIsSubmitting(true);
                utils
                    .confirmPassword(
                        formData.email,
                        code,
                        passwordData.newPassword
                    )
                    .then((result) => {
                        setIsSubmitting(false);
                        setSuccess(true);
                    })
                    .catch((e) => {
                        setError(e.message);
                        setIsSubmitting(false);
                    });
            }
        }
    };

    const resendCode = () => {
        setResentCode(false);
        setResendingCode(true);
        utils
            .resendConfirmationCode(formData.email)
            .then((result) => {
                setResendingCode(false);
                setResentCode(true);
            })
            .catch((e) => {
                setError(e.message);
                setResendingCode(false);
            });
    };

    return (
        <div className="w-full h-full flex flex-row justify-center">
            {!confirmPassword && !success && (
                <div className="m-5 shadow-lg mt-10 p-5 grid place-items-center text-blueGray-800 text-base border-2 rounded-md py-10 bg-white max-w-400">
                    <div className="flex flex-row space-x-5 pb-5">
                        <div>
                            <Logo width={60} height={60} />
                        </div>
                        <div className="sm:text-4xl text-2xl self-center">
                            BenchMarker
                        </div>
                    </div>
                    <div className="text-xl pb-2 w-full">Password Recovery</div>
                    <div className="text-sm mb-5">
                        Enter your email address and we'll send you a
                        verification code
                    </div>
                    <div className="grid gap-y-5 w-full">
                        <input
                            placeholder="Please enter your email"
                            onChange={handleFormInputChange}
                            required
                            type="email"
                            name="email"
                            value={formData.email}
                            className="h-10 rounded-md pl-2 border-2 text-sm w-full"
                        />
                        <div className="w-full" onClick={handleFormSubmit}>
                            <PillButton
                                text="SEND VERIFICATION CODE"
                                bg="teal-500"
                                textC="white"
                                loading={isSubmitting}
                            />
                        </div>
                        <div className="border-t-2 w-full" />
                        <Link to="/login">
                            <div className="text-teal-500 font-semibold cursor-pointer w-full text-center">
                                Return to sign In
                            </div>
                        </Link>
                    </div>
                </div>
            )}
            {confirmPassword && !success && (
                <div className=" shadow-lg mt-10 p-5 m-5 grid place-items-center text-blueGray-800 text-base border-2 rounded-md py-10 bg-white max-w-400">
                    <div className="flex flex-row space-x-5 pb-5">
                        <div>
                            <Logo width={60} height={60} />
                        </div>
                        <div className="sm:text-4xl text-2xl self-center">
                            BenchMarker
                        </div>
                    </div>
                    <div className="text-xl pb-5">Check Your Inbox</div>
                    <div className="text-sm sm:text-base px-5">
                        We have sent a confirmation code to you at{" "}
                    </div>
                    <strong className="w-full text-center">
                        {formData.email}
                    </strong>
                    <div className="m-auto py-5">
                        {codeInfo && (
                            <div className={"authErrorMessage"}>{codeInfo}</div>
                        )}
                        {error && (
                            <div className={"authErrorMessage"}>{error}</div>
                        )}
                        <ReactCodeInput
                            name={"verificationCode"}
                            inputStyle={
                                !isMobile
                                    ? {
                                          fontFamily: "monospace",
                                          width: "14.6%",
                                          MozAppearance: "textfield",
                                          borderRadius: "6px",
                                          border: "1px solid",
                                          boxShadow:
                                              "0px 0px 10px 0px rgba(0,0,0,.10)",
                                          margin: "1%",
                                          paddingLeft: "4%",
                                          height: "50px",
                                          fontSize: "1.5em",
                                          boxSizing: "border-box",
                                          color: "black",
                                          backgroundColor: "white",
                                          borderColor: "lightgrey",
                                      }
                                    : {}
                            }
                            inputMode={"numeric"}
                            type="number"
                            fields={6}
                            onChange={(e) => {
                                setCode(e);
                                setCodeInfo(null);
                            }}
                        />
                    </div>
                    {passwordInfo && (
                        <div className="">
                            <ErrorPanel message={passwordInfo} />
                        </div>
                    )}
                    {showPasswordField && (
                        <div className="flex flex-row w-full relative mb-5">
                            <input
                                className="h-10 rounded-md pl-2 border-2 text-sm w-full"
                                type={passwordShown ? "text" : "password"}
                                placeholder="Create a new password"
                                value={passwordData.newPassword}
                                name="newPassword"
                                onChange={handlePasswordInputChange}
                                required
                            />
                            {passwordShown ? (
                                <EyeIcon
                                    className="w-6 h-6 cursor-pointer absolute top-2 right-2"
                                    onClick={() => {
                                        setPasswordShown(!passwordShown);
                                    }}
                                />
                            ) : (
                                <EyeOffIcon
                                    className="w-6 h-6 cursor-pointer absolute top-2 right-2"
                                    onClick={() => {
                                        setPasswordShown(!passwordShown);
                                    }}
                                />
                            )}
                            <div className="absolute mt-8 w-full">
                                <PasswordStrengthMeter
                                    valid={validatePassword(
                                        passwordData.newPassword
                                    )}
                                    password={passwordData.newPassword}
                                />
                            </div>
                        </div>
                    )}
                    <div className="grid place-items-center pt-10 gap-y-5 w-full">
                        <div
                            className="w-full"
                            onClick={handleNewPasswordSubmit}
                        >
                            <PillButton
                                text="SET NEW PASSWORD"
                                bg="teal-500"
                                textC="white"
                                loading={isSubmitting}
                            />
                        </div>
                        <div className="border-t-2 w-full" />
                        <div className="flex flex-row">
                            <div
                                className="text-teal-500 font-semibold underline cursor-pointer"
                                onClick={resendCode}
                            >
                                Resend confirmation code
                            </div>
                            <div>
                                {resendingCode && !resentCode && (
                                    <RefreshIcon className="w-5 h-5 animate-spin text-teal-500" />
                                )}
                                {!resendingCode && resentCode && (
                                    <CheckCircleIcon className="w-5 h-5 text-teal-500" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {confirmPassword && success && (
                <div className=" shadow-lg mt-10 p-5 m-5 grid place-items-center text-blueGray-800 text-base border-2 rounded-md py-10 bg-white max-w-400">
                    <div className="flex flex-row space-x-5 pb-5">
                        <div>
                            <Logo width={60} height={60} />
                        </div>
                        <div className="sm:text-4xl text-2xl self-center">
                            BenchMarker
                        </div>
                    </div>
                    <div className="text-xl pb-5">SUCCESS!</div>
                    <div className="text-sm sm:text-base px-5 mb-5">
                        You can now sign in using your new password
                    </div>
                    <div className="grid place-items-center w-full">
                        <div className="w-full">
                            <Link to="/login">
                                <PillButton
                                    text="SIGN IN"
                                    bg="teal-500"
                                    textC="white"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ForgotPassword;
