import { ExclamationCircleIcon } from "@heroicons/react/outline";
import React from "react";

const ErrorPanel = (props) => {
    return (
        <div className="">
            <div className="bg-amber-100 rounded-lg px-4 py-2 sm:text-sm text-xs xl:text-base text-amber-600 flex flex-row sm:gap-x-2 gap-x-1">
                <div className="w-6 h-6">
                    <ExclamationCircleIcon />
                </div>
                {props.message}
            </div>
        </div>
    );
};

export default ErrorPanel;
