import React, { useState, useEffect } from "react";
import DisplayValue from "../../Text/DisplayValue";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { getQueryParameter } from "../../../utils/queryParams";
import { InformationCircleIcon } from "@heroicons/react/outline";
import PopupDialog from "../../Modals/PopupDialog";

const WASH_COUNT_QUERY = gql`
    query SessionTotalsQuery(
        $assetId: Int!
        $dayAgo: timestamptz!
        $monthAgo: timestamptz!
        $yearAgo: timestamptz!
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    day: washwatch_sessions_aggregate(
                        where: { end_time: { _gte: $dayAgo } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    month: washwatch_sessions_aggregate(
                        where: { end_time: { _gte: $monthAgo } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    year: washwatch_sessions_aggregate(
                        where: { end_time: { _gte: $yearAgo } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    firstSession: washwatch_sessions(
                        limit: 1
                        order_by: { end_time: asc }
                    ) {
                        id
                        end_time
                    }
                    lastSession: washwatch_sessions(
                        limit: 1
                        order_by: { end_time: desc }
                    ) {
                        id
                        end_time
                    }
                }
            }
        }
    }
`;

const QUERY_DOOR_CYCLES_AGGREGATE = gql`
    query getDoorCyclesTotal(
        $assetId: Int!
        $dayAgo: timestamptz!
        $monthAgo: timestamptz!
        $yearAgo: timestamptz!
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    day: doorinformer_measurement_data_aggregate(
                        where: { time: { _gte: $dayAgo } }
                    ) {
                        aggregate {
                            sum {
                                count1
                            }
                        }
                    }
                    month: doorinformer_measurement_data_aggregate(
                        where: { time: { _gte: $monthAgo } }
                    ) {
                        aggregate {
                            sum {
                                count1
                            }
                        }
                    }
                    year: doorinformer_measurement_data_aggregate(
                        where: { time: { _gte: $yearAgo } }
                    ) {
                        aggregate {
                            sum {
                                count1
                            }
                        }
                    }
                    latest: doorinformer_measurement_data(
                        limit: 1
                        order_by: { time: desc_nulls_last }
                    ) {
                        id
                        time
                    }
                    earliest: doorinformer_measurement_data(
                        limit: 1
                        order_by: { time: asc_nulls_last }
                    ) {
                        id
                        time
                    }
                }
            }
        }
    }
`;

const QUERY_VAC_SESSION_COUNTS = gql`
    query getVacSessionCount(
        $assetId: Int!
        $dayAgo: timestamptz!
        $monthAgo: timestamptz!
        $yearAgo: timestamptz!
        $_gte: timestamptz = ""
    ) {
        asset_by_pk(id: $assetId) {
            id
            measurement_points {
                id
                measurement_point {
                    id
                    device_type
                    day: vac_sessions_aggregate(
                        where: { start_time: { _gte: $dayAgo } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    month: vac_sessions_aggregate(
                        where: { start_time: { _gte: $monthAgo } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    year: vac_sessions_aggregate(
                        where: { start_time: { _gte: $yearAgo } }
                    ) {
                        aggregate {
                            count
                        }
                    }
                    last: vac_sessions(
                        order_by: { start_time: desc_nulls_last }
                        limit: 1
                    ) {
                        id
                        start_time
                    }
                    first: vac_sessions(
                        order_by: { start_time: asc_nulls_last }
                        limit: 1
                    ) {
                        id
                        start_time
                    }
                }
            }
        }
    }
`;

const titles = {
    DoorInformer: "Door Cycles",
    WASHWATCH: "Wash Programs",
    VAC: "Vacuum Sessions",
};

const zero = 0;

const AssetTotalsPanel = (props) => {
    const assetId = getQueryParameter("assetId");
    const [dayAgo] = useState(dayjs().startOf("day"));
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [yearAgo] = useState(dayjs().subtract(365, "days"));
    const [totalCounts, setTotalCounts] = useState();
    const [deviceType, setDeviceType] = useState();
    const [openInfo, setOpenInfo] = useState(false);

    const { data: sessionCountData, error: sessionCountError } = useQuery(
        WASH_COUNT_QUERY,
        {
            skip: props.type !== "WASHWATCH",
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                assetId: assetId,
                dayAgo: dayAgo,
                monthAgo: monthAgo,
                yearAgo: yearAgo,
            },
            onError: () => console.log(sessionCountError),
        }
    );

    const { data: doorCyclesData, error: doorCyclesError } = useQuery(
        QUERY_DOOR_CYCLES_AGGREGATE,
        {
            skip: props.type !== "DoorInformer",
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                assetId: assetId,
                dayAgo: dayAgo,
                monthAgo: monthAgo,
                yearAgo: yearAgo,
            },
            onError: () => console.log(doorCyclesError),
        }
    );

    const { data: vacData, error: vacDataError } = useQuery(
        QUERY_VAC_SESSION_COUNTS,
        {
            skip: props.type !== "VAC",
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                assetId: assetId,
                dayAgo: dayAgo,
                monthAgo: monthAgo,
                yearAgo: yearAgo,
            },
            onError: () => console.log(vacDataError),
        }
    );

    useEffect(() => {
        if (
            !doorCyclesData ||
            !doorCyclesData.asset_by_pk ||
            !doorCyclesData.asset_by_pk.measurement_points[0] ||
            !doorCyclesData.asset_by_pk.measurement_points[0]
                .measurement_point ||
            !doorCyclesData.asset_by_pk.measurement_points[0].measurement_point
                .earliest
        )
            return;
        let mp =
            doorCyclesData.asset_by_pk.measurement_points[0].measurement_point;
        setDeviceType(mp.device_type);
        if (mp.latest.length < 1) {
            setTotalCounts({
                today: 0,
                month: 0,
                avgDay: 0,
                avgMonth: 0,
            });
            return;
        }
        let latest = dayjs(mp.latest[0].time);
        let earliest = dayjs(mp.earliest[0].time);
        setTotalCounts(
            countPerPeriod(mp.day, mp.month, mp.year, earliest, latest, "door")
        );
    }, [doorCyclesData]);

    useEffect(() => {
        if (
            !sessionCountData ||
            !sessionCountData.asset_by_pk ||
            !sessionCountData.asset_by_pk.measurement_points[0] ||
            !sessionCountData.asset_by_pk.measurement_points[0]
                .measurement_point
        )
            return;
        let mp =
            sessionCountData.asset_by_pk.measurement_points[0]
                .measurement_point;
        setDeviceType(mp.device_type);
        if (mp.firstSession.length < 1) {
            setTotalCounts({
                today: 0,
                month: 0,
                avgDay: 0,
                avgMonth: 0,
            });
            return;
        }
        let firstSessionTime = dayjs(mp.firstSession[0].end_time);
        let lastSessionTime = dayjs(mp.lastSession[0].end_time);

        setTotalCounts(
            countPerPeriod(
                mp.day,
                mp.month,
                mp.year,
                firstSessionTime,
                lastSessionTime,
                "WWH"
            )
        );
    }, [sessionCountData]);

    useEffect(() => {
        if (
            !vacData ||
            !vacData.asset_by_pk ||
            !vacData.asset_by_pk.measurement_points[0] ||
            !vacData.asset_by_pk.measurement_points[0].measurement_point ||
            !vacData.asset_by_pk.measurement_points[0].measurement_point
                .first ||
            !vacData.asset_by_pk.measurement_points[0].measurement_point.month
        )
            return;
        let mp = vacData.asset_by_pk.measurement_points[0].measurement_point;
        setDeviceType(mp.device_type);
        if (!mp.first || mp.first.length < 1) {
            setTotalCounts({
                today: 0,
                month: 0,
                avgDay: 0,
                avgMonth: 0,
            });
            return;
        }
        let firstSessionTime = dayjs(mp.first[0].start_time);
        let lastSessionTime = dayjs(mp.last[0].start_time);
        setTotalCounts(
            countPerPeriod(
                mp.day,
                mp.month,
                mp.year,
                firstSessionTime,
                lastSessionTime,
                "vac"
            )
        );
    }, [vacData]);

    // returns the count for each period
    const countPerPeriod = (day, month, year, first, last, device) => {
        let diffDays =
            last.diff(first, "day", true) >= 1
                ? last.diff(first, "day", true)
                : 1;
        let diffMonths =
            last.diff(first, "month", true) > 12
                ? 12
                : last.diff(first, "month");

        if (device === "door") {
            let counts = {
                today: day.aggregate.sum.count1,
                month: month.aggregate.sum.count1,
                avgDay:
                    diffDays >= 30
                        ? month.aggregate.sum.count1 / 30
                        : month.aggregate.sum.count1 / diffDays,
                avgMonth:
                    diffMonths <= 1
                        ? month.aggregate.sum.count1
                        : year.aggregate.sum.count1 / diffMonths,
            };
            return counts;
        } else {
            let counts = {
                today: day.aggregate.count,
                month: month.aggregate.count,
                avgDay:
                    diffDays >= 30
                        ? month.aggregate.count / 30
                        : month.aggregate.count / diffDays,
                avgMonth:
                    diffMonths <= 1
                        ? month.aggregate.count
                        : year.aggregate.count / diffMonths,
            };
            return counts;
        }
    };

    if (!totalCounts || !deviceType) return <div></div>;

    return (
        <div className="border-2 rounded-lg bg-white px-3 h-24 sm:h-30 py-1">
            <PopupDialog
                open={openInfo}
                close={setOpenInfo}
                title={titles[deviceType]}
            />
            <div className="flex flex-row justify-between">
                <div className="flex flew-row">
                    <div className="text-lg xl:text-2xl">
                        {titles[deviceType]}
                    </div>
                    <div
                        className="ml-2 w-5 h-4 self-center cursor-pointer"
                        onClick={() => setOpenInfo(true)}
                    >
                        <InformationCircleIcon />
                    </div>
                </div>

                <div className="self-center text-trueGray-400 xl:text-base text-xs">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row justify-between space-x-5 2xl:space-x-12 lg:pt-4 px-1 lg:px-3 xl:px-8 sm:pt-0 pt-4">
                <DisplayValue
                    title="TODAY"
                    value={
                        totalCounts.today
                            ? totalCounts.today.toFixed(0)
                            : zero.toFixed(0)
                    }
                />
                <DisplayValue
                    title="MONTH"
                    value={
                        totalCounts.month
                            ? totalCounts.month.toFixed(0)
                            : zero.toFixed(0)
                    }
                />
                <DisplayValue
                    title="AVG DAILY"
                    value={
                        totalCounts.avgDay
                            ? totalCounts.avgDay.toFixed(0)
                            : zero.toFixed(0)
                    }
                />
                <DisplayValue
                    title="AVG MONTH"
                    value={
                        totalCounts.avgMonth
                            ? totalCounts.avgMonth.toFixed(0)
                            : zero.toFixed(0)
                    }
                />
            </div>
        </div>
    );
};

export default AssetTotalsPanel;
