import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import DisplayValue from "../../Text/DisplayValue";
import LineGraph from "../../Graphs/LineGraph";
import { InformationCircleIcon } from "@heroicons/react/outline";
import PopupDialog from "../../Modals/PopupDialog";

const ASSET_PERFORMANCE_QUERY = gql`
    query getAssetPerformanceLastMonth(
        $start: timestamp!
        $end: timestamp!
        $assetIds: _int4!
        $monthAgo: timestamptz!
        $goalAssets: [Int!]
    ) {
        assets_performance_grouped(
            args: { asset_ids: $assetIds, end_time: $end, start_time: $start }
            order_by: { time: asc }
        ) {
            rating
        }
        asset_performance_aggregate(
            where: { time: { _gte: $monthAgo }, asset_id: { _in: $goalAssets } }
        ) {
            aggregate {
                avg {
                    rating
                }
                sum {
                    downtime
                }
            }
        }
    }
`;

const AssetPerformance = (props) => {
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [currentTime] = useState(dayjs().format());
    const [monthlyRating, setMonthlyRating] = useState();
    const [monthlyDowntime, setMonthlyDowntime] = useState();
    const [performanceGraph, setPerformanceGraph] = useState();
    const [openInfo, setOpenInfo] = useState(false);

    const { data: assetPerformanceData, error: assetNetworkError } = useQuery(
        ASSET_PERFORMANCE_QUERY,
        {
            fetchPolicy: "network-only", // Used for first execution
            nextFetchPolicy: "cache-first", // Used for subsequent executions
            variables: {
                start: monthAgo,
                end: currentTime,
                assetIds: `{${props.assetIds}}`,
                goalAssets: props.assetIds,
                monthAgo: monthAgo,
            },
            onError: () => console.log(assetNetworkError),
        }
    );

    useEffect(() => {
        if (!assetPerformanceData) return;
        let graphData = [];
        let monthlyRating = (
            assetPerformanceData.asset_performance_aggregate.aggregate.avg
                .rating
                ? assetPerformanceData.asset_performance_aggregate.aggregate.avg
                      .rating * 100
                : 100
        ).toFixed(1);
        let monthlyDowntime = (
            assetPerformanceData.asset_performance_aggregate.aggregate.sum
                .downtime
                ? assetPerformanceData.asset_performance_aggregate.aggregate.sum
                      .downtime / 60
                : 0
        ).toFixed(1);
        setMonthlyRating(monthlyRating);
        setMonthlyDowntime(monthlyDowntime);

        assetPerformanceData.assets_performance_grouped.map((val) =>
            graphData.push(parseInt((val.rating * 100).toFixed(1)))
        );
        backFill(graphData);
        setPerformanceGraph(graphData);
    }, [assetPerformanceData]);

    const backFill = (dataArray) => {
        if (dataArray.length < 30) {
            let daysToFill = 30 - dataArray.length - 1;
            for (let i = 0; i < daysToFill; i++) {
                dataArray.push(100);
            }
        } else return;
    };

    if (!monthlyRating || !monthlyDowntime || !performanceGraph) {
        return <div></div>;
    }

    return (
        <div className="border-2 rounded-lg bg-white px-3 pt-1">
            <PopupDialog
                open={openInfo}
                close={setOpenInfo}
                title="ASSET PERFORMANCE"
            />
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <div className="text-lg xl:text-2xl">Performance</div>
                    <div
                        className="ml-2 w-5 h-4 self-center cursor-pointer"
                        onClick={() => setOpenInfo(true)}
                    >
                        <InformationCircleIcon />
                    </div>
                </div>

                <div className="self-center text-trueGray-400 xl:text-base text-xs">
                    {dayjs().format("HH:mm DD/MM/YYYY")}
                </div>
            </div>
            <div className="flex flex-row space-x-3 lg:space-x-8 2xl:space-x-16 xl:px-8 lg:px-4">
                <div className="self-center pb-4 sm:pb-0">
                    <DisplayValue
                        title="PERFORMANCE"
                        value={monthlyRating}
                        unit="%"
                    />
                </div>
                <div className="self-center pb-4 sm:pb-0">
                    <DisplayValue
                        title="IMPACT"
                        value={monthlyDowntime}
                        unit="hr"
                        colour="red-600"
                    />
                </div>
                <div className="w-full h-20">
                    <LineGraph data={performanceGraph} min={0} max={100} />
                </div>
            </div>
        </div>
    );
};

export default AssetPerformance;
