import * as HIcons from "@heroicons/react/solid";
import classnames from "classnames";

const DynamicHeroIcon = (props) => {
    const { ...icons } = HIcons;
    const TheIcon = icons[props.icon];

    return (
        <TheIcon
            className={classnames(
                `h-${props.height}`,
                `w-${props.width}`,
                "m-auto"
            )}
        />

    );
};

export default DynamicHeroIcon;
