import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";

const STATUS = {
    0: "PENDING",
    1: "QUEUED",
    2: "RETRYING",
    20: "IN FLIGHT",
    21: "IN FLIGHT",
    80: "IN FLIGHT",
    90: "SUCCESS",
    91: "FAIL",
    92: "FAIL",
    93: "FAIL",
    94: "FAIL",
    95: "FAIL",
    96: "FAIL",
    97: "FAIL",
    98: "FAIL",
    99: "FAIL",
    100: "FAIL",
};

const History = (props) => {
    return (
        <div className="sm:mx-10 mx-2">
            <div className="font-semibold">Command History</div>
            <div className="max-h-40 overflow-y-scroll">
                {props.buttonCommands.map((command, index) => {
                    return (
                        <div
                            className="flex flex-row justify-between sm:text-base text-2xs"
                            key={index}
                        >
                            <div className="w-1/2">
                                {dayjs(command.time).format(
                                    "ddd D MMM, h:mm a"
                                )}
                            </div>
                            <div className="w-1/2">
                                {command.user && command.user.email}
                            </div>
                            <div className="w-1/2 truncate">
                                {command.button_sequence.startsWith(
                                    "washtec-login"
                                )
                                    ? "LOGIN"
                                    : command.button_sequence.startsWith(
                                          "washtec-logout"
                                      )
                                    ? "LOGOUT"
                                    : command.button_sequence
                                          .replace("washtec-button", "")
                                          .replace(
                                              "washtec-display all report",
                                              "REFRESH DISPLAY"
                                          )
                                          .replace("washtec-reset", "RESET")
                                          .toUpperCase()}
                            </div>
                            <div className="w-1/5 flex justify-center mb-1">
                                {command.downlink && (
                                    <span
                                        className={classNames(
                                            "sm: text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full",
                                            command.downlink.status === 90 &&
                                                "text-teal-600 bg-teal-200",
                                            command.downlink.status > 90 &&
                                                "text-red-600 bg-red-200",
                                            command.downlink.status < 90 &&
                                                "text-trueGray-600 bg-trueGray-200"
                                        )}
                                    >
                                        {STATUS[command.downlink.status]}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default History;
