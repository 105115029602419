import { InformationCircleIcon, PlusIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import AddGoalForm from "../../components/Forms/AddGoalForm";
import DataTable from "../../components/Tables/DataTable";
import { gql, useQuery } from "@apollo/client";
import PopupDialog from "../../components/Modals/PopupDialog";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../utils/queryParams";
import GoalDetails from "../GoalDetails/GoalDetails";

const GOALS_QUERY = gql`
    query getGoals($id: Int!) {
        organisation_by_pk(id: $id) {
            id
            goals(order_by: { month_performance: asc_nulls_first }) {
                name
                id
                description
                month_performance
                annual_performance
                assets {
                    id
                    asset {
                        id
                    }
                }
            }
        }
    }
`;

const Goals = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const orgIdParam = getQueryParameter("organisationId");
    const goalIdParam = getQueryParameter("goalId");
    const [createGoal, setCreateGoal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const history = useHistory();
    const [canEdit, setCanEdit] = useState(false);
    const [goals, setGoals] = useState();

    const {
        data: goalsData,
        error: goalsError,
        refetch: refetchGoals,
        startPolling,
        stopPolling,
    } = useQuery(GOALS_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            id: props.orgId,
        },
        onError: () => console.log(goalsError),
    });

    const Loading = () => {
        return (
            <div className="flex flex-row space-x-3 items-center pt-2">
                <div
                    style={{ animationDelay: "0.1s" }}
                    className="w-1 h-1 rounded-full bg-blueGray-800 animate-ping"
                ></div>
                <div
                    style={{ animationDelay: "0.3s" }}
                    className="w-1 h-1  rounded-full bg-blueGray-800 animate-ping"
                ></div>
                <div
                    style={{ animationDelay: "0.5s" }}
                    className="w-1 h-1  rounded-full bg-blueGray-800 animate-ping"
                ></div>
            </div>
        );
    };

    useEffect(() => {
        if (!goalsData) return;
        let poll = false;
        setGoals(
            goalsData.organisation_by_pk.goals.map((goal) => {
                if (
                    goal.month_performance === null ||
                    goal.annual_performance === null
                ) {
                    poll = true;
                }
                return {
                    ...goal,
                    month_performance: goal.month_performance ? (
                        goal.month_performance.toFixed(1)
                    ) : (
                        <Loading />
                    ),
                    annual_performance: goal.annual_performance ? (
                        goal.annual_performance.toFixed(1)
                    ) : (
                        <Loading />
                    ),
                };
            })
        );
        if (poll && startPolling) {
            startPolling(2000);
        } else if (stopPolling) {
            stopPolling();
        }
    }, [goalsData, startPolling, stopPolling]);

    useEffect(() => {
        let organisationPermission = props.permissions.find(
            (role) =>
                role.role >= 1 &&
                parseInt(role.organisation_id) === parseInt(orgIdParam)
        );
        if (organisationPermission) setCanEdit(true);
        else setCanEdit(false);
    }, [props.permissions, orgIdParam]);

    const setGoalParam = (id) => {
        history.push(`goals?organisationId=${orgIdParam}&goalId=${id}`);
    };

    if (!goals) return <div></div>;
    return goalIdParam ? (
        <GoalDetails hasEdit={canEdit} />
    ) : (
        <div
            className={`w-full h-screen xl:px-12 px-3 sm:px-6 ${
                isMobile ? "pt-5" : "pt-10"
            } bg-trueGray-50`}
        >
            <div className="flex flex-row justify-between">
                <div className="text-teal-600 text-3xl xl:pl-1.5">Goals</div>
            </div>
            <div className="sm:space-x-7 pb-56 overflow-y-auto">
                <div
                    className={`${
                        isMobile ? "mt-5" : "mt-10"
                    } w-full sm:w-1/2 xl:w-2/5 border-2 rounded-lg h-auto float-left bg-white`}
                >
                    <div className="flex flex-row justify-between m-4">
                        <div className="flex flex-row gap-x-1">
                            <div className="text-2xl">Goals</div>
                            <div
                                className="w-6 h-6 self-center cursor-pointer "
                                onClick={() => setOpenInfo(true)}
                            >
                                <InformationCircleIcon />
                            </div>
                            <PopupDialog
                                open={openInfo}
                                close={setOpenInfo}
                                title="GOALS"
                            />
                        </div>
                        {canEdit && (
                            <div className="flex flex-row gap-x-2">
                                <div className="text-lg">Add New Goal</div>
                                <div
                                    className="mt-0.5 w-6 h-6 text-white rounded-full bg-teal-500 p-0.5 cursor-pointer"
                                    onClick={() => setCreateGoal(true)}
                                >
                                    <PlusIcon />
                                </div>
                            </div>
                        )}
                    </div>

                    <DataTable
                        headerStyle="grid grid-cols-3 justify-items-end px-2 pl-5 xl:text-base text-xs"
                        rowStyle="grid grid-cols-3 justify-items-end px-5 py-4 border-t hover:text-teal-500 cursor-pointer"
                        data={goals}
                        titles={[
                            { Header: "NAME", accessor: "name" },
                            // { Header: "GOAL", accessor: "description" },
                            {
                                Header: "30 DAY %",
                                accessor: "month_performance",
                            },
                            {
                                Header: "ANNUAL %",
                                accessor: "annual_performance",
                            },
                        ]}
                        setUrl={setGoalParam}
                    />
                </div>
                {createGoal && (
                    <AddGoalForm close={setCreateGoal} refetch={refetchGoals} />
                )}
            </div>
        </div>
    );
};

export default Goals;
