import React, { Fragment, useState, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PillButton from "../../Miscellaneous/PillButton";
import { getQueryParameter } from "../../../utils/queryParams";
import { gql, useMutation } from "@apollo/client";

const MUTATION_UPDATE_ASSET_DETAILS = gql`
  mutation updateAssetDetails(
    $assetId: Int!
    $model: String
    $notes: String
    $serialNumber: String
    $itemNumber: String
    $year: Int
  ) {
    update_asset_by_pk(
      _set: {
        model: $model
        notes: $notes
        serial_number: $serialNumber
        item_number: $itemNumber
        year: $year
      }
      pk_columns: { id: $assetId }
    ) {
      id
    }
  }
`;

const EditAssetDetails = (props) => {
  const assetId = getQueryParameter("assetId");
  const assetDetails = props.details;
  const [formData, setFormData] = useState({
    model: assetDetails.model,
    year: assetDetails.year,
    itemNumber: assetDetails.item_number,
    serialNumber: assetDetails.serial_number,
    notes: assetDetails.notes,
  });

  const [updateDetails] = useMutation(MUTATION_UPDATE_ASSET_DETAILS);

  const handleUpdate = useCallback(() => {
    updateDetails({
      variables: {
        assetId: assetId,
        model: formData.model,
        notes: formData.notes,
        serialNumber: formData.serialNumber,
        itemNumber: formData.itemNumber,
        year: formData.year,
      },
    })
      .then((r) => {
        props.refetch();
        console.log("updated");
      })
      .catch((e) => console.log(e));
  }, [assetId, formData, props, updateDetails]);

  const handleInputChange = (event) => {
    setFormData((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => props.close(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block xl:max-w-xl max-w-lg  p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
              <Dialog.Title as="h3" className="text-lg font-medium leading">
                {props.title}
              </Dialog.Title>
              <div className="mt-4">
                {" "}
                <div className="flex-col pl-4 space-y-3 text-xs xl:text-base text-trueGray-400 pr-1">
                  <div className="grid grid-cols-2">
                    <div>ASSET TYPE</div>
                    <div className="text-blueGray-800">
                      {assetDetails.asset_type
                        ? assetDetails.asset_type.name
                        : "---"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>MODEL</div>
                    <input
                      name="model"
                      onChange={handleInputChange}
                      className="text-blueGray-800 pl-1 border-2 rounded-md"
                      value={formData.model}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div>YEAR</div>
                    <input
                      name="year"
                      onChange={handleInputChange}
                      className="text-blueGray-800 pl-1 border-2 rounded-md"
                      value={formData.year}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div>ITEM NUMBER</div>
                    <input
                      name="itemNumber"
                      onChange={handleInputChange}
                      className="text-blueGray-800 pl-1 border-2 rounded-md"
                      value={formData.itemNumber}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div>SERIAL NUMBER</div>
                    <input
                      name="serialNumber"
                      onChange={handleInputChange}
                      className="text-blueGray-800 pl-1 border-2 rounded-md"
                      value={formData.serialNumber}
                    />
                  </div>
                  <div className="grid grid-cols-2">
                    <div>NOTES</div>
                    <textarea
                      name="notes"
                      onChange={handleInputChange}
                      className="text-blueGray-800 pl-1 border-2 rounded-md h-20"
                      value={formData.notes}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4" onClick={() => props.close(false)}>
                <div className="flex flex-row gap-x-4">
                  <div className="w-1/2" onClick={handleUpdate}>
                    <PillButton text="UPDATE" bg="teal-500" textC="white" />
                  </div>
                  <div className="w-1/2">
                    <PillButton text="CANCEL" bg="white" textC="teal-500" />
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditAssetDetails;
