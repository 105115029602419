import React from "react";
import { useState } from "react";
import { Tab } from "@headlessui/react";
import DynamicHeroIcon from "../../Miscellaneous/DynamicHeroIcon";
import { Link } from "react-router-dom";
import { Logo } from "../../../assets/Svgs/Logo";
import useWindowDimensions from "../../../utils/getWindowDimensions";
import DefaultFooter from "../DefaultFooter";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SideNavigation = (props) => {
  const currentUrl = window.location.pathname;
  const windowWidth = useWindowDimensions().width;

  let [categories] = useState({
    Home: "HomeIcon",
    Goals: "PresentationChartLineIcon",
    Issues: "ExclamationIcon",
    Prompts: "ChartSquareBarIcon",
    Requests: "QuestionMarkCircleIcon",
    Reports: "ClipboardCheckIcon",
    Assets: "OfficeBuildingIcon",
    Settings: "CogIcon",
  });
  return (
    <div className="bg-blueGray-800 p-2 xl:w-72 lg:w-56 w-48 h-full flex flex-col justify-between fixed">
      <div>
        <div className="flex justify-center mt-5 mb-12">
          <Logo width={windowWidth < 1024 ? 40 : 50} height={50} />
          <div className="text-white lg:text-xl text-lg mt-2 lg:ml-3 ml-2">
            BenchMarker
          </div>
        </div>
        <Tab.Group vertical className="">
          <Tab.List className="">
            {Object.keys(categories).map((category, index) => (
              <Link
                key={index}
                to={
                  category === "Issues" ||
                  category === "Prompts" ||
                  category === "Requests"
                    ? `/${category.toLocaleLowerCase()}?filter=open&organisationId=${
                        props.orgId
                      }`
                    : category === "Assets"
                    ? `/${category.toLocaleLowerCase()}?filter=low&organisationId=${
                        props.orgId
                      }`
                    : `/${category.toLowerCase()}?organisationId=${props.orgId}`
                }
              >
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      "text-base flex flex-row h-10 sm:h-14 mb-4 pl-6 lg:pl-10 gap-x-5",
                      currentUrl.includes(category.toLowerCase())
                        ? "text-teal-500"
                        : "text-white"
                    )
                  }
                >
                  <DynamicHeroIcon
                    icon={categories[category]}
                    height={5}
                    width={5}
                    className="text-blueGray-800"
                  />
                  <div className="self-center">{category}</div>
                </Tab>
              </Link>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>
      <div className="text-white pl-5 pb-4">
        <DefaultFooter />
      </div>
    </div>
  );
};

export default SideNavigation;
