import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { BarChart } from "echarts/charts";
import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components
echarts.use([
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    BarChart,
    CanvasRenderer,
    DataZoomComponent,
]);

const StackedBarGraph = (props) => {
    const chartRef = useRef(null);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [chartObject, setChartObject] = useState(null);

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);
    useEffect(() => {
        if (!chartRef.current || !props.data || !chartObject) return;
        let data = [];
        if (Array.isArray(props.data)) {
            data.push({
                name: "Vac Sessions",
                type: "bar",
                data: props.data,
            });
        } else {
            data.push(
                {
                    name: "Program 1",
                    type: "bar",
                    stack: "one",
                    data: props.data.p1.length > 0 ? props.data.p1 : [0],
                },
                {
                    name: "Program 2",
                    type: "bar",
                    stack: "one",
                    data: props.data.p2.length > 0 ? props.data.p2 : [0],
                },
                {
                    name: "Program 3",
                    type: "bar",
                    stack: "one",
                    data: props.data.p3.length > 0 ? props.data.p3 : [0],
                },
                {
                    name: "Program 4",
                    type: "bar",
                    stack: "one",
                    data: props.data.p4.length > 0 ? props.data.p4 : [0],
                },
                {
                    name: "Program 5",
                    type: "bar",
                    stack: "one",
                    data: props.data.p5.length > 0 ? props.data.p5 : [0],
                },
                {
                    name: "Program 6",
                    type: "bar",
                    stack: "one",
                    data: props.data.p6.length > 0 ? props.data.p6 : [0],
                }
            );
        }

        let option = {
            tooltip: {},
            xAxis: {
                type: "category",
                data: props.xAxis,
                axisLine: { onZero: true, show: false },
                axisTick: { show: false },
                splitLine: { show: false },
                splitArea: { show: false },
                label: { show: false },
                axisLabel: {
                    margin: isMobile ? 5 : 10,
                    fontStyle: "bolder",
                    fontSize: isMobile ? 10 : 12,
                },
            },
            yAxis: {
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    margin: isMobile ? 5 : 10,
                    fontStyle: "bolder",
                    fontSize: isMobile ? 10 : 12,
                },
            },
            grid: {
                show: false,
            },
            dataZoom: {
                start: 0,
                type: "inside",
            },
            series: data,
        };
        chartObject.setOption(option);
    }, [chartRef, props, chartObject, isMobile]);

    return (
        <div className="h-56 w-full">
            <div ref={chartRef} className="h-full w-full" />
        </div>
    );
};

export default StackedBarGraph;
