import React, { useState, useEffect } from "react";
import IprValue from "../../Text/IprValue";
import IprTable from "../../../components/Tables/IprTable";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { getQueryParameter } from "../../../utils/queryParams";
import { STATUS_CHOICES } from "../../../utils/constants";
import IprDetailsModal from "../../Modals/IprDetailsModal";

const QUERY_IPR_COUNTS = gql`
    query getIprCounts($assetId: Int!, $monthAgo: timestamptz!) {
        asset_by_pk(id: $assetId) {
            id
            issuesTotal: issues_aggregate {
                aggregate {
                    count
                }
            }
            issuesOpen: issues_aggregate(where: { status: { _lte: 2 } }) {
                aggregate {
                    count
                }
            }
            issuesMonth: issues_aggregate(
                where: { time_raised: { _gte: $monthAgo } }
            ) {
                aggregate {
                    count
                }
            }
            promptsTotal: prompts_aggregate {
                aggregate {
                    count
                }
            }
            promptsOpen: prompts_aggregate(where: { status: { _lte: 2 } }) {
                aggregate {
                    count
                }
            }
            promptsMonth: prompts_aggregate(
                where: { time_raised: { _gte: $monthAgo } }
            ) {
                aggregate {
                    count
                }
            }
            requestsMonth: requests_aggregate(
                where: { time: { _gte: $monthAgo } }
            ) {
                aggregate {
                    count
                }
            }
            requestsTotal: requests_aggregate {
                aggregate {
                    count
                }
            }
            requestsOpen: requests_aggregate(where: { open: { _eq: true } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const QUERY_IPR_DETAILS = gql`
    query getIprDetails($assetId: Int!) {
        asset_by_pk(id: $assetId) {
            id
            issues(order_by: { time_raised: desc }) {
                id
                time_raised
                summary
                status
                locally_resolvable
                service_provider {
                    id
                    name
                }
            }
            prompts(order_by: { time_raised: desc }) {
                id
                time_raised
                description
                status
            }
            requests(order_by: { time: desc }) {
                id
                time
                description
                open
            }
        }
    }
`;

const IprPanel = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const assetId = getQueryParameter("assetId");
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [IprCounts, setIprCounts] = useState();
    const [iprTableData, setIprTableData] = useState();
    const [iprFilter, setIprFilter] = useState("Issues");
    // const [showDialog, setShowDialog] = useState(False);
    const [selectedIpr, setSelectedIpr] = useState(null);
    const [titles, setTitles] = useState();

    const {
        data: iprCounts,
        error: iprCountsError,
        refetch: refetchIprCount,
    } = useQuery(QUERY_IPR_COUNTS, {
        fetchPolicy: "network-only", // Used for first execution
        nextFetchPolicy: "cache-first", // Used for subsequent executions
        variables: {
            assetId: assetId,
            monthAgo: monthAgo,
        },
        onError: () => console.log(iprCountsError),
    });

    const {
        data: iprDetails,
        error: iprDetailsError,
        refetch: refetchIprDetails,
    } = useQuery(QUERY_IPR_DETAILS, {
        variables: {
            assetId: assetId,
        },
        onError: () => console.log(iprDetailsError),
    });

    useEffect(() => {
        if (!iprDetails || !iprDetails.asset_by_pk) return;
        let data = iprDetails.asset_by_pk;
        let issues = [];
        let prompts = [];
        let requests = [];

        data.issues.map((issue) => {
            return issues.push({
                id: issue.id,
                type: "issue",
                desc: issue.summary,
                status: STATUS_CHOICES[issue.status],
                time: issue.time_raised,
                provider: issue.locally_resolvable
                    ? "Local"
                    : issue.service_provider
                    ? issue.service_provider.name
                    : "No service provider",
            });
        });
        data.prompts.map((prompt) => {
            return prompts.push({
                id: prompt.id,
                type: "prompt",
                desc: prompt.description,
                status: STATUS_CHOICES[prompt.status],
                time: prompt.time_raised,
            });
        });
        data.requests.map((request) => {
            return requests.push({
                id: request.id,
                type: "request",
                desc: request.description,
                status: request.open ? "Open" : "Closed",
                time: request.time,
            });
        });
        let dataObject = {
            Issues: issues,
            Prompts: prompts,
            Requests: requests,
        };
        setIprTableData(dataObject);
    }, [iprDetails]);

    useEffect(() => {
        if (!iprCounts || !iprCounts.asset_by_pk) return;
        let data = iprCounts.asset_by_pk;
        let dataObject = {
            Issues: {
                open: data.issuesOpen.aggregate.count,
                month: data.issuesMonth.aggregate.count,
                total: data.issuesTotal.aggregate.count,
            },
            Prompts: {
                open: data.promptsOpen.aggregate.count,
                month: data.promptsMonth.aggregate.count,
                total: data.promptsTotal.aggregate.count,
            },
            Requests: {
                open: data.requestsOpen.aggregate.count,
                month: data.requestsMonth.aggregate.count,
                total: data.requestsTotal.aggregate.count,
            },
        };
        setIprCounts(dataObject);
    }, [iprCounts]);

    useEffect(() => {
        if (iprFilter === "Issues") {
            setTitles([
                {
                    Header: "Time Raised",
                    accessor: "time",
                },
                {
                    Header: "Status",
                    accessor: "status",
                },
                { Header: "Service Provider", accessor: "provider" },
                { Header: "Summary", accessor: "desc" },
            ]);
        } else {
            setTitles([
                {
                    Header: "Time Raised",
                    accessor: "time",
                },
                {
                    Header: "Status",
                    accessor: "status",
                },
                { Header: "Description", accessor: "desc" },
            ]);
        }
    }, [iprFilter]);

    const refetchData = () => {
        refetchIprCount();
        refetchIprDetails();
    };

    const showDialog = (item) => {
        setSelectedIpr(item);
    };

    const hideDialog = () => {
        setSelectedIpr(null);
    };

    if (!IprCounts || !iprTableData || !titles) return <div></div>;

    return (
        <div className="border-2 rounded-lg bg-white h-72 py-1.5 w-full flex flex-row gap-x-6">
            <div className="w-1/5 sm:w-1/4 sm:space-y-0 space-y-3">
                <div
                    onClick={() => setIprFilter("Issues")}
                    className={
                        iprFilter === "Issues"
                            ? `bg-coolGray-50 cursor-pointer text-teal-500 sm:text-base text-xs lg:text-xl pl-1 ${
                                  isMobile ? "w-28" : "w-full"
                              }  sm:pl-3`
                            : "cursor-pointer sm:text-base text-xs lg:text-xl pl-1 sm:pl-3"
                    }
                >
                    <div>Issues</div>
                    <div className="flex flex-row sm:justify-between gap-x-1 w-full">
                        <IprValue
                            title={"OPEN"}
                            value={IprCounts.Issues.open}
                            parent="Issues"
                            filter={iprFilter}
                        />
                        <IprValue
                            title={"MONTH"}
                            value={IprCounts.Issues.month}
                            parent="Issues"
                            filter={iprFilter}
                        />
                        <IprValue
                            title={"TOTAL"}
                            value={IprCounts.Issues.total}
                            parent="Issues"
                            filter={iprFilter}
                        />
                    </div>
                </div>
                <div
                    onClick={() => setIprFilter("Prompts")}
                    className={
                        iprFilter === "Prompts"
                            ? `bg-coolGray-50 cursor-pointer text-teal-500 sm:text-base text-xs lg:text-xl pl-1 ${
                                  isMobile ? "w-28" : "w-full"
                              }  sm:pl-3`
                            : "cursor-pointer sm:text-base text-xs lg:text-xl pl-1 sm:pl-3"
                    }
                >
                    <div>Prompts</div>
                    <div className="flex flex-row sm:justify-between gap-x-1">
                        <IprValue
                            title={"OPEN"}
                            value={IprCounts.Prompts.open}
                            parent="Prompts"
                            filter={iprFilter}
                        />
                        <IprValue
                            title={"MONTH"}
                            value={IprCounts.Prompts.month}
                            parent="Prompts"
                            filter={iprFilter}
                        />
                        <IprValue
                            title={"TOTAL"}
                            value={IprCounts.Prompts.total}
                            parent="Prompts"
                            filter={iprFilter}
                        />
                    </div>
                </div>
                <div
                    onClick={() => setIprFilter("Requests")}
                    className={
                        iprFilter === "Requests"
                            ? `bg-coolGray-50 cursor-pointer text-teal-500 sm:text-base text-xs lg:text-xl pl-1 ${
                                  isMobile ? "w-28" : "w-full"
                              }  sm:pl-3`
                            : "cursor-pointer sm:text-base text-xs lg:text-xl pl-1 sm:pl-3"
                    }
                >
                    <div>Requests</div>
                    <div className="flex flex-row sm:justify-between gap-x-1">
                        <IprValue
                            title={"OPEN"}
                            value={IprCounts.Requests.open}
                            parent="Requests"
                            filter={iprFilter}
                        />
                        <IprValue
                            title={"MONTH"}
                            value={IprCounts.Requests.month}
                            parent="Requests"
                            filter={iprFilter}
                        />
                        <IprValue
                            title={"TOTAL"}
                            value={IprCounts.Requests.total}
                            parent="Requests"
                            filter={iprFilter}
                        />
                    </div>
                </div>
            </div>
            <div className="lg:h-72 w-4/5 sm:w-3/4 lg:pl-0 pl-5">
                <IprDetailsModal
                    item={selectedIpr}
                    open={selectedIpr !== null}
                    // refetch={props.refetch}
                    {...props}
                    closeClicked={hideDialog}
                />
                <IprTable
                    data={iprTableData[iprFilter]}
                    titles={titles}
                    filter={iprFilter ? iprFilter : "open"}
                    rowClicked={showDialog}
                    refetch={refetchData}
                />
            </div>
        </div>
    );
};

export default IprPanel;
