import React, { useState, useEffect } from "react";
import FilterValue from "../../components/Text/FilterValue";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import LargeLineGraph from "../../components/Graphs/LargeLineGraph";
import IprDetails from "../../components/Panels/IprDetails/IprDetails";
import { useHistory } from "react-router";
import { calcByDay } from "../../utils/helperFunctions";
import DataTable from "../../components/Tables/DataTable";
import { getQueryParameter } from "../../utils/queryParams";

const QUERY_ISSUES_TOTAL = gql`
    query issuesTotal(
        $orgId: Int!
        $yearAgo: timestamptz!
        $monthAgo: timestamptz!
    ) {
        organisation_by_pk(id: $orgId) {
            id
            total_closed: issues_aggregate(
                where: { status: { _eq: 10 }, time_raised: { _gte: $monthAgo } }
            ) {
                aggregate {
                    count
                }
            }
            total_resolved: issues_aggregate(
                where: { status: { _eq: 9 }, time_raised: { _gte: $yearAgo } }
            ) {
                aggregate {
                    count
                }
            }
            total_progress: issues_aggregate(
                where: {
                    status: { _gte: 1, _lte: 2 }
                    time_raised: { _gte: $yearAgo }
                }
            ) {
                aggregate {
                    count
                }
            }
            total_open: issues_aggregate(
                where: { status: { _lte: 2 }, time_raised: { _gte: $yearAgo } }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const QUERY_ISSUES = gql`
    query issues(
        $orgId: Int!
        $statusMax: Int!
        $statusMin: Int!
        $raised: timestamptz
    ) {
        organisation_by_pk(id: $orgId) {
            id
            issues(
                where: {
                    status: { _gte: $statusMin, _lte: $statusMax }
                    time_raised: { _gte: $raised }
                }
                order_by: { time_raised: desc }
            ) {
                id
                time_raised
                asset {
                    id
                    description
                }
                site {
                    id
                    name
                }
            }
            issueGraph: issues {
                id
                time_raised
            }
        }
    }
`;

const Issues = (props) => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [monthAgo] = useState(dayjs().subtract(30, "days"));
    const [yearAgo] = useState(dayjs().subtract(1, "year"));
    const [totalOpen, setTotalOpen] = useState();
    const [totalProgress, setTotalProgress] = useState();
    const [totalResolved, setTotalResolved] = useState();
    const [totalClosed, setTotalClosed] = useState();
    const [activeTableData, setActiveTableData] = useState();
    const [activeTitle, setActiveTitle] = useState();
    const [filterStatusMin, setFilterStatusMin] = useState(0);
    const [filterStatusMax, setFilterStatusMax] = useState(2);
    const [issueGraphData, setIssueGraphData] = useState();
    const [selectedIssue, setSelectedIssue] = useState();
    const [raised, setRaised] = useState(dayjs().subtract(1, "year"));
    const history = useHistory();
    const orgIdParam = getQueryParameter("organisationId");
    const filterParam = getQueryParameter("filter");
    const issueIdParam = getQueryParameter("issueId");

    const setIssueIdParam = (id) => {
        if (id) {
            history.push(
                `?filter=${filterParam}&organisationId=${orgIdParam}&issueId=${id}`
            );
        } else {
            history.push(`?filter=${filterParam}&organisationId=${orgIdParam}`);
        }
    };

    const setFilterParam = (title) => {
        history.push(`/issues?filter=${title}&organisationId=${orgIdParam}`);
    };

    const {
        error: issuesBySiteError,
        data: issuesData,
        refetch: refetchIssues,
    } = useQuery(QUERY_ISSUES, {
        fetchPolicy: "network-only",
        variables: {
            orgId: props.orgId,
            statusMin: filterStatusMin,
            statusMax: filterStatusMax,
            raised: raised,
        },
        onError: () => console.log(issuesBySiteError),
    });

    const {
        error: issuesTotalError,
        data: issuesTotalData,
        refetch: refetchIssueTotals,
    } = useQuery(QUERY_ISSUES_TOTAL, {
        fetchPolicy: "network-only",
        variables: {
            orgId: props.orgId,
            monthAgo: monthAgo,
            yearAgo: yearAgo,
        },
        onError: () => console.log(issuesTotalError),
    });

    useEffect(() => {
        if (
            issuesTotalData === undefined ||
            issuesTotalData.organisation_by_pk === undefined ||
            issuesTotalData.organisation_by_pk.total_open === undefined ||
            issuesTotalData.organisation_by_pk.total_closed === undefined
        )
            return;
        setTotalOpen(
            issuesTotalData.organisation_by_pk.total_open.aggregate.count
        );
        setTotalProgress(
            issuesTotalData.organisation_by_pk.total_progress.aggregate.count
        );
        setTotalResolved(
            issuesTotalData.organisation_by_pk.total_resolved.aggregate.count
        );
        setTotalClosed(
            issuesTotalData.organisation_by_pk.total_closed.aggregate.count
        );
    }, [issuesTotalData]);

    //handling issue data for acknowledged, unacknowledged and open
    useEffect(() => {
        if (
            issuesData === undefined ||
            issuesData.organisation_by_pk === undefined ||
            issuesData.organisation_by_pk.issues === undefined
        )
            return;
        let issues = [];
        issuesData.organisation_by_pk.issues.map((issue) => {
            return issues.push({
                issue: issue,
                col1: dayjs(issue.time_raised).format("YYYY-MM-DD"),
                col2: dayjs(issue.time_raised).format("HH:mm"),
                col3: issue.asset.description,
                col4: issue.site.name,
                id: issue.id,
            });
        });
        setActiveTableData(issues);
        setIssueGraphData(calcByDay(issuesData.organisation_by_pk.issueGraph));
    }, [issuesData]);

    useEffect(() => {
        if (!filterParam) return;
        if (filterParam === "open") {
            setActiveTitle("Open");
            setFilterStatusMax(2);
            setFilterStatusMin(0);
            setRaised(dayjs().subtract(1, "year"));
        } else if (filterParam === "progress") {
            setActiveTitle("Progress");
            setFilterStatusMax(2);
            setFilterStatusMin(1);
            setRaised(dayjs().subtract(1, "year"));
        } else if (filterParam === "resolved") {
            setActiveTitle("Resolved");
            setFilterStatusMax(9);
            setFilterStatusMin(9);
            setRaised(dayjs().subtract(1, "year"));
        } else if (filterParam === "closed") {
            setActiveTitle("Closed");
            setFilterStatusMax(10);
            setFilterStatusMin(10);
            setRaised(dayjs().subtract(30, "days"));
        }
        setActiveTableData([]);
        refetchIssues();
        refetchIssueTotals();
    }, [filterParam, refetchIssueTotals, refetchIssues]);

    useEffect(() => {
        if (!issueIdParam) return;
        if (isMobile) {
            let container = document.querySelector("#container");
            container.scrollTo({
                top: 800,
                left: 0,
                behavior: "smooth",
            });
        }
        if (!selectedIssue || issueIdParam !== selectedIssue.id) {
            setSelectedIssue({ id: issueIdParam });
        }
    }, [isMobile, issueIdParam, selectedIssue]);

    return (
        <div
            className={`grid sm:grid-cols-2 w-full h-screen xl:px-12 sm:px-6 px-3 pb-32 overflow-y-auto ${
                isMobile ? "pt-5" : "pt-10"
            } bg-trueGray-50`}
            id="container"
        >
            <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between">
                    <div
                        className={`text-teal-600 text-3xl ${
                            isMobile ? "pb-6" : "pb-12"
                        } xl:pl-1.5`}
                    >
                        Issues
                    </div>
                </div>
                {totalOpen !== undefined &&
                    totalProgress !== undefined &&
                    totalResolved !== undefined &&
                    totalClosed !== undefined && (
                        <div className="bg-white flex justify-around rounded-lg border-2 w-full sm:w-11/12 sm:h-24 h-20">
                            <FilterValue
                                setUrl={setFilterParam}
                                title={"OPEN"}
                                value={totalOpen}
                                url="open"
                            />
                            <FilterValue
                                setUrl={setFilterParam}
                                title={"PROGRESS"}
                                value={totalProgress}
                                url="progress"
                            />
                            <FilterValue
                                setUrl={setFilterParam}
                                title={"RESOLVED"}
                                value={totalResolved}
                                url="resolved"
                            />
                            <FilterValue
                                setUrl={setFilterParam}
                                title={"CLOSED"}
                                value={totalClosed}
                                url="closed"
                            />
                        </div>
                    )}
                {activeTableData && (
                    <div
                        className={`${
                            isMobile ? "mt-5" : "mt-10"
                        } bg-white sm:w-11/12 w-full border-2 rounded-lg`}
                    >
                        <div className="text-blueGray-800 m-4 text-2xl">
                            {activeTitle === undefined ? "Open" : activeTitle}
                        </div>
                        <div>
                            <DataTable
                                headerStyle="grid grid-cols-4 pl-2 lg:pl-5 lg:py-2 py-1 place-items-start"
                                rowStyle="grid grid-cols-4 lg:pl-5 pl-2 place-self-start py-4 border-t hover:text-teal-500 cursor-pointer"
                                setUrl={setIssueIdParam}
                                data={activeTableData}
                                onRowClicked={(item) => {
                                    setIssueIdParam(item.id);
                                    setSelectedIssue(item.issue);
                                }}
                                titles={[
                                    { Header: "DATE", accessor: "col1" },
                                    { Header: "TIME", accessor: "col2" },
                                    { Header: "ASSET", accessor: "col3" },
                                    { Header: "SITE", accessor: "col4" },
                                ]}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div
                className={`flex flex-col w-full gap-y-6 ${
                    isMobile ? "pt-5" : "pt-21"
                }`}
            >
                {issueGraphData && !isMobile && (
                    <div className="bg-white rounded-lg border-2 xl:w-3/4 w-full h-72">
                        <LargeLineGraph data={issueGraphData} />
                    </div>
                )}
                {issueIdParam && (
                    <div className="bg-white border-2 xl:w-3/4 w-full rounded-lg mb-4">
                        <IprDetails
                            {...props}
                            issueId={issueIdParam}
                            onCloseClicked={() => {
                                setIssueIdParam(null);
                                setSelectedIssue(null);
                            }}
                            onStatusUpdated={() => {
                                refetchIssues();
                                refetchIssueTotals();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Issues;
