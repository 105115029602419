import classNames from "classnames";
import DynamicHeroIcon from "../../Miscellaneous/DynamicHeroIcon";

const Button = (props) => {

    let bgColor = props.bgColor;
    if (!bgColor) {
        bgColor = "teal";
    }
    let textColor = props.textColor;
    if (!textColor) {
        textColor = "white";
    }
    return (
        <div
            onClick={props.disabled ? null : props.onClick}
            className={classNames(
                `p-1 rounded-md m-1 text-center hover:bg-teal-600 flex flex-col justify-center whitespace-pre-wrap xl:text-base sm:text-sm text-2xs min-w-10 text-white`,
                props.disabled ? "bg-teal-600" : "bg-teal-500 cursor-pointer "
            )}
        >
            <div className="text-md font-medium">
                {props.icon && (
                    <DynamicHeroIcon
                        icon={props.icon}
                        height={props.height}
                        width={props.width}
                    />
                )}
                {props.text && props.text}
                {props.customContent && props.customContent}
            </div>
            {props.bottomContent && (
                <div className="text-xs font-normal">{props.bottomContent}</div>
            )}
        </div>
    );
};

export default Button;
