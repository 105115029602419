import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { createRef } from "react";
import { getUserSession } from "../../../utils/userSession";
import PillButton from "../../Miscellaneous/PillButton";

//  Note: the styling for the remote console/camera canvas/iframe runs on a fine balance. Proceed with caution

const VNCRemoteConsole = (props) => {
    const [open, setOpen] = useState(false);

    const [tunnel, setTunnel] = useState(null);
    const [view, setView] = useState("console");

    const [seconds, setSeconds] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [connectedUrl, setConnectedUrl] = useState(null);
    const [consoleUrl, setConsoleUrl] = useState(null);
    const [cameraUrl, setCameraUrl] = useState(null);
    const [iframeWidth, setIframeWidth] = useState(null);
    const [iframeHeight, setIframeHeight] = useState(null);

    const [showCameraAndFiles, setShowCameraAndFiles] = useState(false);

    const canvasRef = createRef();

    const [files, setFiles] = useState(null);

    const isTablet =
        window.innerHeight > 640 && window.innerWidth > 640 ? true : false;
    const isMobilePortrait =
        window.innerHeight > 640 && window.innerWidth < 640;
    const isMobileLandscape =
        window.innerWidth > 640 && window.innerHeight < 640 ? true : false;
    const isBrowser = window.innerWidth > 1536;

    const userSession = getUserSession();

    const [openedTunnel, setOpenedTunnel] = useState(false);

    const API = process.env.REACT_APP_API_URL;

    const device = props.device;

    useEffect(() => {
        if (isMobileLandscape) {
            setIframeWidth("100%");
            setIframeHeight("80%");
        } else if (!isBrowser && view !== "camera") {
            setIframeHeight("70%");
            setIframeWidth("100%");
        } else if (!isTablet && view === "camera") {
            setIframeWidth(window.innerWidth);
            setIframeHeight(window.innerWidth / 1.77777777778 + 64);
        } else if (!isMobilePortrait) {
            setIframeHeight("540px");
            setIframeWidth("905px");
        }
    }, [isMobilePortrait, isMobileLandscape, isTablet, view, isBrowser]);

    useEffect(() => {
        if (!device) return;
        if (device.dev_eui === "70-b3-d5-fb-f1-00-30-8c") {
            setShowCameraAndFiles(true);
        }
    }, [device]);

    const buildHeaders = useCallback(() => {
        const token = userSession.credentials.idToken;
        let requestHeaders = new Headers();
        requestHeaders.append("pragma", "no-cache");
        requestHeaders.append("cache-control", "no-cache");
        requestHeaders.append("authorization", "Bearer " + token);
        return requestHeaders;
    }, [userSession]);

    const closeTunnel = useCallback(async () => {
        if (!tunnel) return;
        let tunnelId = tunnel.tunnelId;
        return fetch(
            `${API}/closeTunnel?tunnelId=${tunnelId.toString()}`,
            {
                method: "GET",
            },
            3
        )
            .then()
            .then((res) => res.json())
            .then((r) => {
                return r;
            })
            .catch((e) => {});
    }, [API, tunnel]);

    const handleClose = useCallback(() => {
        closeTunnel();
        setOpen(false);
        setConnectedUrl(null);
        setTunnel(null);
        setOpenedTunnel(false);
    }, [closeTunnel]);

    const listTunnels = useCallback(async () => {
        if (!device) return;
        let eui = device.dev_eui;
        let thingName = eui.replaceAll("-", "").toUpperCase();
        let deviceId = device.id;
        let headers = buildHeaders();
        return fetch(
            `${API}/listTunnels?thingName=${thingName}&deviceId=${deviceId}`,
            {
                headers: headers,
                method: "GET",
            }
        )
            .then()
            .then((response) => response.json())
            .then((r) => {
                console.log(r);
                return r;
            })
            .catch((e) => {
                console.log(e);
            });
    }, [API, device, buildHeaders]);

    const openTunnel = useCallback(async () => {
        if (!device) return;
        let eui = device.dev_eui;
        let thingName = eui.replaceAll("-", "").toUpperCase();
        let deviceId = device.id;
        let headers = buildHeaders();
        return fetch(
            `${API}/openTunnel?thingName=${thingName}&deviceId=${deviceId}&userId=${userSession.user.userId}`,
            {
                headers: headers,
                method: "GET",
            }
        )
            .then()
            .then((response) => response.json())
            .then((r) => {
                return r;
            })
            .catch((e) => {
                console.log(e);
                return e;
            });
    }, [API, device, buildHeaders, userSession]);

    const connectToTunnel = useCallback(
        async (tunnelId) => {
            let headers = buildHeaders();
            return fetch(API + "/connectToTunnel?tunnelId=" + tunnelId, {
                headers: headers,
                method: "GET",
            })
                .then()
                .then((response) => response.json())
                .then((r) => {
                    console.log("Tunnel connected" + r);
                    return r;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        [API, buildHeaders]
    );

    const getTunnel = useCallback(() => {
        setIsLoading(true);
        listTunnels().then((r) => {
            if (!r.tunnelSummaries.length > 0 && !openedTunnel) {
                console.log("open new tunnel");
                openTunnel().then((r) => {
                    setTunnel(r);
                });
                setOpenedTunnel(true);
            } else {
                console.log("connect to existing tunnel");
                if (r.tunnelSummaries.length > 0) {
                    let tunnelId = r.tunnelSummaries[0].tunnelId;
                    connectToTunnel(tunnelId).then((r) => {
                        setTunnel(r);
                    });
                }
            }
        });
    }, [connectToTunnel, listTunnels, openTunnel, openedTunnel]);

    useEffect(() => {
        if (open && device) {
            getTunnel();
        }
    }, [open, connectedUrl, listTunnels, device, getTunnel]);

    useEffect(() => {
        if (!tunnel) {
            return;
        }
        let consolePort = tunnel.VNCClientPort;
        let cameraPort = tunnel.localCAMPort;
        let filesPort = tunnel.localIMGPort;

        let consoleURL =
            API.split("/api/")[0].replace(":8081", "") +
            `:${consolePort}/sensys_vnc.html?autoconnect=true&show_dot=true&resize=scale&jwt=
                                            ${userSession.credentials.accessToken.toString()}`;

        let camera =
            API.split("/api/")[0].replace(":8081", "") +
            `:${cameraPort}/snap.jpeg`;

        let files =
            API.split("/api/")[0].replace(":8081", "") + `:${filesPort}/images`;

        setConsoleUrl(consoleURL);
        setIsLoading(false);
        setCameraUrl(camera);
        setFiles(files);
    }, [tunnel, userSession, API]);

    const refreshCamera = useCallback(() => {
        if (!canvasRef.current) return;
        console.log("refresh camera image");
        const context = canvasRef.current.getContext("2d");
        const image = new Image();
        image.src = cameraUrl;
        image.onload = () => {
            context.drawImage(
                image,
                0,
                !isTablet ? 64 : 0,
                !isTablet ? window.innerWidth : 960,
                !isTablet ? window.innerWidth / 1.77777777778 : 540
            );
        };
    }, [cameraUrl, canvasRef, isTablet]);

    useEffect(() => {
        if (view !== "camera" || !open) return;
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1000), 1000);
        } else {
            refreshCamera();
            setSeconds(5000);
        }
    }, [seconds, view, open, canvasRef, refreshCamera]);

    useEffect(() => {
        if (view === "console") {
            setConnectedUrl(consoleUrl);
        }
        if (view === "camera") {
            setConnectedUrl(cameraUrl);
        }
        if (view === "images") {
            setConnectedUrl(files);
        }
    }, [view, consoleUrl, cameraUrl, files, refreshCamera]);

    const Loading = () => {
        return (
            <div className="flex flex-row space-x-3 items-center pt-2">
                <div
                    style={{ animationDelay: "0.1s" }}
                    className="w-1 h-1 rounded-full bg-blueGray-800 animate-ping"
                ></div>
                <div
                    style={{ animationDelay: "0.3s" }}
                    className="w-1 h-1  rounded-full bg-blueGray-800 animate-ping"
                ></div>
                <div
                    style={{ animationDelay: "0.5s" }}
                    className="w-1 h-1  rounded-full bg-blueGray-800 animate-ping"
                ></div>
            </div>
        );
    };

    return (
        <div className="w-full">
            <div
                className="w-full"
                onClick={props.disabled ? null : () => setOpen(true)}
            >
                <PillButton
                    bg="teal-500"
                    textC="white"
                    text="REMOTE CONSOLE"
                    disabled={props.disabled}
                />
            </div>

            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    open={open}
                    onClose={() => {
                        handleClose();
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={classNames(
                                "flex flex-row transition-all transform justify-center",
                                !isBrowser && "w-full h-full"
                            )}
                        >
                            {connectedUrl && !isLoading && (
                                <div
                                    className={classNames(
                                        "overflow-auto",

                                        !isBrowser &&
                                            view !== "camera" &&
                                            "w-full h-full"
                                    )}
                                >
                                    <div
                                        style={{
                                            width: iframeWidth,
                                            height: iframeHeight,
                                            padding: 0,
                                            overflow: "hidden",
                                            backgroundColor:
                                                view === "camera"
                                                    ? "#009688"
                                                    : "white",
                                            paddingTop:
                                                !isTablet && view !== "camera"
                                                    ? 40
                                                    : 0,
                                            marginTop:
                                                !isTablet && view !== "camera"
                                                    ? 20
                                                    : 0,
                                        }}
                                    >
                                        {view !== "camera" ? (
                                            <iframe
                                                width={iframeWidth}
                                                height={iframeWidth}
                                                src={connectedUrl}
                                                title="console"
                                            />
                                        ) : (
                                            <canvas
                                                ref={canvasRef}
                                                width={
                                                    isMobilePortrait &&
                                                    !isMobileLandscape
                                                        ? window.innerWidth
                                                        : 960
                                                }
                                                backgroundColor="blue"
                                                height={
                                                    !isTablet
                                                        ? window.innerWidth /
                                                              1.77777777778 +
                                                          64
                                                        : 540
                                                }
                                            />
                                        )}
                                    </div>
                                    {showCameraAndFiles && (
                                        <>
                                            <div
                                                className="w-32 mt-2 float-left"
                                                onClick={() => {
                                                    setView("console");
                                                }}
                                            >
                                                <PillButton
                                                    bg="teal-500"
                                                    textC="white"
                                                    text={"Console"}
                                                />
                                            </div>
                                            <div
                                                className="w-32 mt-2 float-left ml-2"
                                                onClick={() => {
                                                    setView("camera");
                                                }}
                                            >
                                                <PillButton
                                                    bg="teal-500"
                                                    textC="white"
                                                    text={"Camera"}
                                                />
                                            </div>
                                            <div
                                                className="w-32 mt-2 float-left ml-2"
                                                onClick={() => {
                                                    setView("images");
                                                }}
                                            >
                                                <PillButton
                                                    bg="teal-500"
                                                    textC="white"
                                                    text={"Images"}
                                                />
                                            </div>
                                        </>
                                    )}

                                    <div
                                        className="w-32 mt-2 float-right"
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        <PillButton
                                            bg="teal-500"
                                            textC="white"
                                            text="Close"
                                        />
                                    </div>
                                </div>
                            )}
                            {(!connectedUrl || isLoading) && (
                                <div className="text-lg sm:text-2xl my-8 bg-white p-10 rounded-xl border-2 flex justify-center flex-col h-40 mt-20">
                                    Opening remote console session
                                    <p className="text-sm text-center">
                                        This may take up to 10 seconds...
                                    </p>
                                    <div className="self-center mt-5">
                                        <Loading />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </div>
    );
};

export default VNCRemoteConsole;
