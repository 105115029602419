import React, { useState, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import { gql, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { XCircleIcon } from "@heroicons/react/outline";
import PillButton from "../../Miscellaneous/PillButton";

const MUTATION_INSERT_NOTE = gql`
    mutation InsertNote(
        $time: timestamptz!
        $issueId: Int!
        $description: String!
        $authorId: Int!
    ) {
        insert_ppcs_core_notes_one(
            object: {
                time: $time
                issue_id: $issueId
                description: $description
                author_id: $authorId
            }
        ) {
            id
            author_id
            description
            issue_id
            time
        }
    }
`;

const AddNoteModal = (props) => {
    const [noteInput, setNoteInput] = useState("");
    const [insertNote] = useMutation(MUTATION_INSERT_NOTE);

    const handleSubmit = useCallback(() => {
        if (noteInput === "") return;
        insertNote({
            variables: {
                authorId: props.user.id,
                time: dayjs(),
                issueId: props.issueId,
                description: noteInput,
            },
        })
            .then((r) => {
                console.log("Note Submitted");
                props.refetch();
                props.close(false);
            })
            .catch((e) => console.log(e));
    }, [props, noteInput, insertNote]);

    return (
        <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => props.close(false)}
            open={props.open}
        >
            <div className="min-h-screen px-4 text-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div className="inline-block sm:w-120 p-3 sm:p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
                    <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading flex flex-row justify-between"
                    >
                        {props.title ? props.title.toUpperCase() : ""}
                        <div
                            className="w-7 h-7 cursor-pointer"
                            onClick={() => props.close(false)}
                        >
                            <XCircleIcon />
                        </div>
                    </Dialog.Title>
                    <div className="mt-4 text-trueGray-800">
                        {/* <div className="text-sm pt-4">Add a note</div> */}
                        <textarea
                            onChange={(e) => setNoteInput(e.target.value)}
                            value={noteInput}
                            type="text"
                            placeholder="Add a note"
                            className="sm:w-96 w-72 h-24 px-2 py-2 rounded-md text-sm border-2 placeholder-gray-text text-gray-charcoal"
                        />
                    </div>
                    <div className="flex flex-row gap-x-5 pt-2 w-1/2">
                        <div className="w-full" onClick={handleSubmit}>
                            <PillButton
                                text="SUBMIT"
                                bg="white"
                                textC="teal-500"
                            />
                        </div>
                        <div
                            className="w-full"
                            onClick={() => setNoteInput("")}
                        >
                            <PillButton
                                text="CLEAR"
                                bg="teal-500"
                                textC="white"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default AddNoteModal;
