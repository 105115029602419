export const getFile = async (fileKey, authToken) => {
    return fetch(
        process.env.REACT_APP_MEDIA_API_URL + "/api/getObject?q=" + fileKey,
        {
            headers: {
                authorization: "Bearer " + authToken,
            },
            method: "POST",
        }
    )
        .then()
        .then((response) => response.blob())
        .then((blob) => {
            return URL.createObjectURL(blob);
        })
        .catch((e) => {
            console.log(e);
        });
};
