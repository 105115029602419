import React from "react";
import DataChange from "../../Text/DataChange";
import HomePanelValue from "../../Text/HomePanelValue";
import LineGraph from "../../Graphs/LineGraph";
import SemiCircleProgressBar from "../../Graphs/SemiCircleProgressBar";

const HomeDataPanel = (props) => {
    if (!props) return <div></div>;
    return (
        <div className="w-1/4 h-48 bg-white flex flex-col items-end rounded-2xl">
            <div className="flex flex-row w-full h-full xl:px-5 pt-10">
                <div className="sm:w-1/2 w-1/3 h-full">
                    {props.percentageDuration && props.display === "graph" ? (
                        <SemiCircleProgressBar val={props.percentageDuration} />
                    ) : (
                        <div className="pt-10  xl:pl-10">
                            <HomePanelValue
                                unit={props.unit}
                                value={props.percentageDuration}
                            />
                        </div>
                    )}
                </div>
                <div className="sm:w-1/2 w-2/3 h-1/2 sm:gap-y-8">
                    <LineGraph
                        title={props.title}
                        data={props.lineData}
                        min={props.min}
                        max={props.max}
                    />
                    <div className="pt-5">
                        <DataChange
                            valueChange={props.comparisonVal}
                            colour={props.colour}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeDataPanel;
