import React, { useState, useEffect } from "react";

import SideNavigation from "../../components/Layout/SideNavigation";
import { Switch, Route } from "react-router-dom";
import Home from "../Home/Home";
import {
    clearSession,
    isUserLoggedIn,
    refreshAccessToken,
    updateAccessToken,
    getUserEmail,
    getUserSession,
} from "../../utils/userSession";
import cognitoUtils from "../../utils/cognito";
import Settings from "../Settings/Settings";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Assets from "../Assets/Assets";
import Issues from "../Issues/Issues";
import Prompts from "../Prompts/Prompts";
import Requests from "../Requests/Requests";
import Logout from "../../components/Miscellaneous/Logout";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../utils/queryParams";
import PermissionDenied from "../PermissionDenied/PermissionDenied";
import Reports from "../Reports/Reports";
import OrgSelector from "../../components/Input/OrganisationSelector";
import Goals from "../Goals";
import dayjs from "dayjs";
import { deviceDetect } from "react-device-detect";
import MobileTopBar from "../../components/Mobile/MobileTopBar/MobileTopBar";
import PullToRefresh from "react-simple-pull-to-refresh";
import { RefreshIcon } from "@heroicons/react/outline";
import { SET_USER_SESSION } from "../../redux/actionTypes";
import { store } from "../../redux";
import AssetDetails from "../AssetDetails/AssetDetails";
import { useLocation } from "react-router-dom";

const GET_ORG_ID_FROM_USER_EMAIL = gql`
    query getOrgIDfromUser($email: String!) {
        user_profile(where: { email: { _eq: $email } }) {
            id
            organisation_id
            access_roles {
                role
                organisation_id
                site_id
                zone_id
                asset_id
                asset_type_id
                measurement_point_id
            }
        }
    }
`;
const GET_SITE_IDS_BY_ORG_ID = gql`
    query getSiteIDbyOrgID($orgId: Int!) {
        organisation_by_pk(id: $orgId) {
            id
            name
            sites {
                id
            }
        }
    }
`;

const UPDATE_SESSION_HISTORY_MUTATION = gql`
    mutation insertSessionHitory(
        $application: String!
        $user_id: Int!
        $browser: String!
        $url: String!
        $time: timestamptz!
        $device: String!
    ) {
        insert_ppcs_core_usersessionhistory_one(
            object: {
                application: $application
                browser: $browser
                device: $device
                time: $time
                url: $url
                user_id: $user_id
            }
        ) {
            id
        }
    }
`;

const logOut = () => {
    cognitoUtils.signOutCognitoSession();
    clearSession();
};

const Base = () => {
    if (!isUserLoggedIn()) {
        refreshAccessToken()
            .then(() => {
                updateAccessToken()
                    .then(() => {
                        if (!isUserLoggedIn()) {
                            logOut();
                        }
                        refetchUserOrgData();
                    })
                    .catch((e) => {
                        console.log(e);
                        logOut();
                    });
            })
            .catch((e) => {
                console.log(e);
                logOut();
            });
    }

    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;

    const history = useHistory();
    const location = useLocation();

    const userSession = getUserSession();

    const currentUrl = window.location.href;

    const [orgId, setOrgId] = useState();
    const [totalSites, setTotalSites] = useState();

    const orgIdParam = getQueryParameter("organisationId")
        ? getQueryParameter("organisationId")
        : null;

    const [hasPermission, setHasPermission] = useState(false);
    const [userPermissions, setUserPermissions] = useState();
    const [user, setUser] = useState();

    const [insertSessionHistory] = useMutation(UPDATE_SESSION_HISTORY_MUTATION);

    // GA4 route tracking
    useEffect(() => {
        // Track a page view when the route changes
        window.gtag("config", "G-XPBG6J5XNC", {
            page_path: location.pathname,
        });
    }, [location.pathname]);

    //get orgID from user email
    const {
        error: getUserError,
        data: userOrgData,
        loading: userDataLoading,
        refetch: refetchUserOrgData,
    } = useQuery(GET_ORG_ID_FROM_USER_EMAIL, {
        // skip: !orgIdParam === false,
        fetchPolicy: "network-only",
        variables: {
            email: getUserEmail(),
        },
        onError: () => console.log(getUserError),
    });

    useEffect(() => {
        if (!user) return;
        const deviceInfo = deviceDetect();
        let device = `${deviceInfo.osName} - ${deviceInfo.osVersion}`;
        let browser = `${deviceInfo.browserName} - ${deviceInfo.browserFullVersion}`;
        if (deviceInfo.isMobile) {
            device = `${deviceInfo.vendor} / ${deviceInfo.os} - ${deviceInfo.osVersion}`;
            browser = deviceInfo.ua;
        }
        insertSessionHistory({
            variables: {
                application: "ASSETS",
                time: dayjs(),
                user_id: user.id,
                url: currentUrl,
                browser: browser,
                device: device,
            },
        }).then((r) => {
            console.log("added session history");
        });
    }, [currentUrl, insertSessionHistory, user]);

    useEffect(() => {
        if (!userOrgData) return;
        if (!userOrgData.user_profile[0]) Logout();
        if (!userOrgData.user_profile[0].access_roles) return;
        if (userOrgData.user_profile[0].access_roles.length > 0) {
            setHasPermission(true);
            setUserPermissions(userOrgData.user_profile[0].access_roles);
        }
        setUser(userOrgData.user_profile[0]);

        if (userSession.user) {
            let session = {
                credentials: userSession.credentials,
                user: {
                    userId: userOrgData.user_profile[0].id,
                    ...userSession.user,
                },
            };
            store.dispatch({ type: SET_USER_SESSION, session });
        }

        if (orgIdParam) return;
        let organisationId = userOrgData.user_profile[0].organisation_id;
        setOrgId(organisationId);
        history.push(`?organisationId=${organisationId}`);
    }, [history, orgId, orgIdParam, userOrgData, userSession]);

    useEffect(() => {
        if (!orgIdParam) return;
        setOrgId(orgIdParam);
    }, [orgIdParam]);

    const [getOrgId, { error: siteIdDataError, data: siteIdData }] =
        useLazyQuery(GET_SITE_IDS_BY_ORG_ID, {
            onError: () => console.log(siteIdDataError),
        });

    //setting variables for lazy query
    useEffect(() => {
        if (!orgIdParam) return;
        getOrgId({
            variables: {
                orgId: orgIdParam,
            },
        });
    }, [getOrgId, orgIdParam]);

    //get array of sites
    useEffect(() => {
        if (
            !siteIdData ||
            !siteIdData.organisation_by_pk ||
            !siteIdData.organisation_by_pk.sites
        )
            return;
        let sites = [];
        siteIdData.organisation_by_pk.sites.map((site) => {
            return sites.push(site.id);
        });
        setTotalSites(sites.toString());
    }, [siteIdData]);

    if (!orgIdParam || userDataLoading) return <div></div>;

    if (!hasPermission) return <PermissionDenied />;

    if (!totalSites) return <div></div>;

    return (
        <div
            className={
                !isMobile ? "flex flex-row absolute w-screen" : "flex flex-col"
            }
        >
            {!isMobile ? (
                <SideNavigation orgId={orgIdParam} />
            ) : (
                <MobileTopBar orgId={orgIdParam} />
            )}
            <div
                className={
                    !isMobile
                        ? "flex w-full xl:pl-72 lg:pl-56 pl-48"
                        : "w-screen flex h-screen overflow-y-auto"
                }
            >
                <Switch>
                    <PullToRefresh
                        onRefresh={() => window.location.reload()}
                        isPullable={isMobile}
                        pullDownThreshold={40}
                        maxPullDownDistance={40}
                        resistance={5}
                        pullingContent={
                            <div className="flex flex-row justify-center pt-2 text-teal-500">
                                <RefreshIcon className="w-8 h-8 animate-spin" />
                            </div>
                        }
                        refreshingContent={
                            <div className="flex flex-row justify-center pt-2 text-teal-500">
                                <RefreshIcon className="w-8 h-8 animate-spin" />
                            </div>
                        }
                    >
                        <Route exact path={["/", "/home"]}>
                            <Home orgId={orgIdParam} sites={totalSites} />
                        </Route>
                        <Route exact path="/assets">
                            <Assets
                                orgId={orgIdParam}
                                sites={totalSites}
                                user={user}
                            />
                        </Route>
                        <Route path="/issues">
                            <Issues orgId={orgIdParam} user={user} />
                        </Route>
                        <Route path="/prompts">
                            <Prompts orgId={orgIdParam} />
                        </Route>
                        <Route exact path="/requests">
                            <Requests orgId={orgIdParam} />
                        </Route>
                        <Route exact path="/settings">
                            <Settings orgId={orgIdParam} user={user} />
                        </Route>
                        <Route path="/reports">
                            <Reports orgId={orgIdParam} />
                        </Route>
                        <Route path="/goals">
                            <Goals
                                orgId={orgIdParam}
                                permissions={userPermissions}
                            />
                        </Route>
                        <Route path="/assetDetails">
                            <AssetDetails user={user} orgId={orgId} />
                        </Route>
                    </PullToRefresh>
                </Switch>
            </div>
            {!isMobile && (
                <div className="absolute right-0 mr-5 2xl:mr-20 mt-7">
                    <OrgSelector />
                </div>
            )}
        </div>
    );
};

export default Base;
