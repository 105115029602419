import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core"; //import all charts
import { BoxplotChart } from "echarts/charts";
import {
    DatasetComponent,
    TooltipComponent,
    GridComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { init } from "echarts/core";

//register required components
echarts.use([
    DatasetComponent,
    TooltipComponent,
    GridComponent,
    BoxplotChart,
    CanvasRenderer,
]);

const ProgressPlot = (props) => {
    const chartRef = useRef(null);
    const [chartObject, setChartObject] = useState(null);
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;

    useEffect(() => {
        if (!chartObject) {
            const myChart = init(chartRef.current);
            setChartObject(myChart);
        }
    }, [chartObject]);

    //setting chart
    useEffect(() => {
        if (!chartRef.current || !chartObject) return;

        let option = {
            tooltip: {
                confine: false,
                valueFormatter: (value) =>
                    typeof value !== "string" ? value : value + "°C",
            },
            xAxis: {
                scale: true,
                axisLabel: {
                    formatter: "{value}°C",
                    padding: [-6, 0, 0, 0],
                    fontSize: isMobile ? 8 : 12,
                },
            },
            yAxis: {
                type: "category",
                show: false,
            },
            series: [
                {
                    data: [props.data],
                    name: "Temperature Range",
                    type: "boxplot",
                    datasetIndex: 1,
                    itemStyle: {
                        color: {
                            type: "linear",
                            x: 1,
                            y: 0,
                            x2: 0,
                            y2: 0,
                            colorStops: [
                                {
                                    offset: 0,
                                    color:
                                        parseInt(props.data[2]) > 25
                                            ? "#dc2626"
                                            : "#facc15", // color at 0%
                                },
                                {
                                    offset: 1,
                                    color: "#bae6fd", // color at 100%
                                },
                            ],
                            global: false, // default is false
                        },
                    },
                    encode: {
                        x: [1, 2, 3, 4, 5],
                        itemName: 6,
                        label: [1],
                        tooltip: [1, 2, 3, 4, 5],
                    },
                    dimensions: [
                        null,
                        "30 Day Min",
                        "24 Hour Min",
                        "Current",
                        "24 Hour Max",
                        "30 Day Max",
                        "range",
                    ],
                },
            ],
        };
        chartObject.setOption(option);
    }, [chartObject, props.data, isMobile]);

    if (!props.data) return <div></div>;

    return <div ref={chartRef} className="h-full w-full" />;
};

export default ProgressPlot;
