import React, { Fragment } from "react";
import { Switch } from "@headlessui/react";

const Toggle = (props) => {
    return (
        <Switch
            checked={props.enabled}
            onChange={props.setEnabled}
            as={Fragment}
        >
            {({ checked }) => (
                <button
                    className={`${
                        checked ? "bg-teal-500" : "bg-gray-200"
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                    <span className="sr-only">Enable notifications</span>
                    <span
                        className={`${
                            checked ? "translate-x-6" : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </button>
            )}
        </Switch>
    );
};

export default Toggle;
