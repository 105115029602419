import React, { Fragment, useState } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import PillButton from "../../Miscellaneous/PillButton";
import classnames from "classnames";
import { ThumbUpIcon, ThumbDownIcon } from "@heroicons/react/outline";

const TabbedModal = (props) => {
  let [categories] = useState({
    MACHINE: {
      id: 1,
      title: "Indicates the operational status of the asset.",
      p1: {
        key: "ThumbUpIcon",
        value:
          "Machine is in optimal working order. There are no known \n Issues or Prompts open for the asset.",
      },
      p2: {
        key: "ThumbUpIcon",
        value:
          "Machine is operational but not in optimal state. There may be one or more Prompts open or an Issue that has only recently been resolved.",
      },
      p3: {
        key: "ThumbDownIcon",
        value:
          "Machine is not able to be used. There is one or more Issues open for the asset which is preventing normal operation.",
      },
    },
    STATUS: {
      id: 1,
      title:
        "Shows the current state of the asset, depending on the type of equipment and the information available from the asset.",
      p1: {
        key: "CAR WASH",
        value: "Shows if a wash session is in progress or not.",
      },
      p2: {
        key: "VACUUM",
        value: "Shows if a vacuum session is in progress or not.",
      },
      p3: {
        key: "DOORS",
        value:
          "Shows the current mode of the door, e.g. Auto, Locked, Exit, Open etc.",
      },
    },
    MONITORING: {
      id: 1,
      title: "Shows status of the device monitoring the asset.",
      p1: {
        key: "ThumbUpIcon",
        value: "Monitoring is operational.",
      },
      p2: {
        key: "ThumbUpIcon",
        value:
          "The device is still monitoring but something is not optimal. We are keeping a close eye on this one.",
      },
      p3: {
        key: "ThumpDownIcon",
        value:
          "There is an issue with the monitoring device, our systems have identified this and our team will be working on restoring communications",
      },
    },
  });
  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => props.close(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block sm:w-120 w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
              <Dialog.Title as="h3" className="text-lg font-medium leading">
                {props.title}
              </Dialog.Title>
              <div className="mt-4">
                <div className="w-full px-2  sm:px-0">
                  <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 bg-white rounded-xl">
                      {Object.keys(categories).map((category) => (
                        <Tab
                          key={category}
                          className={({ selected }) =>
                            classnames(
                              selected
                                ? "shadow text-teal-500 w-full py-2.5 text-sm leading-5 font-medium bg-gray-50 rounded-lg"
                                : "text-trueGray-400 w-full py-2.5 text-sm leading-5 font-medium bg-white rounded-lg"
                            )
                          }
                        >
                          {category}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                      {Object.values(categories).map((posts, idx) => (
                        <Tab.Panel
                          key={idx}
                          className={classnames(
                            "bg-white rounded-xl p-3 sm:text-base text-xs"
                          )}
                        >
                          <ul className="space-y-4">
                            <li key={posts.id}>
                              <div className="text-base">{posts.title}</div>
                            </li>
                            <li>
                              {posts.p1 && (
                                <div className="grid grid-cols-5">
                                  {" "}
                                  <div>
                                    {posts.p1.key.includes("Icon") ? (
                                      <div className="w-9 h-9 text-teal-500">
                                        <ThumbUpIcon />
                                      </div>
                                    ) : (
                                      <div className="text-trueGray-400">
                                        {posts.p1.key}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-start-2 col-span-4">
                                    {posts.p1.value}
                                  </div>
                                </div>
                              )}
                            </li>
                            <li>
                              {posts.p2 && (
                                <div className="grid grid-cols-5">
                                  <div>
                                    {posts.p2.key.includes("Icon") ? (
                                      <div className="w-9 h-9 text-black">
                                        <ThumbUpIcon />
                                      </div>
                                    ) : (
                                      <div className="text-trueGray-400">
                                        {posts.p2.key}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-start-2 col-span-4">
                                    {posts.p2.value}
                                  </div>
                                </div>
                              )}
                            </li>
                            <li>
                              {posts.p3 && (
                                <div className="grid grid-cols-5">
                                  <div>
                                    {posts.p3.key.includes("Icon") ? (
                                      <div className="w-9 h-9 text-red-600 ">
                                        <ThumbDownIcon />
                                      </div>
                                    ) : (
                                      <div className="text-trueGray-400">
                                        {posts.p3.key}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-start-2 col-span-4">
                                    {posts.p3.value}
                                  </div>
                                </div>
                              )}
                            </li>

                            <div
                              className="mt-4"
                              onClick={() => props.close(false)}
                            >
                              <PillButton
                                text="THANKS, GOT IT!"
                                textC="white"
                                bg="teal-500"
                              />
                            </div>
                          </ul>
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TabbedModal;
