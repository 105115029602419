import React, { useState } from "react";
import OrganisationSelector from "../../Input/OrganisationSelector";
import MobileSideNav from "../MobileSideNav/MobileSideNav";

const MobileTopBar = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className="bg-blueGray-800 w-screen h-16 z-40 flex flex-row justify-between">
            <div className="self-center">
                <MobileSideNav
                    open={isOpen}
                    close={setIsOpen}
                    orgId={props.orgId}
                />
            </div>
            <div className="self-center h-full">
                <OrganisationSelector />
            </div>
        </div>
    );
};

export default MobileTopBar;
