import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PillButton from "../../Miscellaneous/PillButton";
import { gql, useMutation, useQuery } from "@apollo/client";

const MUTATION_ASSIGN_SERVICE_PROVIDER = gql`
  mutation assignToServiceProvider($issueId: Int!, $providerId: Int!) {
    update_issue_by_pk(
      pk_columns: { id: $issueId }
      _set: { locally_resolvable: false, service_provider_id: $providerId }
    ) {
      id
    }
  }
`;

const QUERY_PROVIDER_NAME = gql`
  query getProvider($providerId: Int!) {
    organisation_by_pk(id: $providerId) {
      id
      name
    }
  }
`;

const ConfirmServiceProviderModal = (props) => {
  const [provider, setProvider] = useState();
  const [assignServiceProvider] = useMutation(MUTATION_ASSIGN_SERVICE_PROVIDER);

  const { data: providerData, error: providerError } = useQuery(
    QUERY_PROVIDER_NAME,
    {
      skip: !props.issue.providerId,
      variables: {
        providerId: props.issue.providerId,
      },
      onError: () => console.log(providerError),
    }
  );

  useEffect(() => {
    if (!providerData || !providerData.organisation_by_pk) return;
    setProvider(providerData.organisation_by_pk.name);
  }, [providerData]);

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => props.close(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-120  p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
              <Dialog.Title as="h3" className="text-2xl font-medium leading">
                {props.title.toUpperCase()}
              </Dialog.Title>
              <div className="mt-4 text-base text-center py-5 space-y-5">
                <div>
                  This action will notify your service provider{" "}
                  {provider && <b>{provider}</b>} to resolve the issue. Are you
                  sure ?
                </div>
                <div>
                  Select <b>CONFIRM</b> to proceed
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <div
                  className="w-1/2"
                  onClick={() => {
                    assignServiceProvider({
                      variables: {
                        issueId: props.issue.id,
                        providerId: props.issue.providerId,
                      },
                    })
                      .then((r) => {
                        props.refetch();
                        console.log("issue assigned to service provider");
                      })
                      .catch((e) => console.log(e));
                    props.close(false);
                  }}
                >
                  <PillButton text="CONFIRM" bg="teal-500" textC="white" />
                </div>
                <div className="w-1/2" onClick={() => props.close(false)}>
                  <PillButton text="CANCEL" bg="white" textC="teal-500" />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmServiceProviderModal;
