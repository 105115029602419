import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { useAsyncDebounce } from "react-table";
import PopupDialog from "../../Modals/PopupDialog";

const SearchInput = (props) => {
    const [showPopup, setShowPopup] = useState(false);

    const onChange = useAsyncDebounce((value) => {
        props.setGlobalFilter(value);
    });

    return (
        <div className="flex flex-row text-blueGray-800 pt-5 pb-2 px-5 w-full">
            <div className=" text-2xl">{props.name}</div>
            <div
                className="sm:w-7 sm:h-7 w-6 h-5 pl-1.5 self-center pt-0.5 cursor-pointer flex"
                onClick={() => setShowPopup(true)}
            >
                <InformationCircleIcon />
            </div>
            <div className="absolute z-20">
                <PopupDialog
                    open={showPopup}
                    close={setShowPopup}
                    title={"SITES AND ASSETS DATA - " + props.colTitle}
                />
            </div>
            <div className="relative pl-6 w-full">
                <span className="absolute inset-y-0 right-0 flex items-center">
                    <button
                        id="timeIcon"
                        type="submit"
                        className="focus:outline-none focus:shadow-outline cursor-default text-blueGray-800"
                        disabled
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </span>
                <input
                    className="w-full h-8 rounded-md border border-trueGray-300 mx-2 text-xs xl:text-md px-2"
                    name="search"
                    placeholder="Enter site name to search..."
                    type="text"
                    value={props.globalFilter || ""}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                />
            </div>
        </div>
    );
};

export default SearchInput;
